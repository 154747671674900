import React, { useState, useEffect } from 'react'
import NenhumDado from '../../../../components/aluno/NenhumDado';
import api from '../../../../services/api';
import { getUsuarioId } from '../../../../services/auth';
import {
    AreaDoAlunoPagesContainer,
    SubtitleAreaDoAluno,
    TitleAreaDoAluno,
    TitleSubAreaDoAluno,
    VideosDate,
    VideosDateContainer,
    VideosWrapper,
} from '../style';
import CardCertificado from './components/CardCertificado'

// DADOS FAKES PARA CERTIFICADO
const exTrilhas = [
    {
        trilha_id: 1, 
        titulo: 'Escola UX & Design',
        descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida et risus erat vitae faucibus magna. Non posuere eget vel amet interdum.',
        modulos: 6,
        videos: 42,
        avaliacoes: 9
    },
    {
        trilha_id: 2, 
        titulo: 'Escola UX & Design 2',
        descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida et risus erat vitae faucibus magna. Non posuere eget vel amet interdum.',
        modulos: 4,
        videos: 20,
        avaliacoes: 4
    },
    {
        trilha_id: 3, 
        titulo: 'Escola UX & Design 3',
        descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida et risus erat vitae faucibus magna. Non posuere eget vel amet interdum.',
        modulos: 10,
        videos: 60,
        avaliacoes: 12
    },
]

export default function MeusCertificados() {
    const [usuarioId] = useState(getUsuarioId())
    const [trilhas, setTrilhas] = useState(exTrilhas)

    useEffect(() => {
        getCertificados()
    }, [])

    const getCertificados = async () => {
        try {
           const response = await api.get(`/trilhas/getCertificadosPorAluno/${usuarioId}`)
            setTrilhas(response.data)
        } catch(error) {
        console.log("🚀 ~ file: index.js ~ line 56 ~ getCertificados ~ error", error)
        }
    }
    return (
        <AreaDoAlunoPagesContainer>
            <TitleSubAreaDoAluno>
                <TitleAreaDoAluno>Meus Certificados</TitleAreaDoAluno>
                <SubtitleAreaDoAluno>
                    Confira seus certificados de conclusão de trilha.
                </SubtitleAreaDoAluno>
            </TitleSubAreaDoAluno>
            <VideosDateContainer>
                <VideosWrapper>
                    {trilhas && trilhas.length > 0 ? trilhas.map(trilha => (
                        <CardCertificado 
                            titulo={trilha.nome}
                            descricao={trilha.conteudo}
                            modulos={trilha.qtdModulosPorTrilha}
                            videos={trilha.qtdVideosPorTrilha}
                            avaliacoes={trilha.qtdAvaliacoesPorTrilha}
                            trilhaId={trilha.trilha_id}
                            dataConclusao={trilha.dataConclusao}
                        />
                    )) : (
                        <NenhumDado text={`Você ainda não tem nenhum certificado`} />
                    )}
                </VideosWrapper>
            </VideosDateContainer>
        </AreaDoAlunoPagesContainer>
    )
}
