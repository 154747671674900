import React, { useRef, useState, useEffect } from 'react';
import ReactNetflixPlayer from './components';
import { API_HOST } from '../../../config_servers';
import { useSnackbar } from 'notistack';
import api from '../../../services/api';
import { useLocation } from 'react-router-dom'

const VideoPlayer = ({
    backButton,
    modulo,
    videoSelecionado,
    videos,
    setVideoSelecionado,
    arrayReprodutionList,
    setArrayReprodutionList,
    breadcrumbs,
    isMeuHistorico,
}) => {

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    const videoRef = useRef(null);

    const handleNextVideo = () => {
        setVideoSelecionado({
            ...videos[videoSelecionado.index + 1],
            index: videoSelecionado.index + 1,
        });

        let array = modulo?.videos.map(function (item, index2) {
            return {
                nome: item.nome_arquivo,
                id: index2 + 1,
                playing: videoSelecionado.index + 1 === index2,
            };
        });

        setArrayReprodutionList(array);
    };

    const handleSelectVideo = (id) => {
        setVideoSelecionado({
            ...videos[id - 1],
            index: id,
        });

        let array = modulo?.videos.map(function (item, index2) {
            return {
                nome: item.nome_arquivo,
                id: index2 + 1,
                playing: id - 1 === index2,
            };
        });

        setArrayReprodutionList(array);
    };

    const handleWatchedTime = async (currentTime, duration) => {

        await api.post('/modulos/updateTempoVideoAssistido', {
            usuarioId: localStorage.getItem('@treinadelta/usuario_id'),
            conteudoId: videoSelecionado.conteudo_id,
            tempoAssistido: Math.round(currentTime),
            duracao: Math.round(duration),
        });
        try {
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 125 ~ handleWatchedTime ~ error',
                error
            );
        }
    };
    return (
        <>
            <ReactNetflixPlayer // Vídeo Link - Just data is required
                id="video"
                src={`${API_HOST}/assets/conteudos/${videoSelecionado.arquivo}`}
                // src={"http://videoinvalid"}
                title={videoSelecionado.nome_arquivo}
                subTitle={modulo?.descricao}
                titleMedia={videoSelecionado.nome_arquivo}
                extraInfoMedia={`${modulo?.nome} (${modulo?.nome_trilha})`}
                videoDesc={`${modulo?.nome} (${modulo?.nome_trilha})`}
                // Text language of player
                playerLanguage="pt"
                // Action when the button X (close) is clicked
                backButton={backButton}
                // The player use the all viewport
                fullPlayer
                autoPlay={true}
                startPosition={videoSelecionado.tempo_assistido_segundos ?? 0}
                // The info of the next video action
                dataNext={{
                    title:
                        videos?.length === videoSelecionado.index + 1
                            ? 'Não existe um próximo vídeo'
                            : videos[videoSelecionado.index + 1]?.nome_arquivo,
                }}
                // The action call when the next video is clicked
                onNextClick={handleNextVideo}
                // The list reproduction data, will be render in this order
                reprodutionList={arrayReprodutionList}
                // The function call when a item in reproductionList is clicked
                onClickItemListReproduction={(id, playing) => {
                    handleSelectVideo(id);
                }}
                // The function is call when the video finish
                onEnded={() => {}}
                // The function is call when the video is playing (One time for frame)
                onTimeUpdate={(event) => {
                    if (
                        event.target.currentTime > 10 &&
                        Math.round(event.target.currentTime) % 15 === 0
                    ) {
                        handleWatchedTime(
                            event.target.currentTime,
                            event.target.duration
                        );
                    }
                }}
                ref={videoRef}
                // Enable the orverlay when player is paused
                overlayEnabled={true}
                // Enabled the auto clode controlls of player
                autoControllCloseEnabled
                // Style
                primaryColor="#2BF2CE"
                secundaryColor="#ffffff"
                fontFamily="Poppins"
                breadcrumbs={breadcrumbs}
                isMeuHistorico={isMeuHistorico}

                // subtitleMedia="/teste.vtt"
            />
        </>
    );
};

export default VideoPlayer;
