import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import "moment/locale/pt-br";
import debounce from 'lodash.debounce';

import {
  Breadcrumbs,
  Box,
  Grid,
  Link,
  Typography,
  Card,
  Tab,
  Tabs,
  Divider,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  List
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Chart from "react-apexcharts";
import api from "../../../services/api";
import SearchLog from "./components/SearchLog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  breadCrumb: {
    color: theme.secondary,
    fontSize: "11px",
    fontWeight: 500,
    letterSpacing: "0.33px",
    lineHeight: "13px",
    textTransform: "uppercase",
  },
  paper: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 420,
  },
  input: {
    flexGrow: 1,
    fontSize: "14px",
    lineHeight: "16px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    maxHeight: 450,
  },
  autocomplete: {
    backgroundColor: "white",
  },
  cardStyle: {
    border: '1px solid #B2BABB', 
    boxShadow: '0.2em 0.2em 0.4em'
  }
}));

const LogList = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialFilters] = useState({
    data_inicio:moment().subtract(1, 'months').format('YYYY-MM-DD'),
    data_final:  moment().format('YYYY-MM-DD'),
    categoria_id: '',
    trilha_id: '',
    categoria_id: '',
    grupo_id: '',
    usuario_id: '',
    modulo_id: '',
    conteudo_id: '',
    setor_usuario_id: '',
    escala_id: ''
  });
  const [filters, setFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(true);
  const [perfilUsuario, setPerfilUsuario] = useState(0);
  const [loadingTrilha, setLoadingTrilha] = useState(false);
  const [indicadores, setIndicadores] = useState([]);
  const [exibirIndicadores, setExibirIndicadores] = useState({});
  const [usuarioId] = useState(
    localStorage.getItem('@treinadelta/usuario_id')
);

  const [grafico, setGrafico] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      title: {
        text: 'Log de tempo',
        align: 'left',
        style: {
          fontFamily:  `"Roboto", "Helvetica", "Arial", sans-serif`,
        }
      },
      xaxis: {
        categories: [],
      },
      colors: ["#ca4dff", "#ffff36", "#00008B"],
    },
    series: [
      {
        name: "Tempo logado",
        data: [],
      },
    ],
  });

  const [avaliacaoLog, setAvaliacaoLog] =useState({
    series: [{
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
  }],
  options: {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    title: {
      text: 'Quantidade de avaliações',
      align: 'left',
      style: {
        fontFamily:  `"Roboto", "Helvetica", "Arial", sans-serif`,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: ['3 agosto', '5 agosto', '9 agosto', '31 agosto'],
    },

  },
})

  const [acesso, setAcesso] = useState({
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 880,
        type: "pie",
      },
      title: {
        text: 'Quantidade de acessos',
        align: 'left',
        style: {
          fontFamily:  `"Roboto", "Helvetica", "Arial", sans-serif`,
        }
      },
      labels: ["Manha", "Tarde", "Noite"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [qtdDownloads, setQtdDownloads] = useState({
    series: [1, 1, 1, 1, 1, 1, 1],
    options: {
      chart: {
        width: 880,
        type: "pie",
      },
      title: {
        text: 'Quantidade de Downloads',
        align: 'left',
        style: {
          fontFamily:  `"Roboto", "Helvetica", "Arial", sans-serif`,
        }
      },
      labels: [
        "Segunda",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sabado",
        "Domingo",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [acessoSemana, setAcessoSemana] = useState({
    series: [
      {
        name: "PRODUCT A",
        data: [44, 55, 41, 67, 22, 43, 55],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: 'Horas logada por dia de semana',
        align: 'left',
        style: {
          fontFamily:  `"Roboto", "Helvetica", "Arial", sans-serif`,
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        categories: [
          "Segunda",
          "Terça-feira",
          "Quarta-feira",
          "Quinta-feira",
          "Sexta-feira",
          "Sabado",
          "Domingo",
        ],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      
    },
  });

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUsuarioByID();
    dadosLog();
    getIndicadoresUsuario();
  }, []);

  const getUsuarioByID = async() => {
    const response = await api.get(`usuarios/${usuarioId}`)
    setPerfilUsuario(response.data.perfil_id )
    setLoadingTrilha(true)
  }
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = async (event) => {
    try {
      event.preventDefault();
      const response = await api.post("/usuarios/log/acesso", filters);
      setAcesso({ ...acesso, series: response.data.data });
    } catch (err) {
      enqueueSnackbar("Não foi possível buscar os dados", {
        variant: "error",
      });
    }
  };

  const handleSearchLog = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await api.post("usuarios/log", filters);
      setAcessoSemana({
        ...acessoSemana,
        series: response.data.dias_semana
      })

      setGrafico({
        ...grafico,
        series: response.data.tempoEmHrs,
        options: {
          ...grafico.options,
          xaxis: { ...grafico.xaxis, categories: response.data.data },
        },
      });
      setQtdDownloads({
        ...qtdDownloads, 
        series: response.data.qtdDownloadsDiasSemanas 
      })
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("Erro ao buscar logs", {
        variant: "error",
      });
    }
  };

  const dadosLog = async () => {
    try {
      const initial_state_date = {data_inicio:moment().subtract(1, 'months').format('YYYY-MM-DD'), data_final:  moment().format('YYYY-MM-DD') }
        setLoading(true);
      const response = await api.post("usuarios/log", initial_state_date);
      setAcessoSemana({
          ...acessoSemana,
          series: response.data.dias_semana
      })
      setQtdDownloads({
        ...qtdDownloads, 
        series: response.data.qtdDownloadsDiasSemanas 
      })
      setGrafico({
        ...grafico,
        series: response.data.tempoEmHrs,
        options: {
          ...grafico.options,
          xaxis: { ...grafico.xaxis, categories: response.data.data },
        },
      });

      const dadosResponse = await api.post("/usuarios/log/avaliacao", initial_state_date);
      setAvaliacaoLog({
        ...avaliacaoLog,
        series: dadosResponse.data.dados,
        options: {
          ...avaliacaoLog.options,
          xaxis: { ...avaliacaoLog.xaxis, categories: dadosResponse.data.numeros }
        },
      })
      
      const dadosAcesso = await api.post("/usuarios/log/acesso", initial_state_date);
      setAcesso({ ...acesso, series: dadosAcesso.data.data });
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("Erro ao buscar logs", {
        variant: "error",
      });
    }
  };

  const getIndicadoresUsuario = async () => {
    try {
      const response = await api.get("/indicador/usuario");
      setIndicadores(response.data);
      let exibirIndic = {}
      response.data.forEach(item => {
        exibirIndic[item.indicador_id] = item.ativado ? 1 : 0
      })
      setExibirIndicadores(exibirIndic)

      let findAtivo = response.data.find(item => item.ativado == 1)
      setValue(findAtivo ? (findAtivo.indicador_id - 1): 0)
    } catch (err) {
      enqueueSnackbar("Não foi possível buscar os indicadores", {
        variant: "error",
      });
    }
  };
  const hundleCheckIndicador = async (indicador_id, ativado) => {
    setIndicadores(indicadores.filter(item => {
        if(item.indicador_id == indicador_id){
          item.ativado = ativado ? 0 : 1
        }
        return item
      })
    )
    setExibirIndicadores({
      ...exibirIndicadores, 
      [indicador_id]: exibirIndicadores[indicador_id] ? 0 : 1
    })
    let findAtivo = indicadores.find(item => item.ativado == 1)
    setValue(findAtivo ? (findAtivo.indicador_id - 1): 0)
  }

  const updateIndicadores = async () => {
    try {
      await api.post("/indicador", exibirIndicadores);

      enqueueSnackbar("Indicadores redefinidos", { variant: 'success' });
      handleDrawerClose();
    } catch (err) {
      enqueueSnackbar("Não foi possível atualizar os indicadores", {
        variant: "error",
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Breadcrumbs
            className={classes.breadCrumb}
            aria-label="breadcrumb"
            gutterBottom
          >
            <Link color="inherit" component={RouterLink} to="/admin/">
              Home
            </Link>
            <Typography className={classes.breadCrumb}>Dashboard</Typography>
          </Breadcrumbs>
          <Typography variant="h3" style={{ paddingBottom: "15px", paddingTop: "15px"}} component="h1">
            Dashboard
          </Typography>
        </Grid>
      </Grid>
      <Divider/>
      <Grid container spacing={3} style={{ borderBottom: 1, borderColor: 'divider'}}>
        <Grid item xs={11}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {exibirIndicadores['1'] == 1 &&
              <Tab label="Horas Logadas" value={0} />
            }
            {exibirIndicadores['2'] == 1 &&
              <Tab label="Avaliação" value={1} />
            }
            {exibirIndicadores['3'] == 1 &&
              <Tab label="Quantidade Acesso" value={2} />
            }
            {exibirIndicadores['4'] == 1 &&
              <Tab label="Número de Downloads" value={3} />
            }
          </Tabs>
        </Grid>
        <Grid item xs={1}>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      
      {(value == 0 && exibirIndicadores['1'] == 1 ) && (
        <>
          <SearchLog handleSearch={handleSearchLog} setFilters={setFilters} perfilUsuario={perfilUsuario} filters={filters} />
            
          
          <Grid container spacing={3} style={{marginTop: '40px'}}>
            <Grid item xs={6}>
              <Card  className={classes.cardStyle}>
                <Chart
                  options={grafico.options}
                  series={grafico.series}
                  type="bar"
                  width={'100%'}
                  height={320}
                  style={{margin: '10px'}}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.cardStyle}>
                <Chart
                  options={acessoSemana.options}
                  series={acessoSemana.series}
                  type="bar"
                  width={'100%'}
                  height={320}
                  style={{margin: '10px'}}
                />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      {(value == 1 && exibirIndicadores['2'] == 1) &&  (
        <>
          <SearchLog handleSearch={handleSearch} setFilters={setFilters} perfilUsuario={perfilUsuario} filters={filters} />
          <Grid container spacing={3} style={{marginTop: '40px'}}>
              <Grid item xs={6}>
                <Card className={classes.cardStyle}>
                  <Chart
                    options={avaliacaoLog.options}
                    series={avaliacaoLog.series}
                    type="line"
                    width={'100%'}
                    height={320}
                    style={{margin: '10px'}}
                  />
                </Card>
              </Grid>
          </Grid>
        </>
      )}
      {(value == 2 && exibirIndicadores['3'] == 1) && (
        <>
          <SearchLog handleSearch={handleSearch} setFilters={setFilters} perfilUsuario={perfilUsuario} filters={filters} />
          <Grid container spacing={3} style={{marginTop: '40px'}}>
              <Grid item xs={6}>
                <Card className={classes.cardStyle}>
                  <Chart
                    options={acesso.options}
                    series={acesso.series}
                    type="pie"
                    width={'100%'}
                    height={320}
                    style={{margin: '10px'}}
                  />
                </Card>
              </Grid>
          </Grid>
        
        </>
      )}
      {(value == 3 && exibirIndicadores['4'] == 1) &&  (
        <>
          <SearchLog handleSearch={handleSearchLog} setFilters={setFilters} perfilUsuario={perfilUsuario} filters={filters} />
          <Grid container spacing={3} style={{marginTop: '40px'}}>
            <Grid item xs={6}>
              <Card className={classes.cardStyle}>
                <Chart
                  options={qtdDownloads.options}
                  series={qtdDownloads.series}
                  type="pie"
                  width={'100%'}
                  height={320}
                  style={{margin: '10px'}}
                />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      {exibirIndicadores['1'] == 0 && exibirIndicadores['2'] == 0 && exibirIndicadores['3'] == 0 &&
        <>
        
        <Typography variant="h5" style={{textAlign: 'center', justifyContent: 'center', marginTop: '10%'}} >
          {/* <Divider style ={{padding: '1px', backgroundColor: '#333549', width: '5%', marginLeft: '37%'}}/> */}
          Selecione os indicadores que deseja visualizar.
        </Typography>
        </>
      }

      <Drawer
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div style={{marginTop:'30%'}}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem key={'titulo'}>
            <ListItemText 
              primary={
                <Typography style={{textAlign: 'center', fontWeight:'bold'}}>Indicadores</Typography>} 
            />
          </ListItem>
          <Divider />
          {indicadores.map((item, index) => (
            <ListItem onClick = {() => hundleCheckIndicador(item.indicador_id, item.ativado)} button key={item.indicador_id} style= {{backgroundColor: item.ativado ? '#daffd6' : 'white'}}>
              <ListItemText primary={item.nome} />
            </ListItem>
          ))}
          <Divider />
          <ListItem onClick={updateIndicadores} button key={'titulo'} style={{textAlign: 'center', backgroundColor: '#333549', marginTop:'30px'}}>
            <ListItemText 
              primary={
                <Typography style={{color: 'white', fontWeight:'bold'}}>Salvar</Typography>}
            />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default LogList;
