/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import CategoryIcon from '@material-ui/icons/Category';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Backdrop from '@material-ui/core/Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import debounce from 'lodash.debounce';
import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Link,
    IconButton,
    Paper,
    Typography,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Select,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    TextField,
} from '@material-ui/core';
import { PrimaryChip, ErrorChip, SuccessChip } from '../../../helpers/helper';

import { hasAccess } from '../../../services/auth';

import TransferList from '../../../components/admin/TransferList';

import api from '../../../services/api';

import { getUsuarioId, getParceiroId } from '../../../services/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    paper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    space: {
        width: '27%',
    },
    autocomplete: {
        backgroundColor: 'white',
        // height: '45.625px'
    },
}));

const UsuariosList = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const acesso = hasAccess('Usuario', 'Usuarios');
    const acessoAdmin = hasAccess('Configurações', 'Parceiros');
    console.log(
        '🚀 ~ file: UsuarioList.js ~ line 109 ~ UsuariosList ~ acesso',
        acesso
    );
    const [usuarios, setUsuarios] = useState([]);
    const [perfis, setPerfis] = useState([]);
    const [initialFilters] = useState({
        nome: '',
        email: '',
        perfil_id: '',
        parceiro_id: '',
    });

    const [filters, setFilters] = useState(initialFilters);
    const [loading, setLoading] = useState(false);
    const [idSelected, setIdSelected] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [openGerenciarGrupos, setOpenGerenciarGrupos] = useState(false);
    const [gruposChecked, setGruposChecked] = useState({});
    const [qtdGruposSelecionados, setQtdGruposSelecionados] = useState(0);
    const [loading2, setLoading2] = useState(false);
    const [grupos, setGrupos] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState({});
    const [usuariosFilter, setUsuariosFilter] = useState([]);
    const [openGerenciarCategorias, setOpenGerenciarCategorias] =
        useState(false);
    const [categoriasIdsObrigatorias, setCategoriasIdsObrigatorias] = useState(
        []
    );
    const [categoriasIdsNaoObrigatorias, setCategoriasIdsNaoObrigatorias] =
        useState([]);
    const [nomeCategorias, setNomeCategorias] = useState({});
    const open = Boolean(anchorEl);
    const [isOpenExcluirUsuario, setIsOpenExcluirUsuario] = useState(false);
    const [textFilterEmail, setTextFilterEmail] = useState('');
    const [textFilterNome, setTextFilterNome] = useState('');
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [parceiros, setParceiros] = useState([]);

    useEffect(() => {
        getParceiro();
        getPerfis();
        getInputUsuarios();
    }, []);

    useEffect(() => {
        getUsuarios(filters, currentPage, perPage);
        getGrupos();
    }, [currentPage, perPage]);

    useEffect(() => {
        getUsuarios(filters, currentPage, perPage);
    }, [filters]);

    const getPerfis = async () => {
        try {
            const response = await api.get('/perfis');
            setPerfis(response.data);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };
    const getParceiro = async () => {
        try {
            let usuario_id = getUsuarioId();
            setLoading(true);

            const response = await api.get(`/parceiros/usuario/parceiro`);
            setParceiros(response.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getInputUsuarios = async () => {
        try {
            const response = await api.get('/usuarios');
            const itens = response.data;
            setUsuariosFilter(itens.filter((item) => item.nome));
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getUsuarios = async (filters = [], currentPage = 0, perPage = 10) => {
        try {
            setLoading(true);
            const response = await api.get('/usuarios/filter', {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setUsuarios(response.data.data);
            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getUsuarios(filters);
    };

    const openMenuActions = (event, usuario) => {
        setAnchorEl(event.currentTarget);
        setUsuarioSelecionado(usuario);
        setIdSelected(usuario.usuario_id);
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const handleOpenGerenciarGrupos = async () => {
        closeMenuActions();
        setOpenGerenciarGrupos(true);
        await getGruposPorUsuario();
    };

    const handleCloseGerenciarGrupos = () => {
        setOpenGerenciarGrupos(false);
        setGruposChecked({});
        setQtdGruposSelecionados(0);
    };

    const getGrupos = async () => {
        try {
            setLoading(true);
            const response = await api.get('/grupos');
            setGrupos(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getGruposPorUsuario = async () => {
        try {
            setLoading2(true);
            const response = (await api.get(`/usuarios/grupos/${idSelected}`))
                .data;
            setGruposChecked(response);

            for (const column in response) {
                if (response[column] === true)
                    setQtdGruposSelecionados((prevState) => prevState + 1);
            }

            setLoading2(false);
        } catch (error) {
            setLoading2(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleGerenciarGrupos = async () => {
        try {
            handleCloseGerenciarGrupos();
            setLoading(true);
            await api.post(`/usuarios/grupos/${idSelected}`, { gruposChecked });
            setLoading(false);

            enqueueSnackbar('Grupos selecionados com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleChangeGruposChecked = async (event) => {
        setGruposChecked({
            ...gruposChecked,
            [event.target.name]: event.target.checked,
        });

        setQtdGruposSelecionados((prevState) =>
            event.target.checked === true ? prevState + 1 : prevState - 1
        );
    };

    const HandleCheckAllGrupos = (event) => {
        let update = { ...gruposChecked };
        grupos.forEach((item) => {
            update = { ...update, [item.grupo_id]: event.target.checked };
        });

        setQtdGruposSelecionados(() =>
            event.target.checked === true ? grupos.length : 0
        );
        setGruposChecked({ ...update });
    };

    const handleOpenGerenciarCategorias = async () => {
        closeMenuActions();
        setOpenGerenciarCategorias(true);
        await getCategoriasPorUsuario();
    };

    const handleCloseGerenciarCategorias = () => {
        setOpenGerenciarCategorias(false);
    };

    const getCategoriasPorUsuario = async () => {
        try {
            setLoading2(true);
            const response = (
                await api.get(`/usuarios/categorias/${idSelected}`)
            ).data;
            setCategoriasIdsObrigatorias(response.categoriasIdsObrigatorias);
            setCategoriasIdsNaoObrigatorias(
                response.categoriasIdsNaoObrigatorias
            );
            console.log(
                '🚀 ~ file: UsuarioList.js ~ line 328 ~ getCategoriasPorUsuario ~ response',
                response
            );
            setNomeCategorias(response.nomeCategorias);
            setLoading2(false);
        } catch (error) {
            setLoading2(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleGerenciarCategorias = async () => {
        try {
            await api.post(`/usuarios/categorias/${idSelected}`, {
                categoriasIdsObrigatorias,
                categoriasIdsNaoObrigatorias,
            });
            setOpenGerenciarCategorias(false);

            enqueueSnackbar('Categorias atualizadas com sucesso!', {
                variant: 'success',
            });
            // handleCloseGerenciarCategorias();
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getUsuarios();
    };

    const handleOpenExcluirUsuario = async () => {
        setIsOpenExcluirUsuario(true);
        setAnchorEl(null);
    };

    const handleCloseExcluirUsuario = async () => {
        setIsOpenExcluirUsuario(false);
    };

    const handleExcluirUsuario = async () => {
        try {
            setAnchorEl(null);
            handleCloseExcluirUsuario();
            await api.delete(`/usuarios/${idSelected}`);
            await getUsuarios(filters, currentPage, perPage);
            enqueueSnackbar('Usuário removido com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };
    console.log('filter', filters);
    const debouncedSave = useRef(debounce(nextValue => setFilters({
        ...filters,
        [nextValue.name]: nextValue.value,
    }), 1000))
		.current;

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Usuários
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Usuários
                    </Typography>
                </Grid>
                {acesso.adicionar && (
                    <Grid
                        container
                        xs={6}
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            component={RouterLink}
                            to="/admin/usuarios/novo"
                            color="primary"
                            variant="contained"
                        >
                            Novo Usuário
                        </Button>
                    </Grid>
                )}
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={3} sm={2}>
                            <TextField
                                placeholder="Nome"
                                onChange={(e) => {
                                    setTextFilterNome(e.target.value)
                                    debouncedSave(e.target);
                                }}
                                name= 'nome'
                                value={textFilterNome}
                                disableUnderline
                                fullWidth
                                displayEmpty
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                            <Paper className={classes.autocomplete}>
                                <TextField
                                    placeholder="E-mail"
                                    onChange={(e) => {
                                        setTextFilterEmail(e.target.value)
                                        debouncedSave(e.target);
                                    }}
                                    name= 'email'
                                    value={textFilterEmail}
                                    disableUnderline
                                    fullWidth
                                    displayEmpty
                                    variant="outlined"
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Paper className={classes.autocomplete}>
                                <Select
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            perfil_id: e.target.value,
                                        })
                                    }
                                    value={filters.perfil_id}
                                    disableUnderline
                                    fullWidth
                                    displayEmpty
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <span style={{ color: '#a4a9ab' }}>
                                            Perfil
                                        </span>
                                    </MenuItem>
                                    {perfis.map((perfil) => (
                                        <MenuItem value={perfil.perfil_id}>
                                            {perfil.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </Grid>
                        {acessoAdmin.visualizar ??
                            <Grid item xs={4} sm={2}>
                                <Paper className={classes.autocomplete}>
                                    <Select
                                        onChange={(e) =>
                                            setFilters({
                                                ...filters,
                                                parceiro_id: e.target.value,
                                            })
                                        }
                                        value={filters.parceiro_id}
                                        disableUnderline
                                        fullWidth
                                        displayEmpty
                                        variant="outlined"
                                    >
                                        <MenuItem value="">
                                            <span style={{ color: '#a4a9ab' }}>
                                                Parceiros
                                            </span>
                                        </MenuItem>
                                        {parceiros.map((parceiro) => (
                                            <MenuItem value={parceiro.parceiro_id}>
                                                {parceiro.nome}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Paper>
                            </Grid>
                        }
                        <Grid item xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{ marginLeft: '2%' }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell>
                                                        E-mail
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Perfil
                                                    </TableCell>
                                                    { acessoAdmin.visualizar ??
                                                        <TableCell align="center">
                                                            Parceiro
                                                        </TableCell>
                                                    }
                                                    <TableCell align="center">
                                                        Ativo
                                                    </TableCell>
                                                    <TableCell align="right" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {usuarios.length ===
                                                        0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {usuarios.map(
                                                                    (
                                                                        usuario
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                usuario.tipo_carroceria_id
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                className={
                                                                                    classes.space
                                                                                }
                                                                            >
                                                                                {
                                                                                    usuario.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell
                                                                                className={
                                                                                    classes.space
                                                                                }
                                                                            >
                                                                                {
                                                                                    usuario.email
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <PrimaryChip
                                                                                    texto={
                                                                                        usuario.perfil
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                            {acessoAdmin.visualizar ??
                                                                                <TableCell align="center">
                                                                                    <PrimaryChip
                                                                                        texto={
                                                                                            usuario.parceiro
                                                                                        }
                                                                                    />
                                                                                </TableCell>
                                                                            }
                                                                            <TableCell align="center">
                                                                                {usuario.ativo ? (
                                                                                    <SuccessChip texto="Ativo" />
                                                                                ) : (
                                                                                    <ErrorChip texto="Inativo" />
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {(acesso.editar ||
                                                                                    acesso[
                                                                                        'gerenciar grupos'
                                                                                    ] ||
                                                                                    acesso[
                                                                                        'gerenciar categorias'
                                                                                    ]) && (
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            openMenuActions(
                                                                                                e,
                                                                                                usuario
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <SettingsIcon color="secondary" />
                                                                                    </IconButton>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={closeMenuActions}
            >
                {acesso.editar && (
                    <MenuItem
                        component={RouterLink}
                        to={`/admin/usuarios/${idSelected}`}
                    >
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <EditIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Editar usuário" />
                    </MenuItem>
                )}
                {acesso.excluir && (
                    <MenuItem onClick={handleOpenExcluirUsuario}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <DeleteIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Excluir usuário" />
                    </MenuItem>
                )}
                {acesso['gerenciar categorias'] && (
                    <MenuItem onClick={handleOpenGerenciarCategorias}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <CategoryIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Gerenciar categorias" />
                    </MenuItem>
                )}
                {acesso['gerenciar grupos'] && (
                    <MenuItem onClick={handleOpenGerenciarGrupos}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <GroupIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Gerenciar grupos" />
                    </MenuItem>
                )}
            </Menu>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="sm"
                open={openGerenciarGrupos}
                onClose={handleCloseGerenciarGrupos}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Gerenciar grupos
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Selecione abaixo os grupos que o usuário{' '}
                        <b>{usuarioSelecionado.nome ?? ''}</b> pertence.
                        <p style={{ marginTop: 8 }}>
                            Inserido em{' '}
                            {qtdGruposSelecionados === 0
                                ? 'nenhum grupo'
                                : qtdGruposSelecionados === 1
                                ? `${qtdGruposSelecionados} grupo`
                                : `${qtdGruposSelecionados} grupos`}
                        </p>
                    </DialogContentText>

                    <Backdrop className={classes.backdrop} open={loading2}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Card>
                        <CardContent style={{ padding: 0 }}>
                            <TableContainer className={classes.container}>
                                <PerfectScrollbar>
                                    <Table size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{ width: '5%' }}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={
                                                            HandleCheckAllGrupos
                                                        }
                                                        inputProps={{
                                                            'aria-label':
                                                                'secondary checkbox',
                                                        }}
                                                        checked={
                                                            qtdGruposSelecionados ===
                                                            grupos.length
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>Grupos</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loading ? (
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={2}
                                                    >
                                                        <CircularProgress />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                <>
                                                    {grupos.map((grupo) => (
                                                        <TableRow
                                                            hover
                                                            key={grupo.grupo_id}
                                                        >
                                                            <TableCell>
                                                                <Checkbox
                                                                    value={
                                                                        grupo.grupo_id
                                                                    }
                                                                    name={
                                                                        grupo.grupo_id
                                                                    }
                                                                    checked={
                                                                        !!gruposChecked[
                                                                            grupo
                                                                                .grupo_id
                                                                        ]
                                                                    }
                                                                    onChange={
                                                                        handleChangeGruposChecked
                                                                    }
                                                                    color="primary"
                                                                    inputProps={{
                                                                        'aria-label':
                                                                            'secondary checkbox',
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {grupo.nome}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </PerfectScrollbar>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button
                        onClick={handleCloseGerenciarGrupos}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleGerenciarGrupos} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="sm"
                open={openGerenciarCategorias}
                onClose={handleCloseGerenciarCategorias}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Gerenciar categorias
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Selecione abaixo as categorias obrigatórias / não
                        obrigatórias
                    </DialogContentText>

                    {loading2 ? (
                        <Backdrop className={classes.backdrop} open={loading2}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (
                        <TransferList
                            left={categoriasIdsNaoObrigatorias}
                            setLeft={setCategoriasIdsNaoObrigatorias}
                            right={categoriasIdsObrigatorias}
                            setRight={setCategoriasIdsObrigatorias}
                            nomeCategorias={nomeCategorias}
                        />
                    )}
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button
                        onClick={handleCloseGerenciarCategorias}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleGerenciarCategorias} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenExcluirUsuario}
                onClose={handleCloseExcluirUsuario}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Exclusão de Usuário
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O usuário será excluído, deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExcluirUsuario} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleExcluirUsuario} color="primary">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UsuariosList;
