import {
    Box,
    Grid,
    Button,
    Tooltip,
    Card,
    CardContent,
    Typography,
    CardMedia,
    CardActions,
    Divider,
    IconButton,
} from '@material-ui/core';
import { PrimaryChip } from '../../../../helpers/helper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { API_HOST } from '../../../../config_servers';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import formatBytes from '../../../../helpers/formatBytes';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        padding: '30px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#BFBFBF',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        width: '95%',
        transition: 'border .24s ease-in-out',
        margin: '0 auto',
    },
    media: {
        height: 80,
    },
    card: {
        borderRadius: 0,
        borderColor: '#BFBFBF',
        color: '#fafafa',
    },
    divider: {
        background: '#BFBFBF',
    },
    formControl: {
        minWidth: 210,
        maxWidth: 300,
    },
    chip: {
        margin: 2,
    },
}));

const CardFiles = (props) => {
    const {file, setFiles, files, i, handleOpenVideo, openMenu, isEdit} = props;
    const classes = useStyles();

    const handleSubirPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index > 0) {
            const arrayPrevIndex = newFiles[index - 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao - 1;
            newFiles[index - 1].posicao = newFiles[index - 1].posicao + 1;

            newFiles[index] = arrayPrevIndex;
            newFiles[index - 1] = file;
        }

        setFiles([...newFiles]);
    };
    const handleDescerPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index < files.length - 1) {
            const arrayNextIndex = newFiles[index + 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao + 1;
            newFiles[index + 1].posicao = newFiles[index + 1].posicao - 1;

            newFiles[index] = arrayNextIndex;
            newFiles[index + 1] = file;
        }

        setFiles([...newFiles]);
    };

    const removeFile = (file, i) => () => {
        const newFiles = [...files];
        const id = newFiles.indexOf(file);
        newFiles.splice(id, 1);
        setFiles([...newFiles]);
    };
    return(<>
        <Grid
            item
            sm={4}
            style={{ flexDirection: 'row', display: 'flex'}}
            xs={12}
        >
            {i != '0' ?
                <div style={{display: 'flex', justifyContent: 'space-between',flexDirection: 'column'}}>
                    <div>
                        <PrimaryChip
                            texto={
                                i+1
                            }
                            size="medium"
                        />
                    </div>
                    <div>
                        <Tooltip   Tooltip
                            title="Mudar posição"
                            placement="top"
                            arrow
                            style={{height: 30, marginTop: -35}}
                        >
                            <IconButton

                                onClick={() =>
                                    handleSubirPosicaoDoDocumento(
                                        file,
                                        i
                                    )
                                }
                            >
                                <ChevronLeftIcon 
                            />
                            </IconButton >
                        </Tooltip>
                    </div>
                    
                    <div></div>
                </div>
                : 
                <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 8}}>
                    <div></div>
                    <PrimaryChip
                        texto={
                            i+1
                        }
                        size="medium"
                    />
                    <div></div>

                </div>
            }
            <Box
                border={1}
                borderColor="#DCDCDC"
            >
                <Card
                    classes={{
                        root: classes.card,
                    }}
                >
                    <CardMedia
                        className={
                            classes.media
                        }
                        image={`${API_HOST}/assets/conteudos/${file.arquivo}`}
                        title=""
                    />

                    <CardContent>
                        <Grid
                            container
                        >
                           
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end"
                            >
                            <Grid
                                   justify="space-between"
                                   style={{ width: "100%", justifyContent: 'space-between', flexDirection: 'row', display: 'flex' , alignItems: 'center'}}
                                   >
                                <div>
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    gutterBottom={
                                        false
                                    }
                                >
                                    {
                                        file.nome_alterado ? file.nome_alterado : file.name
                                    }
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    component="h6"
                                    gutterBottom={
                                        false
                                    }
                                />
                                </div>
                                <div>

                                    <Tooltip
                                        title="Remover conteúdo"
                                        placement="top"
                                        arrow
                                    >
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={removeFile(
                                                file,
                                                i
                                                )}
                                                >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                            
                                    {isEdit &&
                                        <Tooltip
                                            title="Editar arquivo"
                                            placement="top"
                                            arrow
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) =>
                                                    openMenu(
                                                        e,
                                                        file,
                                                        i
                                                    )
                                                }
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            </Grid>

                            </Grid>
                            <Grid
                                item
                                xs={
                                    12
                                }
                            >
                                <Typography
                                    variant="subtitle2"
                                    component="h6"
                                    gutterBottom={
                                        false
                                    }
                                >
                                    {formatBytes(
                                        file.size
                                    )}
                                    <Typography
                                        variant="subtitle2"
                                        component="h6"
                                    >
                                        {
                                            file.descricao
                                        }
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider
                        classes={{
                            root: classes.divider,
                        }}
                    />

                    <CardActions
                        style={{
                            justifyContent:
                                'center',
                        }}
                    >
                        <Button
                            color="default"
                            startIcon={
                                <VisibilityIcon />
                            }
                            onClick={() =>
                                handleOpenVideo(
                                    file
                                )
                            }
                        >
                            Visualizar
                        </Button>
                    </CardActions>
                </Card>
            </Box>
            <Tooltip
                title="Mudar posição"
                placement="top"
                arrow
            >
                <IconButton
                    onClick={() =>
                        handleDescerPosicaoDoDocumento(
                            file,
                            i
                        )
                    }
                >
                    <ChevronRightIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    </>)
}
export default CardFiles;