import styled from "styled-components";

export const InfoAvaliacaoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    border-radius: 10px;
    background: #0000001a;
    padding: 45px 40px;
    gap: 15px;
    transition: all 0.3s ease;
    max-width: 500px;
    & div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
    }
    & div:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
    }
    & div:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
    }
    & div:nth-child(4) {
        grid-area: 3 / 1 / 4 / 3;
    }
    & button:nth-child(5) {
        grid-area: 4 / 1 / 5 / 3;
    }
    & p:nth-child(6) {
        grid-area: 5 / 1 / 6 / 3;
    }

    @media(max-width: 1200px) {
        max-width: unset;
        transform: translate(0) !important;
    }
`;
