import React, { useState } from 'react';
import {
    Info,
    InfoLabel,
    InfoText,
} from '../../../Trilha/components/InfoTrilha/style';
import { useSnackbar } from 'notistack';

import Button from '../../../../../components/aluno/Button';
import {
    ButtonsContainer,
    ModalContainer,
    ModalTitle,
    SubTitleModal,
    TitleModal,
} from '../../../../../components/aluno/Modal/components/ModalCommentary/style';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../../services/api';
import { GridInfoModalInicioAvaliacao } from './style';
import moment from 'moment';

const ModalAvaliacao = ({
    handleModal,
    openModal,
    modulo,
    dadosAvaliacaoAluno,
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(openModal);
    const { moduloId } = useParams();
    const history = useHistory();

    const toggleOpen = () => {
        if (openModal === false) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'overlay';
        }
    };

    const handleInicioAvaliacao = async () => {
        try {
            const response = await api.post(`/avaliacoes/iniciarAvaliacao/`, {
                moduloId,
                usuarioId: localStorage.getItem('@treinadelta/usuario_id'),
            });
            const identificadorAvaliacao = response.data;

            history.push(`/avaliacao/${identificadorAvaliacao}`);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
        }
        document.body.style.overflowY = 'overlay';
    };

    return (
        <ModalContainer iniciarTentativa>
            <ModalTitle>
                <TitleModal>
                    Iniciar avaliação - {dadosAvaliacaoAluno.nome}
                </TitleModal>
            </ModalTitle>
            <SubTitleModal>
                <Info modal>
                    <InfoText>
                        Realmente deseja iniciar a tentativa da avaliação?
                    </InfoText>
                </Info>
            </SubTitleModal>

            <GridInfoModalInicioAvaliacao iniciarTentativa>
                <Info modal>
                    <InfoLabel>Módulo</InfoLabel>
                    <InfoText> {modulo.nome}</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Duração</InfoLabel>
                    <InfoText>
                        {moment
                            .duration(
                                dadosAvaliacaoAluno.segundosDuracaoAvaliacao,
                                'seconds'
                            )
                            .humanize()}
                    </InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Tentativa</InfoLabel>
                    <InfoText>
                        {dadosAvaliacaoAluno.numeroTentativa}/
                        {dadosAvaliacaoAluno.tentativas}
                    </InfoText>
                </Info>
            </GridInfoModalInicioAvaliacao>
            <ButtonsContainer>
                <Button
                    cancel
                    onClick={() => {
                        handleModal(false);
                        toggleOpen();
                    }}
                >
                    Cancelar
                </Button>
                <Button big onClick={handleInicioAvaliacao}>
                    Confirmar e iniciar
                </Button>
            </ButtonsContainer>
        </ModalContainer>
    );
};

export default ModalAvaliacao;
