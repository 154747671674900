import React, { useState } from 'react';
import Button from '../../../../../components/aluno/Button';
import {
    AdditionalInfo,
    GridInfo,
    Info,
    InfoLabel,
    InfoText,
    InfoTrilhaContainer,
    TagInfoTrilhaAvaliacao,
} from './style';
import Tag from '../../../../../components/aluno/Tag';
import {
    IconCheck,
    IconCross,
} from '../../../../../components/aluno/Icons/Icons';
import { useHistory, Link } from 'react-router-dom'
import Modal from '../../../../../components/aluno/Modal';
import ModalInicioAvaliacao from '../../components/InicioAvaliacaoModal';
import moment from 'moment';

const InfoTrilhaAvaliacao = ({
    dataAvaliacao,
    tentativas,
    numeroTentativa,
    tempoDeResposta,
    descricaoAvaliacao,
    acertos,
    numeroQuestoes,
    emCorrecao,
    modulo,
    dadosAvaliacaoAluno,
    possuiCertificado,
}) => {
    const media = (acertos) / numeroQuestoes >= dadosAvaliacaoAluno.porcentagem_acerto;
    const history = useHistory()
    const [openModalInicioAvaliacao, setOpenModalInicioAvaliacao] =
        useState(false);

    const toggleOpenModalInicioAvaliacao = () => {
        if (openModalInicioAvaliacao === false) {
            document.body.style.overflowY = 'hidden';
            setOpenModalInicioAvaliacao(true);
        } else {
            document.body.style.overflowY = 'overlay';
            setOpenModalInicioAvaliacao(false);
        }
    };

    const handleModalInicioAvaliacao = (value) => {
        setOpenModalInicioAvaliacao(value);
    };

    return (
        <>
            <InfoTrilhaContainer>
                <TagInfoTrilhaAvaliacao>
                    {!emCorrecao && (
                        <Tag aprovado={media}>
                            {media
                                ? 'APROVADO'
                                : numeroTentativa > tentativas
                                ? 'REPROVADO'
                                : 'AVALIADO'}
                        </Tag>
                    )}
                </TagInfoTrilhaAvaliacao>
                <GridInfo emCorrecao={emCorrecao}>
                    <Info>
                        <InfoLabel>Data da Avaliação</InfoLabel>
                        <InfoText>
                            {moment(dataAvaliacao)
                                .subtract(3, 'hours')
                                .format('LLL')}
                        </InfoText>
                    </Info>
                    <Info>
                        <InfoLabel>Tentativa</InfoLabel>
                        <InfoText>
                            {numeroTentativa - 1}ª de {tentativas}
                        </InfoText>
                    </Info>
                    <Info>
                        <InfoLabel>Tempo de Resposta</InfoLabel>
                        <InfoText>{tempoDeResposta}</InfoText>
                    </Info>
                    {emCorrecao ? (
                        ''
                    ) : (
                        <Info>
                            <InfoLabel>Acertos</InfoLabel>
                            <InfoText aprovado={media} cardAvaliacao>
                                {acertos} de {numeroQuestoes}{' '}
                                {media ? <IconCheck /> : <IconCross />}
                            </InfoText>
                        </Info>
                    )}
                </GridInfo>
                {!media && 
                    <Button
                        onClick={() => {
                            if (!media && !emCorrecao && !((numeroTentativa-1) > tentativas)) {
                                toggleOpenModalInicioAvaliacao();
                            }
                            
                            if (media) {
                                history.push(`/certificado/${modulo.trilha_id}`)
                            }
                        }}
                        disabled={
                            !emCorrecao && !media && numeroTentativa > tentativas
                        }
                        aprovado={media}
                        big={media}
                        disabled={emCorrecao}
                    >

                        {emCorrecao
                            ? 'Aguarde a correção'
                            : media 
                            ? 'Baixar certificado'
                            : numeroTentativa > tentativas
                            ? 'Número de tentativas excedidas'
                            : `Iniciar Avaliação (${numeroTentativa}ª Tentativa)`}
                    </Button>
                }

                    {possuiCertificado && 
                        <Button
                            onClick={() => {
                                if (media) {
                                    history.push(`/certificado/${modulo.trilha_id}`)
                                }
                            }}
                            aprovado={media}
                            big={media}
                        >   
                           Baixar certificado
                        </Button>
                    }

                <AdditionalInfo>{descricaoAvaliacao}</AdditionalInfo>

                <Modal
                    openModal={openModalInicioAvaliacao}
                    handleModal={handleModalInicioAvaliacao}
                >
                    <ModalInicioAvaliacao
                        modulo={modulo}
                        handleModal={handleModalInicioAvaliacao}
                        openModal={openModalInicioAvaliacao}
                        dadosAvaliacaoAluno={dadosAvaliacaoAluno}
                    />
                </Modal>
            </InfoTrilhaContainer>
        </>
    );
};

export default InfoTrilhaAvaliacao;
