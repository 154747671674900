/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import TitlePage from '../../../components/aluno/TitlePage';
import { MaskedBG } from '../MinhasTrilhas/style';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoopCircleLoading  } from 'react-loadingg';
 
import {
    AttachmentsComments,
    AttachmentsContainer,
    GridVideos,
    SectionTitle,
    TitleContainer,
    TrilhaContainer,
    VideosContainer,
    AttachmentsGrid,
    CommentsContainer,
    LinkComentar,
    CommentsWrapper,
} from './style';
import minhaTrilhaBg from '../../../assets/img/minhatrilhabg.jpg';
import InfoTrilha from './components/InfoTrilha';
import {
    IconCam,
    IconClip,
    IconComment,
    SmileyFace,
} from '../../../components/aluno/Icons/Icons';
import {
    DefaultSmileyFace,
    ImgUserContainer,
} from '../../../components/aluno/Menu/style';
import CardVideo from './components/CardVideo';
import api from '../../../services/api';
import Attachments from './components/Attachments';
import Comment from './components/Comment';
import Modal from '../../../components/aluno/Modal';
import ModalCommentary from '../../../components/aluno/Modal/components/ModalCommentary';
import { useLocation } from 'react-router-dom';
import { API_HOST } from '../../../config_servers';
import moment from 'moment';
import InfoTrilhaAvaliacao from './components/InfoTrilhaAvaliacao';
import VideoPlayer from '../../../components/aluno/VideoPlayer';

const Trilha = () => {
    const [openModal, setOpenModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { moduloId, trilhaId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const [modulo, setModulo] = useState({
        anexos: [],
        videos: [],
        comentarios: [],
    });
    const [dadosAvaliacaoAluno, setDadosAvaliacaoAluno] = useState({});
    const [count, setCount] = useState(0);
    const [avaliacaoId, setAvaliacaoId] = useState();
    const [videoSelecionado, setVideoSelecionado] = useState({});
    const [loading, setLoading] = useState(true)
    const [arrayReprodutionList, setArrayReprodutionList] = useState([]);
    const toggleOpen = () => {
        if (openModal === false) {
            document.body.style.overflowY = 'hidden';
            setOpenModal(true);
        } else {
            document.body.style.overflowY = 'overlay';
            setOpenModal(false);
        }
    };
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const handleModal = (value) => {
        setOpenModal(value);
    };

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        getTrilhasEModulos();
    }, []);

    useEffect(() => {
        getDadosAvaliacao(avaliacaoId, false);
    }, [count]);

    const getDadosAvaliacao = async (avaliacao_id, firstRun = false) => {
        try {
            if (!firstRun) {
                await delay(15000);
            }

            let data = {
                usuario_id: localStorage.getItem('@treinadelta/usuario_id'),
                avaliacao_id: avaliacao_id,
                trilha_id: trilhaId
            };

            if (avaliacao_id) {
                const response = await api.get(
                    '/avaliacoes/getDadosAvaliacao/aluno/',
                    {
                        params: data,
                    }
                );

                setDadosAvaliacaoAluno(response.data);

                if (response.data.tempoExpirado) {
                    await api.post(
                        '/avaliacoes/enviarAvaliacao',
                        response.data.dadosEnviarAvaliacao
                    );

                    enqueueSnackbar(
                        <h4>
                            {
                                'O tempo da sua avaliação expirou e ela foi enviada automaticamente.'
                            }
                        </h4>,
                        {
                            variant: 'warning',
                        }
                    );
                    const response2 = await api.get(
                        '/avaliacoes/getDadosAvaliacao/aluno/',
                        {
                            params: data,
                        }
                    );
                    setDadosAvaliacaoAluno(response2.data);
                }
            }

            setCount((state) => state + 1);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
        }
    };

    const getTrilhasEModulos = async () => {
        try {
            const response = await api.get(`/modulos/${moduloId}/aluno`);
            setModulo(response.data.modulo);

            setAvaliacaoId(response.data.modulo.avaliacao_id);
            await getDadosAvaliacao(response.data.modulo.avaliacao_id, true);
            setLoading(false)
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 28 ~ getTrilhasEModulos ~ error',
                error
            );
        }
    };

    const verificador = (dados) => {
        const results = dados.split('.');
        return results[0];
    };

    return (
        <>
            <MaskedBG
                defaultImage={minhaTrilhaBg}
                image={`${API_HOST}/assets/conteudos/${modulo.imagem}`}
            />
            <TrilhaContainer>
            {loading ? <LoopCircleLoading /> :
                <>
                <TitleContainer>
                    <TitlePage
                        title={modulo.nome}
                        breadcrumbs={{
                            link1: `/minhastrilhas`,
                            nome1: 'Minhas Trilhas',
                            link2: `/minhastrilhas`,
                            nome2: `${modulo.nome_trilha}`,
                            label: modulo.nome,
                        }}
                        videos={modulo.qtdVideos}
                        anexos={modulo.qtdAnexos}
                        comentarios={modulo.qtdModulos ?? 0}
                    >
                        {modulo.descricao}
                    </TitlePage>

                    {modulo.avaliacao && !dadosAvaliacaoAluno.avaliado ? (
                        <InfoTrilha
                            title={modulo.nome_trilha}
                            videos={modulo.qtdVideos}
                            cargaHoraria={moment
                                .duration(
                                    modulo.duracao_video_segundos,
                                    'seconds'
                                )
                                .humanize()}
                            anexos={modulo.qtdAnexos}
                            descricaoAvaliacao={modulo.descricao_avaliacao}
                            modulo={modulo}
                            dadosAvaliacaoAluno={dadosAvaliacaoAluno}
                        />
                    ) : (
                        modulo.avaliacao && (
                            <InfoTrilhaAvaliacao
                                dataAvaliacao={
                                    dadosAvaliacaoAluno.inicioAvaliacao
                                }
                                acertos={dadosAvaliacaoAluno.acertos}
                                numeroQuestoes={
                                    dadosAvaliacaoAluno.numeroQuestoes
                                }
                                tentativas={dadosAvaliacaoAluno.tentativas}
                                numeroTentativa={
                                    dadosAvaliacaoAluno.numeroTentativa
                                }
                                tempoDeResposta={
                                    dadosAvaliacaoAluno.tempoDeResposta
                                }
                                descricaoAvaliacao={
                                    dadosAvaliacaoAluno.descricaoAvaliacao
                                }
                                emCorrecao={dadosAvaliacaoAluno.emCorrecao}
                                modulo={modulo}
                                dadosAvaliacaoAluno={dadosAvaliacaoAluno}
                                possuiCertificado={dadosAvaliacaoAluno.possuiCertificado}
                            />
                        )
                    )}
                </TitleContainer>
              
                <VideosContainer>
                    <SectionTitle>
                        <IconCam />
                        {modulo.qtdVideos === 0
                            ? 'Ainda não há Vídeos'
                            : modulo.qtdVideos === 1
                            ? `${modulo.qtdVideos} Vídeo`
                            : `${modulo.qtdVideos} Vídeos`}
                    </SectionTitle>
                    <GridVideos>
                        {modulo.videos.map((item, index) => {
                            return (
                                <CardVideo
                                    setIsVideoOpen={setIsVideoOpen}
                                    key={item.conteudo_id}
                                    videoUrl={`${API_HOST}/assets/conteudos/${item.arquivo}`}
                                    index={index + 1}
                                    title={verificador(item.nome_arquivo)}
                                    duration={
                                        item.duracao > 3600
                                            ? moment
                                                  .utc(item.duracao * 1000)
                                                  .format('HH:mm:ss')
                                            : moment
                                                  .utc(item.duracao * 1000)
                                                  .format('mm:ss')
                                    }
                                    description={item.descricao}
                                    favorited={item.favoritado === 1}
                                    progress={item.porcentagem_conclusao * 100}
                                    video={item}
                                    videoSelecionado={videoSelecionado}
                                    setVideoSelecionado={setVideoSelecionado}
                                    videos={modulo.videos}
                                    arrayReprodutionList={arrayReprodutionList}
                                    setArrayReprodutionList={
                                        setArrayReprodutionList
                                    }
                                />
                            );
                        })}
                    </GridVideos>
                </VideosContainer>
                </>
            }
                <AttachmentsComments>
                    <AttachmentsContainer>
                        <SectionTitle>
                            <IconClip />
                            {modulo.qtdAnexos === 0
                                ? 'Não há documentos neste módulo'
                                : modulo.qtdAnexos === 1
                                ? `${modulo.qtdAnexos} Documento`
                                : `${modulo.qtdAnexos} Documentos`}
                        </SectionTitle>
                        <AttachmentsGrid>
                            {modulo.anexos.map((item) => (
                                <Attachments
                                    nome_arquivo={item.arquivo}
                                    key={item.conteudo_id}
                                    title={verificador(item.nome_arquivo)}
                                    type={item.type}
                                    description={'descricao'}
                                    conteudo={item}
                                />
                            ))}
                        </AttachmentsGrid>
                    </AttachmentsContainer>
                    <CommentsContainer>
                        <SectionTitle>
                            <IconComment />
                            {modulo.comentarios.length === 0
                                ? 'Ainda não há Reviews'
                                : modulo.comentarios.length === 1
                                ? `${modulo.comentarios.length} Review`
                                : `${modulo.comentarios.length} Reviews`}
                        </SectionTitle>
                        <CommentsWrapper>
                            {modulo.comentarios.map((item) => (
                                <Comment
                                    icon={
                                        !item.nome_imagem ||
                                        item.nome_imagem == 'undefined' ? (
                                            <DefaultSmileyFace>
                                                <SmileyFace />
                                            </DefaultSmileyFace>
                                        ) : (
                                            <ImgUserContainer
                                                src={`${API_HOST}/assets/conteudos/${item.nome_imagem}`}
                                            />
                                        )
                                    }
                                    key={item.conteudo_id}
                                    // image={`${API_HOST}/assets/conteudos/${item.nome_imagem}`}
                                    name={item.nome_usuario}
                                    date={moment(item.data_publicacao).format(
                                        'll'
                                    )}
                                    text={item.conteudo}
                                    rating={item.rating}
                                />
                            ))}
                        </CommentsWrapper>
                        <LinkComentar
                            onClick={() => {
                                toggleOpen();
                            }}
                            empty={modulo.comentarios.length === 0}
                        >
                            Deixe aqui o seu Review
                        </LinkComentar>
                    </CommentsContainer>
                </AttachmentsComments>
          
            </TrilhaContainer>
            <Modal openModal={openModal} handleModal={handleModal}>
                <ModalCommentary
                    modulo={modulo}
                    handleModal={handleModal}
                    openModal={openModal}
                />
            </Modal>

            {location.pathname.includes('watching') && (
                <VideoPlayer
                    backButton={() => {
                        setIsVideoOpen(false);
                        getTrilhasEModulos();
                        history.push(`/trilhas/${trilhaId}/modulos/${moduloId}`)
                    }}
                    modulo={modulo}
                    videoSelecionado={videoSelecionado}
                    setVideoSelecionado={setVideoSelecionado}
                    videos={modulo.videos}
                    arrayReprodutionList={arrayReprodutionList}
                    setArrayReprodutionList={setArrayReprodutionList}
                    breadcrumbs={{
                        link1: `/minhastrilhas`,
                        nome1: 'Minhas Trilhas',
                        label: modulo.nome,
                    }}
                />
            )}
        </>
    );
};

export default Trilha;
