import styled from 'styled-components'

export const CertificadoContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background: white;
    width: 75vw;
    padding: 3.89vw 1.94vw;
    border-radius: 1.29vw;
    filter: drop-shadow(3px 4px 6px rgba(0, 0, 0, 0.2));
    & > svg {
        height: 100%;
        width: 1.68vw;
    }
    @media (min-width: 1440px) {
        transform: scale(0.7) translate(20%);
        transform-origin: left top;
    }
    @media (max-width: 800px) {
        transform: scale(1.2);
        transform-origin: left top;
    }
`;

export const CertificadoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.49vw;
    align-items: center;
    & > svg {
        width: 19.66vw;
        height: 1.86vw;
    }
`;

export const TextCertificadoContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #342f44;
    text-align: center;
    gap: 0.64vw;
    & p {
        font-size: 1.16vw;
        font-weight: 400;
        & span {
            font-size: 1.16vw;
            font-weight: 500;
        }
    }
    & span {
        font-weight: bold;
        font-size: 2.33vw;
    }
`;

export const CertificadoInfos = styled.div`
    display: flex;
    flex-direction: column;
    color: #342f44;
    text-align: center;
    & p {
        font-size: 1.03vw;
        span {
            font-size: 1.03vw;
            font-weight: 500;
        }
    }
`;

export const SignaturesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #342f44;
    width: 95%;
    text-align: center;
    margin-left: auto;
    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    & p {
        font-size: 0.84vw;
        font-weight: 400;
    }
    & span {
        font-size: 1.03vw;
        font-weight: bold;
    }
`;

export const Sign = styled.div`
    color: #1d1d1b;
    font-size: 2.46vw;
    font-family: 'JellyCloud';
    margin-bottom: -0.48vw;
    letter-spacing: 0.12em;
`;

export const CertificadoFooter = styled.footer`
    display: flex;
    align-items: center;
    gap: 1.94vw;
    font-size: 0.84vw;
    color: #333247;

    & svg {
        width: 10.25vw;
        height: 3.37vw;
    }
`;