import React, { useState, useEffect } from 'react';
import { MaskedBG } from '../MinhasTrilhas/style';
import avaliacaoBG from '../../../assets/img/avaliacaoBG.jpg';
import {
    AvaliacaoContainer,
    MobileInfo,
    QuestoesContainer,
    SlidingInfo,
} from './style';
import { TitleContainer } from '../Trilha/style';
import TitlePage from '../../../components/aluno/TitlePage';
import InfoAvaliacao from './components/InfoAvaliacao';
import Questao from './components/Questao';
import { AvaliacaoFakeData } from './fakeData';
import api from '../../../services/api';
import { useTheme } from '@material-ui/styles';
import { useParams } from 'react-router';
import moment from 'moment-timezone';

const Avaliacao = () => {
    const { identificadorAvaliacao } = useParams();
    const [avaliacao, setAvaliacao] = useState({});
    const [questoes, setQuestoes] = useState([]);
    const [questoesRespondidas, setQuestoesRespondidas] = useState(0);
    const [segundos, setSegundos] = useState(0);
    const [minutos, setMinutos] = useState(0);
    const [horas, setHoras] = useState(0);

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        getAvaliacao();
        getRespostasAvaliacaoEmAndamento();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            updateRespostasAvaliacaoEmAndamento();
        }, 1200);

        return () => clearTimeout(delayDebounceFn);
    }, [questoes]);

    const getRespostasAvaliacaoEmAndamento = async () => {
        try {
            const response = (
                await api.get(
                    `/avaliacoes/getRespostasAvaliacaoEmAndamento/${identificadorAvaliacao}`
                )
            ).data;

            setQuestoes(response.questoes);
            setQuestoesRespondidas(response.numeroQuestoesRespondidas);
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 27 ~ getAvaliacao ~ error',
                error
            );
        }
    };

    const getAvaliacao = async () => {
        try {
            const response = (
                await api.get(
                    `/avaliacoes/dadosAvaliacao/${identificadorAvaliacao}`
                )
            ).data;

            setAvaliacao(response.avaliacao);
            calculaTempoDaAvaliacao(response.avaliacao);
            setQuestoes(response.questoes);
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 27 ~ getAvaliacao ~ error',
                error
            );
        }
    };

    const calculaTempoDaAvaliacao = (avaliacao) => {
        const dataInicioAvaliacao = moment(avaliacao.data_inicio).subtract(
            3,
            'hours'
        );
        console.log(
            '🚀 ~ file: index.js ~ line 112 ~ calculaTempoDaAvaliacao ~ avaliacao.data_inicio',
            avaliacao.data_inicio
        );

        const segundosPassadosDoInicioDaAvaliacao = moment().diff(
            dataInicioAvaliacao,
            'seconds'
        );

        if (segundosPassadosDoInicioDaAvaliacao > 2) {
            const horasPassadas = Math.floor(
                segundosPassadosDoInicioDaAvaliacao / 3600
            );
            setHoras(horasPassadas);

            const minutosPassados = Math.floor(
                (segundosPassadosDoInicioDaAvaliacao - horasPassadas * 3600) /
                    60
            );
            setMinutos(minutosPassados);

            const segundosPassados = Math.floor(
                segundosPassadosDoInicioDaAvaliacao % 60
            );
            setSegundos(segundosPassados);
        }
    };

    const updateRespostasAvaliacaoEmAndamento = async () => {
        try {
            await delay(1000);
            await api.post('/avaliacoes/updateRespostasAvaliacaoEmAndamento', {
                questoes,
                avaliacao_id: avaliacao.avaliacao_id,
                identificador_avaliacao: identificadorAvaliacao,
            });
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 66 ~ updateRespostasAvaliacaoEmAndamento ~ error',
                error
            );
        }
    };

    const handleChangeRespostas = (
        event,
        indexQuestao,
        indexResposta,
        tipoQuestao
    ) => {
        const { name, value } = event.target;

        const questao = questoes.find(
            (item, index2) => indexQuestao === index2
        );

        const resposta = questao.respostas[indexResposta];

        if (tipoQuestao === 'multipla_escolha') {
            setQuestoes([
                ...questoes.map((item, index2) => {
                    if (indexQuestao === index2) {
                        if (!(item.multipla_escolha_correta > 0)) {
                            if (questoesRespondidas <= questoes.length) {
                                setQuestoesRespondidas(
                                    (prevState) => prevState + 1
                                );
                            }
                        }
                        return { ...item, multipla_escolha_correta: value };
                    }
                    return item;
                }),
            ]);
        } else if (tipoQuestao === 'caixa_selecao') {
            setQuestoes([
                ...questoes.map((item, index2) => {
                    if (indexQuestao === index2) {
                        const updateRespostas = item.respostas.map(
                            (itemResposta, indexResposta2) =>
                                indexResposta === indexResposta2
                                    ? {
                                          ...resposta,
                                          checkbox_resposta_correta:
                                              event.target.checked,
                                      }
                                    : itemResposta
                        );
                        let possuiResposta = updateRespostas.filter(
                            (itemResposta) =>
                                itemResposta.checkbox_resposta_correta > 0
                        );

                        if (
                            possuiResposta.length > 0 &&
                            !(item.questaoContabilizada === 1)
                        ) {
                            if (questoesRespondidas <= questoes.length) {
                                setQuestoesRespondidas(
                                    (prevState) => prevState + 1
                                );
                            }

                            item.questaoContabilizada = 1;
                        }

                        return { ...item, respostas: updateRespostas };
                    }
                    return item;
                }),
            ]);
        } else {
            setQuestoes([
                ...questoes.map((item, index2) => {
                    if (indexQuestao === index2) {
                        if (
                            item.resposta?.length > 0 &&
                            !(item.questaoContabilizada === 1)
                        ) {
                            if (questoesRespondidas <= questoes.length) {
                                setQuestoesRespondidas(
                                    (prevState) => prevState + 1
                                );
                            }

                            item.questaoContabilizada = 1;
                        } else if (item.resposta?.length === 0) {
                            if (questoesRespondidas > 0) {
                                if (questoesRespondidas > 0) {
                                    setQuestoesRespondidas(
                                        (prevState) => prevState - 1
                                    );
                                }

                                item.questaoContabilizada = null;
                            }
                        }
                        return { ...item, resposta: value };
                    }
                    return item;
                }),
            ]);
        }
    };

    return (
        <>
            <MaskedBG image={avaliacaoBG} />
            <AvaliacaoContainer>
                <TitleContainer infoAvaliacao>
                    <TitlePage
                        title={avaliacao.nome_avaliacao}
                        breadcrumbs
                        questoes={avaliacao.quantidade_questoes}
                        Avaliacao
                    >
                        {avaliacao.descricao_avaliacao}
                    </TitlePage>
                    <SlidingInfo>
                        <InfoAvaliacao
                            questoesRespondidas={questoesRespondidas}
                            qtdQuestoes={avaliacao.quantidade_questoes}
                            numeroTentativa={avaliacao.numero_tentativa}
                            dataInicio={avaliacao.data_inicio}
                            duracao={avaliacao.duracao}
                            questoes={questoes}
                            avaliacao={avaliacao}
                            identificadorAvaliacao={identificadorAvaliacao}
                            horas={horas}
                            setHoras={setHoras}
                            minutos={minutos}
                            setMinutos={setMinutos}
                            segundos={segundos}
                            setSegundos={setSegundos}
                        />
                    </SlidingInfo>
                </TitleContainer>
                <QuestoesContainer>
                    {questoes.map((item, index) => (
                        <Questao
                            handleChangeRespostas={handleChangeRespostas}
                            key={item.questao_id}
                            number={index + 1}
                            title={item.titulo}
                            tipo={item.tipo_questao}
                            questao_id={item.questao_id}
                            respostas={item.respostas}
                            indexQuestao={index}
                            questao={item}
                        />
                    ))}
                </QuestoesContainer>
                <MobileInfo>
                    <InfoAvaliacao
                        questoesRespondidas={questoesRespondidas}
                        qtdQuestoes={avaliacao.quantidade_questoes}
                        numeroTentativa={avaliacao.numero_tentativa}
                        dataInicio={avaliacao.data_inicio}
                        duracao={avaliacao.duracao}
                        questoes={questoes}
                        avaliacao={avaliacao}
                        identificadorAvaliacao={identificadorAvaliacao}
                        horas={horas}
                        setHoras={setHoras}
                        minutos={minutos}
                        setMinutos={setMinutos}
                        segundos={segundos}
                        setSegundos={setSegundos}
                    />
                </MobileInfo>
            </AvaliacaoContainer>
        </>
    );
};

export default Avaliacao;
