import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import api from '../../../../../services/api';
import { getUsuarioId, removeAuthSession } from '../../../../../services/auth';
import { LinkMenu } from '../../style';
import {
    AreaTitle,
    ContainerMenuArea,
    Divider,
    LinksContainer,
    LinkWrapper,
} from './style';

function MenuAreaDoAluno() {
    const history = useHistory();
    const deslogar = async () => {
        await api.post('/usuarios/log/insertLog/', {
            acao: 'fim_tempo_logado',
            usuario_id: getUsuarioId(),
            data_fim: moment().format('YYYY/MM/DD HH:mm:ss'),
        });
        removeAuthSession();
        history.push('/');
    };
    return (
        <ContainerMenuArea>
            <AreaTitle>ÁREA DO ALUNO</AreaTitle>
            <LinksContainer>
                <LinkWrapper>
                    <LinkMenu
                        to="/areadoaluno/meusdados"
                        activeClassName="selected"
                    >
                        Meus Dados
                    </LinkMenu>
                    <Divider />
                </LinkWrapper>
                <LinkWrapper>
                    <LinkMenu
                        to="/areadoaluno/meuhistorico"
                        activeClassName="selected"
                    >
                        Meu Histórico
                    </LinkMenu>
                    <Divider />
                </LinkWrapper>
                <LinkWrapper>
                    <LinkMenu
                        to="/areadoaluno/meusfavoritos"
                        activeClassName="selected"
                    >
                        Meu Favoritos
                    </LinkMenu>
                    <Divider />
                </LinkWrapper>
                <LinkWrapper>
                    <LinkMenu
                        to="/areadoaluno/minhasavaliacoes"
                        activeClassName="selected"
                    >
                        Minhas Avaliações
                    </LinkMenu>
                    <Divider />
                </LinkWrapper>
                <LinkWrapper>
                    <LinkMenu
                        to="/areadoaluno/meuscertificados"
                        activeClassName="selected"
                    >
                        Meus Certificados
                    </LinkMenu>
                    <Divider />
                </LinkWrapper>
                {/* <LinkWrapper>
                    <LinkMenu
                        to="/areadoaluno/ajuda"
                        activeClassName="selected"
                    >
                        Ajuda
                    </LinkMenu>
                    <Divider />
                </LinkWrapper> */}
                <LinkWrapper>
                    <LinkMenu
                        to="/areadoaluno/contato"
                        activeClassName="selected"
                    >
                        Contato
                    </LinkMenu>
                    <Divider />
                </LinkWrapper>
                <LinkWrapper>
                    <LinkMenu
                        to="/" // só pra style
                        onClick={deslogar}
                    >
                        Sair
                    </LinkMenu>
                </LinkWrapper>
            </LinksContainer>
        </ContainerMenuArea>
    );
}

export default MenuAreaDoAluno;
