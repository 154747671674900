import styled from "styled-components";

export const Container = styled.div`
    & label {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        //height: 2.6rem;
        width: fit-content;
        & .icon-container {
            height: 2.35rem;
            min-width: 2.316rem;
            border-radius: 5rem;
            background: #ffffff10;
            align-self: flex-start;
            & svg {
                position: absolute;
                /* top: 0.2rem;
                left: 0.3rem; */
                height: 2rem;
                width: 2.4rem;
                transform: scale(0);
                transition: all 0.2s ease;
            }
        }
        & p {
            font-size: 1.7rem;
            color: #fff;
            margin-left: 20px;
        }
    }
    & input[type="radio"]:checked + label svg {
        transform: scale(1);
        /* top: 0.2rem;
        left: 0.3rem; */
    }
`;
