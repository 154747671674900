import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import api from '../../../services/api';
import { getUsuarioId } from '../../../services/auth';
import moment from 'moment';

import {
    CertificadoHeader,
    VoltarModuloContainer,
    DownloadPrintContainer,
    ButtonContent,
    CertificadoContainer,
    CertificadoWrapper,
    CertificadoContent,
    TextCertificadoContainer,
    CertificadoInfos,
    SignaturesContainer,
    Sign,
    CertificadoFooter,
    CertificadoPageContainer,
} from './style';
import {
    IconBack,
    PrintIcon,
    DownloadIcon,
    CertificadoSide,
    TreinaDeltaBlue,
    DeltaGlobalColored,
} from '../../../components/aluno/Icons/Icons';
import Button from '../../../components/aluno/Button';
import CertificadoView from '../../../components/aluno/CertificadoView';

const Certificado = () => {
    const history = useHistory()
    const { trilhaId } = useParams()
    const [usuarioId] = useState(getUsuarioId())
    
    const [loading, setLoading] = useState()
    const [possuiCertificado, setPossuiCertificado] = useState(true)
    const [dadosCertificado, setDadosCertificado] = useState({})

    useEffect(() => {
        verificaSeUsuarioPossuiCertificado()
    }, [])

    const getInformacoesCertificado = async () => {
        try {
            const response = await api.get('/trilhas/informacoes/certificado', {
                params: {
                    trilha_id: trilhaId,
                    usuario_id: usuarioId
                }
            })
            setDadosCertificado(response.data)
        } catch(error) {
            console.log("🚀 ~ file: index.js ~ line 55 ~ getInformacoesCertificado ~ error", error)
        }
    }

    const verificaSeUsuarioPossuiCertificado = async () => {
        try {
            const response = await api.get('/trilhas/verifica/alunoPossuiCertificado/', {
                params:{
                    trilha_id: trilhaId,
                    usuario_id: usuarioId
                }
            })
            const { possuiCertificado } = response.data

            if (possuiCertificado) {
                await getInformacoesCertificado()
            } else {
                setPossuiCertificado(false)
            }
        } catch(error) {
            console.log("🚀 ~ file: index.js ~ line 43 ~ verificaSeUsuarioPossuiCertificado ~ error", error)
        }
    }

    const exportPdf = () => {
        setLoading(true)
        html2canvas(document.querySelector("#capture")).then(canvas => {
           const imgData = canvas.toDataURL('image/png');
           const pdf = new jsPDF({
               orientation: 'landscape',
            });

            let width = pdf.internal.pageSize.getWidth();
            let height = pdf.internal.pageSize.getHeight();

            var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        // window.location.href=image;
           pdf.addImage(imgData, 'PNG', 0, 0, width, height);
           pdf.save(`certificado-${dadosCertificado.nome_aluno}-${dadosCertificado.nome_modulo}.pdf`); 
       });
       setLoading(false)
    }

    return (
        <CertificadoPageContainer>
            <CertificadoWrapper>
                    <CertificadoHeader>
                        <VoltarModuloContainer onClick={() => history.goBack()}>
                            <IconBack /> Voltar 
                        </VoltarModuloContainer>
                        {possuiCertificado && 
                            <DownloadPrintContainer>
                                <Button big>
                                    <ButtonContent onClick={exportPdf}>
                                        <DownloadIcon />
                                        Download
                                    </ButtonContent>
                                </Button>
                                <Button big>
                                    <ButtonContent>
                                        <PrintIcon />
                                        Imprimir
                                    </ButtonContent>
                                </Button>
                            </DownloadPrintContainer>
                        }
                    </CertificadoHeader>
                    <CertificadoView  dadosCertificado={dadosCertificado} possuiCertificado={possuiCertificado} />
            </CertificadoWrapper>
        </CertificadoPageContainer>
    );
};

export default Certificado;
