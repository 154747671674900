/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-fallthrough */
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import {
    Breadcrumbs,
    CircularProgress,
    Card,
    Grid,
    Button,
    Link,
    Typography,
    CardContent,
    Stepper,
    Step,
    StepLabel,
} from '@material-ui/core';
import * as Yup from 'yup';

import getValidationErors from '../../../utils/geValidationErrors';
import UsuarioForm1 from './components/UsuarioForm1';
import UsuarioForm2 from './components/UsuarioForm2';
import api from '../../../services/api';
import { hasAccess } from '../../../services/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '13px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

function getSteps() {
    return ['Dados gerais', 'Setor'];
}

const UsuariosForm = () => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [usuario, setUsuario] = useState({setor: 0});
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [perfis, setPerfis] = useState([]);
    const [parceiros, setParceiros] = useState([]);
    const [setores, setSetores] = useState([]);
    const formRef = useRef(null);
    const steps = getSteps();
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        getPerfis();
        getSetores();
        getParceiro();
    }, []);

    const getPerfis = async () => {
        try {
            setLoading(true);
            const response = await api.get('/perfis');
            setPerfis(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getParceiro = async () => {
        try {
            setLoading(true);

            const response = await api.get(`/parceiros/usuario/parceiro`);
            setParceiros(response.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getSetores = async () => {
        try {
            const response = await api.get('/usuarios/setores/usuario');
            setSetores(response.data);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar("Não foi possivel buscar os setores", {
                    variant: 'error',
                });
            }
        }
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    const handleNext = async () => {
        setValidationErrors({});
        try {
            if (activeStep === 0) {
                const schema = Yup.object().shape({
                    // email: Yup.string()
                    //     .email('Digite um e-mail válido')
                    //     .required('E-mail obrigatório'),
                    //     usuario: Yup.string().required('Usuário obrigatório'),
                    nome: Yup.string().required('Nome obrigatório'),
                    senha: Yup.string().required('Senha obrigatória'),
                    cpf: Yup.string()
                        .min(14, 'Insira um CPF válido!')
                        .required('Cpf obrigatório'),
                });

                await schema.validate(usuario, {
                    abortEarly: false,
                });
            }
            if (activeStep === 1) {
                const schema = Yup.object().shape({
                    perfil_id: Yup.number().required('Perfil obrigatório'),
                });

                await schema.validate(usuario, {
                    abortEarly: false,
                });
                handleFinish();
                return;
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUsuario({ ...usuario, [name]: value });
        console.log(
            '🚀 ~ file: UsuarioCreate.js ~ line 142 ~ handleInputChange ~ usuario',
            usuario
        );
    };

    const handleFinish = async () => {
        try {
            await api.post('/usuarios', usuario);
            enqueueSnackbar('Usuário cadastrado com sucesso!', {
                variant: 'success',
            });
            history.push('/admin/usuarios');
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
            setLoading(false);
        }
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <UsuarioForm1
                        formRef={formRef}
                        handleBackToPage={handleBackToPage}
                        usuario={usuario}
                        setUsuario={setUsuario}
                        handleInputChange={handleInputChange}
                        validationErrors={validationErrors}
                    />
                );
            case 1:
                return (
                    <UsuarioForm2
                        formRef={formRef}
                        handleBackToPage={handleBackToPage}
                        handleBack={handleBack}
                        usuario={usuario}
                        setUsuario={setUsuario}
                        perfis={perfis}
                        parceiros={parceiros}
                        setores={setores}
                        validationErrors={validationErrors}
                        handleInputChange={handleInputChange}
                    />
                );

            default:
                return (
                    <UsuarioForm1
                        formRef={formRef}
                        usuario={usuario}
                        handleBackToPage={handleBackToPage}
                        setUsuario={setUsuario}
                        validationErrors={validationErrors}
                        handleInputChange={handleInputChange}
                    />
                );
        }
    }

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '80vh' }}
            >
                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/usuarios"
                >
                    Usuários
                </Link>
                <Typography className={classes.breadCrumb}>
                    Novo Usuário
                </Typography>
            </Breadcrumbs>
            <Typography style={{ marginTop: 16 }} variant="h3" component="h1">
                Novo Usuário
            </Typography>

            <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className={classes.content}>
                <Card>
                    <CardContent>
                        <div>
                            {activeStep === steps.length ? (
                                <div className={classes.alignRight}>
                                    <Typography>
                                        Todas as etapas foram concluídas!
                                    </Typography>
                                </div>
                            ) : (
                                <div>
                                    <Typography>
                                        {getStepContent(activeStep)}
                                    </Typography>
                                    <div className={classes.alignRight}>
                                        {activeStep === 0 ? (
                                            <Button
                                                color="default"
                                                onClick={handleBackToPage}
                                            >
                                                Cancelar
                                            </Button>
                                        ) : (
                                            <Button onClick={handleBack}>
                                                Voltar
                                            </Button>
                                        )}
                                        {activeStep === steps.length - 1 ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                Finalizar
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                Prosseguir
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default UsuariosForm;
