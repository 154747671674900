import React from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';

moment.locale('pt-br');

export default function CustomTextField({ name, error, disabled,...rest }) {
    return (
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
            <KeyboardDatePicker
                margin="normal"
                okLabel="Selecionar"
                cancelLabel="Cancelar"
                clearable
                clearLabel="Limpar"
                format="DD/MM/YYYY"
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                inputVariant="outlined"
                fullWidth
                disabled={disabled == null ? false : disabled}
                autoOk
                invalidDateMessage="Formato de data inválido"
                // InputLabelProps={{ shrink: true }}
                style={{ width: '90%' }}
                helperText={error}
                error={error && true}
                FormHelperTextProps={{
                    error: true,
                    autoFocus: true,
                    filled: true,
                    margin: 'dense',
                }}
                {...rest}
            />
        </MuiPickersUtilsProvider>
    );
}
