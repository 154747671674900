/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Tooltip,
    IconButton,
    Typography,
    Fab,
    Card,
    CardContent,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Questao from './Questao';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 230,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        whiteSpace: 'unset',
        wordBreak: 'break-all',
    },
    inputRoot: {
        width: '400',
    },
    floatingButton: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    textField: {
        inputRoot: {
            fontSize: 18,
        },
        labelRoot: {
            fontSize: 18,
            '&$labelFocused': {
                color: 'primary',
            },
        },
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        padding: '30px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#BFBFBF',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        width: '95%',
        transition: 'border .24s ease-in-out',
        margin: '0 auto',
    },
    dropZoneImage: {
        display: 'flex',
        alignItens: 'center',
        justifyContent: 'center',
        // padding: "30px",
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#BFBFBF',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        height: '160px',
        transition: '0.8s',
        marginTop: 16,
        margin: '0 auto',
        // "&:hover": {
        //     opacity: '0.5'
        // },
    },
    media: {
        height: 140,
    },
    card: {
        borderRadius: 0,
        borderColor: '#BFBFBF',
        color: '#fafafa',
    },
    divider: {
        background: '#BFBFBF',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
  
}));

const avaliacaoQuestoes = (props) => {
    const classes = useStyles();
    const [indexSelected, setIndexSelected] = useState({});

    const {
        questoes,
        setQuestoes,
        questoesChecked,
        setFiles,
        files,
        setQuestoesChecked,
    } = props;

    const resetaCaixaDeSelecao = (indexQuestao) => {
        setQuestoes([
            ...questoes.map((item, index2) => {
                if (indexQuestao === index2) {
                    const updateRespostas = item.respostas.map(
                        (itemResposta) => {
                            return {
                                ...itemResposta,
                                checkbox_resposta_correta: '',
                            };
                        }
                    );

                    return { ...item, respostas: updateRespostas };
                }
                return item;
            }),
        ]);
    };

    const handleChangeQuestao = (index, event) => {
        const { name, value } = event.target;

        const questao = questoes.find((item, index2) => index === index2);

        questao[name] = value;

        if (name === 'obrigatoria') {
            questao[name] = event.target.checked;
        }

        const newQuestoes = [
            ...questoes.map((item, index2) =>
                index === index2 ? questao : item
            ),
        ];
        setQuestoes(newQuestoes);
        console.log('questoes', questoes);

        if (name === 'tipo_questao') {
            questao.multipla_escolha_correta = '';
            resetaCaixaDeSelecao(index);
        }

        if (name === 'tipo_questao' && value === 'dissertativo') {
            setQuestoes([
                ...newQuestoes.map((item, index2) => {
                    if (index === index2) {
                        return { ...item, respostas: [{}] };
                    }
                    return item;
                }),
            ]);
        }
    };

    const handleAddQuestoes = () => {
        // setTrilha({ ...trilha, modulos: [...trilha.modulos, {}] });
        setQuestoes([
            ...questoes,
            { respostas: [{}], tipo_questao: 'multipla_escolha', obrigatoria: 1 },
        ]);

        console.log(
            '🚀 ~ file: AvaliacaoQuexstoes.js ~ line 125 ~ handleAddQuestoes ~ questoes',
            questoes
        );
    };

    const handleRemoveQuestao = (index) => {
        setQuestoes([
            ...questoes.map((item, index2) =>
                index === index2 ? { ...item, remover: true } : item
            ),
        ]);
    };

    const handleAddResposta = (index) => {
        setQuestoes([
            ...questoes.map((item, index2) =>
                index === index2
                    ? { ...item, respostas: [...item.respostas, {}] }
                    : item
            ),
        ]);
    };

    const handleRemoveResposta = (indexQuestao, indexResposta) => {
        setQuestoes([
            ...questoes.map((item, index2) => {
                if (indexQuestao === index2) {
                    const updateRespostas = item.respostas.map(
                        (itemResposta, indexResposta2) =>
                            indexResposta === indexResposta2
                                ? { ...itemResposta, remover: true }
                                : itemResposta
                    );

                    return { ...item, respostas: updateRespostas };
                }
                return item;
            }),
        ]);
    };

    const handleChangeRespostas = (event, indexQuestao, indexResposta) => {
        const { name, value } = event.target;

        const questao = questoes.find(
            (item, index2) => indexQuestao === index2
        );

        const resposta = questao.respostas[indexResposta];

        resposta[name] = value;

        if (name === 'checkbox_resposta_correta') {
            setQuestoes([
                ...questoes.map((item, index2) => {
                    if (indexQuestao === index2) {
                        const updateRespostas = item.respostas.map(
                            (itemResposta, indexResposta2) =>
                                indexResposta === indexResposta2
                                    ? {
                                          ...resposta,
                                          [event.target.name]:
                                              event.target.checked,
                                      }
                                    : itemResposta
                        );

                        return { ...item, respostas: updateRespostas };
                    }
                    return item;
                }),
            ]);
        } else {
            setQuestoes([
                ...questoes.map((item, index2) => {
                    if (indexQuestao === index2) {
                        const updateRespostas = item.respostas.map(
                            (itemResposta, indexResposta2) =>
                                indexResposta === indexResposta2
                                    ? resposta
                                    : itemResposta
                        );

                        return { ...item, respostas: updateRespostas };
                    }
                    return item;
                }),
            ]);
        }

        console.log(
            '🚀 ~ file: AvaliacaoQuestoes.js ~ line 150 ~ ...questoes.map ~ questoes',
            questoes
        );
    };

    return (
        <>
            <div>

                <Tooltip title="Adicionar questão" placement="top" arrow>
                    <Fab
                        size="small"
                        className={classes.floatingButton}
                        color="primary"
                        aria-label="add"
                        onClick={handleAddQuestoes}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>

                {questoes.map((questao, index) => (
                    <div style={{ paddingTop: 55 }}>
                        {!questao.remover && (
                            <>
                                <div 
                                   onMouseEnter={() =>{
                                    setIndexSelected(index)
                                }} 
                                onMouseLeave={() => setIndexSelected(null)}>
                                    <Card>
                                        <CardContent>
                                            <Questao
                                                setFiles={setFiles}
                                                files={files}
                                                questao={questao}
                                                indexSelected={indexSelected}
                                                setIndexSelected={setIndexSelected}
                                                index={index}
                                                questoes={questoes}
                                                handleChangeQuestao={
                                                    handleChangeQuestao
                                                }
                                                handleAddResposta={
                                                    handleAddResposta
                                                }
                                                handleRemoveQuestao={
                                                    handleRemoveQuestao
                                                }
                                                handleRemoveResposta={
                                                    handleRemoveResposta
                                                }
                                                classes={classes}
                                                handleChangeRespostas={
                                                    handleChangeRespostas
                                                }
                                                questoesChecked={
                                                    questoesChecked
                                                }
                                                setQuestoesChecked={
                                                    setQuestoesChecked
                                                }
                                            />
                                            {/* {questao.tipo_questao === 'caixa_selecao' && (
                                <QuestaoCaixaSelecao
                                    questao={questao}
                                    index={index}
                                    questoes={questoes}
                                    handleChangeQuestao={handleChangeQuestao}
                                    handleAddResposta={handleAddResposta}
                                    handleRemoveQuestao={handleRemoveQuestao}
                                    handleRemoveResposta={handleRemoveResposta}
                                    classes={classes}
                                    handleChangeRespostas={
                                        handleChangeRespostas
                                    }
                                />
                            )}

                            {questao.tipo_questao === 'multipla_escolha' && (
                                <QuestaoMultiplaEscolha
                                    questao={questao}
                                    index={index}
                                    questoes={questoes}
                                    handleChangeQuestao={handleChangeQuestao}
                                    handleAddResposta={handleAddResposta}
                                    handleRemoveQuestao={handleRemoveQuestao}
                                    handleRemoveResposta={handleRemoveResposta}
                                    classes={classes}
                                    handleChangeRespostas={
                                        handleChangeRespostas
                                    }
                                />
                            )} */}
                                        </CardContent>
                                    </Card>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default avaliacaoQuestoes;
