import styled from 'styled-components';
import { colors } from '../../../../../assets/theme/colors';

export const FavoriteContainer = styled.div`
    position: relative;
    width: 40px;
    height: 100%;
    padding: 12px;
    & svg {
        height: 100%;
        width: 100%;
        min-width: 15px;
    }

    & svg:nth-child(2) {
        transition: 0.3s ease;
        top: 8px;
        left: 0;
        position: absolute;
        height: 18px;
        transition: transform 0.2s ease;
        ${({ favorite }) =>
            favorite ? 'transform: scale(1.25);' : 'transform: scale(0)'};
    }
    & svg:nth-child(2) path {
        fill: ${colors.primary};
    }
`;

export const VideoThumbnail = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 7px;
    transition: 0.3s ease;
    min-width: 125px;
    max-width: 125px;
    height: 71px;
    overflow: hidden;
    @media (max-width: 800px) {
        min-width: 30%;
        max-width: 30%;
        height: 71px;
        align-self: flex-start;
    }
    & .react-thumbnail-generator {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 1;
    }

    & img {
        opacity: 0.3;
        z-index: -1;
        height: 100%;
        border-radius: 7px;
    }
    & svg {
        z-index: 1;
        position: absolute;
        top: 52%;
        left: 44%;
        transform: translateY(-50%);
    }
    & svg path {
        fill: white;
    }

    @media (max-width: 600px) {
        max-width: ${({ areaDoAluno }) => (areaDoAluno ? '205px' : '')};
        height: ${({ areaDoAluno }) => (areaDoAluno ? '130px' : '')};
    }
`;

export const TitleVideo = styled.div`
    display: flex;
    align-items: center;
    & p {
        font-size: 1.6rem;
        font-weight: 500;
        color: #fff;
        @media (max-width: 800px) {
            font-size: 1.4rem;
        }
    }
    & svg path {
        fill: #c4c4c480;
    }
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
`;

export const ProgressContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-left: auto;
    display: flex;
    align-items: center;
    & svg:nth-child(2) {
        height: 10px;
        width: 8px;
        display: ${({ progress }) => (progress >= 90 ? 'none' : '')};
    }
    & svg path {
        transition: 0.3s ease;
        fill: #413e53;
    }
    & svg:nth-child(3) {
        width: 17px;
        height: 14px;
        display: ${({ progress }) => (progress < 90 ? 'none' : '')};
    }
    & svg:nth-child(3) path {
        fill: ${colors.primary};
    }
    @media (max-width: 800px) {
        flex: 1;
    }
    @media (max-width: 600px) {
        ${({ areaDoAluno }) =>
            areaDoAluno ? 'width: 100%; margin: 10px 0;' : ''};
    }
`;

export const ProgressVideo = styled.div`
    height: 7px;
    border-radius: 10px;
    background: #ffffff1a;
    width: 82.27px;
    position: relative;
    overflow: hidden;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: ${({ progress }) => (progress ? `${progress}%` : '')};
        height: 100%;
        background: ${({ progress }) =>
            progress >= 90 ? colors.primary : colors.tertiary};
    }
    @media (max-width: 800px) {
        width: 100%;
        min-width: 82px;
    }

    @media (max-width: 600px) {
        ${({ areaDoAluno }) => (areaDoAluno ? 'width: 100%' : '')};
    }
`;

export const VideoDescription = styled.p`
    font-size: 1.3rem;
    font-weight: 200;
    color: #ffffff;
    opacity: 0.6;
`;

export const TitleAndDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;

export const CardVideoContainer = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    ${({ progress }) =>
        progress >= 90
            ? `${TitleVideo} p { opacity: 0.3;} ${VideoThumbnail} {opacity: 0.3;} ${VideoDescription} {opacity: 0.3}`
            : ''};
    &:hover {
        background: ${({ progress }) =>
            progress >= 90 ? '#ffffff05' : '#ffffff12'};
        ${ProgressContainer} svg:nth-child(2) path {
            fill: #5f5b77;
        }
        ${FavoriteContainer} svg:nth-child(2) path {
            fill: ${colors.primary};
        }
    }
    @media (max-width: 800px) {
        padding: 10px 0;
        gap: 14px;
    }
`;
