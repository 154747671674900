/* eslint-disable no-unused-vars */
import React from 'react';
import { useSnackbar } from 'notistack';

import {
    Box,
    Grid,
    FormGroup,
} from '@material-ui/core';

import {
    cepMask,
    estadoMask
} from '../../../../helpers/masks';

import CustomTextField from '../../../../components/admin/CustomTextField';

const ParceiroForm2= (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const checkCEP = (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json())
            .then(data => {
                if (data.hasOwnProperty("erro")) {
                    enqueueSnackbar('CEP não encontrado!', {
                        variant: 'error',
                    });
                } else {
                    setParceiro({
                        ...parceiro,
                        bairro: data.bairro,
                        endereco: data.logradouro,
                        cidade: data.localidade,
                        estado: data.uf
                    });
                }
          
            });
    }

    const { parceiro, setParceiro, handleInputChange, validationErrors, edit } =
        props;

        return (
        <Box p={2}>
            <Grid
                alignItems="flex-start"
                justifyContent="flex-start"
                container
                xs={6}
                md={2}
            >
                <FormGroup
                    style={{
                        marginLeft: -16,
                    }}
                >
                </FormGroup>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4} >
                    <CustomTextField
                        label="CEP"
                        name="cep"
                        margin="normal"
                        value={parceiro.cep}
                        error={validationErrors.cep}
                        onChange={(e) =>
                            setParceiro({
                                ...parceiro,
                                cep: cepMask(e.target.value),
                            })
                        }
                        onBlur={checkCEP}
                        required
                        fullWidth
                        InputLabelProps={{ shrink: !! parceiro.cep }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Endereço"
                        name="endereco"
                        value={parceiro.endereco}
                        error={validationErrors.endereco}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: !! parceiro.endereco }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Número"
                        name="numero_endereco"
                        value={parceiro.numero_endereco}
                        error={validationErrors.numero_endereco}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: !! parceiro.numero_endereco }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Complemento"
                        name="complemento"
                        value={parceiro.complemento}
                        error={validationErrors.complemento}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: !! parceiro.complemento }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Bairro"
                        name="bairro"
                        value={parceiro.bairro}
                        error={validationErrors.bairro}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: !! parceiro.bairro }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Cidade"
                        name="cidade"
                        value={parceiro.cidade}
                        error={validationErrors.cidade}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: !! parceiro.cidade }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Estado"
                        name="estado"
                        value={parceiro.estado}
                        error={validationErrors.estado}
                        onChange={(e) =>
                            setParceiro({
                                ...parceiro,
                                estado: estadoMask(e.target.value),
                            })
                        }
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: !! parceiro.estado }}
                    />
                </Grid>
                
            </Grid>
        </Box>
    );
};

export default ParceiroForm2;
