import React, { useState } from "react";
import { ModalBlack, ModalContent } from "./style";

const Modal = ({ openModal, handleModal, children }) => {
    const [open, setOpen] = useState(openModal);

    const toggleOpen = () => {
        if (openModal === false) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "overlay";
        }
    };

    return (
        <>
            <ModalBlack
                open={openModal}
                onClick={() => {
                    handleModal(open);
                    toggleOpen();
                }}
            />
            <ModalContent open={openModal}>{children}</ModalContent>
        </>
    );
};

export default Modal;
