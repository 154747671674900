import React from 'react';
import Breadcrumb from '../../../../../../components/aluno/Breadcrumb';
import Button from '../../../../../../components/aluno/Button';
import {
    IconCheck,
    IconCross,
    IconTime,
} from '../../../../../../components/aluno/Icons/Icons';
import Tag from '../../../../../../components/aluno/Tag';
import {
    Info,
    InfoLabel,
    InfoText,
} from '../../../../Trilha/components/InfoTrilhaAvaliacao/style';
import {
    CardAvaliacaoContainer,
    CardAvaliacaoInfoTitle,
    CardAvaliacaoModulo,
    CardAvaliacaoModuloInfo,
    CardAvaliacaoTitle,
    HoursContainerCardAvaliacao,
    InfosWrapper,
    TitleAndTag,
} from './style';
import { useHistory } from 'react-router-dom';
import api from '.././../../../../../services/api';
// import { Container } from './styles';
import { useSnackbar } from 'notistack';

const CardAvaliacao = ({
    tituloModulo,
    image,
    tentativa,
    qtdTentativa,
    acertos,
    qtdAcertos,
    tempoDeResposta,
    hours,
    breadcrumbs,
    isAprovado,
    moduloId,
    avaliacao
}) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const media = (acertos) / qtdAcertos >= avaliacao.porcentagem_acerto;

    const handleInicioAvaliacao = async () => {
        try {
            const response = await api.post(`/avaliacoes/iniciarAvaliacao/`, {
                moduloId,
                usuarioId: localStorage.getItem('@treinadelta/usuario_id'),
            });
            const identificadorAvaliacao = response.data;

            history.push(`/avaliacao/${identificadorAvaliacao}`);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <CardAvaliacaoContainer>
            <CardAvaliacaoModulo image={image}>
                <CardAvaliacaoTitle>{tituloModulo}</CardAvaliacaoTitle>
                <HoursContainerCardAvaliacao>
                    <IconTime />
                    <span>{hours}h</span>
                </HoursContainerCardAvaliacao>
            </CardAvaliacaoModulo>
            <CardAvaliacaoModuloInfo>
                <TitleAndTag>
                    <CardAvaliacaoInfoTitle>
                        {tituloModulo}
                    </CardAvaliacaoInfoTitle>
                    {avaliacao.finalizada !== 2 &&
                        !avaliacao.possuiAvaliacaoEmAndamento && (
                            <Tag aprovado={media} cardAvaliacao>
                                {avaliacao.finalizada === 0
                                    ? ''
                                    : media
                                    ? 'APROVADO'
                                    : tentativa + 1 > qtdTentativa
                                    ? 'REPROVADO'
                                    : 'AVALIADO'}
                            </Tag>
                        )}
                </TitleAndTag>
                <Breadcrumb breadcrumbs={breadcrumbs} />
                <InfosWrapper cardAvaliacao>
                    <Info>
                        <InfoLabel>Tentativa</InfoLabel>
                        <InfoText>
                            {tentativa}ª de {qtdTentativa}ª
                        </InfoText>
                    </Info>
                    {!avaliacao.possuiAvaliacaoEmAndamento && (
                        <>
                            <Info>
                                <InfoLabel>Acertos</InfoLabel>
                                <InfoText aprovado={media} cardAvaliacao>
                                    {acertos} de {qtdAcertos}{' '}
                                    {media ? <IconCheck /> : <IconCross />}
                                </InfoText>
                            </Info>
                            <Info>
                                <InfoLabel>Tempo de Resposta</InfoLabel>
                                <InfoText>{tempoDeResposta}</InfoText>
                            </Info>
                        </>
                    )}
                </InfosWrapper>
                {/* {!avaliacao.possuiAvaliacaoEmAndamento &&
                    !avaliacao.possuiAvaliacaoEmCorrecao &&
                    media && (
                            
                        <Button cardAvaliacao aprovado={media} onClick={() => history.push(`/certificado/${moduloId}`)}>
                            Baixar certificado
                        </Button>
                    )} */}

                {!avaliacao.possuiAvaliacaoAprovada &&
                    !avaliacao.possuiAvaliacaoEmAndamento &&
                    !avaliacao.possuiAvaliacaoEmCorrecao &&
                    avaliacao.finalizada === 1 &&
                    !media &&
                    tentativa < qtdTentativa && (
                        <Button
                            onClick={handleInicioAvaliacao}
                            cardAvaliacao
                            aprovado={media}
                        >
                            {`Iniciar Avaliação (${avaliacao.numeroUltimaTentativa}ª Tentativa)`}
                        </Button>
                    )}
                {avaliacao.possuiAvaliacaoEmAndamento &&
                    avaliacao.finalizada === 0 && (
                        <Button
                            onClick={() =>
                                history.push(
                                    `/avaliacao/${avaliacao.identificador_avaliacao}`
                                )
                            }
                            cardAvaliacao
                            aprovado={media}
                        >
                            {`Retornar para avaliação`}
                        </Button>
                    )}
                {avaliacao.possuiAvaliacaoEmCorrecao &&
                    avaliacao.finalizada === 2 && (
                        <Button
                            onClick={() => {}}
                            cardAvaliacao
                            aprovado={media}
                        >
                            {'Avaliação em correção'}
                        </Button>
                    )}
            </CardAvaliacaoModuloInfo>
        </CardAvaliacaoContainer>
    );
};

export default CardAvaliacao;
