/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Box,
    Grid,
    FormControlLabel,
    FormGroup,
    Button,
    CardContent,
    Card,
    Tooltip,
    TextField,
    IconButton,
    MenuItem,
    Breadcrumbs,
    Link,
    Typography,
} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";

import { useSnackbar } from 'notistack';
import { useHistory, Link as RouterLink, useParams } from 'react-router-dom';
import moment from 'moment';
import CustomTextField from '../../../components/admin/CustomTextField';
import { useDropzone } from "react-dropzone";
import CustomKeyboardDatePicker from '../../../components/admin/CustomKeyboardDatePicker';
import IOSSwitch from '../../../components/admin/IOSwitch';
import { API_HOST } from "../../../config_servers";
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '13px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

const BibliotecaEdit = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles();
    const { id } = useParams();

    const [alert, setAlert] = useState({});
    const [auxliar, setAuxiliar] = useState([])
    const [imagem, setImagem] = useState([])
    const [validationErrors] = useState({});
    const [files, setFiles] = useState([])
    const [categorias, setCategorias] = useState([]);
    const [files2, setFiles2] = useState([]);
    const [livro, setLivro] = useState([]);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAlert({ ...alert, [name]: value });
    };
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        getFilesFromEvent: (event) => myCustomFileGetter(event),
    });

    const handleRemoveImage = (index) => {
        setFiles([]);
      
    };

    const handleRemoveImage2 = (index) => {
        setFiles2([]);
        setImagem([])
      
    };
    async function myCustomFileGetter2(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, "index", {
                value: 0,
                writable: true,
            });

            _files.push(file);
        }
        return _files;
    }
    const {
        acceptedFiles: acceptedFiles2,
        getRootProps: getRootProps2,
        getInputProps: getInputProps2,
    } = useDropzone({
        accept: 'image/jpeg, image/png',
        getFilesFromEvent: (event) => myCustomFileGetter2(event),
    });
    
    useEffect(() => {
        setFiles2([...files2, ...acceptedFiles2]);
    }, [acceptedFiles2]);

    useEffect(() => {
        setFiles([...acceptedFiles]);
    }, [acceptedFiles]);

    async function myCustomFileGetter(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, "index", {
                value: 0,
                writable: true,
            });

            Object.defineProperty(file, "posicao", {
                value: files.length,
                writable: true,
            });

            _files.push(file);
        }

        return _files;
    }

    const handleGetAlert = async () => {
        const response = await api.get(`/bibliotecas/${id}`);
        setAlert(response.data.bibliotecas);
        setImagem(response.data.imagemModulo)
        setLivro(response.data.LivroModulo)
        setAuxiliar(response.data)
    };

    const getCategorias = async () => {
        try {
            const response = await api.get('/categorias');
            setCategorias(response.data);

        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao buscar categoria', {
                    variant: 'error',
                });
            }
        }
    };


    useEffect(() => {
        getCategorias();
        handleGetAlert();
    }, []);

    const handleEditBiblioteca = async () => {
        try {
            await api.put(`/bibliotecas/${id}`, alert);
            if(imagem.length == 0 && !!auxliar?.imagemModulo[0]?.conteudo_id){
                await api.delete(`/conteudos/biblioteca/${auxliar.imagemModulo[0]?.conteudo_id}`);
            } 

            if(livro.length == 0 && !!auxliar?.LivroModulo[0]?.conteudo_id) {
                await api.delete(`/conteudos/biblioteca/${auxliar.LivroModulo[0]?.conteudo_id}`);
            }
            if (files[0]?.name) {
                const formData = new FormData();

                for (const file of files) {
                    formData.append("file", file);
                }

                formData.append("biblioteca_id", id);

                await api.post("/conteudos", formData);
            }

            if (files2[0]?.name) {
                const formData2 = new FormData();

                for (const file of files2) {
                    formData2.append("file", file);
                }
                formData2.append(
                    "imagem_biblioteca_id",
                    id
                );

                await api.post("/conteudos", formData2);
            }

            enqueueSnackbar('Biblioteca editado com sucesso!', {
                variant: 'success',
            });
            
            history.push('/admin/biblioteca');
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao editar biblioteca', {
                    variant: 'error',
                });
            }
        }
    };

    const removeFileFilesBook = (file, i) => () => {
        setFiles([]);
        setLivro([])
    };

    const handlePageAnteriro = async () => {
        history.goBack();
    };

    return (
        <div className={classes.content}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/biblioteca"
                >
                    Biblioteca
                </Link>
                <Typography className={classes.breadCrumb}>
                    Editar biblioteca
                </Typography>
            </Breadcrumbs>
            <Typography style={{ padding: 16 }} variant="h3" component="h1">
                Editar biblioteca
            </Typography>
            <CardContent>
                <Card>
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={alert.ativo}
                                            value={alert.ativo}
                                            onChange={(event) =>
                                                setAlert({
                                                    ...alert,
                                                    ativo: event.target.checked,
                                                })
                                            }
                                            name="status"
                                        />
                                    }
                                    label="Biblioteca ativo"
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Grid>

                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Nome"
                                    name="nome"
                                    margin="normal"
                                    value={alert.nome}
                                    error={validationErrors.nome}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Descrição"
                                    name="descricao"
                                    margin="normal"
                                    multiline="true"
                                    value={alert.descricao}
                                    error={validationErrors.Alert}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                    
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    select
                                    label="Categoria"
                                    name="categoria_id"
                                    value={Number(alert.categoria_id)}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    fullWidth
                                >
                                    {categorias.map((categoria) => (
                                        <MenuItem
                                            value={categoria.categoria_id}
                                        >
                                            {categoria.nome}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>
                            <Grid
                                                    item
                                                    container
                                                    justify="center"
                                                    alignItems="center"
                                                    direction="row"
                                                    xs={12}
                                                    md={12}
                                                
                                                >
                                                    <div
                                                        style={{
                                                            width: "280px",
                                                            marginLeft: 40,
                                                          
                                                        }}
                                                        {...getRootProps2({
                                                            className:
                                                                classes.dropZoneImage,
                                                        })}
                                                    >
                                                        {imagem.length === 0 ? 
                                                            files2.length === 0 ? (
                                                                <>
                                                                    <input
                                                                        {...getInputProps2()}
                                                                    />

                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        component="p"
                                                                        gutterBottom={
                                                                            false
                                                                        }
                                                                        style={{
                                                                            padding: 40,
                                                                            marginTop: 10,
                                                                        }}
                                                                    >
                                                                        Arraste ou
                                                                        clique para
                                                                        adicionar a
                                                                        foto do
                                                                        livro.
                                                                        {/* {moduloId} */}
                                                                    </Typography>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img
                                                                        style={{
                                                                            maxHeight:
                                                                                "100%",
                                                                            maxWidth:
                                                                                "100%",
                                                                        }}
                                                                        src={
                                                                            files2.filter(
                                                                                (
                                                                                    file
                                                                                ) =>
                                                                                    true
                                                                            )
                                                                                .conteudo_id
                                                                                ? `${API_HOST}/assets/conteudos/${
                                                                                    files2.filter(
                                                                                        (
                                                                                            file
                                                                                        ) =>
                                                                                            true
                                                                                    )
                                                                                        .arquivo
                                                                                }`
                                                                                : URL.createObjectURL(
                                                                                    files2.filter(
                                                                                        (
                                                                                            file
                                                                                        ) =>
                                                                                            true
                                                                                    )[0]
                                                                                )
                                                                        }
                                                                    />
                                                                    
                                                           
                                                                </>
                                                            )
                                                        :
                                                            imagem.map((teste) => (
                                                                <h1>
                                                                     <img
                                                                    style={{
                                                                        maxHeight:
                                                                            "100%",
                                                                        maxWidth:
                                                                            "100%",
                                                                    }}
                                                                    src={
                                                                        `${API_HOST}/assets/conteudos/${teste.arquivo}`
                                                                           
                                                                    }
                                                                />
                                                                </h1>
                                                            ))
                                                        }
                                                        
                                                    </div>
                                                    {files2.filter(
                                                        (file) =>
                                                            true
                                                    ).length > 0 || imagem.length != 0 && (
                                                        <Tooltip
                                                            title="Remover imagem"
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                style={{
                                                                    marginRight: 25,
                                                                }}
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() =>
                                                                    handleRemoveImage2(
                                                                        "tre"
                                                                    )
                                                                }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                        {livro.length != 0 ? livro.map((item) => (
                            <div style={{padding: "25px", border: '3px solid rgba(0, 0, 0)', display: "flex", alignItems: "center", flexDirection: "column" }}>
                                    <h1 style={{fontSize: "15px"}}>Livro</h1>
                                
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <h1 style={{fontSize: "12px"}}>{item.nome_arquivo}</h1>
                                    <Tooltip
                                        title="Remover conteúdo"
                                        placement="top"
                                        arrow
                                        >
                                        <IconButton
                                            style={{
                                                marginRight: 2,
                                            }}
                                            edge="end"
                                            aria-label="delete"
                                            onClick={removeFileFilesBook(
                                                "te"
                                                )}
                                                >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) : 
                            <Grid item xs={12} style={{ display: 'flex' }}  justify="center" alignItems="center">
                                <div
                                    style={{ marginTop: 40 }}
                                    {...getRootProps({
                                        className:
                                            classes.dropZone,
                                    })}
                                >
                                    <input
                                        {...getInputProps()}
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        component="p"
                                        gutterBottom={false}
                                    >
                                        Arraste ou clique para
                                        adicionar o pdf
                                    </Typography>
                                </div>
                            </Grid>
                        }
                        {files.length != 0 &&
                        <div >
                                <h1>{files[0].name}</h1>
                                <Tooltip
                                    title="Remover imagem"
                                    arrow
                                    placement="top"
                                >
                                    <IconButton
                                        style={{
                                            marginRight: 25,
                                        }}
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() =>
                                            handleRemoveImage(
                                                "tre"
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                        </div>

                            }
                        </Grid>
                        <Grid
                            container
                            justify="flex-end"
                            style={{ marginTop: 90 }}
                        >
                            <Button
                                color="default"
                                onClick={handlePageAnteriro}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleEditBiblioteca}
                                variant="contained"
                                color="primary"
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Box>
                </Card>
            </CardContent>
        </div>
    );
};

export default BibliotecaEdit;
