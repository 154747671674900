import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(3),
    },
}));

const Footer = (props) => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Typography variant="body1">
                &copy;{' '}
                <Link
                    component="a"
                    href="https://www.deltagrupo.com.br/"
                    target="_blank"
                >
                    Delta Global
                </Link>
            </Typography>
            <Typography variant="caption">
                Todos os direitos reservados
            </Typography>
        </div>
    );
};

Footer.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
};

export default Footer;
