import styled from 'styled-components';

export const RedefinaContainer = styled.div`
    background: #0000001a;
    border-radius: 10px;
    padding: 50px;
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 0;
    }
`;

export const RedefinaTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    & span {
        font-size: 1.8rem;
        font-weight: 200;
        color: #ffffff99;
    }
    & form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        & button  {
            margin-top: 20px;
        }
    }
`;
