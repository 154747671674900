import React from 'react';
import { Box, Grid, MenuItem } from '@material-ui/core';
import moment from 'moment';

import CustomTextField from '../../../../components/admin/CustomTextField';
import CustomKeyboardDatePicker from '../../../../components/admin/CustomKeyboardDatePicker';
import { hasAccess } from '../../../../services/auth';

const UsuarioForm2 = (props) => {
    const {
        perfis,
        parceiros,
        usuario,
        setUsuario,
        handleInputChange,
        validationErrors,
        setores
    } = props;

    return (
        <Box p={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <CustomTextField
                        select
                        label="Setor"
                        name="setor"
                        value={Number(usuario.setor)}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                    >
                        <MenuItem value={0} key={0}>Selecionar</MenuItem>
                        {setores.map((categoria) => (
                            <MenuItem value={categoria.setor_usuario_id} key={categoria.setor_usuario_id}>
                                {categoria.nome}
                            </MenuItem>
                        ))}
                    </CustomTextField>
                </Grid>

                {usuario.setor == 1 &&
                    <Grid item xs={12} sm={4}>
                        <CustomTextField
                            select
                            label="Escala"
                            name="escala"
                            value={usuario.escala || 0}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                        >
                            <MenuItem value={0} key={0}>Selecionar</MenuItem>
                            <MenuItem value={1} key={1}>Dia</MenuItem>
                            <MenuItem value={2} key={2}>Noite</MenuItem>
                            <MenuItem value={3} key={3}>Madrugada</MenuItem>

                        </CustomTextField>
                    </Grid>
                }
                <Grid item xs={12} sm={4}>
                    <CustomTextField
                        label="Cargo"
                        name="cargo"
                        value={usuario.cargo}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomTextField
                        label="Gestor"
                        name="gestor"
                        value={usuario.gestor}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <CustomTextField
                        label="E-mail gestor"
                        name="email_gestor"
                        value={usuario.email_gestor}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <CustomTextField
                        select
                        label="Perfil"
                        name="perfil_id"
                        required
                        value={usuario.perfil_id}
                        error={validationErrors.perfil}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                    >
                        {perfis.map((perfil) => (
                            <MenuItem value={perfil.perfil_id}>
                                {perfil.nome}
                            </MenuItem>
                        ))}
                    </CustomTextField>
                </Grid>

                {hasAccess.Adicionar ??
                    <Grid item xs={12} sm={4}>
                        <CustomTextField
                            select
                            label="Parceiro"
                            name="parceiro_id"
                            required
                            value={usuario.parceiro_id}
                            error={validationErrors.parceiros}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                        >
                            {parceiros.map((parceiro) => (
                                <MenuItem value={parceiro.parceiro_id}>
                                    {parceiro.nome}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </Grid>
                }

                <Grid item xs={12} md={4}>
                    <CustomKeyboardDatePicker
                        label="Data de admissao"
                        value={
                            usuario.data_admissao ? usuario.data_admissao : null
                        }
                        onChange={(val) =>
                            setUsuario({
                                ...usuario,
                                data_admissao: val
                                    ? moment(val).format('YYYY-MM-DD')
                                    : null,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <CustomKeyboardDatePicker
                        label="Data de cadastro"
                        value={usuario.created_at ? usuario.created_at : moment().format('YYYY-MM-DD')}
                        disabled={true}
                        onChange={(val) =>
                            setUsuario({
                                ...usuario,
                                created_at: val
                                    ? moment(val).format('YYYY-MM-DD')
                                    :  moment().format('YYYY-MM-DD'),
                            })
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default UsuarioForm2;
