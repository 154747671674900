import styled from 'styled-components'
import image from '../../../assets/img/default-array-null.svg'

export const NenhumDadoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
`

export const NenhumDadoText = styled.div`
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.4rem;
    opacity: 0.6;
    color: #fff;
`

export const NenhumDadoImage = styled.div`
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: center 50px;
    width: 100%;
    height: 50vh;
`