import React, { useState, useEffect } from 'react';
import RadioButton from '../../../../../components/aluno/Inputs/RadioButton';
import TextInput from '../../../../../components/aluno/Inputs/TextInput';
import Checkbox from '../../../../../components/aluno/Inputs/Checkbox';
import { API_HOST } from '../../../../../config_servers';
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

import Carousel, { arrowsPlugin  } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {
    AlternativasContainer,
    Divider,
    QuestaoContainer,
    QuestaoIndex,
    QuestaoTitle,
    QuestaoTitleContainer,
} from './style';

const Questao = ({
    tipo,
    number,
    title,
    respostas,
    questao_id,
    handleChangeRespostas,
    indexQuestao,
    questao,
}) => {
    const [validation, setValidation] = useState(null);
    const [resposta, setResposta] = useState(null);
    const [imageNumberDots, setImageNumberDots] = useState(0)

  

    useEffect(() => {
        if (resposta !== null) {
            setValidation('respondida');
        } else {
            setValidation(null);
        }
    }, [resposta]);

    return (
        <>
            <Divider />
            <QuestaoContainer tipo={tipo}>
                <QuestaoTitleContainer>
                    <QuestaoIndex>{number}.</QuestaoIndex>
                    <QuestaoTitle>{title}</QuestaoTitle>
                </QuestaoTitleContainer>
                
                {questao.imagens?.length === 1 ? 
                    <img style={{borderRadius: '10px'}} alt="blabla" src={`${API_HOST}/assets/conteudos/${questao.imagens[0].arquivo}`} />
                :
                questao.imagens?.length > 0 && 
                    <>
                        <Carousel
                            plugins={[
                            {
                                resolve: arrowsPlugin,
                                options: {
                                arrowLeft: <FiChevronsLeft style={{color: '#FFCF02', cursor: 'pointer'}} />,
                                arrowLeftDisabled:<FiChevronsLeft  style={{color: '#000', cursor: 'pointer'}} />,
                                arrowRight: <FiChevronsRight style={{color: '#FFCF02', cursor: 'pointer'}}/>,
                                arrowRightDisabled: <FiChevronsRight   style={{color: '#000', cursor: 'pointer'}}/>,
                                addArrowClickHandler: true,
                                }
                            }
                            ]}
                        >
                            {questao.imagens.map(img => (
                                <img alt="imagem da questão" src={`${API_HOST}/assets/conteudos/${img.arquivo}`} />
                            ))}
                        </Carousel>     
                    </>
                }

                <AlternativasContainer>
                    {respostas.map((item, indexResposta) => (
                        <>
                            {tipo === 'multipla_escolha' ? (
                                <RadioButton
                                    onChange={(event) =>
                                        handleChangeRespostas(
                                            event,
                                            indexQuestao,
                                            indexResposta,
                                            tipo
                                        )
                                    }
                                    name={questao_id}
                                    value={item.resposta_questao_id}
                                    checked={
                                        item.resposta_questao_id ===
                                        Number(questao.multipla_escolha_correta)
                                    }
                                    id={item.resposta_questao_id}
                                    label={item.texto}
                                />
                            ) : tipo === 'caixa_selecao' ? (
                                <>
                                    <Checkbox
                                        large
                                        label={item.texto}
                                        name={item.resposta_questao_id}
                                        checked={
                                            !!item.checkbox_resposta_correta
                                        }
                                        onChange={(event) =>
                                            handleChangeRespostas(
                                                event,
                                                indexQuestao,
                                                indexResposta,
                                                tipo
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <TextInput
                                    placeholder="Responda aqui"
                                    validation={questao.resposta ? true : null}
                                    value={questao.resposta}
                                    name="resposta"
                                    questoes
                                    onChange={(event) =>
                                        handleChangeRespostas(
                                            event,
                                            indexQuestao,
                                            indexResposta,
                                            tipo
                                        )
                                    }
                                />
                            )}
                        </>
                    ))}
                </AlternativasContainer>
            </QuestaoContainer>
        </>
    );
};

export default Questao;
