import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';

export const Container = styled.ul`
    display: flex;
    list-style-type: none;
    color: #ffffff;
    align-items: center;
    & a,
    li {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.1;
        @media (max-width: 450px) {
            width: min-content;
        }
    }
    & a {
        text-decoration: none;
        color: ${colors.primary};
    }
    & li:last-child {
        opacity: 0.6;
    }
    & svg {
        opacity: 0.3;
        margin: 0 1.5rem;
        height: 0.739rem;
        width: 0.92rem;
    }
`;
