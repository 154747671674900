/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { hasAccess } from 'services/auth';
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SettingsIcon from "@material-ui/icons/Settings";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import EditIcon from "@material-ui/icons/Edit";
import CustomTextField from "../../../components/admin/CustomTextField";
import getValidationErors from "../../../utils/geValidationErrors";

import {
  Breadcrumbs,
  Button,
  Box,
  IconButton,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  MenuItem,
  TableRow,
  TablePagination,
  TableContainer,
  TextField,
  Menu,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import api from "../../../services/api";
import { hasAccess } from '../../../services/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  breadCrumb: {
    color: theme.secondary,
    fontSize: "11px",
    fontWeight: 500,
    letterSpacing: "0.33px",
    lineHeight: "13px",
    textTransform: "uppercase",
  },
  paper: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 420,
  },
  input: {
    flexGrow: 1,
    fontSize: "14px",
    lineHeight: "16px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    maxHeight: 450,
  },
  autocomplete: {
    backgroundColor: "white",
    // height: '45.625px'
  },
}));

const ContatoList = () => {
  //   const acesso = hasAccess("Conteudo", "assuntos");
  const acessoAdmin = hasAccess("Configuracoes", "Parceiros");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // const acesso = hasAccess('Controle de Acesso', 'Usuários');
  const [initialFilters] = useState({
    nome: "",
    modulo_id: "",
    usuario_id: "",
  });
  const [filters, setFilters] = useState(initialFilters);
  const [historico, setHistorico] = useState(false);
  const [assuntos, setAssuntos] = useState([]);
  const [idSelected, setIdSelected] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [conversaHistorico, setConversaHistorico] = useState({});
  const [usuario_id, setUsuario_id] = useState({});
  const [listaHistorico, setListaHistorico] = useState([]);
  const [
    openCadastroconversaHistorico,
    setOpenCadastroconversaHistorico,
  ] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const open = Boolean(anchorEl);

  useEffect(() => {
    getMensagem(filters, currentPage, perPage);
    getUsuarios();
  }, [currentPage, perPage]);

  const RespostaHistorico = async () => {
    try {
      const data = {
        contato_resposta: `${localStorage.getItem("@treinadelta/usuario_id")}`,
        mensagem: conversaHistorico.mensagem,
        contato_id: idSelected,
      };
      await api.post("/contatos/historico", data);
      enqueueSnackbar('Resposta enviada com sucesso!', {
        variant: 'success',
    });
      closeMenuRespostaHistorico();
    } catch (error) {
      enqueueSnackbar("Erro ao criar a resposta ao contato", {
        variant: "error",
      });
    }
  };

  const getMensagem = async (filters = [], currentPage = 0, perPage = 10) => {
    try {
      setLoading(true);
      const response = await api.get("/contatos/filter", {
        params: {
          ...filters,
          currentPage: currentPage + 1,
          perPage,
        },
      });
      console.log(response.data.data);
      setAssuntos(response.data.data);

      setPaginationInfo({
        pages: response.data.pagination.lastPage,
        total: response.data.pagination.total,
      });
      setLoading(false);
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
        });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setConversaHistorico({ ...conversaHistorico, [name]: value });
  };

  const closeMenuActions = () => {
    setAnchorEl(null);
  };

  const conversaHistoricoOpen = async (id) => {
    const response = await api.get(`/contatos/historico/${id}`);
    console.log(response.data);

    setListaHistorico(response.data);
    setHistorico(true);
  };

  const openMenuActions = (event, id, usuario_id) => {
    setAnchorEl(event.currentTarget);
    setIdSelected(id);
    setUsuario_id(usuario_id);
    console.log(
      "🚀 ~ file: GrupoList.js ~ line 194 ~ openMenuActions ~ id",
      id
    );
  };

  const handleCurrentPageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (event) => {
    setCurrentPage(0);
    setPerPage(event.target.value);
  };

  const handleLimparFiltro = () => {
    setFilters(initialFilters);
    getMensagem(null, currentPage, perPage);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setCurrentPage(0);
    getMensagem(filters);
  };

  const handleOpenEditconversaHistorico = async () => {
    closeMenuActions();
    setOpenCadastroconversaHistorico(true);
  };

  const closeMenuRespostaHistorico = () => {
    setOpenCadastroconversaHistorico(false);
    setConversaHistorico({});
  };

  const getUsuarios = async () => {
    try {
      const response = await api.get("/contatos");
      const itens = response.data;
      setUsuarios(itens);
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Breadcrumbs
            className={classes.breadCrumb}
            aria-label="breadcrumb"
            gutterBottom
          >
            <Link color="inherit" component={RouterLink} to="/admin/">
              Home
            </Link>
            <Typography className={classes.breadCrumb}>Contato</Typography>
          </Breadcrumbs>
          <Typography variant="h3" component="h1">
            Contato
          </Typography>
        </Grid>
      </Grid>

      <form onSubmit={handleSearch}>
        <Box mt={4}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="start"
            alignItems="center"
          >
            <Grid item xs={9} sm={3}>
              <Autocomplete
                getOptionLabel={(usuarios) => usuarios.nome}
                options={usuarios}
                value={
                  usuarios.find((item) => item.nome === filters.nome)
                    ? usuarios.find((item) => item.nome === filters.nome)
                    : null
                }
                onChange={(event, newValue) => {
                  setFilters({
                    ...filters,
                    nome: newValue?.nome ?? "",
                  });
                }}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.autocomplete}
                    label="Usuário"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} alignItems="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
              >
                <SearchIcon />
              </Button>
              <Button
                style={{ marginLeft: "2%" }}
                onClick={handleLimparFiltro}
                variant="outlined"
                color="primary"
                className={classes.autocomplete}
                size="medium"
                type="submit"
              >
                Limpar filtros
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent style={{ padding: 0 }}>
                <PerfectScrollbar>
                  <TableContainer
                    className={isSmallScreen && classes.container}
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Usuário</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Mensagem</TableCell>
                          { acessoAdmin.visualizar && (
                            <TableCell>Parceiro</TableCell>
                          )}
                          <TableCell>Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {assuntos?.length === 0 ? (
                              <TableRow>
                                <TableCell align="center" colSpan={5}>
                                  <Typography variant="body1">
                                    Nenhum registro existente!
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <>
                                {assuntos.map((comentario) => (
                                  <TableRow hover key={comentario.contato_id}>
                                    <TableCell>{comentario.contato_id}</TableCell>
                                    <TableCell>{comentario.nome}</TableCell>

                                    <TableCell>
                                      {`${comentario.email}`}
                                    </TableCell>
                                    <TableCell>{comentario.assunto}</TableCell>
                                    { acessoAdmin.visualizar && (
                                        <TableCell>
                                        {
                                            comentario.nome_parceiro ? comentario.nome_parceiro : '-'
                                        }
                                        </TableCell>
                                    )
                                    }
                                    <TableCell align="right">
                                      <IconButton
                                        size="small"
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        color="secondary"
                                        onClick={(e) =>
                                          openMenuActions(
                                            e,
                                            comentario.contato_id,
                                            comentario.usuario_id
                                          )
                                        }
                                      >
                                        <SettingsIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </PerfectScrollbar>
              </CardContent>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <TablePagination
                  component="div"
                  count={paginationInfo.total}
                  onChangePage={handleCurrentPageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  page={currentPage}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenuActions}
      >
        <MenuItem onClick={handleOpenEditconversaHistorico}>
          <ListItemIcon style={{ minWidth: "32px" }}>
            <EditIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Responder" />
        </MenuItem>
        <MenuItem onClick={() => conversaHistoricoOpen(idSelected)}>
          <ListItemIcon
            style={{
              minWidth: "32px",
            }}
          >
            <ViewHeadlineIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Histórico" />
        </MenuItem>
      </Menu>

      <Dialog
        scroll="paper"
        fullWidth="true"
        maxWidth="xs"
        open={openCadastroconversaHistorico}
        onClose={closeMenuRespostaHistorico}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{"Responder contato"}</DialogTitle>

        <DialogContent>
          <Grid container spacing={2} justify="center">
            <CustomTextField
              label="Resposta"
              name="mensagem"
              value={conversaHistorico.mensagem}
              error={validationErrors.mensagem}
              onChange={handleInputChange}
              margin="normal"
              fullWidth
              required
              displayEmpty
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: 16 }}>
          <Button onClick={closeMenuRespostaHistorico} color="primary">
            Cancelar
          </Button>
          <Button onClick={RespostaHistorico} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        scroll="paper"
        fullWidth="true"
        maxWidth="xs"
        open={historico}
        onClose={() => setHistorico(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {"Historico do contato"}
        </DialogTitle>

        <DialogContent>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Mensagem</TableCell>
                <TableCell>Data e hora</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {listaHistorico?.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        <Typography variant="body1">
                          Nenhum contato existente!
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {listaHistorico.map((contato) => (
                        <TableRow hover key={contato.contato_historico_id}>
                          <TableCell>{contato.nome}</TableCell>
                          <TableCell>{contato.mensagem}</TableCell>
                          <TableCell>
                            {moment(contato.created_at).subtract(
                                3,
                                'hours'
                            ).format("L  HH:mm")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions style={{ marginTop: 16 }}>
          <Button onClick={() => setHistorico(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContatoList;
