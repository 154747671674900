import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)',
        },
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    nav: {
        marginBottom: theme.spacing(2),
    },
}));

const Sidebar = (props) => {
    const { open, variant, onClose, className, ...rest } = props;

    const classes = useStyles();

    const menu = [
        {
            title: 'Cadastros',
            icon: <AssignmentIcon />,
            submenus: [
                {
                    title: 'Associados',
                    href: '/associados',
                },
                {
                    title: 'Veículos',
                    href: '/veiculos',
                },
                {
                    title: 'Tipos de Equipamentos',
                    href: '/tipos_equipamentos',
                },
                {
                    title: 'Tipos de Documentos',
                    href: '/tipos_documentos',
                },
                {
                    title: 'Planos AC',
                    href: '/planos_ac',
                },
                // {
                //    title: 'Cotas',
                //    href: '/cotas'
                // },
                // {
                //    title: 'Grupos',
                //    href: '/grupos'
                // },
                // {
                //    title: 'Usuários',
                //    href: '/usuarios'
                // },
            ],
        },
    ];

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div {...rest} className={clsx(classes.root, className)}>
                {/*
        <Profile />
        <Divider className={classes.divider} />
         */}
                <SidebarNav className={classes.nav} menu={menu} />
                {/* <UpgradePlan /> */}
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    // eslint-disable-next-line react/require-default-props
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

export default Sidebar;
