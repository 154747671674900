/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable  prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useHistory, Link as RouterLink, useParams } from "react-router-dom";

import {
    Grid,
    Card,
    CardContent,
    Divider,
    Breadcrumbs,
    Button,
    Link,
    CircularProgress,
    Typography,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/styles";
import api from "../../../services/api";
import { getUsuarioId } from "../../../services/auth";
import TrilhaForm1 from "./components/TrilhaForm1";
import TrilhaForm2 from "./components/TrilhaForm2";

const modulosInit = {
    modulos: [{}],
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    saveButton: {
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
}));

const TrilhasEdit = (props) => {
    const { match } = props;

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { id } = useParams();

    const [modulos, setModulos] = useState({});
    const [trilha, setTrilha] = useState({ modulos: [{}] });
    const [validationErrors] = useState({});
    const [categorias, setCategorias] = useState([]);
    const [usuario, setUsuario] = useState();
    const [desativaStatus, setDesativaStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const [files, setFiles] = useState([]);
    const [files2, setFiles2] = useState([]);
    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const [isGerenciarConteudo] = useState(match.url.includes("conteudo"));

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "status") {
            setTrilha({
                ...trilha,
                data: {
                    ...trilha.data,
                    ativo: event.target.checked,
                },
            });
        } else {
            setTrilha({
                ...trilha,
                data: { ...trilha.data, [name]: value },
            });
        }
    };

    const getCategorias = async () => {
        try {
            setLoading(true);

            const response = await api.get("/categorias");
            setCategorias(response.data);
        } catch (error) {
            setLoading(false);

            if (error.response) {
                enqueueSnackbar("Falha ao buscar categoria", {
                    variant: "error",
                });
            }
        }
    };

    const getUsuario = async () => {
        try {
            const response = await api.get(`/usuarios/${getUsuarioId()}`);

            if (response.data.perfil_id !== 3) {
                setDesativaStatus(true);
                setTrilha({ ...trilha, status: 1 });
            }
            setUsuario(response.data);
        } catch (error) {
            setLoading(false);

            if (error.response) {
                enqueueSnackbar("Falha ao buscar usuario.", {
                    variant: "error",
                });
            }
        }
    };

    const handleBackToPage = async () => {
        history.goBack();
    };

    const getTrilhas = async () => {
        const response = await api.get(`/trilhas/dados/${id}`);
        const responseModulos = await api.get(
            `/modulos/trilha/${response.data.trilha_id}`
        );

        const data = response.data;
        setTrilha({ data, modulos: [...responseModulos.data] });
        let newFiles = [];
        for (const item of responseModulos.data) {
            newFiles = [...newFiles, ...item.conteudo];
        }
        for (const [index, file] of newFiles.entries()) {
            file.posicao = index;
        }

        setFiles(newFiles);

        setLoading(false);
    };

    useEffect(() => {
        getUsuario();
        getCategorias();
        getTrilhas();
    }, []);

    const handleEditTrilha = async () => {
        try {
            const { modulos, ...trilhas } = trilha;
            await api.put(`/trilhas/${id}`, trilhas.data);
            enqueueSnackbar("Trilha editada com sucesso!", {
                variant: "success",
            });
            history.push("/admin/trilhas");
        } catch (error) {
            enqueueSnackbar("Falha ao buscar trilha.", {
                variant: "error",
            });
        }
    };

    const handleEditConteudo = async () => {
        try {
            const { modulos } = trilha;

            for await (const [index, modulo] of modulos.entries()) {
                if (!modulo.modulo_id && !modulo.remover) {
                    const conteudoDoModulo = files.filter(
                        (file) => file.index === index && !file.remover
                    );

                    const response = await api.post("/modulos", {
                        modulo,
                        trilhaId: id,
                    });

                    const moduloId = response.data.modulo_id;

                    const formData = new FormData();

                    for (const file of conteudoDoModulo) {
                        formData.append("file", file);
                    }

                    formData.append("modulo_id", JSON.stringify(moduloId));

                    await api.post("/conteudos", formData);
                } else if (modulo.modulo_id && !modulo.remover) {
                    const conteudoExistenteDoModulo = files.filter(
                        (file) => file.index === index && file.conteudo_id
                    );

                    modulo.conteudo = conteudoExistenteDoModulo;
                    await api.put(`/modulos/trilha/${id}`, { modulo });

                    const novoConteudoDoModulo = files.filter(
                        (file) => file.index === index && !file.conteudo_id
                    );

                    if (novoConteudoDoModulo.length > 0) {
                        const formData = new FormData();

                        for (const file of novoConteudoDoModulo) {
                            formData.append("file", file);
                        }

                        formData.append(
                            "modulo_id",
                            JSON.stringify(modulo.modulo_id)
                        );

                        await api.post("/conteudos", formData);
                    }
                } else if (modulo.modulo_id && modulo.remover) {
                    await api.delete(`/modulos/${modulo.modulo_id}`);
                }
            }

            enqueueSnackbar("Conteúdo editadao com sucesso!", {
                variant: "success",
            });
            // history.push('/admin/trilhas');
        } catch (error) {
            console.log(
                "🚀 ~ file: TrilhasEdit.js ~ line 247 ~ handleEditConteudo ~ error",
                error
            );
            enqueueSnackbar("Falha ao editar o conteúdo.", {
                variant: "error",
            });
        }
    };

    const handleAddmodulos = () => {
        setTrilha({ ...trilha, modulos: [...trilha.modulos, {}] });
    };

    const handleRemoveModulo = (index) => {
        setTrilha({
            ...trilha,
            modulos: trilha.modulos.map((item, index2) =>
                index === index2 ? { ...item, remover: true } : item
            ),
        });
    };

    const handleChangeModulos = async (index, event) => {
        let { name, value } = event.target;
        let modulo = trilha.modulos.find((item, index2) => index === index2);
        modulo[name] = value;
        setTrilha({
            ...trilha,
            modulos: trilha.modulos.map((item, index2) =>
                index === index2 ? modulo : item
            ),
        });
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/trilhas"
                >
                    Trilhas
                </Link>
                <Typography className={classes.breadCrumb}>
                    {isGerenciarConteudo
                        ? "Gerenciar conteúdo"
                        : "Editar trilha"}
                </Typography>
            </Breadcrumbs>
            <Typography variant="h3" component="h1">
                {isGerenciarConteudo ? "Gerenciar conteúdo" : "Editar trilha"}
            </Typography>
            <div className={classes.content}>
                {loading ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: "80vh" }}
                    >
                        <Grid item xs={3}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <div className={classes.content}>
                        <Card>
                            <CardContent>
                                <>
                                    {!isGerenciarConteudo ? (
                                        <TrilhaForm1
                                            handleInputChange={
                                                handleInputChange
                                            }
                                            validationErrors={validationErrors}
                                            categorias={categorias}
                                            usuario={usuario}
                                            setTrilha={setTrilha}
                                            loading={loading}
                                            desativaStatus={desativaStatus}
                                            trilha={trilha.data}
                                        />
                                    ) : (
                                        <TrilhaForm2
                                            trilha={trilha}
                                            setModulos={setModulos}
                                            handleChangeModulos={
                                                handleChangeModulos
                                            }
                                            modulos={trilha}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                            handleAddmodulos={handleAddmodulos}
                                            handleRemoveModulo={
                                                handleRemoveModulo
                                            }
                                            files={files}
                                            setFiles={setFiles}
                                            files2={files2}
                                            setFiles2={setFiles2}
                                        />
                                    )}

                                    <Divider />
                                    <div className={classes.saveButton}>
                                        <Button
                                            onClick={handleBackToPage}
                                            color="default"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={
                                                isGerenciarConteudo
                                                    ? handleEditConteudo
                                                    : handleEditTrilha
                                            }
                                            disabled={loading}
                                        >
                                            Salvar
                                        </Button>
                                    </div>
                                </>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrilhasEdit;
