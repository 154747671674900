import { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { getUsuarioId } from '../services/auth';
import moment from 'moment';
import { useHistory, useLocation, useParams} from 'react-router-dom';

const UsuarioContext = createContext();

const insertLogUserInicioTempoLogado = async (acao) => {
    try {
        await api.post('/usuarios/log/insertLog/', {
            acao,
            usuario_id: getUsuarioId(),
            data_inicio: moment().format('YYYY/MM/DD HH:mm:ss'),
        });
    } catch (error) {
        console.log('error insertLogUserInicioTempoLogado', error);
    }
};



const verificaSeLogTempoLogadoEstaEncerrado = async () => {
    try {
        const response = await api.get(
            `/usuarios/log/verificaSeLogTempoLogadoEstaEncerrado/${getUsuarioId()}`
        );

        const { isUsuarioLogado } = response.data;
        console.log("🚀 ~ file: UserContext.js ~ line 40 ~ verificaSeLogTempoLogadoEstaEncerrado ~ isUsuarioLogado", isUsuarioLogado)

        if (!isUsuarioLogado) {
            insertLogUserInicioTempoLogado('tempo_logado');
        }
    } catch (error) {
        console.log('error verificaSeLogTempoLogadoEstaEncerrado', error);
    }
};

export function UsuarioContextProvider({ children }) {
    const location = useLocation()
    const [imagemUsuario, setImagemUsuario] = useState(
        localStorage.getItem('')
    );
    const params = useParams()
    const [conteudoIdEmExecucao, setConteudoIdEmExecucao] = useState(null)

    const encerraLogTempoVideoAssistido = async (conteudo_id) => {
        try {
            await api.get(
                `/usuarios/log/encerraLogTempoVideoAssistido/`, {
                    params: {
                        usuario_id: getUsuarioId(),
                        conteudo_id
                    }
                }
            );
        } catch(error) {
            console.log('error encerraLogTempoVideoAssistido', error)
        }
    }

    const insertLogUserFimTempoLogado = async () => {
        try {
            await api.post('/usuarios/log/insertLog/', {
                acao: 'fim_tempo_logado',
                usuario_id: getUsuarioId(),
                data_fim: moment().format('YYYY/MM/DD HH:mm:ss'),
            });
        } catch (error) {
            console.log('error insertLogUserFimTempoLogado', error);
        }
    };

    useEffect(() => {
        if (location.pathname.includes('watching')) {
            const arrayPathname = location.pathname.split('/')
            const conteudoId = arrayPathname.pop();
            setConteudoIdEmExecucao(conteudoId)
        } else {
            console.log("🚀 ~ file: UserContext.js ~ line 80 ~ useEffect ~ conteudoIdEmExecucao", conteudoIdEmExecucao)
            if (conteudoIdEmExecucao > 0) {
                encerraLogTempoVideoAssistido(conteudoIdEmExecucao)
                setConteudoIdEmExecucao(null)
            }
        }
    }, [location.pathname])

    //controle de tempo logado
    let logado = false;

    useEffect(() => {
        logado = true;
        if (logado) {
            insertLogUserInicioTempoLogado('tempo_logado');
        }
    }, []);

    //evento quando usuario fecha a pagina
    window.onbeforeunload = function (event) {
        event.preventDefault()
        insertLogUserFimTempoLogado();
        delete event['returnValue'];
    };

    window.onfocus = async function () {
        // necessário verificar se o usuário está logado no onfocus, pois caso
        // ele tente recarregar a página, mesmo que não continue acom o recarregamento,
        // o evento onbeforeunload será disparado e irá encerrar o tempo de log.
        verificaSeLogTempoLogadoEstaEncerrado();
    };

    window.onblur = function () {};
   
    return (
        <UsuarioContext.Provider value={{ imagemUsuario, setImagemUsuario, insertLogUserFimTempoLogado }}>
            {children}
        </UsuarioContext.Provider>
    );
}
export const useUsuarioContext = () => useContext(UsuarioContext);
