import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { UserName, Xp } from '../../style';
import {
    HamburguerContainer,
    HamburguerLine,
    SideLinkMenu,
    SideMenu,
    SideMenuBlocker,
    SideUserContainer,
    TitleMenuList,
    SectionMenu,
} from './style';

import { API_HOST } from '../../../../../config_servers';
import api from '../../../../../services/api';
import { getUsuarioId, removeAuthSession } from '../../../../../services/auth';
import { useHistory } from 'react-router-dom';

const HamburguerMenu = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [xAnimation, setXAnimation] = useState(false);
    const [dadosUser, setDadosUser] = useState([]);

    const sideMenu = useRef();
    const blocker = useRef();
    const hamburguer = useRef();
    const user = getUsuarioId();
    const history = useHistory();
    const handleScroll = () => {
        const position = sideMenu.current.scrollTop;
        hamburguer.current.style.transform = `translateY(-${position}px)`;
    };

    useEffect(() => {
        getUser();
        sideMenu.current.addEventListener('scroll', handleScroll, {
            passive: true,
        });
        return () => {
            sideMenu.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const getUser = async () => {
        const usuario = await api.get(`/usuarios/${user}`);
        setDadosUser(usuario.data);
    };
    const toggleOpenMenu = () => {
        if (openMenu === false) {
            document.body.style.overflow = 'hidden';
            window.scrollTo({ top: 0, behavior: 'smooth' });
            sideMenu.current.style.transform = 'translateX(0%)';
            sideMenu.current.style.animationName = 'appear';
            blocker.current.style.animationName = 'appearBlocker';
            setOpenMenu(true);
            setXAnimation(true);
        } else {
            sideMenu.current.scrollTo({ top: 0, behavior: 'smooth' });
            sideMenu.current.style.transform = 'translateX(100%)';
            sideMenu.current.style.animationName = 'disappear';
            blocker.current.style.animationName = 'disappearBlocker';
            setXAnimation(false);
            setTimeout(() => {
                setOpenMenu(false);
                document.body.style.overflow = 'overlay';
            }, 300);
        }
    };
    
    const deslogar = async () => {
        await api.post('/usuarios/log/insertLog/', {
            acao: 'fim_tempo_logado',
            usuario_id: getUsuarioId(),
            data_fim: moment().format('YYYY/MM/DD HH:mm:ss'),
        });
        removeAuthSession();
        history.push('/');
    };

    return (
        <>
            <HamburguerContainer
                onClick={() => {
                    toggleOpenMenu();
                }}
                menuOpen={openMenu}
                xAnimation={xAnimation}
                ref={hamburguer}
            >
                <HamburguerLine menuOpen={openMenu} xAnimation={xAnimation} />
                <HamburguerLine menuOpen={openMenu} xAnimation={xAnimation} />
                <HamburguerLine menuOpen={openMenu} xAnimation={xAnimation} />
            </HamburguerContainer>
            <SideMenu menuOpen={openMenu} ref={sideMenu}>
                <SideUserContainer>
                    <img
                        src={`${API_HOST}/assets/conteudos/${dadosUser?.imagem?.arquivo}`}
                        alt="user"
                    />
                    <UserName>{dadosUser.nome} </UserName>
                    {/* <Xp>1548 Xp</Xp> */}
                </SideUserContainer>
                <SectionMenu>
                    <div>
                        <TitleMenuList>Treina Delta</TitleMenuList>
                        <SideLinkMenu
                            to="/minhatrilhas"
                            activeClassName="selected"
                        >
                            Minhas Trilhas
                        </SideLinkMenu>
                        {/* <SideLinkMenu to="/login" activeClassName="selected">
                            Ajuda
                        </SideLinkMenu> */}
                        <SideLinkMenu to="/areadoaluno/contato" activeClassName="selected">
                            Contato
                        </SideLinkMenu>
                    </div>
                    <div>
                        <TitleMenuList>Minha Área</TitleMenuList>
                        <SideLinkMenu to="/areadoaluno/meusdados" activeClassName="selected">
                            Meus Dados
                        </SideLinkMenu>
                        <SideLinkMenu to="/areadoaluno/meuhistorico" activeClassName="selected">
                            Meu Histórico
                        </SideLinkMenu>
                        <SideLinkMenu to="/areadoaluno/meusfavoritos" activeClassName="selected">
                            Meu Favoritos
                        </SideLinkMenu>
                        <SideLinkMenu to="/areadoaluno/minhasavaliacoes" activeClassName="selected">
                            Minhas Avaliações
                        </SideLinkMenu>
                        <SideLinkMenu to="/areadoaluno/meuscertificados" activeClassName="selected">
                            Meus Certificados
                        </SideLinkMenu>

                        <SideLinkMenu
                            to="/login"
                            onClick={deslogar}
                        >
                            Sair
                        </SideLinkMenu>
                    </div>
                </SectionMenu>
            </SideMenu>
            <SideMenuBlocker
                menuOpen={openMenu}
                ref={blocker}
                onClick={() => {
                    toggleOpenMenu();
                }}
            />
        </>
    );
};

export default HamburguerMenu;
