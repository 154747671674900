/* eslint-disable react/self-closing-comp */

import React, { useEffect, useState } from 'react';

import { FormControlLabel, FormGroup, Grid, MenuItem } from '@material-ui/core';
import moment from 'moment';
import CustomTextField from '../../../../components/admin/CustomTextField';
import CustomKeyboardDatePicker from '../../../../components/admin/CustomKeyboardDatePicker';
import IOSSwitch from '../../../../components/admin/IOSwitch';
import api from '../../../../services/api';

const TrilhaForm1 = (props) => {
    const {
        handleInputChange,
        categorias,
        usuario,
        setTrilha,
        trilha,
        desativaStatus,
    } = props;

    const [perfil, setPerfil] = useState([]);

    const getPerfil = async () => {
        const response = await api.get('/perfis');
        setPerfil(response.data);
    };

    useEffect(() => {
        getPerfil();
    }, []);

    return (
        <Grid container spacing={2} justify="center">
            <>
                <Grid
                    container
                    spacing={2}
                    style={{ marginLeft: '8%', marginTop: 32 }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    checked={trilha.ativo}
                                    value={trilha.ativo}
                                    onChange={handleInputChange}
                                    name="status"
                                />
                            }
                            label="Trilha ativa"
                            labelPlacement="start"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={5}>
                    <CustomTextField
                        label="Produtor"
                        name="produtor"
                        margin="normal"
                        disabled
                        select
                        value={trilha.produtor ?? Number(usuario.usuario_id)}
                        onChange={handleInputChange}
                        fullWidth
                    >
                            <MenuItem value={trilha.produtor ?? usuario.usuario_id}>
                                {trilha.nome_produtor ?? usuario.nome}
                            </MenuItem>
                    </CustomTextField>
                </Grid>
                <Grid item xs={12} md={5}>
                    <CustomTextField
                        select
                        value={trilha?.status}
                        label="Status"
                        name="status"
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        disabled={desativaStatus}
                    >
                        {usuario?.perfil_id === 3 ? (
                            <>
                                <MenuItem value={2}>Liberado</MenuItem>
                                <MenuItem value={3}>Em revisâo</MenuItem>
                            </>
                        ) : (
                            <MenuItem value={1}>Em aprovação</MenuItem>
                        )}
                    </CustomTextField>
                </Grid>

                {trilha.status === 3 && (
                    <Grid item xs={12} md={5}>
                        <CustomTextField
                            required
                            label="Justificativa"
                            name="justificativa"
                            multiline="true"
                            margin="normal"
                            value={trilha.justificativa}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={5}>
                    <CustomTextField
                        required
                        label="Nome"
                        name="nome"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={trilha.nome}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <CustomTextField
                        label="Descrição"
                        name="conteudo"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        multiline="true"
                        value={trilha.conteudo}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <CustomKeyboardDatePicker
                        label="Data de início"
                        required
                        fullWidth
                        name="data_inicio"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        value={trilha.data_inicio ? trilha.data_inicio : null}
                        onChange={(val) =>
                            setTrilha({
                                ...trilha,
                                data_inicio: val
                                    ? moment(val).format('YYYY-MM-DD')
                                    : null,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <CustomKeyboardDatePicker
                        label="Data final"
                        required
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        name="conteudo"
                        value={trilha.data_final ? trilha.data_final : null}
                        onChange={(val) =>
                            setTrilha({
                                ...trilha,
                                data_final: val
                                    ? moment(val).format('YYYY-MM-DD')
                                    : null,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <CustomTextField
                        value={Number(trilha.categoria_id)}
                        required
                        select
                        margin="normal"
                        label="Categoria"
                        InputLabelProps={{ shrink: true }}
                        name="categoria_id"
                        onChange={handleInputChange}
                        fullWidth
                    >
                        {categorias.map((categoria) => (
                            <MenuItem value={categoria.categoria_id}>
                                {categoria.nome}
                            </MenuItem>
                        ))}
                    </CustomTextField>
                </Grid>
            </>

            <Grid item xs={12} md={5}></Grid>
        </Grid>
    );
};

export default TrilhaForm1;
