import React from "react";

import { Container } from "./style";

const TextInput = ({ label, ...rest }) => (
  <Container
    {...rest}
    validation={rest.validation}
    secondary={rest.secondary}
    questoes={rest.questoes}
  >
    <input
      type={rest.type ? rest.type : "text"}
      name="name"
      autoComplete="off"
      disabled={rest.disabled}
      required
      value={rest.value}
      placeholder={rest.placeholder}
    />
    <label  htmlFor="name">
      <span>{label}</span>
    </label>
  </Container>
);

export default TextInput;
