import styled from 'styled-components';

export const SearchMenuContainer = styled.div`
    display: flex;
    padding: 25px 10px 15px 25px;
    border-bottom: 1px solid #00000010;
    & input {
        width: 100%;
        border: 0;
        font-size: 1.3rem;
        color: #33324799;
        border: 0;
        outline: 0;
        &::placeholder {
            font-size: 1.3rem;
            color: #33324799;
        }
    }
`;

export const BtnSearch = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    &&&& svg path {
        height: 21px;
        width: 21px;
        fill: #868EA44d;
    }
`;
