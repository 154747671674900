import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/aluno/Button';
import {
    AdditionalInfo,
    GridInfo,
    Info,
    InfoLabel,
    InfoText,
    InfoTrilhaContainer,
} from './style';
import Modal from '../../../../../components/aluno/Modal';
import ModalInicioAvaliacao from '../../components/InicioAvaliacaoModal';
import api from '../../../../../services/api';
import { useSnackbar } from 'notistack';

const InfoTrilha = ({
    title,
    modulo,
    videos,
    cargaHoraria,
    anexos,
    descricaoAvaliacao,
    dadosAvaliacaoAluno,
}) => {
    const [openModalInicioAvaliacao, setOpenModalInicioAvaliacao] =
        useState(false);

    const toggleOpenModalInicioAvaliacao = () => {
        if (openModalInicioAvaliacao === false) {
            document.body.style.overflowY = 'hidden';
            setOpenModalInicioAvaliacao(true);
        } else {
            document.body.style.overflowY = 'overlay';
            setOpenModalInicioAvaliacao(false);
        }
    };

    const handleModalInicioAvaliacao = (value) => {
        setOpenModalInicioAvaliacao(value);
    };

    return (
        <>
            <InfoTrilhaContainer>
                <GridInfo>
                    <Info>
                        <InfoLabel>Trilha</InfoLabel>
                        <InfoText>{title}</InfoText>
                    </Info>
                    <Info>
                        <InfoLabel>Módulo</InfoLabel>
                        <InfoText>{modulo.nome}</InfoText>
                    </Info>
                    <Info>
                        <InfoLabel>Vídeos</InfoLabel>
                        <InfoText>{videos}</InfoText>
                    </Info>
                    <Info>
                        <InfoLabel>Carga Horária</InfoLabel>
                        <InfoText>{cargaHoraria}</InfoText>
                    </Info>
                    <Info>
                        <InfoLabel>Anexos</InfoLabel>
                        <InfoText>{anexos}</InfoText>
                    </Info>
                </GridInfo>
                <InfoLabel>Avaliação para conclusão de Módulo</InfoLabel>
                {dadosAvaliacaoAluno.avaliacaoEmAndamento ? (
                    <Link
                        to={`/avaliacao/${dadosAvaliacaoAluno.identificador_avaliacao}`}
                    >
                        <Button>Retornar para a avaliação</Button>
                    </Link>
                ) : (
                    <Link
                        onClick={() => {
                            toggleOpenModalInicioAvaliacao();
                        }}
                    >
                        <Button>Iniciar avaliação</Button>
                    </Link>
                )}

                <AdditionalInfo>{descricaoAvaliacao}</AdditionalInfo>
            </InfoTrilhaContainer>

            <Modal
                openModal={openModalInicioAvaliacao}
                handleModal={handleModalInicioAvaliacao}
            >
                <ModalInicioAvaliacao
                    modulo={modulo}
                    handleModal={handleModalInicioAvaliacao}
                    openModal={openModalInicioAvaliacao}
                    dadosAvaliacaoAluno={dadosAvaliacaoAluno}
                />
            </Modal>
        </>
    );
};

export default InfoTrilha;
