/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-fallthrough */
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import {
    Breadcrumbs,
    Box,
    CircularProgress,
    Card,
    Grid,
    Button,
    Link,
    Typography,
    CardContent,
    Divider,
    Tabs,
    Tab,
} from '@material-ui/core';
import * as Yup from 'yup';

import getValidationErors from '../../../utils/geValidationErrors';
import UsuarioForm1 from './components/UsuarioForm1';
import UsuarioForm2 from './components/UsuarioForm2';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '13px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const UsuariosForm = (props) => {
    const { match } = props;

    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [perfis, setPerfis] = useState([]);
    const [setores, setSetores] = useState([]);
    const [parceiros, setParceiros] = useState([]);
    const formRef = useRef(null);
    const [validationErrors, setValidationErrors] = useState({});
    const { id } = match.params;
    const [edit] = useState(id > 0);
    useEffect(() => {
        getPerfis();
        getUsuario();
        getSetores();
        getParceiro();
    }, []);

    const getPerfis = async () => {
        try {
            setLoading(true);
            const response = await api.get('/perfis');
            setPerfis(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getUsuario = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/usuarios/${id}`);
            setUsuario({
                ...response.data,
                senha: '',
                ativo: response.data.ativo === 1,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };
    const getSetores = async () => {
        try {
            const response = await api.get('/usuarios/setores/usuario');
            setSetores(response.data);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar("Não foi possivel buscar os setores", {
                    variant: 'error',
                });
            }
        }
    };

    const getParceiro = async () => {
        try {
            setLoading(true);

            const response = await api.get(`/parceiros/usuario/parceiro`);
            setParceiros(response.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    const handleStepChange = async (event, newValue) => {
        try {
            if (activeStep === 0) {
                if(!usuario.cnpj){
                    const schema = Yup.object().shape({
                        nome: Yup.string().required('Nome obrigatório'),
                        usuario: Yup.string().required('Usuário obrigatório'),
                        cpf: Yup.string()
                            .min(14, 'Insira um CPF válido!')
                            .required('Cpf obrigatório'),
                    });

                    await schema.validate(usuario, {
                        abortEarly: false,
                    });
                } else {
                    const schema = Yup.object().shape({
                        nome: Yup.string().required('Nome obrigatório'),
                        usuario: Yup.string().required('Usuário obrigatório'),
                    });

                    await schema.validate(usuario, {
                        abortEarly: false,
                    });
                }
            }
            if (activeStep === 1) {
                const schema = Yup.object().shape({
                    perfil_id: Yup.number().required('Perfil obrigatório'),
                });

                await schema.validate(usuario, {
                    abortEarly: false,
                });
            }

            setActiveStep(newValue);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUsuario({ ...usuario, [name]: value });
    };

    const handleFinish = async () => {
        try {
            if (activeStep === 0) {
                //usuarios com o cnpj sao os usuarios criados quando cria um parceiro 
                if(!usuario.cnpj){
                    const schema = Yup.object().shape({
                        nome: Yup.string().required('Nome obrigatório'),
                        usuario: Yup.string().required('Usuário obrigatório'),
                        cpf: Yup.string()
                            .min(14, 'Insira um CPF válido!')
                            .required('Cpf obrigatório'),
                    });

                    await schema.validate(usuario, {
                        abortEarly: false,
                    });
                } else {
                    const schema = Yup.object().shape({
                        nome: Yup.string().required('Nome obrigatório'),
                        usuario: Yup.string().required('Usuário obrigatório'),
                    });

                    await schema.validate(usuario, {
                        abortEarly: false,
                    });
                }
            }
            if (activeStep === 1) {
                const schema = Yup.object().shape({
                    perfil_id: Yup.number().required('Perfil obrigatório'),
                });

                await schema.validate(usuario, {
                    abortEarly: false,
                });
            }

            await api.put(`/usuarios/${id}`, usuario);
            history.goBack();
            enqueueSnackbar('Usuário editado com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);

                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '80vh' }}
            >
                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/usuarios"
                >
                    Usuários
                </Link>
                <Typography className={classes.breadCrumb}>
                    Editar Usuário
                </Typography>
            </Breadcrumbs>
            <Typography style={{ marginTop: 16 }} variant="h3" component="h1">
                Editar Usuário
            </Typography>

            <>
                <Tabs
                    value={activeStep}
                    onChange={handleStepChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab value={0} label="Dados gerais" />
                    <Tab value={1} label="Setor" />
                </Tabs>
                <div className={classes.content}>
                    <Card>
                        <CardContent>
                            <TabPanel value={activeStep} index={0}>
                                <UsuarioForm1
                                    formRef={formRef}
                                    usuario={usuario}
                                    setUsuario={setUsuario}
                                    handleInputChange={handleInputChange}
                                    validationErrors={validationErrors}
                                    edit={edit}
                                />
                            </TabPanel>
                            <TabPanel value={activeStep} index={1}>
                                <UsuarioForm2
                                    formRef={formRef}
                                    usuario={usuario}
                                    setUsuario={setUsuario}
                                    perfis={perfis}
                                    parceiros={parceiros}
                                    setores={setores}
                                    validationErrors={validationErrors}
                                    handleInputChange={handleInputChange}
                                />
                            </TabPanel>

                            <Divider />

                            <div className={classes.alignRight}>
                                <Button
                                    color="default"
                                    onClick={handleBackToPage}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleFinish()}
                                    disabled={loading}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </>
        </div>
    );
};

export default UsuariosForm;
