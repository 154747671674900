import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import "moment/locale/pt-br";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    Button,
    Box,
    Grid,
    TextField,
    Paper,
    Select,
    MenuItem,
  } from "@material-ui/core";
import api from "../../../../services/api";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
      backgroundColor: "white",
    }
  }));

const SearchLog = (props) => {
    const classes = useStyles();
    const { handleSearch, setFilters, filters, perfilUsuario } = props;
    const [grupos, setGrupos] = useState([]);
    const [trilha , setTrilha] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [modulos, setModulos] = useState([]);
    const [conteudos, setConteudos] = useState([]);
    const [setores, setSetores] = useState([]);

    const getInputGrupos = async () => {
        try {
            const response = await api.get("/grupos");
            const itens = response.data;
            setGrupos(itens)
        } catch (error) {
            console.log(error)
        }
    };
    const getTrilha =async() => {
        try {
            const response = await api.get("/trilhas")
            setTrilha(response.data)
        } catch (error) {
            console.log(error)
        }
      }
    const getCategoria = async() => {
        try {
            const response = await api.get("/categorias")
            setCategorias(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getUsuario = async() => {
        try {
            const response = await api.get("usuarios/")
            setUsuarios(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getModulosTrilha = async (id) => {
        try {
            const response = await api.get(`/modulos/trilha/${id}`);
            const itens = response.data;
            setModulos(itens);
        } catch (error) {
            console.log(error)
        }
    };

    const getConteudos = async (modulo_id) => {
        try {
            const response = await api.get(`/conteudos/video/${modulo_id}`);
            const itens = response.data;
            setConteudos(itens);
        } catch (error) {
            console.log(error)
        }
    };

    const getSetores = async (modulo_id) => {
        try {
            const response = await api.get(`/usuarios/setores/usuario`);
            const itens = response.data;
            setSetores(itens);
        } catch (error) {
            console.log(error)
        }
    };

    const hundleTrilha = (id) => {
        setFilters({...filters, trilha_id: id, modulo_id: '', conteudo_id: ''})
        getModulosTrilha(id);
        if(id){
            getModulosTrilha(id);
        }
    };
    const hundleModulos = (id) => {
        setFilters({...filters, modulo_id: id, conteudo_id: ''})
        if(id){
            getConteudos(id);
        }
    };
    useEffect(() => {
        getInputGrupos();
        getTrilha();
        getCategoria();
        getUsuario();
        getSetores();
    }, [])

    return (
        <form onSubmit={handleSearch}>
            <Box mt={4}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="start"
                    alignItems="center"
                >
                    <Grid item  style={{marginTop: '5px'}} sm={1}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                            <DatePicker
                                placeholder="De:"
                                value={filters.data_inicio ? filters.data_inicio : null}
                                onChange={(val) =>
                                setFilters({
                                    ...filters,
                                    data_inicio: val
                                    ? moment(val).format("YYYY-MM-DD")
                                    : null,
                                })
                                }
                                margin="normal"
                                okLabel="Selecionar"
                                style={{
                                marginTop: "3px",
                                }}
                                cancelLabel="Cancelar"
                                format="DD/MM/YYYY"
                                clearLabel="Limpar"
                                clearable
                                disableUnderline
                                fullWidth
                                autoOk
                                inputVariant="outlined"
                                className={classes.autocomplete}
                                InputLabelProps={{
                                shrink: true,
                                marginTop: "-16px",
                                }}
                                TextFieldComponent={TextField}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item  style={{marginTop: '5px'}} sm={1}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                            <DatePicker
                                placeholder="Até:"
                                value={filters.data_final ? filters.data_final : null}
                                onChange={(val) =>
                                setFilters({
                                    ...filters,
                                    data_final: val ? moment(val).format("YYYY-MM-DD") : null,
                                })
                                }
                                margin="normal"
                                okLabel="Selecionar"
                                style={{
                                marginTop: "3px",
                                }}
                                cancelLabel="Cancelar"
                                format="DD/MM/YYYY"
                                clearLabel="Limpar"
                                clearable
                                disableUnderline
                                fullWidth
                                autoOk
                                inputVariant="outlined"
                                className={classes.autocomplete}
                                InputLabelProps={{
                                shrink: true,
                                marginTop: "-16px",
                                }}
                                TextFieldComponent={TextField}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Paper className={classes.autocomplete}>
                            <Select
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        usuario_id: e.target.value,
                                    })
                                }
                                value={filters.usuario_id}
                                disableUnderline
                                fullWidth
                                displayEmpty
                                variant="outlined"
                            >
                                <MenuItem value="">
                                    <span style={{ color: '#a4a9ab' }}>
                                        Usuário
                                    </span>
                                </MenuItem>
                                {usuarios.map((usuario) => (
                                    <MenuItem value={usuario.usuario_id}>
                                        {usuario.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Paper>
                    </Grid>
                    <Grid item xs={4} style={{marginTop: '1px'}} sm={2}>
                        <Paper className={classes.autocomplete}>
                            <Select
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        grupo_id: e.target.value,
                                    })
                                }
                                value={filters.grupo_id}
                                disableUnderline
                                fullWidth
                                displayEmpty
                                variant="outlined"
                            >
                                <MenuItem value="">
                                    <span style={{ color: '#a4a9ab' }}>
                                        Grupo
                                    </span>
                                </MenuItem>
                                {grupos.map((grupo) => (
                                    <MenuItem value={grupo.grupo_id}>
                                        {grupo.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Paper>
                    </Grid>
                    <Grid item xs={4} style={{marginTop: '1px'}} sm={2}>
                        <Paper className={classes.autocomplete}>
                            <Select
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        setor_usuario_id: e.target.value,
                                    })
                                }
                                value={filters.setor_usuario_id}
                                disableUnderline
                                fullWidth
                                displayEmpty
                                variant="outlined"
                            >
                                <MenuItem value="">
                                    <span style={{ color: '#a4a9ab' }}>
                                        Setor
                                    </span>
                                </MenuItem>
                                {setores.map((setor) => (
                                    <MenuItem value={setor.setor_usuario_id}>
                                        {setor.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Paper>
                    </Grid>
                    {(filters.setor_usuario_id == 1)  &&
                        <Grid item xs={4} style={{marginTop: '1px'}} sm={2}>
                            <Paper className={classes.autocomplete}>
                                <Select
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            escala_id: e.target.value,
                                        })
                                    }
                                    value={filters.escala_id}
                                    disableUnderline
                                    fullWidth
                                    displayEmpty
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <span style={{ color: '#a4a9ab' }}>
                                            Escala
                                        </span>
                                    </MenuItem>
                                    <MenuItem value={1}>Manhã</MenuItem>
                                    <MenuItem value={2}>Tarde</MenuItem>
                                    <MenuItem value={3}>Noite</MenuItem>
                                </Select>
                            </Paper>
                        </Grid>
                    }
                    {(perfilUsuario == 1 || perfilUsuario == 7)  &&
                        <Grid item xs={4} sm={2}>
                            <Paper className={classes.autocomplete}>
                                <Select
                                    onChange={(e) =>
                                        hundleTrilha(e.target.value)
                                    }
                                    value={filters.trilha_id}
                                    disableUnderline
                                    fullWidth
                                    displayEmpty
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <span style={{ color: '#a4a9ab' }}>
                                            Tilha
                                        </span>
                                    </MenuItem>
                                    {trilha.map((trilha) => (
                                        <MenuItem value={trilha.trilha_id}>
                                            {trilha.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </Grid>
                    }
                    {(filters.trilha_id)  &&
                        <Grid item xs={4} sm={2}>
                            <Paper className={classes.autocomplete}>
                                <Select
                                    onChange={(e) =>
                                        hundleModulos(e.target.value)
                                    }
                                    value={filters.modulo_id}
                                    disableUnderline
                                    fullWidth
                                    displayEmpty
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <span style={{ color: '#a4a9ab' }}>
                                            Modulo
                                        </span>
                                    </MenuItem>
                                    {modulos.map((modulo) => (
                                        <MenuItem value={modulo.modulo_id}>
                                            {modulo.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </Grid>
                    }
                    {(filters.modulo_id)  &&
                        <Grid item xs={4} sm={2}>
                            <Paper className={classes.autocomplete}>
                                <Select
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            conteudo_id: e.target.value,
                                        })
                                    }
                                    value={filters.conteudo_id}
                                    disableUnderline
                                    fullWidth
                                    displayEmpty
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <span style={{ color: '#a4a9ab' }}>
                                            Vídeo
                                        </span>
                                    </MenuItem>
                                    {conteudos.map((conteudo) => (
                                        <MenuItem value={conteudo.conteudo_id}>
                                            {conteudo.nome_arquivo}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                        </Grid>
                    }
                    <Grid item xs={4} sm={2}>
                        <Paper className={classes.autocomplete}>
                            <Select
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        categoria_id: e.target.value,
                                    })
                                }
                                value={filters.categoria_id}
                                disableUnderline
                                fullWidth
                                displayEmpty
                                variant="outlined"
                            >
                                <MenuItem value="">
                                    <span style={{ color: '#a4a9ab' }}>
                                        Categoria
                                    </span>
                                </MenuItem>
                                {categorias.map((categoria) => (
                                    <MenuItem value={categoria.categoria_id}>
                                        {categoria.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Paper>
                    </Grid>
                    <Grid 
                        item
                        alignItems="center"
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="medium"
                        >
                            <SearchIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}
export default SearchLog;