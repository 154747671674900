import styled from "styled-components";

export const CardContainer = styled.div`
    width: 100%;
    height: 370px;
    filter: drop-shadow(3px 4px 6px rgba(0, 0, 0, 0.2));
    display: flex;
    align-items: flex-end;
    padding: 20px 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    border-radius: 7px;
    &:hover {
        transform: scale(1.1);
    }
    &:hover > div:nth-child(1) {
        transform: scale(1.2);
        transition: all 8s ease;
    }
`;

export const TitleTagWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`;

export const TitleCard = styled.h2`
    font-size: 2.8rem;
    color: #fff;
`;

export const InfoCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const HoursContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    & svg path {
        fill: #ffffff99;
    }
    & span {
        line-height: 1;
        color: #ffffff99;
        font-size: 1.4rem;
    }
`;

export const ImgCard = styled.div`
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.81) 100%
        ),
        url(${({ image }) => image || ""}), url(${({ defaultImg }) => defaultImg || ""});
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    transition: 0.3s ease;
`;
