import React from 'react'
import Button from '../../../../../../components/aluno/Button';
import { useHistory } from 'react-router-dom'
import { 
    CardCertificadoContainer,
    CardCertificadoDataConclusao,
    CardCertificadoTrilha,
    CardCertificadoImg,
    CardCertificadoTrilhaInfo,
    CardCertificadoTitle,
    CardCertificadoDescription,
    CardCertificadoInfosButton,
    CardCertificadoWrapperCol,
    InfosCol
} from './style'
import defaultCertificado from '../../../../../../assets/img/default-certificado.png'
import moment from 'moment';
export default function CardCertificado({
    titulo, descricao, modulos, videos, avaliacoes, trilhaId, dataConclusao
}) {
    const history = useHistory();
    return (
        <CardCertificadoContainer>
            <CardCertificadoDataConclusao>Concluído em {moment(dataConclusao).format('LLL')}</CardCertificadoDataConclusao>
            <CardCertificadoTrilha>
                <CardCertificadoImg defaultImg={defaultCertificado} />
                <CardCertificadoTrilhaInfo>
                    <CardCertificadoTitle>{titulo}</CardCertificadoTitle>
                    <CardCertificadoDescription>{descricao}</CardCertificadoDescription>
                    <CardCertificadoInfosButton>
                        <CardCertificadoWrapperCol>
                            <InfosCol>
                                <span>Módulos</span>
                                <span>{modulos}</span>
                            </InfosCol>
                            <InfosCol>
                                <span>Videos</span>
                                <span>{videos}</span>
                            </InfosCol>
                            <InfosCol>
                                <span>Avaliações</span>
                                <span>{avaliacoes}</span>
                            </InfosCol>
                        </CardCertificadoWrapperCol>
                        <Button
                            onClick={() =>
                                history.push(
                                    `/certificado/${trilhaId}`
                                )
                            }
                            certificado
                        >Ver Certificado</Button>
                    </CardCertificadoInfosButton>
                </CardCertificadoTrilhaInfo>
            </CardCertificadoTrilha>
        </CardCertificadoContainer>
    )
}
