import React, { useRef } from 'react';
import { InputTextArea } from './style';

const TextArea = ({ value, placeholder, secondary, onChangeText, id, contato }) => {
    const text = useRef();
    function autoGrow() {
        text.current.style.height = '35px';
        text.current.style.height = text.current.scrollHeight + 1 + 'px';
    }
    return (
        <InputTextArea
            ref={text}
            id={id}
            contato={contato}
            value={value}
            placeholder={placeholder}
            secondary={secondary}
            onInput={() => {
                autoGrow();
            }}
            onChange={onChangeText}
        ></InputTextArea>
    );
};

export default TextArea;
