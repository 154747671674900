/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { hasAccess } from 'services/auth';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIcon from '@material-ui/icons/Assignment';

import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    TextField,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    DialogContentText,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
} from '@material-ui/core';
import { PrimaryChip, ErrorChip, SuccessChip } from '../../../helpers/helper';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    paper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        maxHeight: 450,
    },
    autocomplete: {
        backgroundColor: 'white',
        // height: '45.625px'
    },
}));

const ModulosList = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const acesso = hasAccess('Controle de Acesso', 'Usuários');
    const [initialFilters] = useState({
        nome: '',
    });
    const [filters, setFilters] = useState(initialFilters);
    const [modulos, setModulos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({
        total: 0,
    });
    const [idSelected, setIdSelected] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [modulo, setModulo] = useState({});
    const [setUsuarios] = useState([]);
    const [avaliacoesFilter, setAvaliacoesFilter] = useState([]);
    const [trilha, setTrilha] = useState({});
    const [isOpenExcluirModulo, setIsOpenExcluirModulo] = useState(false);

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleBackToPage = () => {
        history.goBack();
    };

    const { trilhaId } = useParams();

    useEffect(() => {
        getInputavaliacoes();
        getTrilha();
    }, []);

    useEffect(() => {
        getModulosPorTrilhaId(filters, currentPage, perPage);
        getUsuarios();
    }, [currentPage, perPage]);

    const getTrilha = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/trilhas/dados/${trilhaId}`);
            setTrilha(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getInputavaliacoes = async () => {
        try {
            setLoading(true);
            const response = await api.get('/avaliacoes');
            const itens = response.data;
            setAvaliacoesFilter(itens.filter((item) => item.nome));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getUsuarios = async () => {
        try {
            setLoading(true);
            const response = (await api.get(`/avaliacoes`)).data;
            setUsuarios(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };
    const openMenuActions = (event, modulo) => {
        setAnchorEl(event.currentTarget);
        setModulo(modulo);
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const getModulosPorTrilhaId = async (
        filters = [],
        currentPage = 0,
        perPage = 10
    ) => {
        try {
            setLoading(true);
            const response = await api.get('/modulos/filter/trilhaId', {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                    trilhaId,
                },
            });
            setModulos(response.data.data);

            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getModulosPorTrilhaId(filters);
    };

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getModulosPorTrilhaId();
    };

    const handleOpenExcluirModulo = async () => {
        setIsOpenExcluirModulo(true);
        setAnchorEl(null);
    };

    const handleCloseExcluirModulo = async () => {
        setIsOpenExcluirModulo(false);
    };

    const handleExcluirModulo = async () => {
        try {
            setAnchorEl(null);
            handleCloseExcluirModulo();
            await api.delete(`/modulos/${modulo.modulo_id}`);
            await getModulosPorTrilhaId(filters, currentPage, perPage);
            enqueueSnackbar('Módulo removido com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/trilhas"
                        >
                            Trilhas
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Modulos ({trilha.nome})
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Módulos
                    </Typography>
                </Grid>
                <Grid container xs={7} justify="flex-end" alignItems="center">
                    {/* <Button
                        style={{ marginRight: 16 }}
                        component={RouterLink}
                        to={`/admin/trilhas/${trilhaId}/modulos/novo`}
                        color="primary"
                        variant="contained"
                    >
                        Avaliação
                    </Button> */}
                    <Button
                        component={RouterLink}
                        to={`/admin/trilhas/${trilhaId}/modulos/novo`}
                        color="primary"
                        variant="contained"
                    >
                        Novo módulo
                    </Button>
                </Grid>
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={3}>
                            <Autocomplete
                                getOptionLabel={(secoes) => secoes.nome}
                                options={avaliacoesFilter}
                                value={
                                    avaliacoesFilter.find(
                                        (item) => item.nome === filters.nome
                                    )
                                        ? avaliacoesFilter.find(
                                              (item) =>
                                                  item.nome === filters.nome
                                          )
                                        : null
                                }
                                onChange={(event, newValue) => {
                                    setFilters({
                                        ...filters,
                                        nome: newValue?.nome ?? '',
                                    });
                                }}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.autocomplete}
                                        label="Nome"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{
                                    marginLeft: '2%',
                                }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent
                                style={{
                                    padding: 0,
                                }}
                            >
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell>
                                                        Descrição
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: '10%',
                                                        }}
                                                        align="center"
                                                    >
                                                        Vídeos
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: '10%',
                                                        }}
                                                        align="center"
                                                    >
                                                        Status
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: '10%',
                                                        }}
                                                        align="right"
                                                    >
                                                        Ações
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {modulos.length ===
                                                        0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {modulos.map(
                                                                    (
                                                                        modulo
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                modulo.secoes_id
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                style={{
                                                                                    width: '7%',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    modulo.modulo_id_trilha
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    modulo.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    modulo.descricao
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <PrimaryChip
                                                                                    texto={
                                                                                        modulo.qtdVideos ??
                                                                                        0
                                                                                    }
                                                                                    size="medium"
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {modulo.ativo ? (
                                                                                    <SuccessChip texto="Ativo" />
                                                                                ) : (
                                                                                    <ErrorChip texto="Inativo" />
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    color="secondary"
                                                                                    aria-label="more"
                                                                                    aria-controls="long-menu"
                                                                                    aria-haspopup="true"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        openMenuActions(
                                                                                            e,
                                                                                            modulo
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <SettingsIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions
                                style={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    onClick={handleBackToPage}
                                    className={classes.backButton}
                                >
                                    Voltar
                                </Button>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={closeMenuActions}
            >
                <MenuItem
                    component={RouterLink}
                    to={`/admin/trilhas/${trilhaId}/modulos/${modulo.modulo_id}/avaliacao/`}
                >
                    <ListItemIcon
                        style={{
                            minWidth: '32px',
                        }}
                    >
                        <AssignmentIcon color="secondary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Visualizar avaliações" />
                </MenuItem>
                <MenuItem
                    component={RouterLink}
                    to={`/admin/trilhas/${trilhaId}/modulos/${modulo.modulo_id}`}
                >
                    <ListItemIcon
                        style={{
                            minWidth: '32px',
                        }}
                    >
                        <EditIcon color="secondary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Editar módulo" />
                </MenuItem>
                <MenuItem onClick={handleOpenExcluirModulo}>
                    <ListItemIcon style={{ minWidth: '32px' }}>
                        <DeleteIcon color="secondary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Excluir módulo" />
                </MenuItem>
            </Menu>

            <Dialog
                open={isOpenExcluirModulo}
                onClose={handleCloseExcluirModulo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Exclusão de Modulo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O Modulo será excluído, deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExcluirModulo} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleExcluirModulo} color="primary">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModulosList;
