/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react' 
import {
    Box,
    Grid,
    FormControlLabel,
    FormGroup,
    Button,
    Tooltip,
    TextField,
    IconButton,
    MenuItem,
    Breadcrumbs,
    Card,
    CardContent,
    Link,
    Typography,
} from '@material-ui/core' 
import DeleteIcon from "@material-ui/icons/Delete" 
import CardActions from '@material-ui/core/CardActions' 

import { makeStyles } from '@material-ui/styles' 
import { useSnackbar } from 'notistack' 
import { Link as RouterLink, useHistory } from 'react-router-dom' 
import moment from 'moment' 
import CustomTextField from '../../../components/admin/CustomTextField' 
import CustomKeyboardDatePicker from '../../../components/admin/CustomKeyboardDatePicker' 
import IOSSwitch from '../../../components/admin/IOSwitch' 
import api from '../../../services/api' 
import { API_HOST } from "../../../config_servers" 

import { useDropzone } from "react-dropzone" 

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
})) 

const BibliotecaCreate = () => {
    const { enqueueSnackbar } = useSnackbar() 
    const history = useHistory() 
    const classes = useStyles() 
    const [files, setFiles] = useState([]) 
    const [files2, setFiles2] = useState([]) 
    const [indexSelected, setIndexSelected] = useState(null) 

    const [alert, setAlert] = useState({}) 
    const [validationErrors] = useState({}) 
    const [categorias, setCategorias] = useState([]) 
    const [grupos, setGrupos] = useState([])


    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        getFilesFromEvent: (event) => myCustomFileGetter(event),
    }) 


    const handleInputChange = (event) => {
        const { name, value } = event.target 
        setAlert({ ...alert, [name]: value }) 
    } 

    const getCategorias = async () => {
        try {
            const response = await api.get('/categorias') 
            setCategorias(response.data) 
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao buscar categoria', {
                    variant: 'error',
                }) 
            }
        }
    } 

    async function myCustomFileGetter(event) {
        const _files = [] 
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files 

        for (let i = 0 ; i < fileList.length;  i++) {
            const file = fileList.item(i) 

            Object.defineProperty(file, "index", {
                value: indexSelected,
                writable: true,
            }) 

            Object.defineProperty(file, "posicao", {
                value: files.length,
                writable: true,
            }) 

            _files.push(file) 
        }

        return _files 
    }

    async function myCustomFileGetter2(event) {
        const _files = [] 
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files 

        for (let i = 0 ; i < fileList.length ; i++) {
            const file = fileList.item(i) 

            Object.defineProperty(file, "index", {
                value: indexSelected,
                writable: true,
            }) 

            _files.push(file) 
        }
        return _files 
    }
    const removeFileFilesBook = (file, i) => () => {
        setFiles([]);
    };


    const {
        acceptedFiles: acceptedFiles2,
        getRootProps: getRootProps2,
        getInputProps: getInputProps2,
    } = useDropzone({
        accept: 'image/jpeg, image/png',
        getFilesFromEvent: (event) => myCustomFileGetter2(event),
    }) 

    useEffect(() => {
        setFiles([acceptedFiles[0]]) 
    }, [acceptedFiles]) 

    
    useEffect(() => {
        setFiles2([...files2, ...acceptedFiles2]) 
    }, [acceptedFiles2]) 



    const getGrupos = async () => {
        try {
            const response = await api.get('/grupos')
            setGrupos(response.data)
        } catch (error) {
            enqueueSnackbar('Falha ao buscar grupos', {
                variant: 'error',
            }) 
        }
    }

    const handleRemoveImage = (index) => {
        setFiles2([]) 
      
    } 

   
    useEffect(() => {
        getCategorias() 
        getGrupos()
    }, []) 

    const handleCreateBiblioteca = async () => {
        try {
            const response = await api.post('/bibliotecas', alert) 
            const id = response.data.biblioteca_id 
            if (!!files[0]?.name) {
                const formData = new FormData() 

                for (const file of files) {
                    formData.append("file", file) 
                }

                formData.append("biblioteca_id", JSON.stringify(id)) 

                await api.post("/conteudos", formData) 
            }
            if (!!files2[0]?.name) {
                const formData2 = new FormData() 

                for (const file of files2) {
                    formData2.append("file", file) 
                }
                formData2.append(
                    "imagem_biblioteca_id",
                    JSON.stringify(id)
                ) 

                await api.post("/conteudos", formData2) 
            }
            enqueueSnackbar('biblioteca criada com sucesso!', {
                variant: 'success',
            }) 
            history.push('/admin/biblioteca') 
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao criar livro', {
                    variant: 'error',
                }) 
            }
        }
    } 

    const handleBackToPage = () => {
        history.goBack() 
    } 

    return (
        <div>
            <Box p={2}>
                <Breadcrumbs
                    className={classes.breadCrumb}
                    aria-label="breadcrumb"
                    gutterBottom
                >
                    <Link color="inherit" component={RouterLink} to="/admin">
                        Home
                    </Link>
                    <Link
                        color="inherit"
                        component={RouterLink}
                        to="/admin/biblioteca"
                    >
                        Livro
                    </Link>
                    <Typography className={classes.breadCrumb}>
                        Novo livro
                    </Typography>
                </Breadcrumbs>
                <Typography style={{ padding: 16 }} variant="h3" component="h1">
                    Novo livro
                </Typography>
                <div className={classes.content}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={alert.ativo}
                                                value={alert.ativo}
                                                onChange={(event) =>
                                                    setAlert({
                                                        ...alert,
                                                        ativo:
                                                            event.target
                                                                .checked,
                                                    })
                                                }
                                                name="status"
                                            />
                                        }
                                        label="Livro ativo"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        label="Nome"
                                        name="nome"
                                        margin="normal"
                                        value={alert.nome}
                                        error={validationErrors.nome}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        label="Descrição"
                                        name="descricao"
                                        margin="normal"
                                        multiline="true"
                                        value={alert.descricao}
                                        error={validationErrors.Alert}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        value={Number(alert.categoria_id)}
                                        required
                                        select
                                        margin="normal"
                                        label="Categoria"
                                        InputLabelProps={{ shrink: true }}
                                        name="categoria_id"
                                        onChange={handleInputChange}
                                        fullWidth
                                    >
                                        {categorias.map((categoria) => (
                                            <MenuItem value={categoria.categoria_id}>
                                                {categoria.nome}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>

                                <Grid
                                            container
                                            style={{ marginTop: 16 }}
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                direction="column"
                                                xs={4}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    justify="center"
                                                    alignItems="center"
                                                    direction="row"
                                                    xs={12}
                                                    md={12}
                                                >
                                                    <div
                                                        style={{
                                                            width: "280px",
                                                        }}
                                                        {...getRootProps2({
                                                            className:
                                                                classes.dropZoneImage,
                                                        })}
                                                    >
                                                        {files2.filter(
                                                            (file) =>
                                                                true
                                                        ).length === 0 ? (
                                                            <>
                                                                <input
                                                                    {...getInputProps2()}
                                                                />

                                                                <Typography
                                                                    variant="subtitle1"
                                                                    component="p"
                                                                    gutterBottom={
                                                                        false
                                                                    }
                                                                    style={{
                                                                        marginTop: 10,
                                                                        textAlign: 'center',
                                                                        padding: 8,
                                                                        border: '3px solid rgba(0, 0, 0)',
                                                                    }}
                                                                >
                                                                    Arraste ou
                                                                    clique para
                                                                    adicionar a
                                                                    foto do
                                                                    livro
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img
                                                                    style={{
                                                                        maxHeight:
                                                                            "100%",
                                                                        maxWidth:
                                                                            "100%",
                                                                    }}
                                                                    src={
                                                                        files2.filter(
                                                                            (
                                                                                file
                                                                            ) =>
                                                                                true
                                                                        )
                                                                            .conteudo_id
                                                                            ? `${API_HOST}/assets/conteudos/${
                                                                                  files2.filter(
                                                                                      (
                                                                                          file
                                                                                      ) =>
                                                                                         true
                                                                                  )
                                                                                      .arquivo
                                                                              }`
                                                                            : URL.createObjectURL(
                                                                                  files2.filter(
                                                                                      (
                                                                                          file
                                                                                      ) =>
                                                                                         true
                                                                                  )[0]
                                                                              )
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    {files2.filter(
                                                        (file) =>
                                                            true
                                                    ).length > 0 ? (
                                                        <Tooltip
                                                            title="Remover imagem"
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                style={{
                                                                    marginRight: 25,
                                                                }}
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() =>
                                                                    handleRemoveImage(
                                                                        "tre"
                                                                    )
                                                                }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip> 
                                                        )
                                                        :
                                                        <>
                                                        </>
                                                   }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div
                                    style={{ marginTop: 40 , marginBottom: 20}}
                                    {...getRootProps({
                                        className:
                                            classes.dropZone,
                                    })}
                                >
                                    <input
                                        {...getInputProps()}
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        component="p"
                                        gutterBottom={false}
                                    >
                                        Arraste ou clique para
                                        adicionar o pdf
                                    </Typography>
                                </div>
                            </Grid>
                                </Grid>
                            </Grid>
                                {files[0]?.name ?
                                    files.map((file1, index) => (
                                    <h1>
                                        <Card className={classes.root}  variant="outlined">
                                            <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <Typography variant="h5" component="h2">
                                                    { files[index]?.name}
                                                </Typography>
                                                <Tooltip
                                                    title="Remover conteúdo"
                                                    placement="top"
                                                    arrow
                                                    >
                                                    <IconButton
                                                        style={{
                                                            marginRight: 2,
                                                        }}
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={removeFileFilesBook(
                                                            "te"
                                                            )}
                                                            >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </CardContent>
                                        </Card>
                                    </h1>))
                                    :
                                   ( <>
                                    </>)
                                }
                            <div className={classes.alignRight}>
                                <Button
                                    color="default"
                                    onClick={handleBackToPage}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={handleCreateBiblioteca}
                                    variant="contained"
                                    color="primary"
                                >
                                    Salvar
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Box>
        </div>
    ) 
} 

export default BibliotecaCreate 
