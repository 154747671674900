import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';

export const TrilhaContainer = styled.div`
    padding: 200px 70px 100px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    @media (max-width: 1060px) {
        padding: 200px 40px 100px;
    }
    @media (max-width: 502px) {
        padding: 200px 5% 100px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: unset;
    }
`;

export const VideosContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const SectionTitle = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    color: #fff;
    font-size: 2.2rem;
    & svg {
        min-width: 20px;
    }
    & svg path {
        fill: ${colors.primary};
    }
`;

export const GridVideos = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
    gap: 30px;
    margin-left: -22px;

    @media (max-width: 800px) {
        margin-left: 0;
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    @media (max-width: 400px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
`;

export const AttachmentsComments = styled.div`
    display: flex;
    gap: 50px;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const AttachmentsContainer = styled.div`
    display: flex;
    gap: 30px;
    flex-direction: column;
    width: 100%;
`;

export const AttachmentsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-left: -22px;

    @media (max-width: 850px) {
        margin-left: 0;
        gap: 10px;
    }
`;

export const CommentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 40%;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

export const LinkComentar = styled.a`
    font-size: 1.4rem;
    color: ${colors.primary};
    cursor: pointer;
    margin-left: ${({ empty }) => (empty ? '0px' : '60px')};
    margin-top: ${({ empty }) => (empty ? '0' : '-10px')};
    @media (max-width: 800px) {
        margin-left: 0;
    }
`;

export const CommentsWrapper = styled.div`
    max-height: 300px;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 7px;
`;
