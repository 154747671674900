import styled from 'styled-components';

export const RightSideMenu = styled.div`
    display: flex;
    align-items: flex-start;
    border-radius: 15px;
`;

export const NotificationContainer = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
`;

export const NotificationDate = styled.p`
    color: #33324760;
    font-size: 0.1rem;
    font-weight: 500;
`;

export const NotificationTitle = styled.h4`
    color: #fff;
    font-size: 1.0rem;
    margin-bottom: 8px;
`;

export const NotificationText = styled.p`
    color: #a4b1c9;
    font-size: 0.85rem;
    text-align: left;
`;

export const ButttonNotification = styled.button`
    width: 240px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: none;
    border-radius: 15px;
    background: #323448 ;
`;
