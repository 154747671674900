import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';

export const CertificadoPageContainer = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5% 0 80px;
    @media (max-width: 800px) {
        justify-content: flex-start;
        padding: 6% 5%;
    }
`;

export const CertificadoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-top: 5%;
    @media (max-width: 800px) {
        margin-top: 6%;
    } */
`;

export const CertificadoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    & > div {
        display: flex;
        align-items: center;
    }

    @media (min-width: 1440px) {
        width: 84%;
    }

    @media (max-width: 800px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;
    }
`;

export const VoltarModuloContainer = styled.div`
    gap: 15px;
    font-size: 1.6rem;
    cursor: pointer;
    color: white;
    & svg {
        width: 12px;
        height: 14px;
        path {
            fill: ${colors.primary};
        }
    }
`;
export const DownloadPrintContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 23px;
`;

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    gap: 13px;
    font-size: 1.5rem;
    & svg {
        opacity: 0.3;
    }
`;


