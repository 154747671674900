import styled from 'styled-components';

export const Container = styled.div`
    & .checkmark {
        display: block;
        /* width: ${({ large }) => (large ? '2.3rem' : '1.5rem')}; */
        width: fit-content;
        /* height: ${({ large }) => (large ? '2.3rem' : '1.5rem')}; */
        background-color: transparent;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${({ gap }) => gap && gap};
        & .icon-container {
            min-width: ${({ large }) => (large ? '2.2rem' : '1.1rem')};
            min-height: ${({ large }) => (large ? '2.2rem' : '1.1rem')};
            background-color: #ffffff10;
            border-radius: 0.2rem;
            align-self: ${({ center }) => center ? "center" : "flex-start"};
            & svg {
                position: absolute;
                transform: scale(0);
                top: 3px;
                left: 1px;
                transition: all 0.2s ease;
                height: ${({ large }) => large && '2rem'};
                width: ${({ large }) => large && '2.4rem'};
            }
        }
        p {
            /* white-space: nowrap;
            position: absolute;
            left: 22px; */
            font-size: ${({ large }) => large && '1.7rem'};
            color: ${({ large }) => large && '#fff'};
            margin-left: ${({ large }) => large && '20px'};
        }
    }

    & input:checked ~ .checkmark svg {
        transform: scale(1);
        top: ${({ center }) => center ? "" : "1px"};
        left: ${({ center }) => center ? "" : "3px"};
    }
`;
