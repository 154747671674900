/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";

import {
    Box,
    Grid,
    Breadcrumbs,
    TableRow,
    TableCell,
    CircularProgress,
    Link,
    Typography,
    Card,
    CardContent,
    Stepper,
    Step,
    StepLabel,
    Button,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";

import { useSnackbar } from "notistack";
import api from "../../../services/api";
import AvaliacaoCadastro from "./components/AvaliacaoFormCadastro";
import AvaliacaoQuestoes from "./components/AvaliacaoQuestoes";
import { getUsuarioId } from "../../../services/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    alignRight: {
        display: "flex",
        justifyContent: "flex-end",
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
}));

function getSteps() {
    return ["Cadastro", "Questões"];
}

const AvaliacaoCreate = () => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [files, setFiles] = useState([]);

    const [avaliacao, setAvaliacao] = useState({ ativo: false, 'cadastrador_id': parseInt(getUsuarioId()) });
    const [validationErrors] = useState({});
    const [trilhas, setTrilhas] = useState([]);
    const [questoes, setQuestoes] = useState([
        { tipo_questao: "multipla_escolha", respostas: [{}], obrigatoria: 1 },
    ]);
    const [loading, setLoading] = useState(false);
    const [questoesChecked, setQuestoesChecked] = useState({});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAvaliacao({ ...avaliacao, [name]: value });
    };

    const getCategorias = async () => {
        try {
            const response = await api.get("/trilhas");
            setTrilhas(response.data);
            console.log(
                "🚀 ~ file: GrupoList.js ~ line 40 ~ getCategorias ~ response.data.data",
                response.data
            );
        } catch (error) {
            if (error.response) {
                enqueueSnackbar("Falha ao buscar trilha", {
                    variant: "error",
                });
            }
        }
    };
    useEffect(() => {
        getCategorias();
    }, []);

    const handleCreateAvaliacao = async () => {
        try {
            setLoading(true)
            const avaliacao_id = await api.post("/avaliacoes", { avaliacao });
            for await (const [index, questao] of questoes.entries()) {
                if(!questao.remover){
                    const dados = await api.post('/avaliacoes/questao', {questao, avaliacao_id: avaliacao_id.data[0]});

                    //conteudo do módulo
                    const questaoFiles = files.filter(
                        (file) => file.index === index
                    );
                    if (questaoFiles.length > 0) {
                        const formData = new FormData();

                        for (const file of questaoFiles) {
                            formData.append("file", file);
                        }

                        formData.append('questao_id', JSON.stringify(dados.data[0]));

                        await api.post('/conteudos', formData);
                    }
                }
            }

            
            enqueueSnackbar("Avaliação criada com sucesso!", {
                variant: "success",
            });
            setLoading(false)
            history.push("/admin/avaliacoes");
        } catch (error) {
            setLoading(false)
            console.log("error ", error.message)
            if (error.response) {
                enqueueSnackbar("Falha ao criar avaliação", {
                    variant: "error",
                });
            }
        }
    };

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <AvaliacaoCadastro
                        avaliacao={avaliacao}
                        setAvaliacao={setAvaliacao}
                        validationErrors={validationErrors}
                        handleInputChange={handleInputChange}
                        trilhas={trilhas}
                    />
                );
            case 1:
                return (
                    <>
                        <AvaliacaoQuestoes
                            setFiles={setFiles}
                            files={files}
                            questoes={questoes}
                            setQuestoes={setQuestoes}
                            questoesChecked={questoesChecked}
                            setQuestoesChecked={setQuestoesChecked}
                        />
                    </>
                );
            default:
                return (
                    <AvaliacaoCadastro
                        avaliacao={avaliacao}
                        setAvaliacao={setAvaliacao}
                        validationErrors={validationErrors}
                        handleInputChange={handleInputChange}
                        trilhas={trilhas}
                    />
                );
        }
    }

    const handleBackToPage = () => {
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/avaliacoes"
                >
                    Avaliações
                </Link>
                <Typography className={classes.breadCrumb}>
                    Nova avaliação
                </Typography>
            </Breadcrumbs>
            <Typography variant="h3" component="h1">
                Nova avaliação
            </Typography>

            <Box p={2}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {loading ? (
                    <TableRow>
                        <TableCell align="center" colSpan={5}>
                            <CircularProgress />
                        </TableCell>
                    </TableRow>
                ) : (
                    <>{getStepContent(activeStep)}</>
                )}
                <Grid container justify="flex-end" style={{ marginTop: 90 }}>
                    {activeStep === 0 ? (
                        <Button color="default" onClick={handleBackToPage}>
                            Cancelar
                        </Button>
                    ) : (
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                        >
                            Voltar
                        </Button>
                    )}
                    {activeStep === steps.length - 1 ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCreateAvaliacao}
                            disabled={loading}
                        >
                            {loading ? 'Salvando' : 'Finalizar'}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                        >
                            Prosseguir
                        </Button>
                    )}
                </Grid>
            </Box>
        </div>
    );
};

export default AvaliacaoCreate;
