/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { useHistory, NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Assessment from '@material-ui/icons/Assessment';
import { API_HOST } from '../../../../config_servers';
import { RightSideMenu, ButttonNotification, NotificationContainer, NotificationTitle, NotificationText } from "./styles"
import {
    Box,
    AppBar,
    Toolbar,
    Badge,
    Hidden,
    IconButton,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
    Button,
    Avatar,
    Popover,
    Divider,
    ListItemIcon
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { removeAuthSession, hasAccess, getUsuarioId } from '../../../../services/auth';
import { useUsuarioContext } from '../../../../contexts/UserContext';
import {
    SmileyFace,
} from '../../../../components/aluno/Icons/Icons';
const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1,
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
    logoHeight: {
        height: '36px',
    },
    menuItemText: {
        fontSize: 16,
        marginTop: 10,
        marginLeft: 8,
        color: 'white',
    },
    active: {
        // color: theme.palette.primary.main,
        // fontWeight: theme.typography.fontWeightMedium,
        // '& $icon': {
        //     color: theme.palette.primary.main,
        // },
    },
    button: {
        // '&.active': {
        //     filter: 'brightness(130%)',
        // },
    },
    usernameText: {
        alignSelf: 'center',
        color: 'white',
        fontSize: '18px',
        marginLeft: '16px',
        whiteSpace: 'nowrap',
        // fontWeight: '400',
    },
}));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const Topbar = (props) => {
    const { className, onSidebarOpen, ...rest } = props;
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();
    const [notifications, setNotifications] = useState([]);
    const [anchorElUsuario, setAnchorElUsuario] = useState(null);
    const [anchorElConteudo, setAnchorElConteudo] = useState(null);
    const [anchorElConfiguracoes, setAnchorElConfiguracoes] = useState(null);
    const [anchorElTreinamento, setAnchorElTreinamento] = useState(null);
    const [anchorElClickIconUser, setAnchorElClickIconUser] = useState(null);
    const [username, setUsername] = useState('');
    const { imagemUsuario } = useUsuarioContext();
    const user = getUsuarioId();

    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        getUsername();
        getNotification()
    }, []);

    const handleClickUsuario = (event) => {
        setAnchorElUsuario(event.currentTarget);
    };

    const handleClickConteudo = (event) => {
        setAnchorElConteudo(event.currentTarget);
    };

    const handleClickConfiguracoes = (event) => {
        setAnchorElConfiguracoes(event.currentTarget);
    };
    const handleClickIconUser = (event) => {
        setAnchorElClickIconUser(event.currentTarget);
    };
    const handleCloseClickIconUser = () => {
        setAnchorElClickIconUser(null);
    };

    const handleClickTreinamento = (event) => {
        setAnchorElTreinamento(event.currentTarget);
    };

    const getNotification = async () => {
        const notification = await api.get(`/usuarios/notification/${user}`)
        setNotifications(notification.data);
        console.log('NOTI', notification.data);
    }

    const handleMarcarComoLida = async (id) => {
        try {
            await api.put(`/alertas/lida/${id}`)
            getNotification();
        } catch (err) {
            alert(err.message)
        }
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleCloseUsuario = () => {
        setAnchorElUsuario(null);
    };

    const handleCloseConteudo = () => {
        setAnchorElConteudo(null);
    };

    const handleCloseConfiguracoes = () => {
        setAnchorElConfiguracoes(null);
    };

    const handleCloseTreinamento = () => {
        setAnchorElTreinamento(null);
    };

    const getUsername = async () => {
        try {
            const response = await api.get(
                `/usuarios/${localStorage.getItem('@treinadelta/usuario_id')}`
            );

            const fullName = response.data.nome.split(' ');
            let firstName = fullName[0];
            let lastName =
                fullName.length > 1 ? fullName[fullName.length - 1] : '';
            setUsername(`${firstName} ${lastName}`);
        } catch (error) {
            console.log('error:', error);
        }
    };

    return (
        <AppBar
            style={{
                background: theme.palette.primary.mainGradient,
                height: '55px',
                display: 'flex',
                justifyContent: 'center',
            }}
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Toolbar>
                <RouterLink to="/admin/">
                    <img
                        className={classes.logoHeight}
                        alt="Logo"
                        src="/images/logos/logo--white.svg"
                    />
                </RouterLink>
                <Box
                    display="flex"
                    style={{ width: '100%' }}
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="start"
                        flexGrow={1}
                    >
                        <Hidden smDown>
                            <>
                                {/* <IconButton
                                    color="inherit"
                                    aria-controls="perfil-menu"
                                    className={classes.button}
                                    component={RouterLink}
                                    exact
                                    to="/admin/"
                                >
                                    <DashboardIcon />
                                    <Typography
                                        variant="body1"
                                        className={classes.menuItemText}
                                        gutterBottom
                                    >
                                        Dashboard
                                    </Typography>
                                </IconButton> */}
                                {hasAccess('Usuario') && (
                                    <IconButton
                                        color="inherit"
                                        aria-controls="menu-usuario"
                                        onClick={handleClickUsuario}
                                        style={{ marginLeft: 48 }}
                                    >
                                        <PersonIcon style={{ marginTop: 1 }} />
                                        <Typography
                                            variant="body1"
                                            className={classes.menuItemText}
                                            gutterBottom
                                        >
                                            Usuário
                                        </Typography>
                                    </IconButton>
                                )}
                                {hasAccess('Conteudo') && (
                                    <IconButton
                                        style={{ marginLeft: 48 }}
                                        color="inherit"
                                        aria-controls="menu-conteudo"
                                        onClick={handleClickConteudo}
                                    >
                                        <AssignmentIcon
                                            style={{ marginTop: 1 }}
                                        />
                                        <Typography
                                            variant="body1"
                                            className={classes.menuItemText}
                                            gutterBottom
                                        >
                                            Conteúdo
                                        </Typography>
                                    </IconButton>
                                )}
                                {hasAccess('Configuracoes') && (
                                    <IconButton
                                        style={{ marginLeft: 48 }}
                                        color="inherit"
                                        aria-controls="menu-configuracoes"
                                        onClick={handleClickConfiguracoes}
                                    >
                                        <SettingsIcon
                                            style={{ marginTop: 1 }}
                                        />
                                        <Typography
                                            variant="body1"
                                            className={classes.menuItemText}
                                            gutterBottom
                                        >
                                            Configurações
                                        </Typography>
                                    </IconButton>
                                )}
                                {hasAccess('Treinamento') && (
                                    <IconButton
                                        style={{ marginLeft: 48 }}
                                        color="inherit"
                                        aria-controls="menu-treinamento"
                                        onClick={handleClickTreinamento}
                                    >
                                        <Assessment
                                            style={{ marginTop: 1 }}
                                        />
                                        <Typography
                                            variant="body1"
                                            className={classes.menuItemText}
                                            gutterBottom
                                        >
                                            Treinamento
                                        </Typography>
                                    </IconButton>
                                )}
                            </>
                        </Hidden>
                    </Box>
                </Box>
                {/* <div className={classes.flexGrow} /> */}
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Tooltip title="Notificações" arrow>
                    <IconButton onClick={handleClick} color="inherit">
                            <Badge
                                badgeContent={notifications.length}
                                color="error"
                                variant="standard"
                            >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Desconectar-se" arrow>
                        <IconButton
                            onClick={async () => {
                                await api.post('/usuarios/log/insertLog/', {
                                    acao: 'fim_tempo_logado',
                                    usuario_id: getUsuarioId(),
                                    data_fim: moment().format('YYYY/MM/DD HH:mm:ss'),
                                });
                                removeAuthSession();
                                history.push('/admin/');
                            }}
                            // component={RouterLink}
                            // to="/admin/login"
                            className={classes.signOutButton}
                            color="inherit"
                        >
                            <InputIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Typography className={classes.usernameText}>{username}</Typography>

                    <IconButton
                        onClick={handleClickIconUser}
                    >
                        <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 56, height: 56 }}
                            src={imagemUsuario ? `${API_HOST}/assets/conteudos/${imagemUsuario}` : ''}
                        > 
                            <SmileyFace /> 
                        </Avatar>
                    </IconButton>       
                    <Hidden mdUp>
                        <IconButton
                            style={{ marginLeft: 32 }}
                            color="inherit"
                            onClick={onSidebarOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                </Box>
            </Toolbar>

            <StyledMenu
                id="menu-usuario"
                anchorEl={anchorElUsuario}
                keepMounted
                open={Boolean(anchorElUsuario)}
                onClose={handleCloseUsuario}
            >
                {hasAccess('Usuario', 'Grupo de usuarios') && (
                    <StyledMenuItem
                        component={RouterLink}
                        to="/admin/grupos"
                        onClick={handleCloseUsuario}
                    >
                        Grupos de usuários
                    </StyledMenuItem>
                )}
                {hasAccess('Usuario', 'Usuarios') && (
                    <StyledMenuItem
                        component={RouterLink}
                        to="/admin/usuarios"
                        onClick={handleCloseUsuario}
                    >
                        Usuários
                    </StyledMenuItem>
                )}
            </StyledMenu>

            <StyledMenu
                id="menu-conteudo"
                anchorEl={anchorElConteudo}
                keepMounted
                open={Boolean(anchorElConteudo)}
                onClose={handleCloseConteudo}
            >
                <StyledMenuItem
                    onClick={handleCloseConteudo}
                    component={RouterLink}
                    to="/admin/trilhas"
                >
                    Trilhas
                </StyledMenuItem>
                {hasAccess('Conteudo', 'Categorias') && (
                    <>
                    <StyledMenuItem
                        onClick={handleCloseConteudo}
                        component={RouterLink}
                        to="/admin/categorias"
                    >
                        Categorias
                    </StyledMenuItem>
                 </>

                )}
            
                 {hasAccess('Conteudo', 'Bibliotecas') && (
                    <StyledMenuItem
                        onClick={handleCloseConteudo}
                        component={RouterLink}
                        to="/admin/biblioteca"
                    >
                        Biblioteca
                    </StyledMenuItem>
                )}

                {/* {hasAccess('Conteudo', 'Categorias') && ( */}
                <StyledMenuItem
                    onClick={handleCloseConteudo}
                    component={RouterLink}
                    to="/admin/comentarios"
                >
                    Comentários
                </StyledMenuItem>
                {/* )} */}
                <StyledMenuItem
                    onClick={handleCloseConteudo}
                    component={RouterLink}
                    to="/admin/avaliacoes"
                >
                    Avaliações
                </StyledMenuItem>
                {/* <StyledMenuItem
                    onClick={handleCloseConteudo}
                    component={RouterLink}
                    to="/admin/secoes"
                >
                    Seções
                </StyledMenuItem> */}
            </StyledMenu>

            <StyledMenu
                id="menu-configuracoes"
                anchorEl={anchorElConfiguracoes}
                keepMounted
                open={Boolean(anchorElConfiguracoes)}
                onClose={handleCloseConfiguracoes}
            >
                <StyledMenuItem
                    onClick={handleCloseConfiguracoes}
                    component={RouterLink}
                    to="/admin/alertas"
                >
                    Alertas
                </StyledMenuItem>
                {hasAccess('Configuracoes', 'Importador') && (
                    <StyledMenuItem
                        component={RouterLink}
                        to="/admin/importador"
                        onClick={handleCloseConfiguracoes}
                    >
                        Importador
                    </StyledMenuItem>
                )}
                {/* <StyledMenuItem onClick={handleCloseConfiguracoes}>
                    Sessões
                </StyledMenuItem> */}
                {hasAccess('Configuracoes', 'Perfis') && (
                    <StyledMenuItem
                        component={RouterLink}
                        to="/admin/perfis"
                        onClick={handleCloseConfiguracoes}
                    >
                        Perfis
                    </StyledMenuItem>
                )}
                  {hasAccess('Configuracoes', 'Contatos') && (
                    <StyledMenuItem
                        component={RouterLink}
                        to="/admin/contatos"
                        onClick={handleCloseConfiguracoes}
                    >
                        Contatos
                    </StyledMenuItem>
                )}
                  {hasAccess('Configuracoes', 'Parceiros') && (
                    <StyledMenuItem
                        component={RouterLink}
                        to="/admin/parceiros"
                        onClick={handleCloseConfiguracoes}
                    >
                        Parceiros
                    </StyledMenuItem>
                )}
            </StyledMenu>

            <StyledMenu
                id="menu-treinamento"
                anchorEl={anchorElTreinamento}
                keepMounted
                open={Boolean(anchorElTreinamento)}
                onClose={handleCloseTreinamento}
            >
                {hasAccess('Treinamento', 'Treinamento Presencial') && (
                    <StyledMenuItem
                        component={RouterLink}
                        to="/admin/treinamento/presencial"
                        onClick={handleCloseTreinamento}
                    >
                        Presencial
                    </StyledMenuItem>
                )}
            </StyledMenu>
            <Menu
                id="menu-icon-buttom"
                anchorEl={anchorElClickIconUser}
                keepMounted
                open={Boolean(anchorElClickIconUser)}
                onClose={handleCloseClickIconUser}
                style={{marginTop: '40px', marginLeft: '-40px'}}
            >
                <MenuItem 
                    component={RouterLink}
                    to={`/admin/usuarios/${getUsuarioId()}`}
                >
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon> 
                Dados Usuário
                </MenuItem>

                <MenuItem 
                    onClick={async () => {
                        await api.post('/usuarios/log/insertLog/', {
                            acao: 'fim_tempo_logado',
                            usuario_id: getUsuarioId(),
                            data_fim: moment().format('YYYY/MM/DD HH:mm:ss'),
                        });
                        removeAuthSession();
                        history.push('/admin/');
                    }}
                >
                <ListItemIcon>
                    <InputIcon/>
                </ListItemIcon> 
                Sair
                </MenuItem>
            </Menu>
            <Popover
                id="simple-popover"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
            >

                {notifications.length ? 
                    <NotificationContainer style={{padding: '6px'}}>{
                        notifications.map((notification, index) =>
                            <div>
                                <ButttonNotification key={notification.alerta_id} onClick={() => handleMarcarComoLida(notification.alerta_usuario_id)} >
                                        {/* notificações que não poussuem o alerta_usuario_id são notificações de alerta */}
                                        <NotificationTitle style={{color: 'red'}}> {notification.alerta_usuario_id === undefined && 'ALERTA'}</NotificationTitle>
                                        <NotificationTitle > {notification.nome} </NotificationTitle>
                                        
                                        <NotificationText dangerouslySetInnerHTML={{ __html: `${notification.conteudo}` }}></NotificationText>

                                </ButttonNotification>
                                {index == notifications.length -1 ?
                                    <></>
                                    :
                                    <Divider />
                                }
                            </div>
                            )}
                        </NotificationContainer>
                        :
                        <Typography style={{padding: '12px'}}>Você não possui notificação.</Typography>
                        
                    
                }
                
            </Popover>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

export default Topbar;
