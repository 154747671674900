/* eslint-disable no-unused-vars */
import React from 'react';
import {
    Box,
    Grid,
    FormGroup,
} from '@material-ui/core';

import {
    cnpjMask,
    phoneMask,
} from '../../../../helpers/masks';
import CustomTextField from '../../../../components/admin/CustomTextField';

const ParceiroForm1 = (props) => {
    const { parceiro, setParceiro, handleInputChange, validationErrors, edit } =
        props;

        return (
        <Box p={2}>
            <Grid
                alignItems="flex-start"
                justifyContent="flex-start"
                container
                xs={6}
                md={2}
            >
                <FormGroup
                    style={{
                        marginLeft: -16,
                    }}
                >
                </FormGroup>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Nome"
                        name="nome"
                        margin="normal"
                        value={parceiro.nome}
                        error={validationErrors.nome}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        InputLabelProps={{ shrink: !! parceiro.nome }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Razão Social"
                        name="razao_social"
                        margin="normal"
                        value={parceiro.razao_social}
                        onChange={handleInputChange}
                        fullWidth
                        InputLabelProps={{ shrink: !! parceiro.razao_social }}
                    />
                </Grid>
                { !edit && (
                    <Grid item xs={12} md={4}>
                        <CustomTextField
                            label="E-mail Login"
                            name="email_login"
                            value={parceiro.email_login}
                            error={validationErrors.email_login}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: !! parceiro.email_login }}
                        />
                    </Grid>
                    )
                }
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="E-mail Contato"
                        name="email_contato"
                        value={parceiro.email_contato}
                        error={validationErrors.email_contato}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: !! parceiro.email_contato }}
                    />
                </Grid>
                { !edit && ( 
                    <Grid item xs={12} md={4}>
                        <CustomTextField
                            label="Telefone"
                            name="telefone"
                            value={parceiro.telefone}
                            onChange={(e) =>
                                setParceiro({
                                    ...parceiro,
                                    telefone: phoneMask(e.target.value),
                                })
                            }
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: !! parceiro.telefone }}
                            required
                        />
                    </Grid>
                    )
                }
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="CNPJ"
                        name="cnpj"
                        value={parceiro.cnpj}
                        error={validationErrors.cnpj}
                        onChange={(e) =>
                            setParceiro({
                                ...parceiro,
                                cnpj: cnpjMask(e.target.value),
                            })
                        }
                        margin="normal"
                        required
                        fullWidth
                        InputLabelProps={{ shrink: !! parceiro.cnpj }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Quantidade de colaboradores"
                        name="qtd_colaboradores"
                        value={parceiro.qtd_colaboradores}
                        error={validationErrors.qtd_colaboradores}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        type="number"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ParceiroForm1;
