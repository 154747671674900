import React, { useState } from 'react';
import {
    Info,
    InfoLabel,
    InfoText,
} from '../../../../../Trilha/components/InfoTrilha/style';
import Button from '../../../../../../../components/aluno/Button';
import {
    ButtonsContainer,
    ModalContainer,
    ModalTitle,
    TitleModal,
} from '../../../../../../../components/aluno/Modal/components/ModalCommentary/style';
import { GridInfoModalAvaliacao } from './style';

const ModalAvaliacao = ({ handleModal, openModal }) => {
    const [open, setOpen] = useState(openModal);

    const toggleOpen = () => {
        if (openModal === false) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'overlay';
        }
    };

    return (
        <ModalContainer>
            <ModalTitle>
                <TitleModal>Confirmar entrega da Avaliação para</TitleModal>
                <TitleModal>conclusão de Módulo</TitleModal>
            </ModalTitle>
            <GridInfoModalAvaliacao>
                <Info modal>
                    <InfoLabel>Trilha</InfoLabel>
                    <InfoText>Escola UX & Design</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Módulo</InfoLabel>
                    <InfoText>Design visual de um site mobile</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Carga Horária</InfoLabel>
                    <InfoText>10 horas</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Data da Avaliação</InfoLabel>
                    <InfoText>23 de Abril de 2021 às 14:34</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Tentativa</InfoLabel>
                    <InfoText>1ª</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Respostas</InfoLabel>
                    <InfoText>11/11</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Tempo de Resposta</InfoLabel>
                    <InfoText>0:01:24</InfoText>
                </Info>
            </GridInfoModalAvaliacao>
            <ButtonsContainer>
                <Button
                    cancel
                    onClick={() => {
                        handleModal(open);
                        toggleOpen();
                    }}
                >
                    Cancelar
                </Button>
                <Button big>Confirmar e entregar avaliação</Button>
            </ButtonsContainer>
        </ModalContainer>
    );
};

export default ModalAvaliacao;
