import React from 'react';
import { SearchMenuContainer, BtnSearch } from './style';
import { IconSearch } from '../../Icons/Icons';

const SearchMenu = ({ search, onChangeSearch }) => {
    return (
        <SearchMenuContainer>
            <input
                placeholder="Pesquise aqui..."
                value={search}
                onChange={onChangeSearch}
                type="text"
                name="searchMenu"
                id="searchMenu"
            />
            <BtnSearch>
                <IconSearch />
            </BtnSearch>
        </SearchMenuContainer>
    );
};

export default SearchMenu;
