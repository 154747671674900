import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Backdrop from '@material-ui/core/Backdrop';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Grid,
    Link,
    IconButton,
    Input,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import * as Yup from 'yup';

import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile,
} from 'react-icons/md';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { hasAccess, setAuthSession } from '../../../services/auth';
import api from '../../../services/api';
import CustomTextField from '../../../components/admin/CustomTextField';
import IOSSwitch from '../../../components/admin/IOSwitch';
import getValidationErors from '../../../utils/geValidationErrors';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        span: {
            marginLeft: '-16px',
        },
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    paper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        maxHeight: 450,
    },
    'rct-title': {
        marginLeft: -16,
    },
    teste: {
        transition: '0.2s',
    },
}));

const ParceirosList = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const acesso = hasAccess('Configuracoes', 'Parceiros');
    const [initialFilters] = useState({
        nome: '',
    });
    const [filters, setFilters] = useState(initialFilters);
    const [parceiros, setParceiros] = useState({});
    const [parceiro, setParceiro] = useState({});
    const [loading, setLoading] = useState(true);
    const [idSelected, setIdSelected] = useState();
    const [parceiroSelecionado, setParceiroSelecionado] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        getParceiros(filters, currentPage, perPage);
    }, [currentPage, perPage]);

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getParceiros();
    };

    const getParceiros = async () => {
        try {
            setLoading(true);
            const response = await api.get('/parceiros/filter', {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setParceiros(response.data.data);
            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };


    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getParceiros(filters);
    };

    const openMenuActions = (event, parceiro) => {
        setAnchorEl(event.currentTarget);
        setParceiroSelecionado(parceiro);
        setIdSelected(parceiro.parceiro_id);
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Parceiros
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Parceiros
                    </Typography>
                </Grid>
                {acesso.adicionar && (
                    <Grid
                        container
                        xs={6}
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            component={RouterLink}
                            to="/admin/parceiros/novo"
                            color="primary"
                            variant="contained"
                        >
                            Novo Parceiro
                        </Button>
                    </Grid>
                )}
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={3}>
                            <Paper className={classes.paper}>
                                <Input
                                    placeholder="Parceiros"
                                    className={classes.input}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            nome: e.target.value,
                                        })
                                    }
                                    value={filters.nome}
                                    disableUnderline
                                    fullWidth
                                />
                            </Paper>
                        </Grid>
                        <Grid container xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{ marginLeft: '2%' }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Parceiro</TableCell>
                                                    <TableCell>
                                                        Razão Social
                                                    </TableCell>
                                                    <TableCell align="right" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {parceiros.length === 0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {parceiros.map(
                                                                    (
                                                                        parceiro
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                parceiro.parceiro_id
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    parceiro.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    parceiro.razao_social
                                                                                }
                                                                            </TableCell>

                                                                            <TableCell align="right">
                                                                                {(acesso.editar) && (
                                                                                    <IconButton
                                                                                        color="secondary"
                                                                                        size="small"
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            openMenuActions(
                                                                                                e,
                                                                                                parceiro
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <SettingsIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={closeMenuActions}
            >
                {acesso.editar && (
                    <MenuItem 
                        component={RouterLink}
                        to={`/admin/parceiros/${idSelected}`}
                    >
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <EditIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Editar parceiro" />
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default ParceirosList;
