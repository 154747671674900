import React from 'react';
import { IconCheck, IconCross } from '../Icons/Icons';
import { TagContainer } from './style';

const Tag = ({ children, ...rest }) => (
    <TagContainer cardAvaliacao={rest.cardAvaliacao} {...rest}>
        {children}
        {rest.reviewed || (rest.aprovado && <IconCheck />)}
        {rest.aprovado === false ? <IconCross /> : ''}
    </TagContainer>
);

export default Tag;
