import styled from 'styled-components';
import { colors } from '../../../../../../assets/theme/colors';

export const InputImageContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    border-radius: 7px;
    background: #ffffff0d;
    padding: 15px 18px;
    width: 100%;
    min-width: 330px;
    @media (max-width: 450px) {
        width: 100%;
        min-width: 250px;
        flex-direction: column;
        padding: 20px 18px 25px;
    }
    @media (max-width: 350px) {
    }
`;

export const ImagePlaceHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff0d;
    height: 65px;
    width: 65px;
`;

export const TitleAndLabel = styled.div`
    display: flex;
    //flex-direction: column;
    gap: 14px;
    margin-top: -10px;
    width: 100%;
    & input[type='file'] {
        display: none;
    }
    @media (max-width: 450px) {
        flex-direction: column;
    }
`;

export const TitleImageInput = styled.div`
    font-size: 1.4rem;
    color: #fff;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    //gap: 20px;
    align-self: flex-start;
    @media (max-width: 450px) {
        text-align: center;
        width: 100%;
    }
`;

export const ButtonImageUpload = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
    path {
        height: 60px;
    }
    @media (max-width: 450px) {
        margin: 0 auto;
    }
`;

export const LabelImageInput = styled.label`
    background: ${colors.primary};
    font-size: 1.2rem;
    color: #333247;
    border-radius: 4px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
        background: #f3c500;
    }
    @media (max-width: 450px) {
        margin: 0 auto;
    }
`;

export const ButtonDelete = styled.p`
    font-size: 1.1rem;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    @media (max-width: 450px) {
        margin: 0 auto;
    }
`;

export const ImageUploaded = styled.div`
    min-height: 65px;
    max-height: 65px;
    min-width: 65px;
    max-width: 65px;
    border-radius: 50%;
    background: url(${({ image }) => image || ''}), #ffffff99;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;
