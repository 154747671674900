/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import PerfectScrollbar from "react-perfect-scrollbar";
import { hasAccess } from "../../../services/auth";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from '@material-ui/icons/Send';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SettingsIcon from "@material-ui/icons/Settings";

import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    TextField,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import { ErrorChip, SuccessChip } from "../../../helpers/helper";

import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
    paper: {
        borderRadius: "4px",
        alignItems: "center",
        padding: theme.spacing(1),
        display: "flex",
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: "14px",
        lineHeight: "16px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    container: {
        maxHeight: 450,
    },
    autocomplete: {
        backgroundColor: "white",
        // height: '45.625px'
    },
}));

const Bibliotecas = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const acesso = hasAccess("Conteudo", "Bibliotecas");
    const acessoAdmin = hasAccess("Configuracoes", "Parceiros");
    const [initialFilters] = useState({
        nome: "",
    });
    const [filters, setFilters] = useState(initialFilters);
    const [biblioteca, setBiblioteca] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [idSelected, setIdSelected] = useState();
    const [bibliotecaSelect, setBibliotecaSelect] = useState({});
    const [setUsuarios] = useState([]);
    const [bibliotecaFilter, setBibliotecaFilter] = useState([]);
    const [isOpenExcluirBiblioteca, setIsOpenExcluirBiblioteca] = useState(false);

    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const open = Boolean(anchorEl);

    useEffect(() => {
        getInputbiblioteca();
    }, []);

    useEffect(() => {
        getbibliotecaas(filters, currentPage, perPage);
        getUsuarios();
    }, [currentPage, perPage]);

    const getInputbiblioteca = async () => {
        try {
            const response = await api.get("/bibliotecas");
            const itens = response.data;
            setBibliotecaFilter(itens.filter((item) => item.nome));
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const getUsuarios = async () => {
        try {
            setLoading(true);
            const response = (await api.get(`/bibliotecas`)).data;
            setUsuarios(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };
    const openMenuActions = (event, bibliotecaas) => {
        setAnchorEl(event.currentTarget);
        setIdSelected(bibliotecaas.biblioteca_id);
        setBibliotecaSelect(bibliotecaas)
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const getbibliotecaas = async (filters = [], currentPage = 0, perPage = 10) => {
        try {
            setLoading(true);
            const response = await api.get("/bibliotecas/filter", {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setBiblioteca(response.data.data);

            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getbibliotecaas(filters);
    };

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getbibliotecaas();
    };


    const handleOpenExcluirABiblioteca = async () => {
        setIsOpenExcluirBiblioteca(true);
        setAnchorEl(null);
    };

    const handleCloseExcluirBiblioteca = async () => {
        setIsOpenExcluirBiblioteca(false);
    };

    const handleExcluirBiblioteca = async () => {
        try {
            setAnchorEl(null);
            handleCloseExcluirBiblioteca();
            await api.delete(`/bibliotecas/${bibliotecaSelect.biblioteca_id}`);
            await getbibliotecaas(filters, currentPage, perPage);
            enqueueSnackbar("Biblioteca removido com sucesso!", {
                variant: "success",
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Bibliotecas
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                    Bibliotecas
                    </Typography>
                </Grid>
                <Grid container xs={6} justify="flex-end" alignItems="center">
                    {acesso.adicionar && (
                        <Button
                            component={RouterLink}
                            to="/admin/biblioteca/novo"
                            color="primary"
                            variant="contained"
                        >
                            Novo livro
                        </Button>
                    )}
                </Grid>
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={3} alignItems="center">
                            <Autocomplete
                                style={{ marginTop: "1px" }}
                                getOptionLabel={(secoes) => secoes.nome}
                                options={bibliotecaFilter}
                                value={
                                    bibliotecaFilter.find(
                                        (item) => item.nome === filters.nome
                                    )
                                        ? bibliotecaFilter.find(
                                              (item) =>
                                                  item.nome === filters.nome
                                          )
                                        : null
                                }
                                onChange={(event, newValue) => {
                                    setFilters({
                                        ...filters,
                                        nome: newValue?.nome ?? "",
                                    });
                                }}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.autocomplete}
                                        label="Nome"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid container xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{ marginLeft: "2%" }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell>
                                                        Descrição
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Status
                                                    </TableCell>
                                                    {acessoAdmin.visualizar && (
                                                        <TableCell align="center">
                                                            Parceiro
                                                        </TableCell>
                                                    )}
                                                    <TableCell align="right" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {biblioteca.length === 0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {biblioteca.map(
                                                                    (
                                                                        secoes
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                secoes.biblioteca_id
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    secoes.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    secoes.descricao
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {secoes.ativo ? (
                                                                                    <SuccessChip texto="Ativo" />
                                                                                ) : (
                                                                                    <ErrorChip texto="Inativo" />
                                                                                )}
                                                                            </TableCell >
                                                                            {acessoAdmin.visualizar && (
                                                                                <TableCell align="center">
                                                                                {
                                                                                    secoes.nome_parceiro ? secoes.nome_parceiro  : '-'
                                                                                }
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell align="right">
                                                                                {(acesso.adicionar ||
                                                                                    acesso.excluir ||
                                                                                    acesso.editar) && (
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        size="small"
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            openMenuActions(
                                                                                                e,
                                                                                                secoes
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <SettingsIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={open}
                onClose={closeMenuActions}
            >
                {acesso.editar && (
                    <MenuItem
                        component={RouterLink}
                        to={`/admin/biblioteca/${idSelected}`}
                    >
                        <ListItemIcon style={{ minWidth: "32px" }}>
                            <EditIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Editar biblioteca" />
                    </MenuItem>
                )}
                {acesso.excluir && (
                    <MenuItem onClick={handleOpenExcluirABiblioteca}>
                        <ListItemIcon style={{ minWidth: "32px" }}>
                            <DeleteIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Excluir biblioteca" />
                    </MenuItem>
                )}
            </Menu>
            <Dialog
                open={isOpenExcluirBiblioteca}
                onClose={handleCloseExcluirBiblioteca}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Exclusão da biblioteca
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O biblioteca será excluído, deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExcluirBiblioteca} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleExcluirBiblioteca} color="primary">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Bibliotecas;
