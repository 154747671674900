/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import PerfectScrollbar from "react-perfect-scrollbar";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    DialogContentText,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Link,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    FormGroup,
    FormControlLabel,
    TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { hasAccess } from "../../../services/auth";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IOSSwitch from "../../../components/admin/IOSwitch";

import api from "../../../services/api";
import CustomTextField from "../../../components/admin/CustomTextField";
import getValidationErors from "../../../utils/geValidationErrors";

import { ErrorChip, SuccessChip } from "../../../helpers/helper";
import DeleteIcon from "@material-ui/icons/Delete";

import { getUsuarioId } from "../../../services/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
    paper: {
        borderRadius: "4px",
        alignItems: "center",
        padding: theme.spacing(1),
        display: "flex",
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: "14px",
        lineHeight: "16px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    container: {
        maxHeight: 450,
    },
}));

const Categorias = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const acesso = hasAccess("Conteudo", "Categorias");
    const acessoAdmin = hasAccess("Configuracoes", "Parceiros");

    const [initialFilters] = useState({
        nome: "",
        email: "",
        perfil_id: "",
    });
    const [filters, setFilters] = useState(initialFilters);
    const [categorias, setCategorias] = useState([]);
    const [categoria, setCategoria] = useState({ 'cadastrador_id': parseInt(getUsuarioId())});
    const [loading, setLoading] = useState(true);
    const [idSelected, setIdSelected] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [openCadastroCategoria, setOpenCadastroCategoria] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [categoriasFilter, setCategoriasFilter] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const open = Boolean(anchorEl);
    const [isOpenExcluirCategoria, setIsOpenExcluirCategoria] = useState(false);

    const isSmallScreen = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        getCategorias(filters, currentPage, perPage);
    }, [currentPage, perPage]);

    const getCategorias = async (
        filters = [],
        currentPage = 0,
        perPage = 10
    ) => {
        try {
            setLoading(true);
            const response = await api.get("/categorias/filter", {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setCategorias(response.data.data);
            console.log(
                "🚀 ~ file: GrupoList.js ~ line 139 ~ getCategorias ~ response.data.data",
                response.data
            );
            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getCategorias(filters);
    };

    const openMenuActions = (event, id) => {
        setAnchorEl(event.currentTarget);
        setIdSelected(id);
        console.log(
            "🚀 ~ file: GrupoList.js ~ line 194 ~ openMenuActions ~ id",
            id
        );
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const handleOpenCadastroCategoria = () => {
        setOpenCadastroCategoria(true);
    };

    const handleCloseCadastroCategoria = () => {
        setOpenCadastroCategoria(false);
        setIsEdit(false);
        setValidationErrors({});
        setCategoria({});
    };

    useEffect(() => {
        getcategoriasInput();
    }, []);

    const getcategoriasInput = async () => {
        try {
            const response = await api.get("/categorias");
            const itens = response.data;
            setCategoriasFilter(itens.filter((item) => item.nome));
        } catch (error) {
            enqueueSnackbar("Erro ao buscar categorias", {
                variant: "error",
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCategoria({ ...categoria, [name]: value });
    };

    const createCategoria = async () => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required("Nome obrigatório"),
            });

            await schema.validate(categoria, {
                abortEarly: false,
            });

            await api.post("/categorias", categoria);
            enqueueSnackbar("Categoria cadastrada com sucesso!", {
                variant: "success",
            });
            getCategorias();
            handleCloseCadastroCategoria();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
            setLoading(false);
        }
    };

    const handleOpenEditCategoria = async () => {
        closeMenuActions();
        setIsEdit(true);
        await getCategoriaById();
        setOpenCadastroCategoria(true);
    };

    const getCategoriaById = async () => {
        try {
            const response = await api.get(`/categorias/${idSelected}`);
            console.log(
                "🚀 ~ file: Categorialist.js ~ line 239 ~ getCategoriaById ~ idSelected",
                idSelected
            );
            setCategoria(response.data);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const editCategoria = async () => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required("Nome obrigatório"),
                descricao: Yup.string().required("Descrição obrigatória"),
            });

            await schema.validate(categoria, {
                abortEarly: false,
            });

            await api.put(`/categorias/${idSelected}`, categoria);
            enqueueSnackbar("Categoria editado com sucesso!", {
                variant: "success",
            });
            getCategorias();
            handleCloseCadastroCategoria();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
            setLoading(false);
        }
    };

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getCategorias();
    };

    const handleOpenExcluirCategoria = async () => {
        setIsOpenExcluirCategoria(true);
        setAnchorEl(null);
    };

    const handleCloseExcluirCategoria = async () => {
        setIsOpenExcluirCategoria(false);
    };

    const handleExcluirCategoria = async () => {
        try {
            setAnchorEl(null);
            handleCloseExcluirCategoria();
            await api.delete(`/Categorias/${idSelected}`);
            await getCategorias(filters, currentPage, perPage);
            enqueueSnackbar("Categoria removida com sucesso!", {
                variant: "success",
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Categorias
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Categorias
                    </Typography>
                </Grid>
                <Grid container xs={6} justify="flex-end" alignItems="center">
                    <Button
                        onClick={handleOpenCadastroCategoria}
                        color="primary"
                        variant="contained"
                    >
                        Nova categoria
                    </Button>
                </Grid>
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={3} alignItems="center">
                            <Autocomplete
                                getOptionLabel={(categoria) => categoria.nome}
                                options={categoriasFilter}
                                value={
                                    categoriasFilter.find(
                                        (item) => item.nome === filters.nome
                                    )
                                        ? categoriasFilter.find(
                                              (item) =>
                                                  item.nome === filters.nome
                                          )
                                        : null
                                }
                                onChange={(event, newValue) => {
                                    setFilters({
                                        ...filters,
                                        nome: newValue?.nome ?? "",
                                    });
                                }}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.autocomplete}
                                        label="Nome"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{ marginLeft: "2%" }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={1}>
                                                        Nome
                                                    </TableCell>
                                                    <TableCell colSpan={1}>
                                                        Descrição
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Ativo
                                                    </TableCell>
                                                    { acessoAdmin.visualizar && (
                                                        <TableCell align="center">
                                                            Parceiro
                                                        </TableCell>
                                                    )}
                                                    <TableCell align="right">
                                                        Ações
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {categorias.length ===
                                                        0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {categorias.map(
                                                                    (
                                                                        categoria
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                categoria.categoria_id
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                style={{
                                                                                    maxWidth: 80,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    categoria.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    categoria.descricao
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {categoria.ativo ? (
                                                                                    <SuccessChip texto="Ativo" />
                                                                                ) : (
                                                                                    <ErrorChip texto="Inativo" />
                                                                                )}
                                                                            </TableCell>
                                                                            {acessoAdmin.visualizar && (
                                                                                <TableCell align="center">
                                                                                {
                                                                                    categoria.nome_parceiro ? categoria.nome_parceiro : '-'
                                                                                }
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell align="right">
                                                                                {acesso.editar && (
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                        color="secondary"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            openMenuActions(
                                                                                                e,
                                                                                                categoria.categoria_id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <SettingsIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={closeMenuActions}
            >
                {acesso.editar && (
                    <MenuItem onClick={handleOpenEditCategoria}>
                        <ListItemIcon style={{ minWidth: "32px" }}>
                            <EditIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Editar categoria" />
                    </MenuItem>
                )}
                {acesso.excluir && (
                    <MenuItem onClick={handleOpenExcluirCategoria}>
                        <ListItemIcon
                            style={{
                                minWidth: "32px",
                            }}
                        >
                            <DeleteIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Excluir categoria" />
                    </MenuItem>
                )}
            </Menu>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="xs"
                open={openCadastroCategoria}
                onClose={handleCloseCadastroCategoria}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {isEdit ? "Editar categoria" : "Criar nova categoria"}
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} justify="flex-start">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        checked={categoria.ativo}
                                        value={categoria.ativo}
                                        onChange={(event) =>
                                            setCategoria({
                                                ...categoria,
                                                ativo: event.target.checked,
                                            })
                                        }
                                        name="status"
                                    />
                                }
                                label="Categoria ativa"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid container spacing={2} justify="center">
                        <CustomTextField
                            label="Nome da categoria"
                            name="nome"
                            value={categoria.nome}
                            error={validationErrors.nome}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            required
                            displayEmpty
                        />
                    </Grid>
                    <Grid container spacing={2} justify="center">
                        <CustomTextField
                            label="Descrição"
                            name="descricao"
                            value={categoria.descricao}
                            error={validationErrors.descricao}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            displayEmpty
                        />
                    </Grid>
                    {/* <Grid>
                        <CustomKeyboardDatePicker
                            label="Data de início"
                            value={
                                categoria.data_inicio
                                    ? categoria.data_inicio
                                    : null
                            }
                            onChange={(val) =>
                                setCategoria({
                                    ...categoria,
                                    data_inicio: val
                                        ? moment(val).format('YYYY-MM-DD')
                                        : null,
                                })
                            }
                            error={validationErrors.data_inicio}
                            style={{ marginLeft: 13, paddingRight: 24 }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid>
                        <CustomKeyboardDatePicker
                            label="Data final"
                            value={
                                categoria.data_final
                                    ? categoria.data_final
                                    : null
                            }
                            onChange={(val) =>
                                setCategoria({
                                    ...categoria,
                                    data_final: val
                                        ? moment(val).format('YYYY-MM-DD')
                                        : null,
                                })
                            }
                            error={validationErrors.data_final}
                            style={{ marginLeft: 13, paddingRight: 24 }}
                            margin="normal"
                        />
                    </Grid> */}
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button
                        onClick={handleCloseCadastroCategoria}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={isEdit ? editCategoria : createCategoria}
                        color="primary"
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenExcluirCategoria}
                onClose={handleCloseExcluirCategoria}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Exclusão de Categoria
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A categoria será excluída, deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseExcluirCategoria}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleExcluirCategoria} color="primary">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Categorias;
