import React, { useState, useRef } from 'react';
import Breadcrumb from '../../../../../components/aluno/Breadcrumb';
import { useHistory, useParams } from 'react-router-dom'
import {
    IconCheck,
    IconHeart,
    IconHeartFull,
    IconPlay,
} from '../../../../../components/aluno/Icons/Icons';
import {
    VideoThumbnail,
    CardVideoContainer,
    TitleVideo,
    ProgressContainer,
    ProgressVideo,
    VideoDescription,
    TitleAndDescWrapper,
    FavoriteContainer,
} from './style';
import ReactVideoThumbnail from 'react-video-thumbnail';
import api from '../../../../../services/api';
import { getUsuarioId } from '../../../../../services/auth';
import moment from 'moment';


const CardVideo = ({
    progress,
    title,
    duration,
    index,
    description,
    videoUrl,
    favorited,
    areaDoAluno,
    onClick,
    setIsVideoOpen,
    video,
    videos,
    videoSelecionado,
    setVideoSelecionado,
    arrayReprodutionList,
    setArrayReprodutionList,
    breadcrumbs,
    isMeusFavoritos
}) => {
    const [favorite, setFavorite] = useState(favorited);
    const filledHeart = useRef();
    const history = useHistory();
    let { trilhaId, moduloId } = useParams();
    if (!trilhaId || !moduloId) {
        trilhaId = video.trilha_id
        moduloId = video.modulo_id
    }

    const initLogVideoAssistidoPorUsuario = async () => {
        try {
            await api.post('/usuarios/log/insertLog/', {
                acao: 'tempo_video_assistido',
                usuario_id: getUsuarioId(),
                data_inicio: moment().format('YYYY/MM/DD HH:mm:ss'),
                conteudo_id: video.conteudo_id,
                modulo_id: video.modulo_id
            });
        } catch(error) {
            console.log('error initLogVideoAssistidoPorUsuario', error)
        }
    }

    const toggleFavorite = async () => {
        await api.post('/modulos/handleVideoFavoritado', {
            usuarioId: localStorage.getItem('@treinadelta/usuario_id'),
            conteudoId: video.conteudo_id,
            favoritado: !favorite,
        });
        setFavorite(!favorite);
    };


    return (
        <CardVideoContainer
            onClick={async () => {
                setVideoSelecionado({ ...video, index: Number(index - 1) });

                let array = videos.map(function (item, index2) {
                    return {
                        nome: item.nome_arquivo,
                        id: index2 + 1,
                        playing: index - 1 === index2,
                    };
                });

                setArrayReprodutionList(array);

                setIsVideoOpen(true);

                if (areaDoAluno) {
                    console.log("🚀 ~ file: index.js ~ line 98 ~ onClick={ ~ isMeusFavoritos", isMeusFavoritos)
                    if (isMeusFavoritos) {
                        history.push(`/areadoaluno/meusfavoritos/watching/${video.conteudo_id}`)
                    } else {
                        history.push(`/areadoaluno/meuhistorico/watching/${video.conteudo_id}`)
                    }
                } else {
                    history.push(`/trilhas/${trilhaId}/modulos/${moduloId}/watching/${video.conteudo_id}`)
                }

                await initLogVideoAssistidoPorUsuario()
            }}
            progress={video?.porcentagem_conclusao * 100 ?? 0}
        >
            <VideoThumbnail areaDoAluno={areaDoAluno}>
                <ReactVideoThumbnail videoUrl={videoUrl} width={125} />
                <IconPlay />
                {/*<img src={thumbnail} alt="Vídeo" /> */}
            </VideoThumbnail>
            <TitleAndDescWrapper>
                <TitleVideo>
                    <p>
                        {index}. {title} • {duration}
                    </p>
                    {!areaDoAluno ? (
                        <FavoriteContainer
                            ref={filledHeart}
                            favorite={favorite}
                            onClick={(e) => {
                                toggleFavorite();
                                e.stopPropagation();
                            }}
                        >
                            <IconHeart />
                            <IconHeartFull />
                        </FavoriteContainer>
                    ) : (
                        ''
                    )}
                    <ProgressContainer
                        progress={video?.porcentagem_conclusao * 100 ?? 0}
                        areaDoAluno={areaDoAluno}
                    >
                        <ProgressVideo
                            progress={video?.porcentagem_conclusao * 100 ?? 0}
                            areaDoAluno={areaDoAluno}
                        />
                        <IconPlay />
                        <IconCheck />
                    </ProgressContainer>
                </TitleVideo>
                {areaDoAluno && (
                    <Breadcrumb breadcrumbs={breadcrumbs} areaDoAluno />
                )}
                <VideoDescription>{description}</VideoDescription>
            </TitleAndDescWrapper>
        </CardVideoContainer>
    );
};

export default CardVideo;
