import React, { useState } from 'react';
import api from '../../../../../services/api';
import constants from '../../../../../helpers/constants';
import Button from '../../../Button';
import TextArea from '../../../Inputs/TextArea';
import TextInput from '../../../Inputs/TextInput';
import StarRating from './components/StarRating';
import { useSnackbar } from 'notistack';

import {
    ButtonsContainer,
    ModalContainer,
    ModalLabel,
    ModalTitle,
    TitleModal,
} from './style';

const ModalCommentary = ({ modulo, handleModal, openModal }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [commentary, setCommentary] = useState('');
    const [rating, setRating] = useState('');
    const [open, setOpen] = useState(openModal);

    const enviarComentario = async (e) => {
        e.preventDefault();
        try {
            await api.post('/comentarios', {
                usuario_id: localStorage.getItem('@treinadelta/usuario_id'),
                modulo_id: modulo.modulo_id,
                conteudo: commentary,
                rating: rating > 0 ? rating : 0,
                status: constants.STATUS_COMENTARIO_NAO_PUBLICADO,
            });

            enqueueSnackbar(
                <h4>
                    {'Seu comentário foi enviado para análise com sucesso.'}
                </h4>,
                {
                    variant: 'success',
                }
            );
            setCommentary('');
            setRating('');
            handleModal(open);
            toggleOpen();
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
        }
    };

    const toggleOpen = () => {
        if (openModal === false) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'overlay';
        }
    };

    return (
        <ModalContainer>
            <ModalTitle>
                <TitleModal>Deixe aqui o seu Review sobre o módulo</TitleModal>
                <TitleModal>"{modulo.nome}"</TitleModal>
            </ModalTitle>
            <form action="#">
                <div>
                    <ModalLabel>Qual sua nota para o módulo:</ModalLabel>
                    <StarRating rating={rating} setRating={setRating} />
                </div>
                <div>
                    <ModalLabel>Seu comentário:</ModalLabel>
                    <TextArea
                        value={commentary}
                        placeholder="Escreva aqui"
                        onChangeText={(e) => setCommentary(e.target.value)}
                    />
                </div>
                <ButtonsContainer>
                    <Button
                        cancel
                        onClick={() => {
                            handleModal(open);
                            toggleOpen();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button big onClick={(e) => enviarComentario(e)}>
                        Enviar comentário
                    </Button>
                </ButtonsContainer>
            </form>
        </ModalContainer>
    );
};

export default ModalCommentary;
