/* eslint-disable import/no-anonymous-default-export */
import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        contrastText: white,
        main: '#323649',
        mainGradient: 'linear-gradient(to right, #333247, #323649)',
    },
    secondary: {
        contrastText: white,
        //     // dark: colors.blue[900],
        //     //main: colors.blue['A400'],
        main: '#2A1B3D',
        // light: colors.blue['A400']
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400],
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400],
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400],
    },
    error: {
        contrastText: white,
        main: '#FD7B72',
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600],
    },
    background: {
        default: white,
        paper: white,
    },
    icon: colors.blueGrey[600],
    divider: colors.grey[200],
};
