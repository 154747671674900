/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import {
    Grid,
    Typography,
    DialogContent,
    CircularProgress,
    IconButton,
    Divider,
    Tooltip,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    MenuItem,
    InputBase,
    Card,
    CardContent,
    FormControlLabel,
    FormGroup,
    FormControl,
    TextField,
    InputLabel,
    Select,
    Input,
    Box,
    Chip,
    CardMedia,
    CardActions,
} from "@material-ui/core";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { PrimaryChip } from '../../../../helpers/helper';
import ReactPlayer from "react-player";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { useTheme } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Backdrop from "@material-ui/core/Backdrop";

import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useSnackbar } from "notistack";
import formatBytes from "../../../../helpers/formatBytes";
import CustomTextField from "../../../../components/admin/CustomTextField";
import api from "../../../../services/api";
import IOSSwitch from "../../../../components/admin/IOSwitch";
import { useParams, useHistory } from "react-router-dom";
import { API_HOST } from "../../../../config_servers";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    alignRight: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: theme.spacing(6),
    },
    dropZone: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: 'center',
        padding: "30px",
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: "#BFBFBF",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        width: "95%",
        transition: "border .24s ease-in-out",
        margin: "0 auto",
    },
    dropZoneImage: {
        display: "flex",
        alignItens: "center",
        justifyContent: "center",
        // padding: "30px",
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: "#BFBFBF",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        height: "160px",
        transition: "0.8s",
        marginTop: 16,
        margin: "0 auto",
        // "&:hover": {
        //     opacity: '0.5'
        // },
    },
    media: {
        height: 80,
    },
    card: {
        borderRadius: 0,
        borderColor: "#BFBFBF",
        color: "#fafafa",
    },
    divider: {
        background: "#BFBFBF",
    },
    formControl: {
        minWidth: 210,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, preRequisitos, theme) {
    return {
        fontWeight:
            preRequisitos.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function TrilhaForm2(props) {
    const {
        handleChangeModulos,
        handleAddmodulos,
        trilha,
        handleRemoveModulo,
        files,
        setFiles,
        files2,
        setFiles2,
        handleChangePreRequisitos,
        handleChangeModuloAtivo,
        setTrilha
    } = props;
    const classes = useStyles();
    const history = useHistory();

    const [openGerenciarConteudo, setOpenGerenciarConteudo] = useState(false);
    const [indexSelected, setIndexSelected] = useState(null);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const { trilhaId, moduloId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [isEdit] = useState(moduloId > 0);
    const [preRequisitos, setPreRequisitos] = useState([]);
    const [modulos, setModulos] = useState([]);
    const [videoFilePath, setVideoFilePath] = useState(null);
    const [selectedFile, setSelectedFile] = useState({});
    const [loading2, setLoading2] = useState(false);
    const theme = useTheme();

    const handleOpenVideo = (file) => {
        setIsOpenVideo(true);
        setSelectedFile(file);
        if (!file.conteudo_id) {
            setVideoFilePath(URL.createObjectURL(file));
        }
    };

    const handleCloseVideo = () => {
        setIsOpenVideo(false);
    };

    async function myCustomFileGetter(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, "index", {
                value: indexSelected,
                writable: true,
            });

            Object.defineProperty(file, "posicao", {
                value: files.length,
                writable: true,
            });

            _files.push(file);
        }

        return _files;
    }

    async function myCustomFileGetter2(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, "index", {
                value: indexSelected,
                writable: true,
            });

            _files.push(file);
        }
        return _files;
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        getFilesFromEvent: (event) => myCustomFileGetter(event),
    });

    const {
        acceptedFiles: acceptedFiles2,
        getRootProps: getRootProps2,
        getInputProps: getInputProps2,
    } = useDropzone({
        accept: "image/jpeg, image/png",
        getFilesFromEvent: (event) => myCustomFileGetter2(event),
    });

    const getAvaliacao = async () => {
        try {
            const response = await api.get("/avaliacoes");
            let itens = response.data;
            setAvaliacoes(itens.filter(item => (
                item.label = String(item.avaliacao_id) 
            )))
            console.log(
                "🚀 ~ file: TrilhaForm2.js ~ line 266 ~ getAvaliacao ~ response",
                response.data
            );
        } catch (error) {
            if (error.message) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newFiles = [...files];

        if (oldIndex < files.length - 1) {
            const arrayNextIndex = newFiles[newIndex];

            // eslint-disable-next-line operator-assignment
            newFiles[oldIndex].posicao = newFiles[oldIndex].posicao + 1;
            newFiles[newIndex].posicao = newFiles[newIndex].posicao - 1;

            newFiles[oldIndex] = arrayNextIndex;
            newFiles[newIndex] = files[oldIndex];
        }

        setFiles([...newFiles]);
       
      };
      const SortableItem = SortableElement(({file , i}) => {
        return(<>
            <Grid
                item
                sm={4}
                style={{ flexDirection: 'row', display: 'flex'}}
                xs={12}
            >
                {i != '0' ?
                    <div style={{display: 'flex', justifyContent: 'space-between',flexDirection: 'column'}}>
                        <div>
                            <PrimaryChip
                                texto={
                                    i+1
                                }
                                size="medium"
                            />
                        </div>
                        <div>
                            <Tooltip   Tooltip
                                title="Mudar posição"
                                placement="top"
                                arrow
                                style={{height: 30, marginTop: -35}}
                            >
                                <IconButton
    
                                    onClick={() =>
                                        handleSubirPosicaoDoDocumento(
                                            file,
                                            i
                                        )
                                    }
                                >
                                    <ChevronLeftIcon 
                                />
                                </IconButton >
                            </Tooltip>
                        </div>
                        
                        <div></div>
                    </div>
                    : 
                    <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 8}}>
                        <div></div>
                        <PrimaryChip
                            texto={
                                i+1
                            }
                            size="medium"
                        />
                        <div></div>
    
                    </div>
                }
                <Box
                    border={1}
                    borderColor="#DCDCDC"
                >
                    <Card
                        classes={{
                            root: classes.card,
                        }}
                    >
                        <CardMedia
                            className={
                                classes.media
                            }
                            image={`${API_HOST}/assets/conteudos/${file.arquivo}`}
                            title=""
                        />
    
                        <CardContent>
                            {/* {!file.conteudo_id && (
                                <ReactPlayer
                                    url={URL.createObjectURL(
                                        file
                                    )}
                                    width="100%"
                                    height="100%"
                                />
                            )} */}
                            <Grid
                                container
                            >
                               
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="flex-end"
                                >
                                <Grid
                                       justify="space-between"
                                       style={{ width: "100%", justifyContent: 'space-between', flexDirection: 'row', display: 'flex' , alignItems: 'center'}}
                                       >
                                    <div>
                                    <Typography
                                        variant="h5"
                                        component="h5"
                                        gutterBottom={
                                            false
                                        }
                                    >
                                        {
                                            file.name
                                        }
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        component="h6"
                                        gutterBottom={
                                            false
                                        }
                                    />
                                    </div>
                                    <div>
    
                                        <Tooltip
                                            title="Remover conteúdo"
                                            placement="top"
                                            arrow
                                        >
                                            <IconButton
                                                style={{
                                                    marginRight: 2,
                                                }}
                                                edge="end"
                                                aria-label="delete"
                                                onClick={removeFile(
                                                    file,
                                                    i
                                                    )}
                                                    >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                
                                    {file.conteudo_id && (
                                        <Tooltip
                                            title="Editar arquivo"
                                            placement="top"
                                            arrow
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                          
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                        </div>
                                </Grid>
    
                                </Grid>
                                <Grid
                                    item
                                    xs={
                                        12
                                    }
                                >
                                    <Typography
                                        variant="subtitle2"
                                        component="h6"
                                        gutterBottom={
                                            false
                                        }
                                    >
                                        {formatBytes(
                                            file.size
                                        )}
                                        {/* {file.descricao && (
                                            <Divider
                                                style={{
                                                    marginTop: 4,
                                                    marginBottom: 4,
                                                }}
                                                classes={{
                                                    root: classes.divider,
                                                }}
                                            />
                                        )} */}
                                        <Typography
                                            variant="subtitle2"
                                            component="h6"
                                        >
                                            {
                                                file.descricao
                                            }
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider
                            classes={{
                                root: classes.divider,
                            }}
                        />
    
                        <CardActions
                            style={{
                                justifyContent:
                                    'center',
                            }}
                        >
                            <Button
                                color="default"
                                startIcon={
                                    <VisibilityIcon />
                                }
                                onClick={() =>
                                    handleOpenVideo(
                                        file
                                    )
                                }
                            >
                                Visualizar
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
                <Tooltip
                    title="Mudar posição"
                    placement="top"
                    arrow
                >
                    <IconButton
                        onClick={() =>
                            handleDescerPosicaoDoDocumento(
                                file,
                                i
                            )
                        }
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </>)
    });

    const SortableList = SortableContainer(({items, index2}) => {
        return(
          <Grid container spacing={3}
          justifyContent="center"
          alignItems="center">
              {!loading &&
              items.map((file, index) => {
                       return file.index ===
                            index2 && ( 
                                <>
                                    <SortableItem key={`item-${index}`} index={index} i={String(index)} file={file} />
                                </>

                    ) 
                    })}
          </Grid>
        )
      })
    useEffect(() => {
        getAvaliacao();
        getModulos();
    }, []);

    const getModulos = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/modulos`);
            let itens = response.data;
            setModulos(itens.filter(item => (
                item.label = item.nome
            )))
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    useEffect(() => {
        setFiles([...files, ...acceptedFiles]);
    }, [acceptedFiles]);

    useEffect(() => {
        setFiles2([...files2, ...acceptedFiles2]);
    }, [acceptedFiles2]);

    const removeFile = (file, i) => () => {
        const newFiles = [...files];
        const id = newFiles.indexOf(file);
        newFiles.splice(id, 1);
        setFiles([...newFiles]);
        // setFiles([
        //     ...files.map((item, index2) =>
        //         i === index2 ? { ...item, remover: true } : item
        //     ),
        // ]);
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    const handleOpenGerenciarConteudo = (index) => {
        console.log(index);
        setIndexSelected(index);
        setOpenGerenciarConteudo(true);
    };

    const handleCloseGerenciarConteudo = () => {
        setOpenGerenciarConteudo(false);
    };

    const handleSubirPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index > 0) {
            const arrayPrevIndex = newFiles[index - 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao - 1;
            newFiles[index - 1].posicao = newFiles[index - 1].posicao + 1;

            newFiles[index] = arrayPrevIndex;
            newFiles[index - 1] = file;
        }

        setFiles([...newFiles]);
    };

    const handleDescerPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index < files.length - 1) {
            const arrayNextIndex = newFiles[index + 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao + 1;
            newFiles[index + 1].posicao = newFiles[index + 1].posicao - 1;

            newFiles[index] = arrayNextIndex;
            newFiles[index + 1] = file;
        }

        setFiles([...newFiles]);
    };

    const handleRemoveImage = (index) => {
        console.log("files2", files2);
        const newFiles = files2.filter((file) => file.index !== index);
        setFiles2([...newFiles]);
      
    };
    let contador_index = 0;
    return (
        <>
            {trilha?.modulos.map((modulo, index) => (
                <>
                    {!modulo.remover && (
                        <>
                            <div
                                onMouseEnter={() => setIndexSelected(index)}
                                onMouseLeave={() => setIndexSelected(null)}
                                className={classes.content}
                                >
                                <Card>
                                    <CardContent>
                                        <Grid container justify="space-between" spacing={2}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <IOSSwitch
                                                            checked={
                                                                modulo.ativo ===
                                                                true
                                                            }
                                                            value={modulo.ativo}
                                                            onChange={(event) =>
                                                                handleChangeModuloAtivo(
                                                                    event,
                                                                    index
                                                                )
                                                            }
                                                            name="status"
                                                        />
                                                    }
                                                    label={`Módulo ${
                                                        contador_index = contador_index + 1
                                                    } - Ativo?`}
                                                    labelPlacement="start"
                                                />
                                            </FormGroup>

                                                <Tooltip
                                                    title="Remover módulo"
                                                    alignSelf
                                                    arrow
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        style={{
                                                            marginRight: 25,
                                                        }}
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() =>
                                                            handleRemoveModulo(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon  />
                                                    </IconButton>
                                                </Tooltip>
                                        </Grid>

                                        <Grid
                                            container
                                            style={{ marginTop: 16 }}
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                direction="column"
                                                xs={4}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    direction="row"
                                                    xs={12}
                                                    md={12}
                                                >
                                                    <div
                                                        style={{
                                                            width: "280px",
                                                            marginLeft: 40,
                                                        }}
                                                        {...getRootProps2({
                                                            className:
                                                                classes.dropZoneImage,
                                                        })}
                                                    >
                                                        {files2.filter(
                                                            (file) =>
                                                                file.index ===
                                                                index
                                                        ).length === 0 ? (
                                                            <>
                                                                <input
                                                                    {...getInputProps2()}
                                                                />

                                                                <Typography
                                                                    variant="subtitle1"
                                                                    component="p"
                                                                    gutterBottom={
                                                                        false
                                                                    }
                                                                    style={{
                                                                        padding: 40,
                                                                        marginTop: 10,
                                                                    }}
                                                                >
                                                                    Arraste ou
                                                                    clique para
                                                                    adicionar a
                                                                    foto do
                                                                    módulo{" "}
                                                                    {moduloId}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img
                                                                    style={{
                                                                        maxHeight:
                                                                            "100%",
                                                                        maxWidth:
                                                                            "100%",
                                                                    }}
                                                                    src={
                                                                        files2.filter(
                                                                            (
                                                                                file
                                                                            ) =>
                                                                                file.index ===
                                                                                index
                                                                        )
                                                                            .conteudo_id
                                                                            ? `${API_HOST}/assets/conteudos/${
                                                                                  files2.filter(
                                                                                      (
                                                                                          file
                                                                                      ) =>
                                                                                          file.index ===
                                                                                          index
                                                                                  )
                                                                                      .arquivo
                                                                              }`
                                                                            : URL.createObjectURL(
                                                                                  files2.filter(
                                                                                      (
                                                                                          file
                                                                                      ) =>
                                                                                          file.index ===
                                                                                          index
                                                                                  )[0]
                                                                              )
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    {files2.filter(
                                                        (file) =>
                                                            file.index === index
                                                    ).length > 0 && (
                                                        <Tooltip
                                                            title="Remover imagem"
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                style={{
                                                                    marginRight: 25,
                                                                }}
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() =>
                                                                    handleRemoveImage(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="column"
                                                xs={12}
                                                md={4}
                                                justify="space-between"
                                                alignItems="stretch"
                                            >
                                                <Grid item xs={6}>
                                                    <CustomTextField
                                                        label="Nome"
                                                        name="nome"
                                                        margin="normal"
                                                        value={modulo.nome}
                                                        // error={validationErrors.nome}
                                                        onChange={(e) =>
                                                            handleChangeModulos(
                                                                index,
                                                                e
                                                            )
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        style={{
                                                            width: "150%",
                                                            marginBottom: 32,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                      <Autocomplete
                                                      style={{
                                                          marginTop: '-11px',
                                                          width: "150%",

                                                      }}
                                                      name="avaliacao_id"

                                                      getOptionLabel={(secoes) => secoes.nome}
                                                      options={avaliacoes}
                                                      value={
                                                        avaliacoes.find(
                                                              (item) => item.avaliacao_id === modulo.avaliacao_id
                                                          )
                                                              ? avaliacoes.find(
                                                                    (item) =>
                                                                        item.avaliacao_id === modulo.avaliacao_id
                                                                )
                                                              : null
                                                      }
                                                      onChange={(e, value) =>
                                                        {
                                                            let modulo = trilha.modulos.find((item, index2) => index === index2);
                                                            modulo['avaliacao_id'] = value.avaliacao_id;
                                                            setTrilha({
                                                                ...trilha,
                                                                modulos: trilha.modulos.map((item,index2) => 
                                                                index === index2 ? modulo: item
                                                                )
                                                            })
                                                        }
                                                    }
                                                      loading={loading}
                                                      renderInput={(params) => (
                                                          <TextField
                                                              {...params}
                                                              className={classes.autocomplete}
                                                              label="Avaliação"
                                                              margin="normal"
                                                              variant="outlined"
                                                          />
                                                      )}
                                                  />
                                                    {/* <CustomTextField
                                                        select
                                                        label="Avaliação"
                                                        name="avaliacao_id"
                                                        value={parseInt(
                                                            modulo.avaliacao_id
                                                        )}
                                                        // // error={validationErrors.avaliacao_id}
                                                        onChange={(e) =>
                                                            handleChangeModulos(
                                                                index,
                                                                e
                                                            )
                                                        }
                                                        margin="normal"
                                                        fullWidth
                                                        style={{
                                                            width: "150%",
                                                        }}
                                                    >
                                                        {avaliacoes.map(
                                                            (avaliacao) => (
                                                                <MenuItem
                                                                    value={
                                                                        avaliacao.avaliacao_id
                                                                    }
                                                                >
                                                                    {
                                                                        avaliacao.nome
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </CustomTextField> */}
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                direction="column"
                                                xs={12}
                                                md={4}
                                            >
                                                <Grid item xs={6}>
                                                    <CustomTextField
                                                        label="Descrição"
                                                        name="descricao"
                                                        margin="normal"
                                                        multiline="true"
                                                        value={modulo.descricao}
                                                        // error={validationErrors.modulo}
                                                        onChange={(e) =>
                                                            handleChangeModulos(
                                                                index,
                                                                e
                                                            )
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        style={{
                                                            width: "150%",
                                                            marginBottom: 32,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                <Autocomplete
                                                      style={{
                                                          marginTop: '-11px',
                                                          width: "150%",

                                                      }}
                                                      getOptionLabel={(secoes) => secoes.label}
                                                      options={modulos}
                                                      value={
                                                        modulos.find(
                                                              (item) => item.modulo_id === modulo?.preRequisito
                                                          )
                                                              ? modulos.find(
                                                                    (item) =>
                                                                        item.modulo_id === modulo?.preRequisito
                                                                )
                                                              : null
                                                      }
                                                      onChange={(e, value) =>
                                                        {
                                                            let modulo = trilha.modulos.find((item, index2) => index === index2);
                                                            modulo['preRequisito'] = value.modulo_id || null;
                                                            setTrilha({
                                                                ...trilha,
                                                                modulos: trilha.modulos.map((item,index2) => 
                                                                index === index2 ? modulo: item || null
                                                                )
                                                            })
                                                        }
                                                    }
                                                      loading={loading}
                                                      renderInput={(params) => (
                                                          <TextField
                                                              {...params}
                                                              className={classes.autocomplete}
                                                              label="Pré-requisito"
                                                              margin="normal"
                                                              variant="outlined"
                                                          />
                                                      )}
                                                  />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div
                                                    style={{ marginTop: 40 }}
                                                    {...getRootProps({
                                                        className:
                                                            classes.dropZone,
                                                    })}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <Typography
                                                        variant="subtitle1"
                                                        component="p"
                                                        gutterBottom={false}
                                                    >
                                                        Arraste ou clique para
                                                        adicionar conteúdos do
                                                        módulo {index + 1}
                                                    </Typography>
                                                </div>

                                                {/* <List style={{ marginTop: 8 }} dense>
                                    {!loading &&
                                        files.map((file, i) => (
                                            <>
                                                <ListItem
                                                    key={file.name}
                                                    divider={
                                                        i + 1 !== files.length
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <FolderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={file.name}
                                                        secondary={formatBytes(
                                                            file.size
                                                        )}
                                                    />
                                                    <IconButton
                                                        onClick={() =>
                                                            handleSubirPosicaoDoDocumento(
                                                                file,
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <KeyboardArrowUpIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleDescerPosicaoDoDocumento(
                                                                file,
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <KeyboardArrowDownIcon />
                                                    </IconButton>
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={removeFile(
                                                                file,
                                                                i
                                                            )}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </>
                                        ))}
                                </List> */}
                                                <Box
                                                    style={{ marginTop: 32 }}
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Grid container spacing={3}>
        <SortableList  onSortEnd={onSortEnd} index2={index}  items={files}  />

                                                       
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>

                            {/* <Grid
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Grid item xs={2} md={1}>
                                    <CustomTextField
                                        label={`Módulo ${index + 1}`}
                                        InputLabelProps={{ shrink: true }}
                                        name="nome"
                                        margin="normal"
                                        multiline="true"
                                        value={modulo.nome}
                                        onChange={(e) =>
                                            handleChangeModulos(index, e)
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={1} md={1}>
                                    <Tooltip
                                        title="Remover módulo"
                                        placement="top"
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                                handleRemoveModulo(index)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} md={4}>
                                    <CustomTextField
                                        label="Descrição"
                                        InputLabelProps={{ shrink: true }}
                                        name="descricao"
                                        margin="normal"
                                        multiline="true"
                                        value={modulo.descricao}
                                        onChange={(e) =>
                                            handleChangeModulos(index, e)
                                        }
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <CustomTextField
                                        select
                                        label="Avaliação"
                                        name="avaliacao_id"
                                        value={modulo.avaliacao_id}
                                        margin="normal"
                                        fullWidth
                                        onChange={(e) =>
                                            handleChangeModulos(index, e)
                                        }
                                    >
                                        {avalicoes.map((avaliacao) => (
                                            <MenuItem
                                                value={avaliacao.avaliacao_id}
                                            >
                                                {avaliacao.nome}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>

                                <Grid
                                    container
                                    justify="flex-start"
                                    alignItems="center"
                                    justifyContent="center"
                                    xs={12}
                                >
                                    <Button
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 80,
                                        }}
                                        onClick={() =>
                                            handleOpenGerenciarConteudo(index)
                                        }
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                    >
                                        Gerenciar conteúdo do módulo {index + 1}
                                    </Button>
                                </Grid>
                            </Grid> */}
                        </>
                    )}
                </>
            ))}

            <Grid style={{ marginTop: 16 }}>
                <Typography variant="subtitle1" component="p">
                    Adicionar módulo
                    <IconButton aria-label="add" onClick={handleAddmodulos}>
                        <AddIcon />
                    </IconButton>
                </Typography>
            </Grid>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="sm"
                open={openGerenciarConteudo}
                onClose={handleCloseGerenciarConteudo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Gerenciar conteúdo - Módulo {indexSelected + 1}
                </DialogTitle>

                <div
                    {...getRootProps({
                        className: classes.dropZone,
                    })}
                >
                    <input {...getInputProps()} />
                    <Typography
                        variant="subtitle1"
                        component="p"
                        gutterBottom={false}
                    >
                        Arraste ou clique para adicionar arquivos do módulo{" "}
                    </Typography>
                </div>

                <List style={{ marginTop: 8 }} dense>
                    {!loading &&
                        files.map((file, i) => (
                            <>
                                {file.index === indexSelected && !file.remover && (
                                    <ListItem
                                        key={file.name}
                                        divider={i + 1 !== files.length}
                                    >
                                        <ListItemIcon>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={file.name}
                                            secondary={formatBytes(file.size)}
                                        />
                                        <IconButton
                                            onClick={() =>
                                                handleSubirPosicaoDoDocumento(
                                                    file,
                                                    i
                                                )
                                            }
                                        >
                                            <KeyboardArrowUpIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleDescerPosicaoDoDocumento(
                                                    file,
                                                    i
                                                )
                                            }
                                        >
                                            <KeyboardArrowDownIcon />
                                        </IconButton>
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={removeFile(file, i)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </>
                        ))}
                </List>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button
                        onClick={handleCloseGerenciarConteudo}
                        variant="contained"
                        color="primary"
                        size="small"
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="md"
                open={isOpenVideo}
                onClose={handleCloseVideo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    Visualizar conteúdo
                </DialogTitle>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={loading2}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {selectedFile.type === "application/pdf" ? (
                            <>
                                {console.log(
                                    "🚀 ~ file: ModulosForm.js ~ line 849 ~ ModuloForm ~ file.mimetype",
                                    selectedFile
                                )}
                                <div>
                                    <iframe
                                        style={{
                                            width: "563px",
                                            height: "666px",
                                        }}
                                        src={videoFilePath}
                                        type="application/pdf"
                                        title="title"
                                    />
                                </div>
                            </>
                        ) : (
                            <ReactPlayer
                                url={
                                    selectedFile.conteudo_id
                                        ? `${API_HOST}/assets/conteudos/${selectedFile.arquivo}`
                                        : videoFilePath
                                }
                                width="80%"
                                height="80%"
                                controls={true}
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button onClick={handleCloseVideo} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TrilhaForm2;
