/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import { colors } from "../../../../assets/theme/colors";
import { IconCheck } from "../../Icons/Icons";
import { Container } from "./style";

const RadioButton = ({ name, id, label, ...rest }) => (
    <Container>
        <input type="radio" name={name} id={id} {...rest} hidden />
        <label htmlFor={id}>
            <div className="icon-container">
                <IconCheck fill={colors.primary} />
            </div>
            <p>{label}</p>
        </label>
    </Container>
);

export default RadioButton;
