import React from 'react';
import {
    IconCam,
    IconClip,
    IconComment,
    IconModulo,
    IconQuestion,
} from '../Icons/Icons';
import {
    ContainerTitlePage,
    InfoContainer,
    InfoSubContainer,
    ModuloInfo,
    Subtitle,
    TagTitle,
    Title,
} from './style';

import Breadcrumb from '../Breadcrumb';

// import { Container } from './styles';

const TitlePage = ({
    children,
    title,
    tagTitle,
    modulos,
    breadcrumbs,
    videos,
    anexos,
    comentarios,
    questoes,
    Avaliacao
}) => {
    return (
        <ContainerTitlePage questoes={questoes}>
            {breadcrumbs ? <Breadcrumb breadcrumbs={breadcrumbs} /> : ''}
            {tagTitle ? <TagTitle>{tagTitle}</TagTitle> : ''}
            <Title Avaliacao={Avaliacao}>{title}</Title>
            <InfoSubContainer>
                {modulos ? (
                    <InfoContainer>
                        <IconModulo />
                        <span>{modulos} Módulos</span>
                    </InfoContainer>
                ) : (
                    ''
                )}
                {questoes ? (
                    <InfoContainer>
                        <IconQuestion />
                        <span>{questoes} questões</span>
                    </InfoContainer>
                ) : (
                    ''
                )}
                {videos || anexos || comentarios ? (
                    <ModuloInfo>
                        <InfoContainer>
                            <IconCam />
                            <span>{videos}</span>
                        </InfoContainer>
                        <InfoContainer>
                            <IconClip />
                            <span>{anexos}</span>
                        </InfoContainer>
                        <InfoContainer>
                            <IconComment />
                            <span>{comentarios}</span>
                        </InfoContainer>
                    </ModuloInfo>
                ) : (
                    ''
                )}
                <Subtitle>{children}</Subtitle>
            </InfoSubContainer>
        </ContainerTitlePage>
    );
};

export default TitlePage;
