/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable  prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import {
    Breadcrumbs,
    Link,
    Typography,
    Card,
    CardContent,
    Grid,
    Button,
    TableCell,
    CircularProgress,
    TableRow,
    Stepper,
    Step,
    StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { useSnackbar } from "notistack";

import api from "../../../services/api";
import { getUsuarioId } from "../../../services/auth";
import TrilhaForm1 from "./components/TrilhaForm1";
import TrilhaForm2 from "./components/TrilhaForm2";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    alignRight: {
        display: "flex",
        justifyContent: "flex-end",
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
}));

function getSteps() {
    return ["Dados", "Módulos"];
}

const modulosInit = {
    modulos: [{}],
};

const TrilhasCreate = () => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const steps = getSteps();

    const [modulos, setModulos] = useState(modulosInit);
    const [trilha, setTrilha] = useState({
        modulos: [{ preRequisito: [] }],
        produtor: getUsuarioId(),
    });
    const [validationErrors] = useState({});
    const [categorias, setCategorias] = useState([]);
    const [usuario, setUsuario] = useState();
    const [desativaStatus, setDesativaStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [files, setFiles] = useState([]);
    const [files2, setFiles2] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "status") {
            setTrilha({
                ...trilha,
                ativo: event.target.checked,
            });
        } else {
            setTrilha({
                ...trilha,
                [name]: value,
            });
        }
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    const getCategorias = async () => {
        try {
            const response = await api.get("/categorias");
            setCategorias(response.data);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar("Falha ao buscar categoria", {
                    variant: "error",
                });
            }
        }
    };

    const getUsuario = async () => {
        try {
            setLoading(true);

            const response = await api.get(`/usuarios/${getUsuarioId()}`);
            if (response.data.perfil_id !== 3) {
                1;
                setDesativaStatus(true);
                setTrilha({ ...trilha, status: 1 });
            }
            setUsuario(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);

            if (error.response) {
                enqueueSnackbar("Falha ao buscar usuario.", {
                    variant: "error",
                });
            }
        }
    };

    useEffect(() => {
        getCategorias();
        getUsuario();
    }, []);

    const handleAddmodulos = () => {
        setTrilha({
            ...trilha,
            modulos: [...trilha.modulos, { preRequisito: [] }],
        });
    };

    const handleRemoveModulo = (index) => {
        setTrilha({
            ...trilha,
            modulos: trilha.modulos.map((item, index2) =>
                index === index2 ? { ...item, remover: true } : item
            ),
        });
    };

    const handleCreateTrilha = async () => {
        try {
            const response = await api.post("/trilhas", trilha);
            const trilhaId = response.data.trilha_id;

            enqueueSnackbar("Salvando, aguarde...", {
                variant: "warning",
                autoHideDuration: 3000,
            });

            for await (const [index, modulo] of trilha.modulos.entries()) {
                if (!modulo.remover) {
                    const preRequisitos = modulo.preRequisito;
                    delete modulo.preRequisito;
                    console.log(
                        "🚀 ~ file: TrilhasCreate.js ~ line 212 ~ forawait ~ modulo",
                        modulo
                    );

                    const response = await api.post("/modulos", {
                        modulo,
                        trilhaId,
                        preRequisitos,
                    });

                    const moduloId = response.data.modulo_id;

                    //conteudo do módulo
                    const moduloFiles = files.filter(
                        (file) => file.index === index
                    );

                    if (moduloFiles.length > 0) {
                        const formData = new FormData();

                        for (const file of moduloFiles) {
                            formData.append("file", file);
                        }

                        formData.append("modulo_id", JSON.stringify(moduloId));

                        await api.post("/conteudos", formData);
                    }

                    //imagem do módulo
                    const moduloImage = files2.filter(
                        (file) => file.index === index
                    );

                    console.log(
                        "🚀 ~ file: TrilhasCreate.js ~ line 208 ~ forawait ~ moduloImage.length",
                        moduloImage.length
                    );
                    if (moduloImage.length > 0) {
                        const formData2 = new FormData();

                        for (const file of moduloImage) {
                            formData2.append("file", file);
                        }
                        formData2.append(
                            "imagem_modulo_id",
                            JSON.stringify(moduloId)
                        );

                        await api.post("/conteudos", formData2);
                    }
                }
            }

            history.push("/admin/trilhas");

            enqueueSnackbar("Trilha criada com sucesso!", {
                variant: "success",
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar("Falha ao criar trilha", {
                    variant: "error",
                });
            }
        }
    };

    const handleChangeModulos = async (index, event) => {
        let { name, value } = event.target;
        let modulo = trilha.modulos.find((item, index2) => index === index2);
        modulo[name] = value;
        setTrilha({
            ...trilha,
            modulos: trilha.modulos.map((item, index2) =>
                index === index2 ? modulo : item
            ),
        });
        console.log("trilha.modulos", trilha.modulos);
    };

    const handleChangePreRequisitos = (event, index) => {
        let modulo = trilha.modulos.find((item, index2) => index === index2);
        modulo["preRequisito"] = event.target.value;
        setTrilha({
            ...trilha,
            modulos: trilha.modulos.map((item, index2) =>
                index === index2 ? modulo : item
            ),
        });
    };

    const handleChangeModuloAtivo = (event, index) => {
        let modulo = trilha.modulos.find((item, index2) => index === index2);
        modulo["ativo"] = event.target.checked;
        setTrilha({
            ...trilha,
            modulos: trilha.modulos.map((item, index2) =>
                index === index2 ? modulo : item
            ),
        });
    };

    const handleNext = async () => {
        try {
            if (activeStep === 1) {
                handleCreateTrilha();
                return;
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
            enqueueSnackbar("Falha ao criar trilha", {
                variant: "error",
            });
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <TrilhaForm1
                        handleInputChange={handleInputChange}
                        validationErrors={validationErrors}
                        categorias={categorias}
                        usuario={usuario}
                        setModulos={setModulos}
                        setTrilha={setTrilha}
                        desativaStatus={desativaStatus}
                        trilha={trilha}
                    />
                );
            case 1:
                return (
                    <div>
                        <TrilhaForm2
                            trilha={trilha}
                            setTrilha={setTrilha}
                            setModulo={setModulos}
                            handleChangeModulos={handleChangeModulos}
                            modulo={modulos}
                            handleInputChange={handleInputChange}
                            handleAddmodulos={handleAddmodulos}
                            handleRemoveModulo={handleRemoveModulo}
                            files={files}
                            setFiles={setFiles}
                            files2={files2}
                            setFiles2={setFiles2}
                            handleChangePreRequisitos={
                                handleChangePreRequisitos
                            }
                            handleChangeModuloAtivo={handleChangeModuloAtivo}
                        />
                    </div>
                );
            default:
                return (
                    <TrilhaForm1
                        handleInputChange={handleInputChange}
                        validationErrors={validationErrors}
                        categorias={categorias}
                        usuario={usuario}
                        setTrilha={setTrilha}
                        desativaStatus={desativaStatus}
                        trilha={trilha}
                    />
                );
        }
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/trilhas"
                >
                    Trilhas
                </Link>
                <Typography className={classes.breadCrumb}>
                    Nova trilha
                </Typography>
            </Breadcrumbs>
            <Typography variant="h3" component="h1">
                Nova trilha
            </Typography>
            <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {loading ? (
                <TableRow>
                    <TableCell align="center" colSpan={5}>
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            ) : (
                <div className={classes.content}>
                    <Card>
                        <CardContent>
                            <>{getStepContent(activeStep)}</>
                        </CardContent>
                    </Card>
                </div>
            )}
            <Grid container justify="flex-end" style={{ marginTop: 90 }}>
                {activeStep === 0 ? (
                    <>
                        <Button onClick={handleBackToPage} color="default">
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                        >
                            Próximo
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={handleBack} color="default">
                            Voltar
                        </Button>
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                        >
                            Salvar
                        </Button>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default TrilhasCreate;
