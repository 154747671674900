import React from 'react';
import TransferList from '../../../../components/admin/TransferList';

const GerenciarCategoriaDialog = (props) => {
    const {
        categoriasIdsObrigatorias,
        setCategoriasIdsObrigatorias,
        categoriasIdsNaoObrigatorias,
        setCategoriasIdsNaoObrigatorias,
        nomeCategorias,
    } = props;

    return (
        <>
            <TransferList
                left={categoriasIdsNaoObrigatorias}
                setLeft={setCategoriasIdsNaoObrigatorias}
                right={categoriasIdsObrigatorias}
                setRight={setCategoriasIdsObrigatorias}
                nomeCategorias={nomeCategorias}
            />
        </>
    );
};

export default GerenciarCategoriaDialog;
