/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import {
    Box,
    Grid,
    Breadcrumbs,
    Link,
    Typography,
    Card,
    CardContent,
    MenuItem,
    Button,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItemText,
    Menu,
    Divider,
    ListItemIcon,
    DialogContentText,
    TextField


} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, Link as RouterLink, useParams } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from "notistack";
import api from "../../../services/api";
import CustomTextField from './../../../components/admin/CustomTextField';
import CustomKeyboardDatePicker from './../../../components/admin/CustomKeyboardDatePicker';
import CardFiles from './components/CardFiles'
import ReactPlayer from 'react-player';
import { API_HOST } from '../../../config_servers';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    alignRight: {
        display: "flex",
        justifyContent: "flex-end",
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        padding: '30px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#BFBFBF',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        width: '95%',
        transition: 'border .24s ease-in-out',
        margin: '0 auto',
    }
}));

const TreinamentoPresencialCreate = () => {
    const classes = useStyles();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [treinamento, setTreinamento] = useState({
        nome: '', 
        grupo_id: '', 
        data: '', 
        quantidade_aluno: '', 
        quantidade_presentes: '', 
        descricao: '',
        hora_inicio: '',
        hora_fim:''
    });
    const [grupos, setGrupos] = useState([]);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [videoFilePath, setVideoFilePath] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [indexSelected, setIndexSelected] = useState({});
    const [isOpenAlterarDescricao, setIsOpenAlterarDescricao] = useState(false);
    const [descricaoArquivo, setDescricaoArquivo] = useState('');
    const [isOpenRenomearConteudo, setIsOpenRenomearConteudo] = useState(false);
    const [nomeArquivo, setNomeArquivo] = useState('');
    const [duracao, setDuracao] = useState('00:00');
    const [percentual, setPercentual] = useState(0.00);
    const { treinamento_id } = useParams();
    const open = Boolean(anchorEl);

    const handleBackToPage = () => {
        history.goBack();
    };
    const handleUpdateTreinamento = async () => {
        try {
            const snackbarKey = enqueueSnackbar('Salvando, por favor aguarde...', { variant: 'warning', persist: true });

            const conteudoExistenteTreinamento = files.filter(
                (file) => file.conteudo_id
            );
            treinamento.conteudo = conteudoExistenteTreinamento;
            
            
            await api.put('/treinamento', {treinamento})
            const novoConteudoDoModulo = files.filter(
                (file) => !file.conteudo_id
            );

            if (novoConteudoDoModulo.length > 0) {
                const formData = new FormData();
                for (const file of novoConteudoDoModulo) {
                    formData.append('file', file);
                }

                formData.append('treinamento_id', JSON.stringify(treinamento.treinamento_id));
                
                await api.post('/conteudos', formData);
            }

            closeSnackbar(snackbarKey)
            enqueueSnackbar('Alterações salvas com sucesso', {
                variant: 'success'
            });
            //history.push("/admin/treinamento/presencial");
        } catch (error) {
            setLoading(false)
            if (error.response) {
                enqueueSnackbar(error.response.error, {
                    variant: "error",
                });
            }
        }
    };
    const getTreinamento = async () => {
        try {
            const response = await api.get(`/treinamento/${treinamento_id}`);
            setTreinamento(response.data);
            setFiles(response.data.conteudo);
            let treinamento = response.data
            if(treinamento.quantidade_aluno && treinamento.quantidade_presentes)
                setPercentual(((Number(treinamento.quantidade_presentes) / Number(treinamento.quantidade_aluno))*100).toFixed(2))
            if(treinamento.hora_inicio && treinamento.hora_fim){
                let duracao = getSecondsForMinutes(moment("2022-01-07 "+ treinamento.hora_fim).diff(moment("2022-01-07 "+ treinamento.hora_inicio), 'minutes'))
                setDuracao(duracao)
            }
        } catch (error) {
            
            enqueueSnackbar('Não foi posssivel encontrar o treinamento', {
                variant: 'error',
            });
            history.push("/admin/treinamento/presencial");
            
        }
    };
    const getGrupos = async () => {
        try {
            const response = await api.get('/grupos');
            setGrupos(response.data);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };
    useEffect(() => {
        getGrupos();
        getTreinamento();
    },[])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        getFilesFromEvent: (event) => myCustomFileGetter(event),
    });

    useEffect(() => {
        setFiles([...files, ...acceptedFiles]);
    }, [acceptedFiles]);

    async function myCustomFileGetter(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);
            Object.defineProperty(file, 'posicao', {
                value: Number(files.length) + i + 1,
                writable: true,
            });

            _files.push(file);
        }
        return _files;
    }
    const getSecondsForMinutes = (minutos) =>{
        if(minutos < 0) return '00:00'
        let hora = minutos/60 | 0;
        let min = minutos%60 | 0;
        return String(hora).padStart(2, '0') + ':'+ String(min).padStart(2, '0')
    }

    const handleTime = async (campo, valor) => {
        setTreinamento({...treinamento, [campo]: valor.format('HH:mm') })
        if(campo == 'hora_inicio' && treinamento.hora_fim){
            let duracao = getSecondsForMinutes(moment("2022-01-07 "+ treinamento.hora_fim).diff(valor.format('2022-01-07 HH:mm'), 'minutes'))
            setDuracao(duracao)
        }else if(campo == 'hora_fim' && treinamento.hora_inicio){
            let duracao = getSecondsForMinutes(moment(valor.format('2022-01-07 HH:mm')).diff(moment("2022-01-07 "+ treinamento.hora_inicio), 'minutes'))
            setDuracao(duracao)
        }
    }
    const handlePresentes = async (campo, valor) => {
        if(!valor.match(/^\d+/)) return;
        if(campo == 'quantidade_aluno' && treinamento.quantidade_presentes){
            setPercentual(((Number(treinamento.quantidade_presentes) / Number(valor))*100).toFixed(2))
        }else if(campo == 'quantidade_presentes' && treinamento.quantidade_aluno){
            setPercentual(((Number(valor) / Number(treinamento.quantidade_aluno))*100).toFixed(2))
        }
        setTreinamento({...treinamento, [campo]: valor}) 
    }

    const handleOpenVideo = (file) => {
        setIsOpenVideo(true);
        setSelectedFile(file);
        if (!file.conteudo_id) {
            setVideoFilePath(URL.createObjectURL(file));
        }
    };

    const handleCloseVideo = () => {
        setIsOpenVideo(false);
    };

    const openMenu = (event, file, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedFile(file);
        setIndexSelected(index);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenCloseRenomarConteudo = () => {
        setAnchorEl(null);
        setIsOpenRenomearConteudo(!isOpenRenomearConteudo);
    };
    const handleRenomearConteudo = () => {
        
        files[indexSelected].name = nomeArquivo
        setFiles(files);
        setNomeArquivo('');
        setSelectedFile('');
        setIndexSelected('');
        handleOpenCloseRenomarConteudo();
    };
    const handleOpenCloseAlterarDescricao = () => {
        setAnchorEl(null);
        setIsOpenAlterarDescricao(!isOpenAlterarDescricao);
    };

    const handleAlterarDescricao = () => {
        files[indexSelected].descricao = descricaoArquivo
        setFiles(files);
        setDescricaoArquivo('');
        setSelectedFile('');
        setIndexSelected('');
        handleOpenCloseAlterarDescricao();
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/treinamento/presencial"
                >
                    Treinamento
                </Link>
                <Typography className={classes.breadCrumb}>
                    Editar Treinamento
                </Typography>
            </Breadcrumbs>
            <Typography variant="h3" component="h1" style={{ marginTop:'30px' }}>
                Editar Treinamento
            </Typography>

            <div className={classes.content}>
                <Card>
                    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                        <Box>
                            <Grid container spacing={1}  xs={12}>
                                <Grid item xs={4}>
                                    <CustomTextField
                                        required
                                        label="Nome"
                                        name="nome"
                                        margin="normal"
                                        value={treinamento.nome}
                                        onChange={e => setTreinamento({...treinamento, nome: e.target.value })}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <CustomTextField
                                        select
                                        required
                                        label="Grupo"
                                        name="grupo"
                                        margin="normal"
                                        onChange={e => setTreinamento({...treinamento, grupo_id: e.target.value })}
                                        value={treinamento.grupo_id}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    >
                                        {grupos.map((grupo) => (
                                            <MenuItem value={grupo.grupo_id}>
                                                {grupo.nome}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <CustomKeyboardDatePicker
                                        label="Data"
                                        required
                                        fullWidth
                                        name="data"
                                        InputLabelProps={{ shrink: true }}
                                        value={treinamento.data}
                                        margin="normal"
                                        onChange={(val) =>
                                            setTreinamento({
                                                ...treinamento,
                                                data: val
                                                    ? moment(val).format('YYYY-MM-DD')
                                                    : null,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} style={{display: 'flex', alignItems: 'center', marginTop:'8px'}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                                        <TimePicker
                                            clearable
                                            ampm={false}
                                            label="Início"
                                            value={treinamento.hora_inicio ? moment(`2022-01-07 ${treinamento.hora_inicio}`) : null}
                                            onChange={newValue => handleTime('hora_inicio', newValue)}
                                            inputVariant="outlined"
                                        /> -
                                        <TimePicker
                                            clearable
                                            ampm={false}
                                            label="Fim"
                                            value={treinamento.hora_fim ? moment(`2022-01-07 ${treinamento.hora_fim}`) : null}
                                            onChange={(newValue) => handleTime('hora_fim', newValue)}
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={1}>
                                    <CustomTextField
                                        label="Duração"
                                        name="duracao"
                                        margin="normal"
                                        value={duracao}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <CustomTextField
                                        required
                                        label="Quantidade de alunos inscritos"
                                        name="quantidade_aluno"
                                        margin="normal"
                                        value={treinamento.quantidade_aluno}
                                        onChange={e => handlePresentes("quantidade_aluno", e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <CustomTextField
                                        required
                                        label="Quantidade de presentes"
                                        name="quantidade_presentes"
                                        margin="normal"
                                        value={treinamento.quantidade_presentes}
                                        onChange={e => handlePresentes("quantidade_presentes", e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <CustomTextField
                                        required
                                        label="Percentual de presentes"
                                        name="presentes"
                                        margin="normal"
                                        value={percentual}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        type="number"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <CustomTextField
                                        required
                                        label="Descrição"
                                        name="descricao"
                                        margin="normal"
                                        value={treinamento.descricao}
                                        onChange={e => setTreinamento({...treinamento, descricao: e.target.value })}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        multiline
                                        rowsMax="10"
                                        rows="10"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{justifyContent: 'center', display: 'flex'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div
                                        style={{ marginTop: 40 }}
                                        {...getRootProps({
                                            className: classes.dropZone,
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <Typography
                                            variant="subtitle1"
                                            component="p"
                                            gutterBottom={false}
                                        >
                                            Arraste ou clique para adicionar
                                            conteúdos do treinamento
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            style={{ marginTop: 32, marginLeft: 45 }}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid container spacing={3}
                                justifyContent="center"
                                alignItems="center"
                            >
                                {!loading && files.length > 0 &&
                                files.map((file, index) => {
                                    return(
                                    <CardFiles key={`item-${index}`} i={index} file={file} setFiles={setFiles} files={files} handleOpenVideo={handleOpenVideo} openMenu={openMenu} isEdit={!!file.conteudo_id} />
                                )})}
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </div>
            <Grid container justify="flex-end" style={{ marginTop: 30 }}>
                <Button  
                    color="default" 
                    onClick={handleBackToPage}
                >
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick = {handleUpdateTreinamento}
                    disabled = {loading}
                >
                    Alterar
                </Button>
            </Grid>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="md"
                open={isOpenVideo}
                onClose={handleCloseVideo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    Visualizar conteúdo
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {selectedFile.type === 'application/pdf' ? (
                            <>
                                {console.log(
                                    '🚀 ~ file: ModulosForm.js ~ line 849 ~ ModuloForm ~ file.mimetype',
                                    selectedFile
                                )}
                                <div>
                                    <iframe
                                        style={{
                                            width: '563px',
                                            height: '666px',
                                        }}
                                        src={selectedFile.conteudo_id
                                            ? `${API_HOST}/assets/conteudos/${selectedFile.arquivo}`
                                            : videoFilePath}
                                        type="application/pdf"
                                        title="title"
                                    />
                                </div>
                            </>
                        ) : (
                            <ReactPlayer
                                url={
                                    selectedFile.conteudo_id
                                        ? `${API_HOST}/assets/conteudos/${selectedFile.arquivo}`
                                        : videoFilePath
                                }
                                width="80%"
                                height="80%"
                                controls={true}
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button onClick={handleCloseVideo} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleOpenCloseAlterarDescricao}>
                    <ListItemIcon style={{ minWidth: '32px' }}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Alterar descrição" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleOpenCloseRenomarConteudo}>
                    <ListItemIcon style={{ minWidth: '32px' }}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Renomear arquivo" />
                </MenuItem>
            </Menu>

            <Dialog
                open={isOpenAlterarDescricao}
                onClose={handleOpenCloseAlterarDescricao}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Editar descrição
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informe abaixo a nova descrição para o conteúdo:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={descricaoArquivo}
                        onChange={(e) => setDescricaoArquivo(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleOpenCloseAlterarDescricao}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleAlterarDescricao} color="primary">
                        Alterar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isOpenRenomearConteudo}
                onClose={handleOpenCloseRenomarConteudo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Renomear conteúdo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informe abaixo o novo nome para o conteúdo:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={nomeArquivo}
                        onChange={(e) => setNomeArquivo(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleOpenCloseRenomarConteudo}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleRenomearConteudo} color="primary">
                        Alterar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TreinamentoPresencialCreate;
