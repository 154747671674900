/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
import api from '../services/api';

export const isAuthenticated = () => {
    return !!localStorage.getItem('@treinadelta/token');
};

export const getUsuarioId = () => {
    return localStorage.getItem('@treinadelta/usuario_id') || null;
};

export const getToken = () => {
    return localStorage.getItem('@treinadelta/token') || null;
};

export const hasAccess = (grupo, secao = false) => {
    const acessos = JSON.parse(localStorage.getItem('@treinadelta/acessos'));
    if (secao) {
        const tela = `${String(grupo)}.${String(secao)}`;
        if (acessos[tela]) {
            const acessosFormatados = {};

            for (const value of acessos[tela]) {
                acessosFormatados[value.toLowerCase()] = true;
            }

            return acessosFormatados;
        }
        return false;
    }

    return acessos[grupo] ?? false;
};

export const setAuthSession = (token, usuarioId, acessos, imagemUsuario) => {
    localStorage.setItem('@treinadelta/token', token);
    localStorage.setItem('@treinadelta/usuario_id', usuarioId);
    localStorage.setItem('@treinadelta/acessos', JSON.stringify(acessos));
    localStorage.setItem('@treinadelta/imagem_usuario', imagemUsuario);
};

export const removeAuthSession = () => {
    localStorage.removeItem('@treinadelta/token');
    localStorage.removeItem('@treinadelta/usuario_id');
    localStorage.removeItem('@treinadelta/acessos');
    localStorage.removeItem('@treinadelta/imagem_usuario');
};

export const getParceiroId = async () => {
    try {
        let usuario_id = getUsuarioId();

        const response = await api.get(`/parceiros/usuario/${usuario_id}`);
        return response.data;
    } catch (error) {
        if (error.response) {
            return 'ERRO';
        }
    }
};
