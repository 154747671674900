import styled from "styled-components";
import { colors } from "../../../../assets/theme/colors";

export const Container = styled.div`
    position: relative;
    height: 80px;
    min-width: 25rem;
    overflow: hidden;
    & span {
        font-size: 1.6rem;
        color: ${({ secondary }) => (secondary ? colors.secondary : "#ffffff")};
    }
    & input {
        width: 100%;
        height: 100%;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 200;
        padding-top: 35px;
        border: none;
        outline: none;
        background: transparent;
        &:disabled {
            color: #ffffff4d;
        }
        &::placeholder {
            color: ${({ questoes }) => (questoes ? "#fff" : "#333333")};
            opacity: 0.3;
            font-style: italic;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 50000s ease-in-out 0s;
            -webkit-text-fill-color: white !important;
        }
    }
    & label {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border-bottom: ${({ validation, secondary }) => {
                if (validation && validation === true) {
                    return `2px solid ${colors.tertiary};`;
                }
                if (validation === false) {
                    return `2px solid ${colors.quaternary};`;
                }
                if (validation === "respondida") {
                    return `2px solid ${colors.primary};`;
                }
                if (secondary) {
                    return `2px solid #E1E1E3;`;
                }
                return `2px solid #ffffff10;`;
            }}
            & span {
            position: absolute;
            bottom: 5px;
            left: 0px;
            transition: all 0.3s ease;
        }
    }

    & input:focus + label span,
    input:-webkit-autofill + label span,
    input:valid + label span,
    input:disabled + label span,
    input:placeholder-shown + label span {
        transform: translateY(-170%);
        font-size: 1.4rem;
        opacity: 0.6;
        font-weight: 200;
    }
`;
