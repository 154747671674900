/* eslint-disable no-param-reassign */
export const cpfMask = (value) => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const cnpjMask = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
};

export const cpfCnpj = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length <= 11) {
        return cpfMask(value);
    }
    return cnpjMask(value);
};

export const phoneMask = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length >= 11) {
        return value.replace(
            /(\d{2})(\d{1})(\d{4})(\d{4})\d*?$/g,
            '($1) $2 $3-$4'
        );
    }
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{1})/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
};

export const cepMask = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');
};

export const estadoMask = (value) => {
    return value
        .toUpperCase()
        .replace(/[0-9]/g, '')
        .replace(/(\w{2})(\w)/, '');
};

export const placaMask = (value) => {
    return value.replace(/(\w*)(.*)/g, '$1').replace(/(\w{7})(\w)/, '$1');
};

export const chassiMask = (value) => {
    return value.replace(/(\w{17})(\w)/, '$1');
};

export const renavamMask = (value) => {
    return value.replace(/\D/g, '').replace(/(\d{11})(\w*)/, '$1');
};

export const moneyMask = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d+)(\d{2})/g, '$1,$2')
        .replace(/(\d)(?=(\d{3})+,)/g, '$1.');
};

export const removeNonNumericCharacters = (value) => {
    return value.replace(/\D/g, '');
};
