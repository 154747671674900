/* eslint-disable no-unused-vars */
import React from 'react';
import {
    Box,
    Grid,
    FormControlLabel,
    FormGroup,
    MenuItem,
} from '@material-ui/core';

import moment from 'moment';
import {
    cpfMask,
    phoneMask,
    removeNonNumericCharacters,
} from '../../../../helpers/masks';
import CustomTextField from '../../../../components/admin/CustomTextField';
import CustomKeyboardDatePicker from '../../../../components/admin/CustomKeyboardDatePicker';
import IOSSwitch from '../../../../components/admin/IOSwitch';

const UsuarioForm1 = (props) => {
    const { usuario, setUsuario, handleInputChange, validationErrors, edit } =
        props;

        return (
        <Box p={2}>
            <Grid
                alignItems="flex-start"
                justifyContent="flex-start"
                container
                xs={6}
                md={2}
            >
                <FormGroup
                    style={{
                        marginLeft: -16,
                    }}
                >
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={usuario.ativo ? true : false}
                                value={usuario.ativo}
                                onChange={(event) =>
                                    setUsuario({
                                        ...usuario,
                                        ativo: event.target.checked,
                                    })
                                }
                                name="status"
                            />
                        }
                        label="Usuário ativo"
                        labelPlacement="start"
                    />
                </FormGroup>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Nome"
                        name="nome"
                        margin="normal"
                        value={usuario.nome}
                        error={validationErrors.nome}
                        onChange={handleInputChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Usuário"
                        name="usuario"
                        margin="normal"
                        value={
                            usuario.usuario
                                ? usuario.usuario
                                : usuario.email
                                ? usuario.email
                                : usuario.telefone &&
                                  removeNonNumericCharacters(usuario.telefone)
                        }
                        error={validationErrors.usuario}
                        onChange={handleInputChange}
                        disabled
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Senha"
                        name="senha"
                        margin="normal"
                        type="password"
                        value={usuario.senha}
                        error={validationErrors.senha}
                        onChange={handleInputChange}
                        required={!!edit}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="E-mail"
                        name="email"
                        value={usuario.email}
                        error={validationErrors.email}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="Telefone"
                        name="telefone"
                        value={usuario.telefone}
                        onChange={(e) =>
                            setUsuario({
                                ...usuario,
                                telefone: phoneMask(e.target.value),
                            })
                        }
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                {!usuario.cnpj &&
                    <Grid item xs={12} md={4}>
                        <CustomTextField
                            label="CPF"
                            name="cpf"
                            value={usuario.cpf}
                            error={validationErrors.cpf}
                            onChange={(e) =>
                                setUsuario({
                                    ...usuario,
                                    cpf: cpfMask(e.target.value),
                                })
                            }
                            margin="normal"
                            required
                            fullWidth
                        />
                    </Grid>
                }

                <Grid item xs={12} md={4}>
                    <CustomTextField
                        select
                        label="Escolaridade"
                        name="escolaridade"
                        value={usuario.escolaridade}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                    >
                        <MenuItem value={1}>Ensino fundamental</MenuItem>
                        <MenuItem value={2}>Ensino médio</MenuItem>
                        <MenuItem value={3}>Ensino técnico</MenuItem>
                        <MenuItem value={4}>Superior cursando</MenuItem>
                        <MenuItem value={5}>Superior completo</MenuItem>
                    </CustomTextField>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomKeyboardDatePicker
                        label="Data de nascimento"
                        value={
                            usuario.data_nascimento
                                ? usuario.data_nascimento
                                : null
                        }
                        onChange={(val) =>
                            setUsuario({
                                ...usuario,
                                data_nascimento: val
                                    ? moment(val).format('YYYY-MM-DD')
                                    : null,
                            })
                        }
                    />
                </Grid>
                {/* <Grid item xs={12} md={4}>
                    <CustomKeyboardDatePicker
                        label="Data de admissão"
                        value={
                            usuario.data_admissao ? usuario.data_admissao : null
                        }
                        onChange={(val) =>
                            setUsuario({
                                ...usuario,
                                data_admissao: val
                                    ? moment(val).format('YYYY-MM-DD')
                                    : null,
                            })
                        }
                    />
                </Grid> */}
            </Grid>
        </Box>
    );
};

export default UsuarioForm1;
