import React, { useEffect, useRef } from 'react';
import { ButtonDropContainer, Drop, DropList } from './style';

const ButtonDrop = ({ children, icon, open, changeOpen, ...rest }) => {
    // const [active, setActive] = useState(false);
    const node = useRef();

    // const handleClick = (e) => {
    //     if (node.current.contains(e.target)) {
    //         // inside click
    //     }
    //     // outside click
    //     function closeMenu() {
    //         changeOpen(false);
    //     }
    //     closeMenu();
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClick);

    //     return () => {
    //         document.removeEventListener('mousedown', handleClick);
    //     };
    // }, []);

    // const handleClickOutside = (event) => {
    //     if (node.current && !node.current.contains(event.target)) {
    //         changeOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside, true);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside, true);
    //     };
    // });

    const clickListener = (e) => {
        if (!node?.current?.contains(e.target)) {
            changeOpen(false);
        }
    };

    useEffect(() => {
        // Attach the listeners on component mount.
        document.addEventListener('click', clickListener);
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener('click', clickListener);
        };
    }, []);

    return (
        <ButtonDropContainer
            active={open}
            onClick={() => {
                changeOpen(!open);
            }}
            notification={rest.notification}
            user={rest.user}
            search={rest.search}
            ref={node}
        >
            {icon}
            <Drop active={open} onClick={(e) => e.stopPropagation()}>
                <DropList active={open}>{children}</DropList>
            </Drop>
        </ButtonDropContainer>
    );
};

export default ButtonDrop;
