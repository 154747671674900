import React, { useState } from 'react';
import {
    Info,
    InfoLabel,
    InfoText,
} from '../../../Trilha/components/InfoTrilha/style';
import { useSnackbar } from 'notistack';

import Button from '../../../../../components/aluno/Button';
import {
    ButtonsContainer,
    ModalContainer,
    ModalTitle,
    SubTitleModal,
    TitleModal,
} from '../../../../../components/aluno/Modal/components/ModalCommentary/style';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../../services/api';
import { GridInfoModalInicioAvaliacao } from './style';
import moment from 'moment';

const ModalAvaliacao = ({
    handleModal,
    openModal,
    avaliacao,
    tempoDuracao,
    qtdQuestoes,
    questoesRespondidas,
    handleEnviarAvaliacao,
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(openModal);
    const { moduloId } = useParams();
    const history = useHistory();

    const toggleOpen = () => {
        if (openModal === false) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'overlay';
        }
    };

    return (
        <ModalContainer iniciarTentativa>
            <ModalTitle>
                <TitleModal>
                    Entregar avaliação - {avaliacao?.nome_avaliacao}
                </TitleModal>
            </ModalTitle>
            <SubTitleModal>
                <Info modal>
                    <InfoText>
                        Realmente deseja finalizar a tentativa da avaliação?
                    </InfoText>
                </Info>
            </SubTitleModal>

            <GridInfoModalInicioAvaliacao iniciarTentativa>
                <Info modal>
                    <InfoLabel>Módulo</InfoLabel>
                    <InfoText> {avaliacao?.nome_modulo}</InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Duração</InfoLabel>
                    <InfoText>
                        {tempoDuracao}
                    </InfoText>
                </Info>
                <Info modal>
                    <InfoLabel>Falta responder </InfoLabel>
                    <InfoText>
                        {qtdQuestoes - questoesRespondidas < 0
                            ? 0
                            : qtdQuestoes - questoesRespondidas}
                        /{qtdQuestoes}
                    </InfoText>
                </Info>
            </GridInfoModalInicioAvaliacao>
            <ButtonsContainer>
                <Button
                    cancel
                    onClick={() => {
                        handleModal(false);
                        toggleOpen();
                    }}
                >
                    Cancelar
                </Button>
                <Button big onClick={handleEnviarAvaliacao}>
                    Confirmar e entregar
                </Button>
            </ButtonsContainer>
        </ModalContainer>
    );
};

export default ModalAvaliacao;
