/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory, Link as RouterLink, useParams } from 'react-router-dom';

import {
    Box,
    Grid,
    Breadcrumbs,
    CircularProgress,
    Button,
    Link,
    Tabs,
    Card,
    CardContent,
    Typography,
    Tab,
    Divider,
    RadioGroup,
    Checkbox,
    FormControlLabel,
    Radio,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import api from '../../../services/api';
import CustomTextField from '../../../components/admin/CustomTextField';
import { ErrorChip, SuccessChip } from '../../../helpers/helper';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    saveButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const VisualizarResultadoAvaliacao = () => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { trilhaId, moduloId, resultadoAvaliacaoId } = useParams();
    const [validationErrors] = useState({});
    const [trilhas, setTrilhas] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [modulo, setModulo] = useState({});
    const [trilha, setTrilha] = useState({});
    const [loading, setLoading] = useState(true);
    const [questoes, setQuestoes] = useState([
        { tipo_questao: 'multipla_escolha', respostas: [{}] },
    ]);
    const [resultado, setResultado] = useState({ questoes: [] });
    const [questoesChecked, setQuestoesChecked] = useState({});
    const [usuario, setUsuario] = useState({});
    const [avaliacao, setAvaliacao] = useState({ respostas: [] });
    const [isOpenRespostaCorreta, setIsOpenRespostaCorreta] = useState(false);
    const [isOpenRespostaErrada, setIsOpenRespostaErrada] = useState(false);
    const [questaoSelecionada, setQuestaoSelecionada] = useState({});
    // const [questoes, setQuestoes] = useState([]);

    useEffect(() => {
        getResultadoAvaliacao();
        getModulo();
        getTrilha();
        getUsuario();
    }, []);

    const getResultadoAvaliacao = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `/modulos/avaliacao/${resultadoAvaliacaoId}`
            );
            setResultado(response.data);
            console.log(
                '🚀 ~ file: VisualizarResultadoAvaliacao.js ~ line 92 ~ getResultadoAvaliacao ~ response.data',
                response.data
            );

            const response2 = await api.get(
                `/avaliacoes/${response.data.avaliacao_id}`
            );
            setAvaliacao(response2.data.avaliacao);
            console.log(
                '🚀 ~ file: VisualizarResultadoAvaliacao.js ~ line 104 ~ getResultadoAvaliacao ~ response.data',
                response2.data
            );
            setQuestoes(response2.data.questoes);
            setLoading(false);
        } catch (error) {
            setLoading(false);

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getTrilha = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/trilhas/dados/${trilhaId}`);
            setTrilha(response.data);
            setLoading(false);
            console.log(
                '🚀 ~ file: ResultadoAvaliacao.js ~ line 134 ~ getTrilha ~ response.data',
                response.data
            );
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getModulo = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/modulos/${moduloId}`);
            console.log(
                '🚀 ~ file: ResultadoAvaliacao.js ~ line 154 ~ getModulo ~ response.data',
                response.data
            );
            setModulo(response.data.modulo);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getUsuario = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `/usuarios/${localStorage.getItem('@treinadelta/usuario_id')}`
            );
            console.log(
                '🚀 ~ file: ResultadoAvaliacao.js ~ line 154 ~ getModulo ~ response.data',
                response.data
            );
            setUsuario(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    // const updateAvaliacao = async () => {
    //     try {
    //         await api.put(`/avaliacoes/${id}`, { avaliacao, questoes });
    //         enqueueSnackbar('Avaliação editada com sucesso!', {
    //             variant: 'success',
    //         });
    //         history.push('/admin/avaliacoes');
    //     } catch (error) {
    //         if (error.response) {
    //             enqueueSnackbar('Falha ao criar avaliação', {
    //                 variant: 'error',
    //             });
    //         }
    //     }
    // };

    const handleBackToPage = () => {
        history.goBack();
    };

    const handleOpenRespostaCorreta = (questao_id) => {
        setIsOpenRespostaCorreta(!isOpenRespostaCorreta);
        setQuestaoSelecionada(questao_id);
    };

    const handleOpenRespostaErrada = (questao_id) => {
        setIsOpenRespostaErrada(!isOpenRespostaErrada);
        setQuestaoSelecionada(questao_id);
    };

    const handleRespostaCorreta = async () => {
        try {
            setLoading(true);
            handleOpenRespostaCorreta();
            await api.post(`/avaliacoes/questaoDissertativaCorreta`, {
                questao_id: questaoSelecionada,
                resultado_avaliacao_id: resultadoAvaliacaoId,
            });

            const response = await api.get(
                `/modulos/avaliacao/${resultadoAvaliacaoId}`
            );
            setResultado(response.data);
            setLoading(false);
            enqueueSnackbar('Resposta corrigida com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleRespostaErrada = async () => {
        try {
            setLoading(true);
            handleOpenRespostaErrada();
            await api.post(`/avaliacoes/questaoDissertativaErrada`, {
                questao_id: questaoSelecionada,
                resultado_avaliacao_id: resultadoAvaliacaoId,
            });

            const response = await api.get(
                `/modulos/avaliacao/${resultadoAvaliacaoId}`
            );
            setResultado(response.data);
            setLoading(false);
            enqueueSnackbar('Resposta corrigida com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/trilhas"
                >
                    Trilhas ({trilha.nome})
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/admin/trilhas/${trilhaId}/modulos/${moduloId}`}
                >
                    {`Módulo ${moduloId} (${modulo.nome})`}
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/admin/trilhas/${trilhaId}/modulos/${moduloId}/avaliacao`}
                >
                    {`Avaliações`}
                </Link>
                <Typography className={classes.breadCrumb}>
                    {`Avaliação (${usuario.nome})`}
                </Typography>
            </Breadcrumbs>
            <Typography variant="h3" component="h1">
                {'Avaliação'}
            </Typography>

            {loading ? (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '80vh' }}
                >
                    <Grid item xs={3}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : (
                <div>
                    {resultado.questoes.map((questao, index) => (
                        <div style={{ paddingTop: 38 }}>
                            <Card>
                                <CardContent>
                                    <>
                                        <Grid item container>
                                            <>
                                            {questao.acerto === 1 ? (
                                                    <CheckIcon
                                                        style={{
                                                            minWidth: "25px",
                                                            marginTop: 10,
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            color: '#4BCA81',
                                                        }}
                                                    />
                                                ) : (
                                                    <CloseIcon
                                                        style={{
                                                            minWidth: "25px",
                                                            marginTop: 10,
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            color: '#FD7B72',
                                                        }}
                                                    />
                                                )}
                                                <CustomTextField
                                                    style={{
                                                        width: '80%',
                                                    }}
                                                    label={`Questão ${
                                                        index + 1
                                                    }`}
                                                    name="titulo"
                                                    margin="normal"
                                                    value={questao.titulo}
                                                    multiline
                                                    rowsMax={18}
                                                    
                                                    onChange={() => {}}
                                                    fullWidth
                                                    InputProps={{ disableUnderline: true }}

                                                />
                                            

                                                <Grid
                                                    style={{
                                                        marginTop: 16,
                                                        width: '100%',
                                                    }}
                                                    item
                                                    container
                                                    xs={12}
                                                    direction="row"
                                                    spacing={2}
                                                    justify="flex-start"
                                                    alignItems="center"
                                                >
                                                    <RadioGroup
                                                        aria-label="gender"
                                                        name="multipla_escolha_correta"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        row
                                                        value={
                                                            questao.multipla_escolha_usuario
                                                        }
                                                        onChange={() => {}}
                                                    >
                                                        {questao.respostas.map(
                                                            (
                                                                resposta,
                                                                indexResposta
                                                            ) => (
                                                                <>
                                                                    <Grid
                                                                        container
                                                                    >
                                                                        {!resposta.remover &&
                                                                            (questao.tipo_questao ===
                                                                                'caixa_selecao' ||
                                                                                questao.tipo_questao ===
                                                                                    'multipla_escolha') && (
                                                                                <>
                                                                                 <Grid style={{minWidth: "25px"}}>
                                                                                        {questao.tipo_questao ===
                                                                                        'multipla_escolha' ? (
                                                                                            resposta.resposta_questao_id ===
                                                                                            questao.multipla_escolha_correta && (
                                                                                                <CheckIcon
                                                                                                    style={{
                                                                                                        marginTop: 15,
                                                                                                        color: '#4BCA81',
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                            //  : (
                                                                                            //     <CloseIcon
                                                                                            //         style={{
                                                                                            //             marginTop: 20,
                                                                                            //             marginLeft: 32,
                                                                                            //             color: '#FD7B72',
                                                                                            //         }}
                                                                                            //     />
                                                                                            // )
                                                                                        ) : questao.tipo_questao ===
                                                                                              'caixa_selecao' &&
                                                                                          resposta.acerto ===
                                                                                              1 && (
                                                                                            <CheckIcon
                                                                                                style={{
                                                                                                    marginTop: 20,
                                                                                                    color: '#4BCA81',
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                        //  : (
                                                                                        //     <CloseIcon
                                                                                        //         style={{
                                                                                        //             marginTop: 20,
                                                                                        //             marginLeft: 32,
                                                                                        //             color: '#FD7B72',
                                                                                        //         }}
                                                                                        //     />
                                                                                        // )
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        container
                                                                                        direction="column"
                                                                                        justify="flex-start"
                                                                                        alignItems="flex-start"
                                                                                        xs={
                                                                                            6
                                                                                        }
                                                                                        style={{
                                                                                            padding: 5,
                                                                                        }}
                                                                                    >
                                                                                        {questao.tipo_questao ===
                                                                                            'caixa_selecao' && (
                                                                                            <Checkbox
                                                                                                style={{
                                                                                                    marginLeft:
                                                                                                        -6,
                                                                                                }}
                                                                                                name="checkbox_resposta_correta"
                                                                                                onChange={() => {}}
                                                                                                checked={
                                                                                                    resposta.checkbox_resposta_usuario
                                                                                                }
                                                                                                color="primary"
                                                                                            />
                                                                                        )}

                                                                                        {questao.tipo_questao ===
                                                                                            'multipla_escolha' && (
                                                                                            <>
                                                                                                <FormControlLabel
                                                                                                    value={
                                                                                                        resposta.resposta_questao_id
                                                                                                    }
                                                                                                    control={
                                                                                                        <Radio
                                                                                                            color="primary"
                                                                                                            style={{
                                                                                                                marginLeft: 7,
                                                                                                            }}
                                                                                                        />
                                                                                                    }
                                                                                                    checked={
                                                                                                        String(
                                                                                                            questao.multipla_escolha_usuario
                                                                                                        ) ===
                                                                                                        String(
                                                                                                            resposta.resposta_questao_id
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </>
                                                                                        )}

                                                                                        <TextField
                                                                                            InputProps={{
                                                                                                classes:
                                                                                                    {
                                                                                                        root: classes.inputRoot,
                                                                                                    },
                                                                                                disableUnderline: true
                                                                                            }}
                                                                                            style={{
                                                                                                marginLeft: 33,
                                                                                                marginTop:
                                                                                                    -34,
                                                                                            }}
                                                                                            name="texto"
                                                                                            value={
                                                                                                resposta.texto
                                                                                            }
                                                                                            multiline
                                                                                            rowsMax={
                                                                                                15
                                                                                            }
                                                                                            onChange={() => {}}
                                                                                            fullWidth
                                                  

                                                                                        />
                                                                                    </Grid>
                                                                                   
                                                                                </>
                                                                            )}
                                                                    </Grid>

                                                                    {!resposta.remover &&
                                                                        questao.tipo_questao ===
                                                                            'dissertativo' && (
                                                                            <>
                                                                                <Grid
                                                                                    item
                                                                                    container
                                                                                    direction="row"
                                                                                    justify="space-between"
                                                                                    alignItems="space-between"
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        container
                                                                                    >
                                                                                        <CustomTextField
                                                                                            style={{
                                                                                                marginLeft: 6,
                                                                                                marginBottom: 32,
                                                                                                width: '80%',
                                                                                            }}
                                                                                            label="Resposta"
                                                                                            name="texto"
                                                                                            margin="normal"
                                                                                            value={
                                                                                                questao.resposta
                                                                                            }
                                                                                            multiline
                                                                                            rowsMax={
                                                                                                15
                                                                                            }
                                                                                            onChange={() => {}}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                            fullWidth
                                                                                        />
                                                                                    </Grid>
                                                                                    {!(
                                                                                        questao.acerto >
                                                                                        0
                                                                                    ) && (
                                                                                        <Grid
                                                                                            item
                                                                                            container
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '30%',
                                                                                            }}
                                                                                        >
                                                                                            <IconButton
                                                                                                onClick={() =>
                                                                                                    handleOpenRespostaCorreta(
                                                                                                        questao.questao_id
                                                                                                    )
                                                                                                }
                                                                                                variant="none"
                                                                                                size="large"
                                                                                            >
                                                                                                <SuccessChip
                                                                                                    size="large"
                                                                                                    onClick={() => {}}
                                                                                                    texto="Resposta correta"
                                                                                                />
                                                                                            </IconButton>
                                                                                            <IconButton
                                                                                                onClick={() =>
                                                                                                    handleOpenRespostaErrada(
                                                                                                        questao.questao_id
                                                                                                    )
                                                                                                }
                                                                                                variant="none"
                                                                                            >
                                                                                                <ErrorChip
                                                                                                    size="large"
                                                                                                    onClick={() => {}}
                                                                                                    texto="Resposta errada"
                                                                                                />
                                                                                            </IconButton>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                </>
                                                            )
                                                        )}
                                                    </RadioGroup>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    <Divider
                                                        variant="middle"
                                                        style={
                                                            {
                                                                // marginTop: 28,
                                                                // marginBottom: 23,
                                                            }
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        </Grid>
                                    </>
                                </CardContent>
                            </Card>
                        </div>
                    ))}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            onClick={handleBackToPage}
                            className={classes.backButton}
                        >
                            Voltar
                        </Button>
                    </div>
                </div>
            )}
            <Dialog
                open={isOpenRespostaCorreta}
                onClose={handleOpenRespostaCorreta}
                aria-labelledby="confirm-dialog"
            >
                <DialogTitle id="confirm-dialog">Resposta Correta</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Essa resposta será corrigida como <b>correta</b>, deseja
                        continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOpenRespostaCorreta} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleRespostaCorreta} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenRespostaErrada}
                onClose={handleOpenRespostaErrada}
                aria-labelledby="confirm-dialog"
            >
                <DialogTitle id="confirm-dialog">Resposta Errada</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Essa resposta será corrigida como <b>errada</b>, deseja
                        continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOpenRespostaErrada} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleRespostaErrada} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default VisualizarResultadoAvaliacao;
