/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import {
    Box,
    Grid,
    FormControlLabel,
    FormGroup,
    Button,
    CircularProgress,
    TextField,
    MenuItem,
    Breadcrumbs,
    Tooltip,
    Card,
    CardContent,
    Link,
    Typography,
    List,
    CardMedia,
    CardActions,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    Input,
    Chip,
    Menu,
    DialogContentText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';

import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import formatBytes from '../../../helpers/formatBytes';
import CustomTextField from '../../../components/admin/CustomTextField';
import CustomKeyboardDatePicker from '../../../components/admin/CustomKeyboardDatePicker';
import IOSSwitch from '../../../components/admin/IOSwitch';
import api from '../../../services/api';
import Backdrop from '@material-ui/core/Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { PrimaryChip } from '../../../helpers/helper';

import { API_HOST } from '../../../config_servers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, preRequisitos, theme) {
    return {
        fontWeight:
            preRequisitos.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(6),
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        padding: '30px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#BFBFBF',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        width: '95%',
        transition: 'border .24s ease-in-out',
        margin: '0 auto',
    },
    dropZoneImage: {
        display: 'flex',
        alignItens: 'center',
        justifyContent: 'center',
        // padding: "30px",
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#BFBFBF',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        height: '160px',
        transition: '0.8s',
        marginTop: 16,
        margin: '0 auto',
        // "&:hover": {
        //     opacity: '0.5'
        // },
    },
    media: {
        height: 80,
    },
    card: {
        borderRadius: 0,
        borderColor: '#BFBFBF',
        color: '#fafafa',
    },
    divider: {
        background: '#BFBFBF',
    },
    formControl: {
        minWidth: 210,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ModuloForm = (props) => {
    const { match } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const [validationErrors] = useState({});
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [modulo, setModulo] = useState({});
    const [files, setFiles] = useState([]);
    const [files2, setFiles2] = useState([]);
    const [loading, setLoading] = useState(false);
    const [trilha, setTrilha] = useState({});
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [videoFilePath, setVideoFilePath] = useState(null);
    const [preRequisitos, setPreRequisitos] = useState([]);
    const [modulos, setModulos] = useState([]);
    const { trilhaId, moduloId } = useParams();
    const [isEdit] = useState(moduloId > 0);
    const [fileSelected, setFileSelected] = useState({});
    const [indexSelected, setIndexSelected] = useState({});
    const [nomeArquivo, setNomeArquivo] = useState('');
    const [descricaoArquivo, setDescricaoArquivo] = useState('');
    const [isOpenRenomearConteudo, setIsOpenRenomearConteudo] = useState(false);
    const [isOpenAlterarDescricao, setIsOpenAlterarDescricao] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [idUploadEmProgresso, setIdUploadEmProgresso] = useState(0);
    const [idSnackbar, setIdSnackbar] = useState(null)
    const open = Boolean(anchorEl);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModulo({ ...modulo, [name]: value });
    };

    useEffect(() => {
        getAvaliacoes();
        getTrilha();
        isEdit && getModulo();
        getModulos();
    }, []);

    useEffect(() => {
        if (isUploading && idUploadEmProgresso > 0) {
            const interval = setInterval(() => {
                if (isUploading) {
                    verificaSeUploadFinalizou(idUploadEmProgresso)
                } 
              }, 1000 * 5);
              return () => clearInterval(interval); 
        }
    }, [isUploading, idUploadEmProgresso])

    const verificaSeUploadFinalizou = async (id) => {
        try {
            if (id > 0) {
                const response = await api.get(`/conteudos/verificaSeUploadFinalizou/${id}`);
                console.log("🚀 ~ file: ModulosForm.js ~ line 245 ~ verificaSeUploadFinalizou ~ response", response)
                if (response.data.finalizado) {
                    enqueueSnackbar('Módulo editado com sucesso', {
                        variant: 'success',
                    });
                    closeSnackbar(idSnackbar)
                    setIsUploading(false)
                    setLoading3(false)
                }
            } else {
                console.log('!idUpload > 0')
            }

        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao buscar categoria', {
                    variant: 'error',
                });
            }
        } 
    }

    const getAvaliacoes = async () => {
        try {
            const response = await api.get('/avaliacoes');
            let itens = response.data;

            setAvaliacoes(itens.filter(item => (
                item.label = String(item.avaliacao_id) 
            )))
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao buscar categoria', {
                    variant: 'error',
                });
            }
        } 
    };
    const getModulo = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/modulos/${moduloId}`);

            const newModulo = response.data.modulo;
            const imagemModulo = response.data.imagemModulo;

            if (imagemModulo.length > 0) {
                const fileImageArray = [];

                fileImageArray.push(imagemModulo[0]);
                setFiles2(fileImageArray);
                console.log(
                    '🚀 ~ file: ModulosForm.js ~ line 258 ~ getModulo ~ fileImageArray',
                    fileImageArray
                );
            }

            console.log(newModulo)
            setModulo(newModulo);
            setPreRequisitos(response.data.preRequisitos);

            let newFiles = [];

            newFiles = [...newFiles, ...newModulo.conteudo];

            for (const [index, file] of newFiles.entries()) {
                file.posicao = index;
            }
            setFiles(newFiles);

            console.log(
                '🚀 ~ file: ModulosForm.js ~ line 273 ~ getModulo ~ newFiles',
                newFiles
            );
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getModulos = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/modulos/trilha/${trilhaId}`)
            console.log(response.data)

            setModulos(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getTrilha = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/trilhas/dados/${trilhaId}`);
            setTrilha(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleCreateModulo = async () => {
        try {
            setLoading3(true);
            console.log(modulo)
            const response = await api.post('/modulos', {
                modulo,
                trilhaId,
                preRequisitos,
            });
            const snackbarKey = enqueueSnackbar('Salvando, por favor aguarde...', { variant: 'warning', persist: true });
            setIdSnackbar(snackbarKey)
           

            const moduloId = response.data.modulo_id;

            if (files.length > 0) {
                setIsUploading(true);

                const response = await api.post('/conteudos/insertInicioUpload')
                const upload_em_progresso_id = response.data.upload_em_progresso_id
                setIdUploadEmProgresso(upload_em_progresso_id)

                const formData = new FormData();

                for (const file of files) {
                    formData.append('file', file);
                }

                formData.append('modulo_id', JSON.stringify(moduloId));
                formData.append('upload_em_progresso_id', JSON.stringify(upload_em_progresso_id));

                await api.post('/conteudos', formData);
                setIsUploading(false);

            }

            console.log('dksadksasa');
            console.log('files2 length', files2.length);
            if (files2.length > 0) {
                const formData = new FormData();

                for (const file of files2) {
                    formData.append('file', file);
                }

                formData.append('imagem_modulo_id', JSON.stringify(moduloId));
                console.log(
                    '🚀 ~ file: ModulosForm.js ~ line 322 ~ handleCreateModulo ~ JSON.stringify(moduloId)',
                    JSON.stringify(moduloId)
                );

                await api.post('/conteudos', formData);
            }

            enqueueSnackbar('Módulo criado com sucesso!', {
                variant: 'success',
            });
            closeSnackbar(snackbarKey)
            setLoading3(false);
            history.push(`/admin/trilhas/${trilhaId}/modulos`);
        } catch (error) {
            setLoading3(false);
            closeSnackbar(idSnackbar)
            if (error.response) {
                enqueueSnackbar('Falha ao criar módulo', {
                    variant: 'error',
                });
            }
        }
    };

    const handleUpdateModulo = async () => {
        try {
            setLoading3(true);
            const snackbarKey = enqueueSnackbar('Salvando, por favor aguarde...', { variant: 'warning', persist: true });
            setIdSnackbar(snackbarKey)

            const conteudoExistenteDoModulo = files.filter(
                (file) => file.conteudo_id
            );
            modulo.conteudo = conteudoExistenteDoModulo;
            await api.put(`/modulos/trilha/${moduloId}`, {
                modulo,
                preRequisitos,
            });

            const novoConteudoDoModulo = files.filter(
                (file) => !file.conteudo_id
            );

            if (novoConteudoDoModulo.length > 0) {
                setIsUploading(true);

               
                const response = await api.post('/conteudos/insertInicioUpload')
                const upload_em_progresso_id = response.data.upload_em_progresso_id

                setIdUploadEmProgresso(upload_em_progresso_id)
                const formData = new FormData();

                for (const file of novoConteudoDoModulo) {
                    formData.append('file', file);
                }

                formData.append('modulo_id', JSON.stringify(modulo.modulo_id));
                formData.append('upload_em_progresso_id', JSON.stringify(upload_em_progresso_id));
                
                await api.post('/conteudos', formData);
                setIsUploading(false)
            }

            //remove imagem do modulo e adiciona a que está no files2, caso seja alterado
            if (!files2.length > 0) {
                await api.delete(`/conteudos/${moduloId}`);
            }
            if (files2.length > 0 && !(files2[0].conteudo_id > 0)) {
                console.log('chegou files2.conteudo_iddsfsdfsdfsfsdf');

                await api.delete(`/conteudos/${moduloId}`);

                const formData = new FormData();

                for (const file of files2) {
                    formData.append('file', file);
                }

                formData.append('imagem_modulo_id', moduloId);
                console.log(
                    '🚀 ~ file: ModulosForm.js ~ line 392 ~ handleUpdateModulo ~ JSON.stringify(moduloId)',
                    JSON.stringify(moduloId)
                );

                await api.post('/conteudos', formData);
            }

            getModulo();

            enqueueSnackbar('Módulo editado com sucesso!', {
                variant: 'success',
            });
            setLoading3(false);
            closeSnackbar(snackbarKey)

            // history.push(`/admin/trilhas/${trilhaId}/modulos`);
        } catch (error) {
            setLoading3(false);
            closeSnackbar(idSnackbar)
            if (error.response) {
                enqueueSnackbar('Falha ao criar módulo', {
                    variant: 'error',
                });
            }
        }
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    async function myCustomFileGetter(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, 'index', {
                // value: indexSelected,
                writable: true,
            });

            Object.defineProperty(file, 'posicao', {
                value: Number(files.length) + i + 1,
                writable: true,
            });

            _files.push(file);
        }
        console.log('files', files);
        return _files;
    }

    async function myCustomFileGetter2(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            _files.push(file);
        }
        console.log('files2', files);
        return _files;
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        getFilesFromEvent: (event) => myCustomFileGetter(event),
    });

    const {
        acceptedFiles: acceptedFiles2,
        getRootProps: getRootProps2,
        getInputProps: getInputProps2,
    } = useDropzone({
        accept: 'image/jpeg, image/png',
        getFilesFromEvent: (event) => myCustomFileGetter2(event),
    });

    useEffect(() => {
        setFiles([...files, ...acceptedFiles]);
    }, [acceptedFiles]);

    useEffect(() => {
        setFiles2([...files2, ...acceptedFiles2]);
    }, [acceptedFiles2]);

    const removeFile = (file, i) => () => {
        const newFiles = [...files];
        const id = newFiles.indexOf(file);
        newFiles.splice(id, 1);
        setFiles([...newFiles]);
        // setFiles([
        //     ...files.map((item, index2) =>
        //         i === index2 ? { ...item, remover: true } : item
        //     ),
        // ]);
        console.log('files', files);
    };

    const handleSubirPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index > 0) {
            const arrayPrevIndex = newFiles[index - 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao - 1;
            newFiles[index - 1].posicao = newFiles[index - 1].posicao + 1;

            newFiles[index] = arrayPrevIndex;
            newFiles[index - 1] = file;
        }

        setFiles([...newFiles]);
    };
    const onSortEnd = ({oldIndex, newIndex}) => {
        const newFiles = [...files];

        if (oldIndex < files.length - 1) {
            const arrayNextIndex = newFiles[newIndex];

            // eslint-disable-next-line operator-assignment
            newFiles[oldIndex].posicao = newFiles[oldIndex].posicao + 1;
            newFiles[newIndex].posicao = newFiles[newIndex].posicao - 1;

            newFiles[oldIndex] = arrayNextIndex;
            newFiles[newIndex] = files[oldIndex];
        }

        setFiles([...newFiles]);
       
      };
      
    const handleDescerPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index > 0) {
            const arrayPrevIndex = newFiles[index + 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao + 1;
            newFiles[index + 1].posicao = newFiles[index + 1].posicao - 1;

            newFiles[index] = arrayPrevIndex;
            newFiles[index + 1] = file;
        }

        setFiles([...newFiles]);
    };

    const handleOpenVideo = (file) => {
        setIsOpenVideo(true);
        setSelectedFile(file);
        if (!file.conteudo_id) {
            setVideoFilePath(URL.createObjectURL(file));
        }
    };

    const handleCloseVideo = () => {
        setIsOpenVideo(false);
    };

    const handleRemoveImage = () => {
        setFiles2([]);
    };
    const SortableItem = SortableElement(({file , i}) => {
    return(<>
        <Grid
            item
            sm={4}
            style={{ flexDirection: 'row', display: 'flex'}}
            xs={12}
        >
            {i != '0' ?
                <div style={{display: 'flex', justifyContent: 'space-between',flexDirection: 'column'}}>
                    <div>
                        <PrimaryChip
                            texto={
                                i+1
                            }
                            size="medium"
                        />
                    </div>
                    <div>
                        <Tooltip   Tooltip
                            title="Mudar posição"
                            placement="top"
                            arrow
                            style={{height: 30, marginTop: -35}}
                        >
                            <IconButton

                                onClick={() =>
                                    handleSubirPosicaoDoDocumento(
                                        file,
                                        i
                                    )
                                }
                            >
                                <ChevronLeftIcon 
                            />
                            </IconButton >
                        </Tooltip>
                    </div>
                    
                    <div></div>
                </div>
                : 
                <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 8}}>
                    <div></div>
                    <PrimaryChip
                        texto={
                            i+1
                        }
                        size="medium"
                    />
                    <div></div>

                </div>
            }
            <Box
                border={1}
                borderColor="#DCDCDC"
            >
                <Card
                    classes={{
                        root: classes.card,
                    }}
                >
                    <CardMedia
                        className={
                            classes.media
                        }
                        image={`${API_HOST}/assets/conteudos/${file.arquivo}`}
                        title=""
                    />

                    <CardContent>
                        <Grid
                            container
                        >
                           
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end"
                            >
                            <Grid
                                   justify="space-between"
                                   style={{ width: "100%", justifyContent: 'space-between', flexDirection: 'row', display: 'flex' , alignItems: 'center'}}
                                   >
                                <div>
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    gutterBottom={
                                        false
                                    }
                                >
                                    {
                                        file.name
                                    }
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    component="h6"
                                    gutterBottom={
                                        false
                                    }
                                />
                                </div>
                                <div>

                                    <Tooltip
                                        title="Remover conteúdo"
                                        placement="top"
                                        arrow
                                    >
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={removeFile(
                                                file,
                                                i
                                                )}
                                                >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                            
                                {file.conteudo_id && (
                                        <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            onClick={(e) =>
                                                openMenu(
                                                    e,
                                                    file,
                                                    i
                                                )
                                            }
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                )}
                                </div>
                            </Grid>

                            </Grid>
                            <Grid
                                item
                                xs={
                                    12
                                }
                            >
                                <Typography
                                    variant="subtitle2"
                                    component="h6"
                                    gutterBottom={
                                        false
                                    }
                                >
                                    {formatBytes(
                                        file.size
                                    )}
                                    <Typography
                                        variant="subtitle2"
                                        component="h6"
                                    >
                                        {
                                            file.descricao
                                        }
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider
                        classes={{
                            root: classes.divider,
                        }}
                    />

                    <CardActions
                        style={{
                            justifyContent:
                                'center',
                        }}
                    >
                        <Button
                            color="default"
                            startIcon={
                                <VisibilityIcon />
                            }
                            onClick={() =>
                                handleOpenVideo(
                                    file
                                )
                            }
                        >
                            Visualizar
                        </Button>
                    </CardActions>
                </Card>
            </Box>
            {/* <Tooltip
                title="Mudar posição"
                placement="top"
                arrow
            >
                <IconButton
                    onClick={() =>
                        handleDescerPosicaoDoDocumento(
                            file,
                            i
                        )
                    }
                >
                    <ChevronRightIcon />
                </IconButton>
            </Tooltip> */}
        </Grid>
    </>)
});

    const SortableList = SortableContainer(({items}) => {
      return(
        <Grid container spacing={3}
        justifyContent="center"
        alignItems="center">
            {!loading &&
            items.map((file, index) => {
                return(
                <SortableItem key={`item-${index}`} index={index} i={String(index)} file={file} />
            )})}
        </Grid>
      )
    })

    const openMenu = (event, file, index) => {
        setAnchorEl(event.currentTarget);
        setFileSelected(file);
        setIndexSelected(index);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleRenomearConteudo = () => {
        setFiles([
            ...files.map((item, index2) =>
                indexSelected === index2 ? { ...item, name: nomeArquivo } : item
            ),
        ]);
        setNomeArquivo('');
        setFileSelected('');
        setIndexSelected('');
        handleOpenCloseRenomarConteudo();
    };

    const handleAlterarDescricao = () => {
        setFiles([
            ...files.map((item, index2) =>
                indexSelected === index2
                    ? { ...item, descricao: descricaoArquivo }
                    : item
            ),
        ]);
        setDescricaoArquivo('');
        setFileSelected('');
        setIndexSelected('');
        handleOpenCloseAlterarDescricao();
    };

    const handleOpenCloseRenomarConteudo = () => {
        setAnchorEl(null);
        setIsOpenRenomearConteudo(!isOpenRenomearConteudo);
        const index = files.indexOf(fileSelected);
        setNomeArquivo(files[index].name);
    };

    const handleOpenCloseAlterarDescricao = () => {
        setAnchorEl(null);
        setIsOpenAlterarDescricao(!isOpenAlterarDescricao);
        const index = files.indexOf(fileSelected);
        setDescricaoArquivo(files[index].descricao);
    };

    return (
        <div>
            <Box p={2}>
                <Breadcrumbs
                    className={classes.breadCrumb}
                    aria-label="breadcrumb"
                    gutterBottom
                >
                    <Link color="inherit" component={RouterLink} to="/admin/">
                        Home
                    </Link>
                    <Link
                        color="inherit"
                        component={RouterLink}
                        to="/admin/trilhas"
                    >
                        Trilhas
                    </Link>
                    <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/admin/trilhas/${trilhaId}/modulos`}
                    >
                        Modulos ({trilha.nome})
                    </Link>
                    getRootProps2
                    <Typography className={classes.breadCrumb}>
                        {isEdit ? `Editar módulo ${moduloId}` : 'Novo módulo'}
                    </Typography>
                </Breadcrumbs>
                <Typography variant="h3" component="h1">
                    {isEdit ? 'Editar módulo' : 'Novo módulo'}
                </Typography>
                <div className={classes.content}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={modulo.ativo === true}
                                                value={modulo.ativo}
                                                onChange={(event) =>
                                                    setModulo({
                                                        ...modulo,
                                                        ativo: event.target
                                                            .checked,
                                                    })
                                                }
                                                name="status"
                                            />
                                        }
                                        label="Módulo ativo"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid
                                container
                                style={{ marginTop: 16 }}
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item direction="column" xs={4}>
                                    <Grid
                                        item
                                        container
                                        justify="flex-start"
                                        alignItems="center"
                                        direction="row"
                                        xs={12}
                                        md={12}
                                    >
                                        <div
                                            style={{
                                                width: '280px',
                                                marginLeft: 40,
                                            }}
                                            {...getRootProps2({
                                                className:
                                                    classes.dropZoneImage,
                                            })}
                                        >
                                            {files2.length === 0 ? (
                                                <>
                                                    <input
                                                        {...getInputProps2()}
                                                    />

                                                    <Typography
                                                        variant="subtitle1"
                                                        component="p"
                                                        gutterBottom={false}
                                                        style={{
                                                            padding: 40,
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        Arraste ou clique para
                                                        adicionar a foto do
                                                        módulo {moduloId}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        style={{
                                                            maxHeight: '100%',
                                                            maxWidth: '100%',
                                                        }}
                                                        src={
                                                            files2[0]
                                                                .conteudo_id
                                                                ? `${API_HOST}/assets/conteudos/${files2[0].arquivo}`
                                                                : URL.createObjectURL(
                                                                      files2[0]
                                                                  )
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                        {files2.length !== 0 && (
                                            <Tooltip
                                                title="Remover imagem"
                                                arrow
                                                placement="top"
                                            >
                                                <IconButton
                                                    style={{ marginRight: 50 }}
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() =>
                                                        handleRemoveImage()
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    xs={12}
                                    md={4}
                                    justify="space-between"
                                    alignItems="stretch"
                                >
                                    <Grid item xs={6}>
                                        <CustomTextField
                                            label="Nome"
                                            name="nome"
                                            margin="normal"
                                            value={modulo.nome}
                                            error={validationErrors.nome}
                                            onChange={handleInputChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            style={{
                                                width: '150%',
                                                marginBottom: 32,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            style={{
                                                marginTop: '-11px',
                                                width: "150%",

                                            }}
                                            getOptionLabel={(secoes) => secoes.nome}
                                            options={avaliacoes}
                                            value={
                                            avaliacoes.find(
                                                    (item) => item.avaliacao_id === modulo.avaliacao_id
                                                )
                                                    ? avaliacoes.find(
                                                        (item) =>
                                                            item.avaliacao_id === modulo.avaliacao_id
                                                    )
                                                    : null
                                            }
                                            onChange={(e, value) =>
                                            {
                                                setModulo({
                                                    ...modulo,
                                                    avaliacao_id: value?.avaliacao_id ? value.avaliacao_id : null
                                                })
                                            }
                                        }
                                            loading={loading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.autocomplete}
                                                    label="Avaliação"
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    xs={12}
                                    md={4}
                                >
                                    
                                    <Grid item xs={6}>
                                        <CustomTextField
                                            label="Descrição"
                                            name="descricao"
                                            margin="normal"
                                            multiline="true"
                                            value={modulo.descricao}
                                            error={validationErrors.modulo}
                                            onChange={handleInputChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            style={{
                                                width: '150%',
                                                marginBottom: 32,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            style={{
                                                marginTop: '-11px',
                                                width: "150%",

                                            }}
                                            getOptionLabel={(secoes) => secoes.modulo_id_trilha + ' - ' + secoes.nome}
                                            options={modulos}
                                            value={
                                                modulos.find(
                                                    (item) => item.modulo_id === modulo.pre_requisito_id
                                                )
                                                    ? modulos.find(
                                                        (item) =>
                                                            item.modulo_id === modulo.pre_requisito_id
                                                    )
                                                    : null
                                            }
                                            onChange={(e, value) =>
                                            {
                                                setModulo({
                                                    ...modulo,
                                                    pre_requisito_id: value.modulo_id
                                                })
                                            }
                                        }
                                            loading={loading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.autocomplete}
                                                    label="Pré requisito"
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {/* <CustomTextField
                                            select
                                            label="Pré-requisitos"
                                            name="pre_requisito_id"
                                            value={parseInt(
                                                modulo.pre_requisito_id
                                            )}
                                            // error={validationErrors.avaliacao_id}
                                            onChange={handleInputChange}
                                            margin="normal"
                                            fullWidth
                                            displayEmpty
                                            style={{ width: '150%' }}
                                        >
                                            <MenuItem value="">
                                                Sem pré-requisito
                                            </MenuItem>
                                            {modulos.map((modulo) => (
                                                        <MenuItem
                                                            key={
                                                                modulo.modulo_id
                                                            }
                                                            value={
                                                                modulo.modulo_id
                                                            }
                                                        >
                                                            {modulo.modulo_id_trilha} - {modulo.nome}
                                                        </MenuItem>
                                                    ))}
                                        </CustomTextField> */}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div
                                        style={{ marginTop: 40 }}
                                        {...getRootProps({
                                            className: classes.dropZone,
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <Typography
                                            variant="subtitle1"
                                            component="p"
                                            gutterBottom={false}
                                        >
                                            Arraste ou clique para adicionar
                                            conteúdos do módulo{' '}
                                        </Typography>
                                    </div>

                                    <Box
                                        style={{ marginTop: 32, marginLeft: 45 }}
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <SortableList axis lockAxis lockToContainerEdges={true}  onSortEnd={onSortEnd} items={files} />
                                    </Box>
                                </Grid>
                            </Grid>
                            <div className={classes.alignRight}>
                                <Button
                                    color="default"
                                    onClick={handleBackToPage}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={
                                        isEdit
                                            ? handleUpdateModulo
                                            : handleCreateModulo
                                    }
                                    variant="contained"
                                    color="primary"
                                    disabled={loading3}
                                >
                                    {isUploading ? 'Salvando' : 'Salvar'}
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Box>
            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="md"
                open={isOpenVideo}
                onClose={handleCloseVideo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    Visualizar conteúdo
                </DialogTitle>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={loading2}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {selectedFile.type === 'application/pdf' ? (
                            <>
                                {console.log(
                                    '🚀 ~ file: ModulosForm.js ~ line 849 ~ ModuloForm ~ file.mimetype',
                                    selectedFile
                                )}
                                <div>
                                    <iframe
                                        style={{
                                            width: '563px',
                                            height: '666px',
                                        }}
                                        src={videoFilePath}
                                        type="application/pdf"
                                        title="title"
                                    />
                                </div>
                            </>
                        ) : (
                            <ReactPlayer
                                url={
                                    selectedFile.conteudo_id
                                        ? `${API_HOST}/assets/conteudos/${selectedFile.arquivo}`
                                        : videoFilePath
                                }
                                width="80%"
                                height="80%"
                                controls={true}
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button onClick={handleCloseVideo} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleOpenCloseAlterarDescricao}>
                    <ListItemIcon style={{ minWidth: '32px' }}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Alterar descrição" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleOpenCloseRenomarConteudo}>
                    <ListItemIcon style={{ minWidth: '32px' }}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Renomear arquivo" />
                </MenuItem>
            </Menu>

            <Dialog
                open={isOpenRenomearConteudo}
                onClose={handleOpenCloseRenomarConteudo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Renomear conteúdo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informe abaixo o novo nome para o conteúdo:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={nomeArquivo}
                        onChange={(e) => setNomeArquivo(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleOpenCloseRenomarConteudo}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleRenomearConteudo} color="primary">
                        Alterar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenAlterarDescricao}
                onClose={handleOpenCloseAlterarDescricao}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Editar descrição
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informe abaixo a nova descrição para o conteúdo:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={descricaoArquivo}
                        onChange={(e) => setDescricaoArquivo(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleOpenCloseAlterarDescricao}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleAlterarDescricao} color="primary">
                        Alterar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModuloForm;
