/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import PerfectScrollbar from "react-perfect-scrollbar";
import SearchIcon from "@material-ui/icons/Search";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import CategoryIcon from "@material-ui/icons/Category";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from 'lodash.debounce';
import Backdrop from "@material-ui/core/Backdrop";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
    Breadcrumbs,
    Checkbox,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stepper,
    DialogContentText,
    Step,
    StepLabel,
    FormGroup,
    FormControlLabel,
    Grid,
    Link,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { ErrorChip, SuccessChip } from "../../../helpers/helper";
import { hasAccess } from "../../../services/auth";

import api from "../../../services/api";
import CustomTextField from "../../../components/admin/CustomTextField";
import IOSSwitch from "../../../components/admin/IOSwitch";
import getValidationErors from "../../../utils/geValidationErrors";

import GerenciarCategoriaDialog from "./components/GerenciarCategoriaDialog";
import GerenciarUsuariosDialog from "./components/GerenciarUsuariosDialog";
import { getUsuarioId } from "../../../services/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
    paper: {
        borderRadius: "4px",
        alignItems: "center",
        padding: theme.spacing(1),
        display: "flex",
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: "14px",
        lineHeight: "16px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    container: {
        maxHeight: 450,
    },
    autocomplete: {
        backgroundColor: "white",
        // height: '45.625px'
    },
}));

function getSteps() {
    return ["Disponíveis", "Selecionadas"];
}

const GrupoList = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const acesso = hasAccess("Usuario", "Grupo de usuarios");
    const acessoAdmin = hasAccess("Configuracoes", "Parceiros");
    const [initialFilters] = useState({
        nome: "",
        email: "",
        perfil_id: "",
    });
    const [filters, setFilters] = useState(initialFilters);
    const [grupos, setGrupos] = useState([]);
    const [grupo, setGrupo] = useState({ 'cadastrador_id': parseInt(getUsuarioId())});

    const [loading, setLoading] = useState(true);
    const [idSelected, setIdSelected] = useState();
    const [grupoSelecionado, setGrupoSelecionado] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [openCadastroGrupo, setOpenCadastroGrupo] = useState(false);
    const [openGerenciarCategorias, setOpenGerenciarCategorias] =
        useState(false);
    const [openGerenciarUsuarios, setOpenGerenciarUsuarios] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    const [usuarios, setUsuarios] = useState([]);
    const [usuariosFilter, setUsuariosFilter] = useState({});
    const [usuariosChecked, setUsuariosChecked] = useState({});
    const [loading2, setLoading2] = useState(false);
    const [qtdUsuariosSelecionados, setQtdUsuariosSelecionados] = useState(0);
    const [gruposFilter, setGruposFilter] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [categoriasDisponiveisChecked, setCategoriasDisponiveisChecked] =
        useState({});
    const [categoriasIdsObrigatorias, setCategoriasIdsObrigatorias] = useState(
        []
    );
    const [categoriasIdsNaoObrigatorias, setCategoriasIdsNaoObrigatorias] =
        useState([]);
    const [categoriasIdsDisponiveis, setCategoriasIdsDisponiveis] = useState(
        []
    );

    const [qtdCategoriasDisponiveis, setQtdCategoriasDisponiveis] = useState(0);
    const [nomeCategorias, setNomeCategorias] = useState({});
    const open = Boolean(anchorEl);
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const [isOpenExcluirGrupo, setIsOpenExcluirGrupo] = useState(false);
    const [textFilterNome, setTextFilterNome] = useState(''); 

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCategoriasIdsNaoObrigatorias(
            categoriasIdsNaoObrigatorias.sort((a, b) => a - b)
        );
        setCategoriasIdsObrigatorias(
            categoriasIdsObrigatorias.sort((a, b) => a - b)
        );
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        getInputGrupos();
    }, []);

    useEffect(() => {
        getGrupos(filters, currentPage, perPage);
        getUsuarios();
        getCategorias();
    }, [currentPage, perPage, filters]);

    const getCategorias = async () => {
        try {
            const response = await api.get("/categorias");
            setCategorias(response.data);
            console.log(
                "🚀 ~ file: GrupoList.js ~ line 192 ~ getCategorias ~ response.data",
                response.data
            );
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const getInputGrupos = async () => {
        try {
            const response = await api.get("/grupos");
            const itens = response.data;
            setGruposFilter(itens.filter((item) => item.nome));
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const handleUsuariosFilter = async () => {
        try {
            setLoading(true);
            const response = (
                await api.get(`/usuarios/filter`, {
                    params: {
                        ...usuariosFilter,
                    },
                })
            ).data;
            setUsuarios(response);
            setLoading(false);
        } catch (error) {
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
            });
        }
    };

    const getUsuarios = async () => {
        try {
            setLoading(true);
            const response = (await api.get(`/usuarios`)).data;
            setUsuarios(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const getUsuariosPorGrupo = async () => {
        try {
            setLoading2(true);
            const response = (await api.get(`/grupos/usuarios/${idSelected}`))
                .data;
            setUsuariosChecked(response);

            for (const column in response) {
                if (response[column] === true)
                    setQtdUsuariosSelecionados((prevState) => prevState + 1);
            }
            setLoading2(false);
        } catch (error) {
            setLoading2(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const getGrupos = async (filters = [], currentPage = 0, perPage = 10) => {
        try {
            setLoading(true);
            const response = await api.get("/grupos/filter", {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setGrupos(response.data.data);
            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const getCategoriasPorGrupo = async () => {
        try {
            setLoading2(true);
            const response = (await api.get(`/grupos/categorias/${idSelected}`))
                .data;
            setCategoriasIdsObrigatorias(response.categoriasIdsObrigatorias);
            setCategoriasIdsNaoObrigatorias(
                response.categoriasIdsNaoObrigatorias
            );
            setNomeCategorias(response.nomeCategorias);
            setCategoriasDisponiveisChecked(
                response.categoriasIdDisponiveisChecked
            );
            setCategoriasIdsDisponiveis(response.categoriasIdsDisponiveis);
            setLoading2(false);
        } catch (error) {
            setLoading2(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getGrupos(filters);
    };

    const openMenuActions = (event, grupo) => {
        setAnchorEl(event.currentTarget);
        setGrupoSelecionado(grupo);
        setIdSelected(grupo.grupo_id);
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const handleOpenCadastroGrupo = () => {
        setOpenCadastroGrupo(true);
    };

    const handleCloseCadastroGrupo = () => {
        setOpenCadastroGrupo(false);
        setIsEdit(false);
        setValidationErrors({});
        setGrupo({});
    };

    const handleOpenGerenciarCategorias = async () => {
        closeMenuActions();
        setOpenGerenciarCategorias(true);
        await getCategoriasPorGrupo();
    };

    const handleCloseGerenciarCategorias = () => {
        setOpenGerenciarCategorias(false);
        setActiveStep(0);
    };

    const handleOpenGerenciarUsuarios = async () => {
        closeMenuActions();
        setOpenGerenciarUsuarios(true);
        await getUsuariosPorGrupo();
    };

    const handleCloseGerenciarUsuarios = () => {
        getUsuarios();
        setOpenGerenciarUsuarios(false);
        setUsuariosChecked({});
        setQtdUsuariosSelecionados(0);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setGrupo({ ...grupo, [name]: value });
    };

    const createGrupo = async () => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required("Nome obrigatório"),
                descricao: Yup.string().required("Descrição obrigatória"),
            });
            await schema.validate(grupo, {
                abortEarly: false,
            });

            await api.post("/grupos", grupo);
            enqueueSnackbar("Grupo cadastrado com sucesso!", {
                variant: "success",
            });
            getGrupos();
            handleCloseCadastroGrupo();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
            setLoading(false);
        }
    };

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getGrupos();
    };

    const handleOpenEditGrupo = async () => {
        closeMenuActions();
        setIsEdit(true);
        await getGrupoById();
        setOpenCadastroGrupo(true);
    };

    const getGrupoById = async () => {
        try {
            const response = await api.get(`/grupos/${idSelected}`);
            console.log(
                "🚀 ~ file: GrupoList.js ~ line 239 ~ getGrupoById ~ idSelected",
                idSelected
            );
            setGrupo(response.data);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const editGrupo = async () => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required("Nome obrigatório"),
                descricao: Yup.string().required("Descrição obrigatória"),
            });

            await schema.validate(grupo, {
                abortEarly: false,
            });

            await api.put(`/grupos/${idSelected}`, grupo);
            enqueueSnackbar("Grupo editado com sucesso!", {
                variant: "success",
            });
            getGrupos();
            handleCloseCadastroGrupo();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
            setLoading(false);
        }
    };

    const handleCheckCategoriasDisponiveis = (event) => {
        setCategoriasDisponiveisChecked({
            ...categoriasDisponiveisChecked,
            [event.target.name]: event.target.checked,
        });

        if (event.target.checked === true) {
            setCategoriasIdsDisponiveis([
                ...categoriasIdsDisponiveis,
                event.target.name,
            ]);

            setCategoriasIdsNaoObrigatorias([
                ...categoriasIdsNaoObrigatorias,
                event.target.name,
            ]);
            console.log(
                "true ~categorias id disponiveis:",
                categoriasIdsDisponiveis
            );
            console.log(
                "true ~categorias id obrigatorias:",
                categoriasIdsObrigatorias
            );
            console.log(
                "true ~categorias id nao obrigatorias:",
                categoriasIdsNaoObrigatorias
            );
            console.log(
                "true ~categorias disponiveis checked:",
                categoriasDisponiveisChecked
            );
        } else {
            setCategoriasIdsDisponiveis([
                ...categoriasIdsDisponiveis.filter(
                    // eslint-disable-next-line eqeqeq
                    (categoriaId) => categoriaId != event.target.name
                ),
            ]);
            setCategoriasIdsNaoObrigatorias([
                ...categoriasIdsNaoObrigatorias.filter(
                    // eslint-disable-next-line eqeqeq
                    (categoriaId) => categoriaId != event.target.name
                ),
            ]);
            setCategoriasIdsObrigatorias([
                ...categoriasIdsObrigatorias.filter(
                    // eslint-disable-next-line eqeqeq
                    (categoriaId) => categoriaId != event.target.name
                ),
            ]);
            console.log(
                "🚀false ~categorias id disponiveis:",
                categoriasIdsDisponiveis
            );
            console.log(
                "🚀false ~categorias id obrigatorias:",
                categoriasIdsObrigatorias
            );
            console.log(
                "🚀false ~categorias id nao obrigatorias:",
                categoriasIdsNaoObrigatorias
            );
        }

        setQtdCategoriasDisponiveis((prevState) =>
            event.target.checked === true ? prevState + 1 : prevState - 1
        );
        console.log(
            "🚀 ~qtd categorias disponiveis:",
            qtdCategoriasDisponiveis
        );
    };

    const handleCheckAllCategoriasDisponiveis = (event) => {
        let update = { ...categoriasDisponiveisChecked };
        categorias.forEach((item) => {
            update = { ...update, [item.categoria_id]: event.target.checked };
        });

        setQtdCategoriasDisponiveis(() =>
            event.target.checked === true ? categorias.length : 0
        );

        setCategoriasDisponiveisChecked({ ...update });
    };

    const handleOpenExcluirGrupo = async () => {
        setIsOpenExcluirGrupo(true);
        setAnchorEl(null);
    };

    const handleCloseExcluirGrupo = async () => {
        setIsOpenExcluirGrupo(false);
    };

    const handleExcluirGrupo = async () => {
        try {
            setAnchorEl(null);
            handleCloseExcluirGrupo();
            await api.delete(`/grupos/${idSelected}`);
            await getGrupos(filters, currentPage, perPage);
            enqueueSnackbar("Grupo removido com sucesso!", {
                variant: "success",
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <TableContainer
                                    className={classes.container}
                                    style={{ maxHeight: "9%" }}
                                >
                                    <PerfectScrollbar>
                                        <Table size="small" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ width: "5%" }}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={
                                                                handleCheckAllCategoriasDisponiveis
                                                            }
                                                            inputProps={{
                                                                "aria-label":
                                                                    "secondary checkbox",
                                                            }}
                                                            checked={
                                                                qtdCategoriasDisponiveis ===
                                                                categorias.length
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Categorias
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={2}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {categorias.map(
                                                            (categoria) => (
                                                                <TableRow
                                                                    hover
                                                                    key={
                                                                        categoria.categoria_id
                                                                    }
                                                                >
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            value={
                                                                                categoria.categoria_id
                                                                            }
                                                                            name={
                                                                                categoria.categoria_id
                                                                            }
                                                                            checked={
                                                                                !!categoriasDisponiveisChecked[
                                                                                    categoria
                                                                                        .categoria_id
                                                                                ]
                                                                            }
                                                                            onChange={
                                                                                handleCheckCategoriasDisponiveis
                                                                            }
                                                                            color="primary"
                                                                            inputProps={{
                                                                                "aria-label":
                                                                                    "secondary checkbox",
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            categoria.nome
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </PerfectScrollbar>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </>
                );
            case 1:
                return (
                    <>
                        <GerenciarCategoriaDialog
                            classes={classes}
                            openGerenciarCategorias
                            handleCloseGerenciarCategorias={
                                handleCloseGerenciarCategorias
                            }
                            loading2={loading2}
                            categoriasIdsObrigatorias={
                                categoriasIdsObrigatorias
                            }
                            setCategoriasIdsObrigatorias={
                                setCategoriasIdsObrigatorias
                            }
                            categoriasIdsNaoObrigatorias={
                                categoriasIdsNaoObrigatorias
                            }
                            setCategoriasIdsNaoObrigatorias={
                                setCategoriasIdsNaoObrigatorias
                            }
                            nomeCategorias={nomeCategorias}
                            setNomeCategorias={setNomeCategorias}
                            idSelected={idSelected}
                            enqueueSnackbar={enqueueSnackbar}
                        />
                    </>
                );
            default:
                return "case 0";
        }
    }

    const handleGerenciarCategorias = async () => {
        try {
            await api.post(`/grupos/categorias/${idSelected}`, {
                categoriasIdsObrigatorias,
                categoriasDisponiveisChecked,
            });

            enqueueSnackbar("Categorias atualizadas com sucesso!", {
                variant: "success",
            });
            handleCloseGerenciarCategorias();
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const debouncedSave = useRef(debounce(nextValue => setFilters({
        ...filters,
        [nextValue.name]: nextValue.value,
    }), 1000))
		.current;

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Grupos
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Grupos
                    </Typography>
                </Grid>
                {acesso.adicionar && (
                    <Grid
                        container
                        xs={6}
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={handleOpenCadastroGrupo}
                            color="primary"
                            variant="contained"
                        >
                            Novo Grupo
                        </Button>
                    </Grid>
                )}
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={3} alignItems="center">
                            <TextField
                                placeholder="Nome"
                                onChange={(e) => {
                                    setTextFilterNome(e.target.value)
                                    debouncedSave(e.target);
                                }}
                                name= 'nome'
                                value={textFilterNome}
                                disableUnderline
                                fullWidth
                                displayEmpty
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{ marginLeft: "2%" }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell >Descrição</TableCell>
                                                    <TableCell align="center" >Quantidade de usuários</TableCell>

                                                    { acessoAdmin.visualizar && (
                                                        <TableCell align="center">Parceiro</TableCell>
                                                    )}

                                                    <TableCell align="center">Ativo</TableCell>
                                                    <TableCell align="right" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {grupos.length === 0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {grupos.map(
                                                                    (grupo) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                grupo.grupo_id
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    grupo.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    grupo.descricao
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {
                                                                                    grupo.quantidade_usuario
                                                                                }
                                                                            </TableCell>
                                                                            { acessoAdmin.visualizar && (
                                                                                <TableCell align='center'>
                                                                                {
                                                                                    grupo.nome_parceiro ? grupo.nome_parceiro : '-'
                                                                                }
                                                                            </TableCell>
                                                                            )
                                                                            }
                                                                            <TableCell align="center">
                                                                                {grupo.ativo ? (
                                                                                    <SuccessChip texto="Ativo" />
                                                                                ) : (
                                                                                    <ErrorChip texto="Inativo" />
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {(acesso.editar ||
                                                                                    acesso[
                                                                                        "gerenciar categorias"
                                                                                    ] ||
                                                                                    acesso[
                                                                                        "gerenciar usuarios"
                                                                                    ]) && (
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        color="secondary"
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            openMenuActions(
                                                                                                e,
                                                                                                grupo
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <SettingsIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={closeMenuActions}
            >
                {acesso.editar && (
                    <MenuItem onClick={handleOpenEditGrupo}>
                        <ListItemIcon style={{ minWidth: "32px" }}>
                            <EditIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Editar grupo" />
                    </MenuItem>
                )}
                {acesso.excluir && (
                    <MenuItem onClick={handleOpenExcluirGrupo}>
                        <ListItemIcon style={{ minWidth: "32px" }}>
                            <DeleteIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Excluir grupo" />
                    </MenuItem>
                )}
                {acesso["gerenciar usuarios"] && (
                    <MenuItem onClick={handleOpenGerenciarUsuarios}>
                        <ListItemIcon style={{ minWidth: "32px" }}>
                            <AccountTreeIcon
                                color="secondary"
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Gerenciar usuários" />
                    </MenuItem>
                )}
                {acesso["gerenciar categorias"] && (
                    <MenuItem onClick={handleOpenGerenciarCategorias}>
                        <ListItemIcon style={{ minWidth: "32px" }}>
                            <CategoryIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Gerenciar categorias" />
                    </MenuItem>
                )}
            </Menu>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="xs"
                open={openCadastroGrupo}
                onClose={handleCloseCadastroGrupo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    {isEdit ? "Editar grupo" : "Criar novo grupo"}
                </DialogTitle>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Grid container spacing={2} justify="flex-start">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        checked={grupo.ativo}
                                        value={grupo.ativo}
                                        onChange={(event) =>
                                            setGrupo({
                                                ...grupo,
                                                ativo: event.target.checked,
                                            })
                                        }
                                        name="status"
                                    />
                                }
                                label="Grupo ativo"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid
                        style={{ marginTop: 8 }}
                        container
                        spacing={2}
                        justify="center"
                    >
                        <CustomTextField
                            label="Nome do grupo"
                            name="nome"
                            value={grupo.nome}
                            error={validationErrors.nome}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            required
                            displayEmpty
                        />
                    </Grid>
                    <Grid
                        style={{ marginTop: 8 }}
                        container
                        spacing={2}
                        justify="center"
                    >
                        <CustomTextField
                            label="Descrição"
                            name="descricao"
                            value={grupo.descricao}
                            error={validationErrors.descricao}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            displayEmpty
                        />
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button onClick={handleCloseCadastroGrupo} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={isEdit ? editGrupo : createGrupo}
                        color="primary"
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth={activeStep === 0 ? "sm" : "md"}
                open={openGerenciarCategorias}
                onClose={handleCloseGerenciarCategorias}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {`Gerenciar categorias - grupo ${grupoSelecionado.nome} `}
                </DialogTitle>
                <DialogContent style={{ marginTop: -16 }}>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <>
                        <div>
                            <Box p={2}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                {loading ? (
                                    <TableRow>
                                        <TableCell align="center" colSpan={5}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>{getStepContent(activeStep)}</>
                                )}
                            </Box>
                        </div>
                    </>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end">
                        {activeStep === 0 ? (
                            <Button color="default" onClick={() => {}}>
                                Cancelar
                            </Button>
                        ) : (
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Voltar
                            </Button>
                        )}
                        {activeStep === steps.length - 1 ? (
                            <Button
                                color="primary"
                                onClick={handleGerenciarCategorias}
                            >
                                Finalizar
                            </Button>
                        ) : (
                            <Button color="primary" onClick={handleNext}>
                                Prosseguir
                            </Button>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>

            <GerenciarUsuariosDialog
                openGerenciarUsuarios={openGerenciarUsuarios}
                setOpenGerenciarUsuarios={setOpenGerenciarUsuarios}
                setUsuariosFilter={setUsuariosFilter}
                handleUsuariosFilter={handleUsuariosFilter}
                usuariosFilter={usuariosFilter}
                handleCloseGerenciarUsuarios={handleCloseGerenciarUsuarios}
                grupoSelecionado={grupoSelecionado}
                qtdUsuariosSelecionados={qtdUsuariosSelecionados}
                setQtdUsuariosSelecionados={setQtdUsuariosSelecionados}
                usuariosChecked={usuariosChecked}
                setUsuariosChecked={setUsuariosChecked}
                usuarios={usuarios}
                idSelected={idSelected}
                classes={classes}
                loading={loading}
                loading2={loading2}
            />

            <Dialog
                open={isOpenExcluirGrupo}
                onClose={handleCloseExcluirGrupo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Exclusão de Grupo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O grupo será excluído, deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExcluirGrupo} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleExcluirGrupo} color="primary">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default GrupoList;
