/* eslint-disable global-require */
/* eslint-disable no-unused-expressions */
import React from "react";
import { Switch, Redirect } from "react-router-dom";

import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from "../layoutsMaterialUi";
import Login from "../pages/admin/Login";
import PrivateRouteAdmin from "../components/admin/PrivateRouteAdmin";
import PublicRouteAdmin from "../components/admin/PublicRouteAdmin";
import UsuarioCreate from "../pages/admin/Usuarios/UsuarioCreate";
import UsuarioEdit from "../pages/admin/Usuarios/UsuarioEdit";
import UsuarioList from "../pages/admin/Usuarios/UsuarioList";
import Dashboard from "../pages/admin/Dashboard";
import GrupoList from "../pages/admin/Grupos/GrupoList";
import Categorias from "../pages/admin/Categorias/CategoriaList";
import Perfis from "../pages/admin/Perfis"; 
import Importador from "../pages/admin/Importador";
// import Secoes from '../pages/Admin/Secoes';
import Alertas from "../pages/admin/Alertas/AlertaList";
import AlertaCreate from "../pages/admin/Alertas/AlertaCreate";
import AlertaEdit from "../pages/admin/Alertas/AlertaEdit";
import TrilhaLista from "../pages/admin/Trilhas/TrilhaLista";
import TrilhasCreate from "../pages/admin/Trilhas/TrilhasCreate";
import TrilhasEdit from "../pages/admin/Trilhas/TrilhasEdit";
import AvaliacaoList from "../pages/admin/Avaliacao/AvaliacaoList";
import AvaliacaoCreate from "../pages/admin/Avaliacao/AvaliacaoCreate";
import AvaliacaoEditar from "../pages/admin/Avaliacao/AvaliacaoEditar";
import ModulosList from "../pages/admin/Modulos/ModulosList";
import ModulosForm from "../pages/admin/Modulos/ModulosForm";
import ComentariosList from "../pages/admin/Comentarios/ComentariosList";
import ContatoList from "../pages/admin/Contatos/ContatoList";
import ResultadoAvaliacao from "../pages/admin/Modulos/ResultadoAvaliacao";
import VisualizarResultadoAvaliacao from "../pages/admin/Modulos/VisualizarResultadoAvaliacao";
import BibliotecaCreate from "../pages/admin/Biblioteca/BibliotecaCreate";
import Bibliotecas from "../pages/admin/Biblioteca/BibliotecaList";
import BibliotecaEdit from "../pages/admin/Biblioteca/BibliotecaEdit";
import TreinamentoPresencialList from "../pages/admin/TreinamentoPresencial/TreinamentoPresencialList";
import TreinamentoPresencialCreate from "../pages/admin/TreinamentoPresencial/TreinamentoPresencialCreate";
import TreinamentoPresencialEdit from "../pages/admin/TreinamentoPresencial/TreinamentoPresencialEdit";
import ParceirosList from "../pages/admin/Parceiros/ParceirosList"; 
import ParceirosCreate from "../pages/admin/Parceiros/ParceiroCreate"; 
import ParceirosEdit from "../pages/admin/Parceiros/ParceiroEdit"; 

if(window.location.pathname.includes("/admin")) {
  import ("../styles/global.scss");
}

export default function Routes() {
  return (
      <Switch>
        <PublicRouteAdmin
          component={Login}
          exact
          layout={MinimalLayout}
          path="/admin/login"
        />
          <PublicRouteAdmin
          component={Login}
          exact
          layout={MinimalLayout}
          path="/admin/recuperar_acesso"
        />codigo
        <PublicRouteAdmin
          component={Login}
          exact
          layout={MinimalLayout}
          path="/admin/verificar_sms/:telefone"
        />
        <PublicRouteAdmin
          component={Login}
          exact
          layout={MinimalLayout}
          path="/admin/resetar_senha/:codigo"
        />
        <PrivateRouteAdmin
            component={Dashboard}
            exact
            layout={MainLayout}
            path="/admin/"
        />
        <PrivateRouteAdmin exact path="/admin/">
          <Redirect to="/admin/trilhas" />
        </PrivateRouteAdmin>
        <PrivateRouteAdmin
          component={UsuarioList}
          exact
          layout={MainLayout}
          path="/admin/usuarios"
        />
        <PrivateRouteAdmin
          component={UsuarioCreate}
          exact
          layout={MainLayout}
          path="/admin/usuarios/novo"
        />
        <PrivateRouteAdmin
          component={UsuarioEdit}
          exact
          layout={MainLayout}
          path="/admin/usuarios/:id"
        />
        <PrivateRouteAdmin
          component={GrupoList}
          exact
          layout={MainLayout}
          path="/admin/grupos"
        />
        <PrivateRouteAdmin
          component={Categorias}
          exact
          layout={MainLayout}
          path="/admin/Categorias"
        />
        <PrivateRouteAdmin
          component={Perfis}
          exact
          layout={MainLayout}
          path="/admin/perfis"
        />
        {/* <PrivateRouteAdmin
                    component={Secoes}
                    exact
                    layout={MainLayout}
                    path="/admin/secoes"
                /> */}
        <PrivateRouteAdmin
          component={Importador}
          exact
          layout={MainLayout}
          path="/admin/importador"
        />
        {/*
          BibliotecaCreate,Bibliotecas,BiliotecaEdit
        */}
        <PrivateRouteAdmin
          component={Bibliotecas}
          exact
          layout={MainLayout}
          path="/admin/biblioteca"
        />
        <PrivateRouteAdmin
          component={BibliotecaCreate}
          exact
          layout={MainLayout}
          path="/admin/biblioteca/novo"
        />
        <PrivateRouteAdmin
          component={BibliotecaEdit}
          exact
          layout={MainLayout}
          path="/admin/biblioteca/:id"
        />
        <PrivateRouteAdmin
          component={Alertas}
          exact
          layout={MainLayout}
          path="/admin/alertas"
        />
        <PrivateRouteAdmin
          component={AlertaCreate}
          exact
          layout={MainLayout}
          path="/admin/alertas/novo"
        />
        <PrivateRouteAdmin
          component={AlertaEdit}
          exact
          layout={MainLayout}
          path="/admin/alertas/:id"
        />
        <PrivateRouteAdmin
          component={TrilhaLista}
          exact
          layout={MainLayout}
          path="/admin/trilhas"
        />

        <PrivateRouteAdmin
          component={TrilhasCreate}
          exact
          layout={MainLayout}
          path="/admin/trilhas/novo"
        />

          <PrivateRouteAdmin
          component={TrilhasEdit}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:id"
        />
        <PrivateRouteAdmin
          component={TrilhasEdit}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:id"
        />
        <PrivateRouteAdmin
          component={TrilhasEdit}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:id/conteudo"
        />
        <PrivateRouteAdmin
          component={AvaliacaoList}
          exact
          layout={MainLayout}
          path="/admin/avaliacoes"
        />

        <PrivateRouteAdmin
          component={AvaliacaoCreate}
          exact
          layout={MainLayout}
          path="/admin/avaliacoes/novo"
        />
        <PrivateRouteAdmin
          component={AvaliacaoEditar}
          exact
          layout={MainLayout}
          path="/admin/avaliacoes/:id"
        />
        <PrivateRouteAdmin
          component={ModulosList}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:trilhaId/modulos"
        />
        <PrivateRouteAdmin
          component={ModulosForm}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:trilhaId/modulos/novo"
        />
        <PrivateRouteAdmin
          component={ModulosForm}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:trilhaId/modulos/:moduloId"
        />
        <PrivateRouteAdmin
          component={ResultadoAvaliacao}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:trilhaId/modulos/:moduloId/avaliacao"
        />
        <PrivateRouteAdmin
          component={VisualizarResultadoAvaliacao}
          exact
          layout={MainLayout}
          path="/admin/trilhas/:trilhaId/modulos/:moduloId/avaliacao/:resultadoAvaliacaoId"
        />
        <PrivateRouteAdmin
          component={ComentariosList}
          exact
          layout={MainLayout}
          path="/admin/comentarios"
        />
        <PrivateRouteAdmin
          component={ContatoList}
          exact
          layout={MainLayout}
          path="/admin/contatos"
        />
        <PrivateRouteAdmin
          component={TreinamentoPresencialList}
          exact
          layout={MainLayout}
          path="/admin/treinamento/presencial"
        />
        <PrivateRouteAdmin
          component={TreinamentoPresencialCreate}
          exact
          layout={MainLayout}
          path="/admin/treinamento/presencial/novo"
        />
        <PrivateRouteAdmin
          component={TreinamentoPresencialEdit}
          exact
          layout={MainLayout}
          path="/admin/treinamento/presencial/editar/:treinamento_id"
        />
        <PrivateRouteAdmin
          component={ParceirosList}
          exact
          layout={MainLayout}
          path="/admin/parceiros"
        />
        <PrivateRouteAdmin
          component={ParceirosCreate}
          exact
          layout={MainLayout}
          path="/admin/parceiros/novo"
        />
        <PrivateRouteAdmin
          component={ParceirosEdit}
          exact
          layout={MainLayout}
          path="/admin/parceiros/:id"
        />
        
      </Switch>
  );
}
