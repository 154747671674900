import React, { useState, useEffect } from 'react';
import TextInput from '../../../../components/aluno/Inputs/TextInput';
import {
    AreaDoAlunoPagesContainer,
    SubtitleAreaDoAluno,
    TitleAreaDoAluno,
    TitleSubAreaDoAluno,
} from '../style';

import InputFileImage from './components/InputFileImage';
import { InputsWrapperMeusDados, MeusDadosContainer } from './style';
import Button from '../../../../components/aluno/Button';
import TitlePage from '../../../../components/aluno/TitlePage';
import api from '../../../../services/api';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { API_HOST } from '../../../../config_servers';
import { useUsuarioContext } from '../../../../contexts/UserContext';
const useStyles = makeStyles((theme) => ({
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
}));

const MeusDados = () => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const [image, setImage] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState(null);
    const { setImagemUsuario } = useUsuarioContext();
    const [usuario, setUsuario] = useState({
        nome: '',
        email: '',
        senha: '',
        confirmarSenha: '',
    });

    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
                reader.fileName = e.target.files[0].name;
                setFileName(reader.fileName);
                setFiles(e.target.files);
            }
        };
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    useEffect(() => {
        getUsuario();
    }, []);

    const getUsuario = async () => {
        try {
            const response = await api.get(
                `/usuarios/${localStorage.getItem('@treinadelta/usuario_id')}`
            );
            setUsuario(response.data);
            if (response.data.imagem) {
                setImage(
                    `${API_HOST}/assets/conteudos/${response.data.imagem.arquivo}`
                );
                localStorage.setItem(
                    '@treinadelta/imagem_usuario',
                    response.data.imagem.arquivo
                );
                setImagemUsuario(response.data.imagem.arquivo);
            }
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 30 ~ getUsuario ~ error',
                error
            );
        }
    };

    const deleteImageUser = async () => {
        try {
            await api.delete(
                `conteudos/usuario/${localStorage.getItem(
                    '@treinadelta/usuario_id'
                )}`
            );
            setImage(null);
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 30 ~ getUsuario ~ error',
                error
            );
        }
    };

    const handleEditUsuario = async (e) => {
        e.preventDefault();
        try {
            if (usuario.confirmarSenha !== usuario.senha) {
                enqueueSnackbar(<h4>As senhas informadas não coincidem!</h4>, {
                    variant: 'error',
                });
                return;
            }

            const response = await api.put(
                `/usuarios/${localStorage.getItem('@treinadelta/usuario_id')}`,
                usuario
            );

            if (files.length > 0) {
                const formData = new FormData();

                for (const file of files) {
                    formData.append('file', file);
                }

                formData.append(
                    'usuario_id',
                    JSON.parse(localStorage.getItem('@treinadelta/usuario_id'))
                );

                await api.post('/conteudos', formData);
            }
            let user = { ...response.data, senha: '' };
            user = { ...user, confirmarSenha: '' };
            setUsuario(user);
            enqueueSnackbar(<h4>Usuário editado com sucesso!</h4>, {
                variant: 'success',
            });
            await getUsuario();
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
            console.log(
                '🚀 ~ file: index.js ~ line 30 ~ getUsuario ~ error',
                error
            );
        }
    };

    return (
        <AreaDoAlunoPagesContainer>
            <TitleSubAreaDoAluno>
                <TitlePage
                    breadcrumbs={{
                        link1: `/minhastrilhas`,
                        nome1: 'Home',
                        label: 'Meus Dados',
                    }}
                />
                <TitleAreaDoAluno>Meus Dados</TitleAreaDoAluno>
                <SubtitleAreaDoAluno>
                    Nesta sessão você pode personalizar seu avatar, alterar a
                    senha e visualizar seus dados de acesso.
                </SubtitleAreaDoAluno>
            </TitleSubAreaDoAluno>
            <MeusDadosContainer action="#">
                <InputFileImage
                    fileName={fileName}
                    image={image}
                    deleteImageUser={deleteImageUser}
                    imageHandler={imageHandler}
                />
                <InputsWrapperMeusDados>
                    <TextInput
                        label="Nome"
                        validation={usuario.nome.length > 2}
                        value={usuario.nome}
                        onChange={(e) =>
                            setUsuario({
                                ...usuario,
                                nome: e.target.value,
                            })
                        }
                    />
                    <TextInput
                        label="E-mail"
                        validation={usuario.email.includes('@')}
                        value={usuario.email}
                        onChange={(e) =>
                            setUsuario({
                                ...usuario,
                                email: e.target.value,
                            })
                        }
                    />
                    <TextInput
                        label="Senha"
                        type="password"
                        validation={usuario.senha && usuario.senha.length > 4}
                        value={usuario.senha}
                        onChange={(e) =>
                            setUsuario({
                                ...usuario,
                                senha: e.target.value,
                            })
                        }
                    />
                    <TextInput
                        label="Confirmar Senha"
                        type="password"
                        validation={
                            usuario.senha &&
                            usuario.confirmarSenha === usuario.senha
                        }
                        value={usuario.confirmarSenha}
                        onChange={(e) =>
                            setUsuario({
                                ...usuario,
                                confirmarSenha: e.target.value,
                            })
                        }
                    />
                </InputsWrapperMeusDados>
                <Button onClick={(e) => handleEditUsuario(e)} big>
                    Salvar alterações
                </Button>
            </MeusDadosContainer>
        </AreaDoAlunoPagesContainer>
    );
};

export default MeusDados;
