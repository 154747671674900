import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';

import {
    Footer,
    FooterSections,
    GrupoDeltaParagraph,
    ItemUserMenu,
    LeftSideMenu,
    LinkMenu,
    MenuContainer,
    MenuUserContainer,
    NameAndXpContainer,
    NotificationContainer,
    NotificationDate,
    NotificationText,
    NotificationTitle,
    RightSideMenu,
    SearchButton,
    SectionFooterNav,
    SectionGrupoDelta,
    UserContainer,
    UserName,
    Xp,
    NavLinkFooter,
    SectionSocial,
    SocialLinks,
    CopyrightFooter,
    LinkMenuDrop,
    AreaDoAlunoContainer,
    DefaultSmileyFace,
    Row,
    ImgUserContainer,
} from './style';
import {
    IconFacebook,
    IconHistory,
    IconInstagram,
    IconLinkedin,
    IconLogout,
    IconNotify,
    IconNotifyPainted,
    IconSearch,
    IconStar,
    IconUser,
    LogoTreinaDelta,
    LogoTreinaDeltaMobile,
    GrupoDeltaLogo,
    SmileyFace,
} from '../Icons/Icons';
import { DropItem } from '../ButtonDrop/style';
import ButtonDrop from '../ButtonDrop';
import HamburguerMenu from './components/HamburguerMenu';
import MenuAreaDoAluno from './components/MenuAreaDoAluno';
import { useEffect } from 'react';
import api from '../../../services/api';
import { getUsuarioId, removeAuthSession } from '../../../services/auth';
import { API_HOST } from '../../../config_servers';
import { Divider } from '@material-ui/core';
import SearchMenu from '../Inputs/SearchMenu';
import { useUsuarioContext } from '../../../contexts/UserContext';
const fakeNotifications = Array(15).fill({
    date: '12/04/2021',
    title: 'Atualização do sistema',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida et risus erat.',
});


const Menu = ({ children, ...props }) => {
    const [open, setOpen] = useState(false);
    const [openNotify, setOpenNotify] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [search, setSearch] = useState('');
    const [resultadoPesquisa, setResultadoPesquisa] = useState([]);
    const [dadosUser, setDadosUser] = useState([]);
    const [notification , setNotification] = useState([])
    const { imagemUsuario } = useUsuarioContext();
    const [loadingSearch, setLoadingSearch] = useState(true);
    const history = useHistory();
    const user = getUsuarioId();

    useEffect(() => {
        setLoadingSearch(true);
        const delayDebounceFn = setTimeout(() => {
            getResultadosPorPesquisa();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const getResultadosPorPesquisa = async () => {
        try {
            const response = await api.get('/usuarios/pesquisa/geral/', {
                params: {
                    search,
                    usuario_id: user,
                },
            });
            setResultadoPesquisa(response.data);

            setLoadingSearch(false);
        } catch (error) {
            setLoadingSearch(false);
        }
    };

    const Handlechange = (value) => {
        setOpen(value);
    };
    const deslogar = async () => {
        await api.post('/usuarios/log/insertLog/', {
            acao: 'fim_tempo_logado',
            usuario_id: getUsuarioId(),
            data_fim: moment().format('YYYY/MM/DD HH:mm:ss'),
        });
        removeAuthSession();
        history.push('/');
        
    };
    const HandlechangeNotify = (value) => {
        setOpenNotify(value);
    };

    const HandlechangeSearch = (value) => {
        setOpenSearch(value);
        setSearch('');
        setResultadoPesquisa([]);
    };

    const onChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    useEffect(() => {
        getNotification();
    }, [moment().format('LT')])

    useEffect(() => {
        getUser();
        getNotification();
    }, []);

    const handleMarcarComoLida = async (id) => {
        try {
            await api.put(`/alertas/lida/${id}`)
            getNotification();
        } catch (err) {
            alert(err.message)
        }
    }

    const getNotification = async () => {
        const notification = await api.get(`/usuarios/notification/${user}`)
        setNotification(notification.data)
    }

    const getUser = async () => {
        const usuario = await api.get(`/usuarios/${user}`);
        setDadosUser(usuario.data);
    };

    const NotifyIcons = () => (
        <>
            <IconNotify />
            <IconNotifyPainted />
        </>
    );

    const pathname = window.location.pathname;

    const isAreaDoAluno = pathname.includes('areadoaluno');

    const renderChildren = () => {
        return <>{children}</>;
    };

    const closeAllDrops = () => {
        setOpenNotify(false);
        setOpenSearch(false);
        setSearch('');
        setResultadoPesquisa([]);
    };

    return (
        <>
            <Row>
                <MenuContainer>
                    <LeftSideMenu>
                        <LogoTreinaDelta />
                        <LogoTreinaDeltaMobile />
                        <LinkMenu
                            to="/minhastrilhas"
                            activeClassName="selected"
                        >
                            Minhas Trilhas
                        </LinkMenu>
                        {/* <LinkMenu to="/login" activeClassName="selected">
                        Ajuda
                    </LinkMenu> */}
                      <LinkMenu
                            to="/areadoaluno/minhasavaliacoes"
                            activeClassName="selected"
                        >
                            Minhas Avaliações
                        </LinkMenu>
                        <LinkMenu
                            to="/areadoaluno/contato"
                            activeClassName="selected"
                        >
                            Contato
                        </LinkMenu>
                      
                    </LeftSideMenu>
                    <RightSideMenu>
                        <ButtonDrop
                            icon={NotifyIcons()}
                            notification
                            open={openNotify}
                            changeOpen={HandlechangeNotify}
                        >
                            <DropItem notification>
                                <NotificationContainer>
                         
                                    {notification.length == 0 ? 
                                    <>
                                       <NotificationTitle>
                                            Nenhuma notificação
                                       </NotificationTitle>
                                       <NotificationText>
                                            Você não possui notificações
                                       </NotificationText>
                                    </>
                                    :
                                    notification.map((notificacao, index) => (
                                        <div onClick={() => handleMarcarComoLida(notificacao.alerta_usuario_id)}>
                                            {/* notificações que não poussuem o alerta_usuario_id são notificações de alerta */}
                                            <NotificationText style={{color: 'red'}}> {notificacao.alerta_usuario_id === undefined && 'ALERTA'}</NotificationText>
                                            <NotificationTitle>
                                            {notificacao.nome}
                                            </NotificationTitle>
                                            <NotificationText >
                                                <div  style={{fontSize: "23px"}} dangerouslySetInnerHTML={{ __html: `${notificacao.conteudo}` }} />
                                            </NotificationText>
                                            {index+1 != notification.length && 
                                                <Divider style={{marginTop: 15, marginBottom: 15, width: "200px"}} />
                                            }
                                      </div>
                                      ))}
                                </NotificationContainer>
                            </DropItem>
                        </ButtonDrop>
                        <ButtonDrop
                            icon={<IconSearch fill="#ffffff40" />}
                            search
                            notification
                            open={openSearch}
                            changeOpen={HandlechangeSearch}
                        >
                            <SearchMenu
                                search={search}
                                onChangeSearch={onChangeSearch}
                            />
                            {resultadoPesquisa.length < 1 &&
                            search.length > 2 &&
                            !loadingSearch ? (
                                <DropItem notification>
                                    <NotificationContainer>
                                        <NotificationTitle>
                                            {'Nenhum resultado encontrado'}
                                        </NotificationTitle>
                                    </NotificationContainer>
                                </DropItem>
                            ) : (
                                resultadoPesquisa.map((item) => (
                                    // <Link to={item.caminho}>
                                    <DropItem
                                        onClick={() => {
                                            //     history.push('/minhastrilhas');
                                            history.push(item.caminho);
                                            history.go(0);
                                        }}
                                        notification
                                    >
                                        <NotificationContainer>
                                            <NotificationTitle>
                                                {item.nome}
                                            </NotificationTitle>
                                            <NotificationText>
                                                {item.subtitulo}
                                            </NotificationText>
                                        </NotificationContainer>
                                    </DropItem>
                                    // </Link>
                                ))
                            )}
                        </ButtonDrop>
                        {/* <SearchButton>
                        <IconSearch fill="#ffffff40" />
                    </SearchButton> */}
                        <UserContainer
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(!open);
                                closeAllDrops();
                            }}
                        >
                            <NameAndXpContainer>
                                <UserName>{dadosUser.nome}</UserName>
                                {/* <Xp>1548 Xp</Xp> */}
                            </NameAndXpContainer>

                            <ButtonDrop
                                icon={
                                    !imagemUsuario ||
                                    imagemUsuario == 'undefined' ? (
                                        <DefaultSmileyFace>
                                            <SmileyFace />
                                        </DefaultSmileyFace>
                                    ) : (
                                        <ImgUserContainer
                                            src={`${API_HOST}/assets/conteudos/${imagemUsuario}`}
                                        />
                                    )
                                }
                                user
                                open={open}
                                changeOpen={Handlechange}
                            >
                                <LinkMenuDrop to="/areadoaluno/meusdados">
                                    <DropItem user>
                                        <MenuUserContainer>
                                            {/* <IconUser /> */}
                                            <ItemUserMenu>
                                                Meus Dados
                                            </ItemUserMenu>
                                        </MenuUserContainer>
                                    </DropItem>
                                </LinkMenuDrop>
                                <LinkMenuDrop to="/areadoaluno/meuhistorico">
                                    <DropItem user>
                                        <MenuUserContainer>
                                            {/* <IconHistory /> */}
                                            <ItemUserMenu>
                                                Meu Histórico
                                            </ItemUserMenu>
                                        </MenuUserContainer>
                                    </DropItem>
                                </LinkMenuDrop>
                                <LinkMenuDrop to="/areadoaluno/meusfavoritos">
                                    <DropItem user>
                                        <MenuUserContainer>
                                            {/* <IconStar /> */}
                                            <ItemUserMenu>
                                                Meus Favoritos
                                            </ItemUserMenu>
                                        </MenuUserContainer>
                                    </DropItem>
                                </LinkMenuDrop>
                                <LinkMenuDrop to="/areadoaluno/minhasavaliacoes">
                                    <DropItem user>
                                        <MenuUserContainer>
                                            {/* <IconStar /> */}
                                            <ItemUserMenu>
                                                Minhas Avaliações
                                            </ItemUserMenu>
                                        </MenuUserContainer>
                                    </DropItem>
                                </LinkMenuDrop>
                                <LinkMenuDrop to="/areadoaluno/meuscertificados">
                                    <DropItem user>
                                        <MenuUserContainer>
                                            {/* <IconStar /> */}
                                            <ItemUserMenu>
                                                Meus Certificados
                                            </ItemUserMenu>
                                        </MenuUserContainer>
                                    </DropItem>
                                </LinkMenuDrop>
                                <LinkMenuDrop onClick={deslogar}>
                                    <DropItem user>
                                        <MenuUserContainer>
                                            {/* <IconLogout /> */}
                                            <ItemUserMenu>Sair</ItemUserMenu>
                                        </MenuUserContainer>
                                    </DropItem>
                                </LinkMenuDrop>
                            </ButtonDrop>
                        </UserContainer>
                        <HamburguerMenu />
                    </RightSideMenu>
                </MenuContainer>
                {isAreaDoAluno === true ? (
                    <AreaDoAlunoContainer>
                        <MenuAreaDoAluno />
                        {renderChildren()}
                    </AreaDoAlunoContainer>
                ) : (
                    renderChildren()
                )}
            </Row>
            <Footer>
                <Row footer>
                    <FooterSections>
                        <SectionGrupoDelta>
                            <GrupoDeltaLogo />
                            <GrupoDeltaParagraph>
                                Somos uma empresa que alia inovação com
                                atendimento de excelência. Somos referência
                                nacional em serviços e tecnologia para o mercado
                                de seguros e transportes, desenvolvendo soluções
                                customizadas em uma conduta humanizada, ética e
                                assertiva.
                            </GrupoDeltaParagraph>
                        </SectionGrupoDelta>
                        <SectionFooterNav>
                            <NavLinkFooter to="/minhastrilhas">
                                Minhas Trilhas
                            </NavLinkFooter>
                            <NavLinkFooter to="/areadoaluno/minhasavaliacoes">
                                Minhas Avaliações
                            </NavLinkFooter>
                            {/* <NavLinkFooter to="/login">Ajuda</NavLinkFooter> */}
                            <NavLinkFooter to="/areadoaluno/contato">
                                Contato
                            </NavLinkFooter>
                            
                        </SectionFooterNav>
                        <SectionSocial>
                            <SocialLinks
                                target="_blank"
                                href="https://www.facebook.com/deltaglobalbr/"
                            >
                                <IconFacebook />
                                <p>Facebook</p>
                            </SocialLinks>
                            <SocialLinks
                                target="_blank"
                                href="https://www.instagram.com/deltaglobalbr/"
                            >
                                <IconInstagram />
                                <p>Instagram</p>
                            </SocialLinks>
                            <SocialLinks
                                target="_blank"
                                href="https://www.linkedin.com/company/deltaglobalbr/"
                            >
                                <IconLinkedin />
                                <p>Linkedin</p>
                            </SocialLinks>
                        </SectionSocial>
                    </FooterSections>
                    <CopyrightFooter>
                        © {new Date().getFullYear()}  Treina Delta by Delta Global. Todos os direitos
                        reservados
                    </CopyrightFooter>
                </Row>
            </Footer>
        </>
    );
};

export default Menu;
