import styled from "styled-components";

export const GridInfoModalAvaliacao = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin: 30px 0;
    @media (max-width: 400px) {
        margin: 0;
    }
`;
