/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { isAuthenticated, removeAuthSession } from '../../services/auth';

function PrivateRouteAdmin({ component: Component, layout: Layout, ...rest }) {
    const location = useLocation();
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated() ? (
                    Layout ? (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <>
                        {removeAuthSession()}
                        <Redirect
                            to={{
                                pathname: '/admin/login',
                                state: { from: props.location },
                            }}
                        />
                    </>
                )
            }
        />
    );
}

export default PrivateRouteAdmin;
