import styled from 'styled-components';
import { colors } from '../../../../../assets/theme/colors';

export const InfoTrilhaContainer = styled.div`
    padding: 45px 40px;
    border-radius: 10px;
    background: #0000001a;
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
    min-width: 400px;
    @media (max-width: 1200px) {
        min-width: unset;
        width: 100%;
    }
`;

export const GridInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: ${({ emCorrecao }) =>
        emCorrecao ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
    grid-column-gap: 24px;
    grid-row-gap: 20px;
    margin-bottom: ${({ emCorrecao }) => emCorrecao ? "15px" : ""};
    & > div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
    }
    & > div:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
    }
    & > div:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
    }
    & > div:nth-child(4) {
        grid-area: 3 / 1 / 4 / 3;
    }
`;

export const InfoLabel = styled.p`
    font-size: 1.4rem;
    opacity: ${({ normal }) => (normal ? 1 : 0.6)};
    color: #fff;
`;

export const InfoText = styled.div`
    color: #fff;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    & svg {
        height: 16px;
        width: 19px;
        margin-left: 8px;
        margin-top: ${({ aprovado }) => aprovado && '-2px'};
        rect {
            fill: ${colors.quaternary};
        }
        path {
            fill: ${({ cardAvaliacao }) =>
                cardAvaliacao ? colors.tertiary : colors.primary};
        }
    }
`;

export const AdditionalInfo = styled.p`
    color: #fff;
    opacity: 0.6;
    font-size: 1.2rem;
    max-width: 280px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    & ${InfoLabel}, ${InfoText} {
        color: ${({ modal }) => (modal ? '#333247' : '#fff')};
    }
`;

export const TagInfoTrilhaAvaliacao = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
`;
