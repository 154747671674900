import styled from 'styled-components';
import { colors } from '../../../../../assets/theme/colors';

export const QuestaoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ tipo }) =>
        tipo === 'multipla_escolha' || tipo === 'caixa_selecao' ? '30px' : '0'};
    width: 60%;
    @media (max-width: 1200px) {
        width: 100%;
    }
    & svg {
        font-size: 3.5rem;
    }
    /* img {
        max-height: 600px;
    } */
`;

export const QuestaoTitleContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const QuestaoTitle = styled.p`
    font-size: 1.9rem;
    color: #fff;
    opacity: 0.6;
    font-weight: 200;
    margin-top: 14px;
`;

export const QuestaoIndex = styled.p`
    font-size: 3.2rem;
    font-weight: 700;
    color: ${colors.primary};
`;

export const AlternativasContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 40px;
    margin-bottom: 60px;
`;

export const Divider = styled.div`
    width: 60%;
    height: 1px;
    background: white;
    opacity: 0.05;
    margin-bottom: 50px;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;
