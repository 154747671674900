import styled from "styled-components";

export const MeusDadosContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const InputsWrapperMeusDados = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    row-gap: 20px;
    & textarea {
        width: 100%;
    }

    @media (max-width: 650px) {
        & div:nth-child(1) {
            width: 100%;
        }
        & div:nth-child(2) {
            width: 100%;
        }
        & textarea {
            margin-top: 15px;
        }
    }
`;

export const InputLabel = styled.label`
  font-size: 1.4rem;
  color: #ffffff99;
`;