import styled from 'styled-components';

export const ModalContainer = styled.div`
    width: 750px;
    display: flex;
    flex-direction: column;
    gap: ${({ iniciarTentativa }) => (iniciarTentativa ? "30px" : "20px")};;
    padding: 45px;
    background: #fff;
    border-radius: 10px;
    pointer-events: auto;
    overflow: overlay;
    & input {
        color: #333333;
    }

    & textarea::placeholder {
        color: #3333334d;
    }
    & form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 15px;
    }
    @media (max-width: 800px) {
        width: 100%;
        border-radius: 0;
        height: 100%;
        gap: 70px;
        padding: 65px 45px;
    }
    @media (max-width: 500px) {
        padding: 65px 20px;
    }
`;

export const ModalTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const TitleModal = styled.h3`
    font-size: 2.1rem;
    text-align: center;
    color: #333247;
    font-weight: 500;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    & button:first-child {
        align-self: flex-end;
    }
    @media (max-width: 800px) {
        margin-top: auto;
    }
    @media (max-width: 550px) {
        flex-direction: column;
        gap: 20px;
        & button {
            width: 100%;
            padding: 1.5rem 2.5rem;
            &:nth-child(1) {
                order: 2;
            }
            &:nth-child(2) {
                order: 1;
            }
        }
    }
`;

export const ModalLabel = styled.p`
    font-size: 1.4rem;
    color: #33324799;
    margin-bottom: 10px;
`;

export const SubTitleModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
