/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable  no-unused-vars */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import GroupIcon from '@material-ui/icons/Group';
import { hasAccess } from '../../../services/auth';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/Delete';

import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    TextField,
    IconButton,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Backdrop,
    DialogActions,
    Checkbox,
    Paper,
    Collapse,
    List,
    ListItem,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PrimaryChip, ErrorChip, SuccessChip } from '../../../helpers/helper';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    paper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        maxHeight: 450,
    },
    autocomplete: {
        backgroundColor: 'white',
        // height: '45.625px'
    },
    papers: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
}));

const TrilhaLista = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const acesso = hasAccess('Conteudo', 'Trilhas');
    const acessoAdmin = hasAccess('Configuracoes', 'Parceiros');
    const [initialFilters] = useState({
        nome: '',
        data_inicio: '',
        data_final: '',
    });
    const [filters, setFilters] = useState(initialFilters);
    const [trilha, setTrilha] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({
        total: 0,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [idSelected, setIdSelected] = useState();
    const [grupos, setGrupos] = useState([]);
    const [setUsuarios] = useState([]);
    const [loading2, setLoading2] = useState(false);
    const [qtdGruposSelecionados, setQtdGruposSelecionados] = useState(0);
    const [trilhaFilter, setTrilhaFilter] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState([]);
    const [openGerenciarGrupos, setOpenGerenciarGrupos] = useState(false);
    const [openModulos, setOpenModulos] = useState(false);
    const [gruposChecked, setGruposChecked] = useState({});
    const [modulos] = useState([]);
    const [openModulo, setOppenModulo] = useState(false);
    const [openAulas, setOpenAulas] = useState(false);
    const [linkAula, setLinkAula] = useState('');
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const open = Boolean(anchorEl);
    const [isOpenExcluirTrilha, setIsOpenExcluirTrilha] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getInputTrilha();
    }, []);

    useEffect(() => {
        getTrilhas(filters, currentPage, perPage);
        getUsuarios();
        getGrupos();
    }, [currentPage, perPage]);

    const handleGerenciarGrupos = async () => {
        try {
            setLoading(true);
            await api.post(`/trilhas/grupos/${idSelected}`, {
                gruposChecked,
            });
            setLoading(false);

            enqueueSnackbar('Grupos selecionados com sucesso!', {
                variant: 'success',
            });
            setOpenGerenciarGrupos(false);

        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    const getGruposPorTrilha = async () => {
        try {
            setLoading2(true);
            const response = (await api.get(`/trilhas/grupos/${idSelected}`))
                .data;
            console.log(
                '🚀 ~ file: TrilhaLista.js ~ line 188 ~ getGruposPorTrilha ~ response',
                response
            );
            setGruposChecked(response);

            for (const column in response) {
                if (response[column] === true)
                    setQtdGruposSelecionados((prevState) => prevState + 1);
            }

            setLoading2(false);
        } catch (error) {
            setLoading2(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getGrupos = async () => {
        try {
            setLoading(true);
            const response = await api.get('/grupos');
            setGrupos(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };
    const handleChangeGruposChecked = async (event) => {
        setGruposChecked({
            ...gruposChecked,
            [event.target.name]: event.target.checked,
        });

        setQtdGruposSelecionados((prevState) =>
            event.target.checked === true ? prevState + 1 : prevState - 1
        );
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const handleOpenGerenciarGrupos = async () => {
        await getGruposPorTrilha();
        closeMenuActions();
        setOpenGerenciarGrupos(true);
    };

    // const getModulos = async () => {
    //     const response = await api.get(`/modulos/trilha/${idSelected}`);
    //     setModulo(response.data);
    //     console.log(response.data);
    // };

    // const handleOpenAssistirConteudo = async () => {
    //     await getModulos();
    //     closeMenuActions();
    //     setOpenModulos(true);
    // };
    const handleCloseAssistirConteudo = async () => {
        setOpenModulos(false);
    };

    const handleCloseAssistirAula = async () => {
        setOpenAulas(false);
    };
    const handleCloseGerenciarGrupos = () => {
        setOpenGerenciarGrupos(false);
        setGruposChecked({});
        setQtdGruposSelecionados(0);
    };

    const openMenuActions = (event, trilhas) => {
        setAnchorEl(event.currentTarget);
        setIdSelected(trilhas.trilha_id);
        setUsuarioSelecionado(trilhas);
    };

    const handleDuplicarTrilha = async () => {
        try {
            await api.post(`/trilhas/duplicar/${idSelected}`);
            enqueueSnackbar('Trilha duplicada com sucesso!', {
                variant: 'success',
            });

            closeMenuActions();
            getTrilhas(filters, currentPage, perPage);
        } catch (error) {
            enqueueSnackbar('Falha ao duplicar trilha', {
                variant: 'error',
            });
        }
    };

    const getInputTrilha = async () => {
        try {
            const response = await api.get('/trilhas');
            const itens = response.data;
            setTrilhaFilter(itens.filter((item) => item.nome));
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getUsuarios = async () => {
        try {
            setLoading(true);
            const response = (await api.get(`/trilhas`)).data;
            setUsuarios(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    function video(verifica) {
        if (verifica === 'mp4' || verifica === 'mkv' || verifica === 'webm') {
            return true;
        }
    }

    const HandleCheckAllGrupos = (event) => {
        let update = {
            ...gruposChecked,
        };
        grupos.forEach((item) => {
            update = {
                ...update,
                [item.grupo_id]: event.target.checked,
            };
        });

        setQtdGruposSelecionados(() =>
            event.target.checked === true ? grupos.length : 0
        );
        setGruposChecked({
            ...update,
        });
    };

    const getTrilhas = async (filters = [], currentPage = 0, perPage = 10) => {
        try {
            setLoading(true);
            const response = await api.get('/trilhas/filter', {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setTrilha(response.data.data);

            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getTrilhas(filters);
    };

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getTrilhas();
    };

    const openAula = (id) => {
        setLinkAula(`http://localhost:4005/assets/conteudos/${id}`);
        setOpenAulas(true);
    };

    const handleOpenExcluirTrilha = async () => {
        setIsOpenExcluirTrilha(true);
        setAnchorEl(null);
    };

    const handleCloseExcluirTrilha = async () => {
        setIsOpenExcluirTrilha(false);
    };

    const handleExcluirTrilha = async () => {
        try {
            setAnchorEl(null);
            handleCloseExcluirTrilha();
            await api.delete(`/Trilhas/${idSelected}`);
            await getTrilhas(filters, currentPage, perPage);
            enqueueSnackbar('Trilha removida com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Trilhas
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Trilhas
                    </Typography>
                </Grid>
                <Grid container xs={6} justify="flex-end" alignItems="center">
                    <Button
                        component={RouterLink}
                        to="/admin/trilhas/novo"
                        color="primary"
                        variant="contained"
                    >
                        Nova trilha
                    </Button>
                </Grid>
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={5} sm={2}>
                            <Autocomplete
                                style={{
                                    marginTop: '-11px',
                                }}
                                getOptionLabel={(secoes) => secoes.nome}
                                options={trilhaFilter}
                                value={
                                    trilhaFilter.find(
                                        (item) => item.nome === filters.nome
                                    )
                                        ? trilhaFilter.find(
                                              (item) =>
                                                  item.nome === filters.nome
                                          )
                                        : null
                                }
                                onChange={(event, newValue) => {
                                    setFilters({
                                        ...filters,
                                        nome: newValue?.nome ?? '',
                                    });
                                }}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.autocomplete}
                                        label="Nome"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={5} sm={2}>
                            <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                locale="pt-br"
                            >
                                <DatePicker
                                    placeholder="De:"
                                    value={
                                        filters.data_inicio
                                            ? filters.data_inicio
                                            : null
                                    }
                                    onChange={(val) =>
                                        setFilters({
                                            ...filters,
                                            data_inicio: val
                                                ? moment(val).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : null,
                                        })
                                    }
                                    margin="normal"
                                    okLabel="Selecionar"
                                    style={{
                                        marginTop: '3px',
                                    }}
                                    cancelLabel="Cancelar"
                                    format="DD/MM/YYYY"
                                    clearLabel="Limpar"
                                    clearable
                                    disableUnderline
                                    fullWidth
                                    autoOk
                                    inputVariant="outlined"
                                    className={classes.autocomplete}
                                    InputLabelProps={{
                                        shrink: true,
                                        marginTop: '-16px',
                                    }}
                                    TextFieldComponent={TextField}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={5} sm={2}>
                            <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                locale="pt-br"
                            >
                                <DatePicker
                                    placeholder="Até:"
                                    value={
                                        filters.data_final
                                            ? filters.data_final
                                            : null
                                    }
                                    onChange={(val) =>
                                        setFilters({
                                            ...filters,
                                            data_final: val
                                                ? moment(val).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : null,
                                        })
                                    }
                                    margin="normal"
                                    okLabel="Selecionar"
                                    style={{
                                        marginTop: '3px',
                                    }}
                                    cancelLabel="Cancelar"
                                    format="DD/MM/YYYY"
                                    clearLabel="Limpar"
                                    clearable
                                    disableUnderline
                                    fullWidth
                                    autoOk
                                    inputVariant="outlined"
                                    className={classes.autocomplete}
                                    InputLabelProps={{
                                        shrink: true,
                                        marginTop: '-16px',
                                    }}
                                    TextFieldComponent={TextField}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{
                                    marginLeft: '2%',
                                }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent
                                style={{
                                    padding: 0,
                                }}
                            >
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell>
                                                        Descrição
                                                    </TableCell>
                                                    {acessoAdmin.visualizar && (
                                                        <TableCell
                                                            style={{
                                                                width: '10%',
                                                            }}
                                                            align="center"
                                                        >
                                                            Parceiro
                                                        </TableCell>
                                                    )}
                                                    <TableCell
                                                        style={{
                                                            width: '10%',
                                                        }}
                                                        align="center"
                                                    >
                                                        Módulos
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: '10%',
                                                        }}
                                                        align="center"
                                                    >
                                                        Vídeos
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: '10%',
                                                        }}
                                                        align="center"
                                                    >
                                                        Status
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: '10%',
                                                        }}
                                                    />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {trilha.length === 0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {trilha.map(
                                                                    (
                                                                        trilha
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                trilha.trilha_id
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    trilha.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    trilha.conteudo
                                                                                }
                                                                            </TableCell>

                                                                            {acessoAdmin.visualizar && (
                                                                                <TableCell align="center">
                                                                                {
                                                                                    trilha.nome_parceiro ? trilha.nome_parceiro : '-'
                                                                                }
                                                                                </TableCell>
                                                                            )}

                                                                            <TableCell align="center">
                                                                                <PrimaryChip
                                                                                    texto={
                                                                                        trilha.qtdModulos
                                                                                    }
                                                                                    size="medium"
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <PrimaryChip
                                                                                    texto={
                                                                                        trilha.qtdVideos ??
                                                                                        0
                                                                                    }
                                                                                    size="medium"
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {trilha.ativo ? (
                                                                                    <SuccessChip texto="Ativo" />
                                                                                ) : (
                                                                                    <ErrorChip texto="Inativo" />
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                <IconButton
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                    aria-label="more"
                                                                                    aria-controls="long-menu"
                                                                                    aria-haspopup="true"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        openMenuActions(
                                                                                            e,
                                                                                            trilha
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <SettingsIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions
                                style={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={closeMenuActions}
            >
                {acesso['duplicar trilha'] && (
                    <MenuItem onClick={handleDuplicarTrilha}>
                        <ListItemIcon
                            style={{
                                minWidth: '32px',
                            }}
                        >
                            <FileCopySharpIcon
                                color="secondary"
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Duplicar trilha" />
                    </MenuItem>
                )}
                {acesso['gerenciar grupos'] && (
                    <MenuItem onClick={handleOpenGerenciarGrupos}>
                        <ListItemIcon
                            style={{
                                minWidth: '32px',
                            }}
                        >
                            <GroupIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Gerenciar grupos" />
                    </MenuItem>
                )}
                {acesso.editar && (
                    <MenuItem
                        component={RouterLink}
                        to={`/admin/trilhas/${idSelected}`}
                    >
                        <ListItemIcon
                            style={{
                                minWidth: '32px',
                            }}
                        >
                            <EditIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Editar trilha" />
                    </MenuItem>
                )}
                {acesso.excluir && (
                    <MenuItem onClick={handleOpenExcluirTrilha}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <DeleteIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Excluir trilha" />
                    </MenuItem>
                )}
                {acesso.modulos && (
                    <MenuItem
                        component={RouterLink}
                        to={`/admin/trilhas/${idSelected}/modulos`}
                    >
                        <ListItemIcon
                            style={{
                                minWidth: '32px',
                            }}
                        >
                            <PlaylistAddIcon
                                color="secondary"
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Módulos" />
                    </MenuItem>
                )}
            </Menu>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="sm"
                open={openGerenciarGrupos}
                onClose={handleCloseGerenciarGrupos}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Gerenciar grupos
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Selecione abaixo os grupos que o usuário{' '}
                        <b>{usuarioSelecionado.nome ?? ''}</b> pertence.
                        <p
                            style={{
                                marginTop: 8,
                            }}
                        >
                            Inserido em{' '}
                            {qtdGruposSelecionados === 0
                                ? 'nenhum grupo'
                                : qtdGruposSelecionados === 1
                                ? `${qtdGruposSelecionados} grupo`
                                : `${qtdGruposSelecionados} grupos`}
                        </p>
                    </DialogContentText>

                    <Backdrop className={classes.backdrop} open={loading2}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Card>
                        <CardContent
                            style={{
                                padding: 0,
                            }}
                        >
                            <TableContainer className={classes.container}>
                                <PerfectScrollbar>
                                    <Table size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        width: '5%',
                                                    }}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={
                                                            HandleCheckAllGrupos
                                                        }
                                                        inputProps={{
                                                            'aria-label':
                                                                'secondary checkbox',
                                                        }}
                                                        checked={
                                                            qtdGruposSelecionados ===
                                                            grupos.length
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>Grupos</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loading ? (
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={2}
                                                    >
                                                        <CircularProgress />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                <>
                                                    {grupos.map((grupo) => (
                                                        <TableRow
                                                            hover
                                                            key={grupo.grupo_id}
                                                        >
                                                            <TableCell>
                                                                <Checkbox
                                                                    value={
                                                                        grupo.grupo_id
                                                                    }
                                                                    name={
                                                                        grupo.grupo_id
                                                                    }
                                                                    checked={
                                                                        !!gruposChecked[
                                                                            grupo
                                                                                .grupo_id
                                                                        ]
                                                                    }
                                                                    onChange={
                                                                        handleChangeGruposChecked
                                                                    }
                                                                    color="primary"
                                                                    inputProps={{
                                                                        'aria-label':
                                                                            'secondary checkbox',
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {grupo.nome}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </PerfectScrollbar>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </DialogContent>

                <DialogActions
                    style={{
                        marginTop: 16,
                    }}
                >
                    <Button
                        onClick={handleCloseGerenciarGrupos}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleGerenciarGrupos} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="sm"
                open={openModulos}
                onClose={handleCloseAssistirConteudo}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Visualizar módulos
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>Conteúdos</DialogContentText>

                    <Backdrop className={classes.backdrop} open={loading2}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Card>
                        <>
                            {modulos.map((modulo) => (
                                <Grid container spacing={3}>
                                    <Grid
                                        onClick={() =>
                                            setOppenModulo(!openModulo)
                                        }
                                        item
                                        xs={4}
                                        key={modulo.modulo_id}
                                    >
                                        <Paper className={classes.papers}>
                                            Módulo {modulo.nome}
                                        </Paper>
                                    </Grid>

                                    <Collapse
                                        in={openModulo}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List>
                                            {modulo.conteudo?.map((teste) => {
                                                return (
                                                    <>
                                                        <ListItem
                                                            key={
                                                                teste.conteudo_id
                                                            }
                                                            className={
                                                                classes.item
                                                            }
                                                            disableGutters
                                                        >
                                                            <Button
                                                                activeClassName={
                                                                    classes.active
                                                                }
                                                                className={
                                                                    classes.button
                                                                }
                                                                style={{
                                                                    paddingLeft:
                                                                        '40px',
                                                                }}
                                                            >
                                                                {teste.arquivo.split(
                                                                    '.'
                                                                )[1] ===
                                                                'png' ? (
                                                                    <img
                                                                        style={{
                                                                            height: 230,
                                                                        }}
                                                                        alt="Logo"
                                                                        src={`http://localhost:4005/assets/conteudos/${teste.arquivo}`}
                                                                    />
                                                                ) : video(
                                                                      teste.arquivo.split(
                                                                          '.'
                                                                      )[1]
                                                                  ) ? (
                                                                    <Button
                                                                        onClick={() =>
                                                                            openAula(
                                                                                teste.arquivo
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            teste.arquivo
                                                                        }
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        onClick={() =>
                                                                            window.open(
                                                                                `http://localhost:4005/assets/conteudos/${teste.arquivo}`,
                                                                                '_blank'
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            teste.arquivo
                                                                        }
                                                                    </Button>
                                                                )}
                                                            </Button>
                                                        </ListItem>
                                                    </>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                </Grid>
                            ))}
                        </>
                    </Card>
                </DialogContent>
            </Dialog> */}
            <Dialog
                scroll="paper"
                fullWidth="true"
                open={openAulas}
                onClose={handleCloseAssistirAula}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Visualizar aula
                </DialogTitle>

                <DialogContent>
                    <Card
                        style={{
                            height: '70vh',
                            width: '41vw',
                        }}
                    >
                        <iframe
                            src={linkAula}
                            title="Aula"
                            width="100%"
                            height="100%"
                        />
                    </Card>
                </DialogContent>
            </Dialog>

            <Dialog
                open={isOpenExcluirTrilha}
                onClose={handleCloseExcluirTrilha}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Exclusão de Trilha
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A Trilha, sua avaliação, módulos e conteúdos serão
                        excluídos, deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExcluirTrilha} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleExcluirTrilha} color="primary">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TrilhaLista;
