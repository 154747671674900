/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import HttpsIcon from '@material-ui/icons/Https';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Backdrop from '@material-ui/core/Backdrop';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckboxTree from 'react-checkbox-tree';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    FormGroup,
    FormControlLabel,
    Grid,
    Link,
    IconButton,
    Input,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import * as Yup from 'yup';

import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile,
} from 'react-icons/md';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { hasAccess, setAuthSession } from '../../../services/auth';
import api from '../../../services/api';
import CustomTextField from '../../../components/admin/CustomTextField';
import IOSSwitch from '../../../components/admin/IOSwitch';
import getValidationErors from '../../../utils/geValidationErrors';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        span: {
            marginLeft: '-16px',
        },
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    paper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        maxHeight: 450,
    },
    'rct-title': {
        marginLeft: -16,
    },
    teste: {
        transition: '0.2s',
    },
}));

const PerfisList = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const acesso = hasAccess('Configuracoes', 'Perfis');
    const [initialFilters] = useState({
        nome: '',
    });
    const [filters, setFilters] = useState(initialFilters);
    const [perfis, setPerfis] = useState({});
    const [perfil, setPerfil] = useState({});
    const [loading, setLoading] = useState(true);
    const [idSelected, setIdSelected] = useState();
    const [perfilSelecionado, setPerfilSelecionado] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [openCadastroPerfil, setOpenCadastroPerfil] = useState(false);
    const [openGerenciarPermissoes, setOpenGerenciarPermissoes] =
        useState(false);

    const [validationErrors, setValidationErrors] = useState({});
    const [checked, setChecked] = useState(['regra1', 'regra3']);
    const [expanded, setExpanded] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isOpenExcluirPerfil, setIsOpenExcluirPerfil] = useState(false);

    const open = Boolean(anchorEl);

    const icons = {
        check: (
            <CheckBoxIcon
                style={{ marginBottom: -5, marginRight: -15 }}
                fontSize="small"
                color="primary"
            />
        ),
        uncheck: (
            <CheckBoxOutlineBlankIcon
                style={{ marginBottom: -5, marginRight: -15 }}
                fontSize="small"
                color="primary"
            />
        ),
        halfCheck: (
            <IndeterminateCheckBoxIcon
                style={{ marginBottom: -5, marginRight: -15 }}
                fontSize="small"
                color="primary"
            />
        ),
        expandClose: (
            <MdChevronRight
                style={{ marginBottom: -5, marginRight: -5 }}
                className="rct-icon rct-icon-expand-close"
            />
        ),
        expandOpen: (
            <MdKeyboardArrowDown
                style={{ marginBottom: -5, marginRight: -5 }}
                className="rct-icon rct-icon-expand-open"
            />
        ),
        expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
        collapseAll: (
            <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
        ),
        parentClose: (
            <MdFolder
                style={{ display: 'none' }}
                className="rct-icon rct-icon-parent-close"
            />
        ),
        parentOpen: (
            <MdFolderOpen
                style={{ display: 'none' }}
                className="rct-icon rct-icon-parent-open"
            />
        ),
        leaf: <ListAltIcon style={{ display: 'none' }} color="primary" />,
    };

    const nodes = [
        {
            // tela -> grupo
            value: 'usuario',
            label: 'Usuario',
            children: [
                {
                    // tela -> sessao
                    value: 'tela_id_2',
                    label: 'Grupos de usuário',
                    children: [
                        {
                            // value -> regra.regra_id
                            value: '1',
                            label: 'Visualizar',
                        },
                        {
                            value: '2',
                            label: 'Adicionar',
                        },
                        {
                            value: '3',
                            label: 'Editar',
                        },
                        {
                            value: '31',
                            label: 'Excluir',
                        },
                        {
                            value: '4',
                            label: 'Gerenciar categorias',
                        },
                        {
                            value: '5',
                            label: 'Gerenciar usuários',
                        },
                    ],
                },
                {
                    value: 'tela_id_3',
                    label: 'Usuários',
                    children: [
                        {
                            value: '6',
                            label: 'Visualizar',
                        },
                        {
                            value: '7',
                            label: 'Adicionar',
                        },
                        {
                            value: '8',
                            label: 'Editar',
                        },
                        {
                            value: '30',
                            label: 'Excluir',
                        },
                        {
                            value: '9',
                            label: 'Gerenciar categorias',
                        },
                        {
                            value: '10',
                            label: 'Gerenciar grupos',
                        },
                    ],
                },
            ],
        },
        {
            value: 'conteudo',
            label: 'Conteúdo',
            children: [
                {
                    value: 'tela_id_5',
                    label: 'Categorias',
                    children: [
                        {
                            value: '11',
                            label: 'Visualizar',
                        },
                        {
                            value: '12',
                            label: 'Adicionar',
                        },
                        {
                            value: '13',
                            label: 'Editar',
                        },
                        {
                            value: '32',
                            label: 'Excluir',
                        },
                    ],
                },
                {
                    value: 'tela_id_4',
                    label: 'Trilhas',
                    children: [
                        {
                            value: '18',
                            label: 'Duplicar trilha',
                        },
                        {
                            value: '19',
                            label: 'Gerenciar grupos',
                        },

                        {
                            value: '21',
                            label: 'Modulos',
                        },
                        {
                            value: '22',
                            label: 'Adicionar',
                        },
                        {
                            value: '20',
                            label: 'Editar',
                        },
                        {
                            value: '34',
                            label: 'Excluir',
                        },
                    ],
                },
                {
                    value: 'tela_id_11',
                    label: 'Comentários',
                    children: [
                        {
                            value: '35',
                            label: 'Visualizar',
                        },
                        {
                            value: '36',
                            label: 'Aprovar e reprovar comentários',
                        },
                    ],
                },
                {
                    value: 'tela_id_6',
                    label: 'Avaliações',
                    children: [
                        {
                            value: '22',
                            label: 'Adicionar',
                        },
                        {
                            value: '23',
                            label: 'Editar',
                        },
                    ],
                },
                {
                    value: 'tela_id_13',
                    label: 'Biblioteca',
                    children: [
                        {
                            value: '38',
                            label: 'Adicionar',
                        },
                        {
                            value: '39',
                            label: 'Editar',
                        },
                        {
                            value: '40',
                            label: 'Excluir',
                        },
                    ],
                },
            ],
        },
        {
            value: 'configuracoes',
            label: 'Configurações',
            children: [
                {
                    value: 'tela_id_10',
                    label: 'Perfis',
                    children: [
                        {
                            value: '14',
                            label: 'Visualizar',
                        },
                        {
                            value: '15',
                            label: 'Adicionar',
                        },
                        {
                            value: '16',
                            label: 'Editar',
                        },
                        {
                            value: '29',
                            label: 'Excluir',
                        },
                        {
                            value: '17',
                            label: 'Gerenciar permissões',
                        },
                    ],
                },
                {
                    value: 'tela_id_7',
                    label: 'Alertas',
                    children: [
                        {
                            value: '25',
                            label: 'Adicionar',
                        },
                        {
                            value: '26',
                            label: 'Editar',
                        },
                        {
                            value: '33',
                            label: 'Excluir',
                        },
                    ],
                },
                {
                    value: 'tela_id_8',
                    label: 'Importador',
                    children: [
                        {
                            value: '27',
                            label: 'Importar',
                        },
                        {
                            value: '28',
                            label: 'Baixar',
                        },
                    ],
                },
                {
                    value: 'tela_id_12',
                    label: 'Contato',
                    children: [
                        {
                            value: '37',
                            label: 'Visualizar',
                        },
                        // {
                        //     value: '38',
                        //     label: 'Editar',
                        // },
                        // {
                        //     value: '39',
                        //     label: 'Excluir',
                        // },
                    ],
                },
                {
                    value: 'tela_id_15',
                    label: 'Parceiros',
                    children: [
                        {
                            value: '42',
                            label: 'Adicionar',
                        },
                        {
                            value: '43',
                            label: 'Editar',
                        },
                        {
                            value: '44',
                            label: 'Visualizar',
                        },
                        {
                            value: '45',
                            label: 'Excluir',
                        },
                    ],
                },
            ],
        },
        {
            value: 'Treinamento',
            label: 'Treinamento',
            children: [
                {
                    value: 'tela_id_14',
                    label: 'Treinamento Presencial',
                    children: [
                        {
                            value: '41',
                            label: 'Visualizar',
                        },
                    ],
                },
            ],
        },
    ];

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        getPerfis(filters, currentPage, perPage);
    }, [currentPage, perPage]);

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getPerfis();
    };

    const getPerfis = async () => {
        try {
            setLoading(true);
            const response = await api.get('/perfis/filter', {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setPerfis(response.data.data);
            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleInsertPermissoesPorGrupoId = async () => {
        try {
            await api.post(`/perfis/permissoes/${idSelected}`, { checked });
            enqueueSnackbar('Permissões alteradas com sucesso!', {
                variant: 'success',
            });
            handleCloseGerenciarPermissoes();
            await api.get('/verificar_token').then((response) => {
                setAuthSession(
                    response.data.token,
                    response.data.usuario_id,
                    response.data.acessos
                );
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
            setLoading(false);
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getPerfis(filters);
    };

    const openMenuActions = (event, perfil) => {
        setAnchorEl(event.currentTarget);
        setPerfilSelecionado(perfil);
        setIdSelected(perfil.perfil_id);
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const handleOpenCadastroPerfil = () => {
        setOpenCadastroPerfil(true);
    };

    const handleCloseCadastroPerfil = () => {
        setOpenCadastroPerfil(false);
        setIsEdit(false);
        setValidationErrors({});
        setPerfil({});
    };

    const getPermissoesByPerfilId = async () => {
        try {
            const response = await api.get(`/perfis/permissoes/${idSelected}`);
            setChecked(response.data);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleOpenGerenciarPermissoes = async () => {
        await getPermissoesByPerfilId();
        setOpenGerenciarPermissoes(true);
        closeMenuActions();
    };

    const handleCloseGerenciarPermissoes = () => {
        setOpenGerenciarPermissoes(false);
        setChecked([]);
        setExpanded([]);
    };

    const handleOpenExcluirPerfil = async () => {
        setIsOpenExcluirPerfil(true);
        setAnchorEl(null);
    };

    const handleCloseExcluirPerfil = async () => {
        setIsOpenExcluirPerfil(false);
    };

    const handleExcluirPerfil = async () => {
        try {
            handleCloseExcluirPerfil();
            setAnchorEl(null);
            await api.delete(`/perfis/${idSelected}`);
            await getPerfis();
            enqueueSnackbar('Perfil removido com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPerfil({ ...perfil, [name]: value });
    };

    const createPerfil = async () => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required('Nome obrigatório'),
                descricao: Yup.string().required('Descrição obrigatória'),
            });

            await schema.validate(perfil, {
                abortEarly: false,
            });

            await api.post('/perfis', perfil);
            enqueueSnackbar('Perfil cadastrado com sucesso!', {
                variant: 'success',
            });
            getPerfis();
            handleCloseCadastroPerfil();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
            setLoading(false);
        }
    };

    const handleOpenEditPerfil = async () => {
        closeMenuActions();
        setIsEdit(true);
        await getPerfilById();
        setOpenCadastroPerfil(true);
    };

    const getPerfilById = async () => {
        try {
            const response = await api.get(`/perfis/${idSelected}`);

            setPerfil(response.data);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const editPerfil = async () => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required('Nome obrigatório'),
                descricao: Yup.string().required('Descrição obrigatória'),
            });

            await schema.validate(perfil, {
                abortEarly: false,
            });

            await api.put(`/perfis/${idSelected}`, perfil);
            enqueueSnackbar('Perfil editado com sucesso!', {
                variant: 'success',
            });
            getPerfis();
            handleCloseCadastroPerfil();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
            setLoading(false);
        }
    };

    const onCheck = async (value) => {
        console.log('🚀 ~ file: index.js ~ line 385 ~ onCheck ~ value', value);
        setChecked(value);
    };

    const onExpand = async (value) => {
        console.log('🚀 ~ file: index.js ~ line 390 ~ onExpand ~ value', value);
        setExpanded(value);
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Perfis
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Perfis
                    </Typography>
                </Grid>
                {acesso.adicionar && (
                    <Grid
                        container
                        xs={6}
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={handleOpenCadastroPerfil}
                            color="primary"
                            variant="contained"
                        >
                            Novo perfil
                        </Button>
                    </Grid>
                )}
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={3}>
                            <Paper className={classes.paper}>
                                <Input
                                    placeholder="Nome"
                                    className={classes.input}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            nome: e.target.value,
                                        })
                                    }
                                    value={filters.nome}
                                    disableUnderline
                                    fullWidth
                                />
                            </Paper>
                        </Grid>
                        <Grid container xs={4} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{ marginLeft: '2%' }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell>
                                                        Descrição
                                                    </TableCell>
                                                    <TableCell align="right" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {perfis.length === 0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {perfis.map(
                                                                    (
                                                                        perfil
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                perfil.perfil_id
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    perfil.nome
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    perfil.descricao
                                                                                }
                                                                            </TableCell>

                                                                            <TableCell align="right">
                                                                                {(acesso.editar ||
                                                                                    acesso[
                                                                                        'gerenciar permissões'
                                                                                    ]) && (
                                                                                    <IconButton
                                                                                        color="secondary"
                                                                                        size="small"
                                                                                        aria-label="more"
                                                                                        aria-controls="long-menu"
                                                                                        aria-haspopup="true"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            openMenuActions(
                                                                                                e,
                                                                                                perfil
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <SettingsIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={closeMenuActions}
            >
                {acesso.editar && (
                    <MenuItem onClick={handleOpenEditPerfil}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <EditIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Editar perfil" />
                    </MenuItem>
                )}
                {acesso['gerenciar permissões'] && (
                    <MenuItem onClick={handleOpenGerenciarPermissoes}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <ListIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Gerenciar permissões" />
                    </MenuItem>
                )}
                {acesso.excluir && (
                    <MenuItem onClick={handleOpenExcluirPerfil}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <DeleteIcon color="secondary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Excluir perfil" />
                    </MenuItem>
                )}
            </Menu>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="xs"
                open={openCadastroPerfil}
                onClose={handleCloseCadastroPerfil}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    {isEdit ? 'Gerenciar permissões' : 'Novo perfil'}
                </DialogTitle>

                <DialogContent>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Grid
                        style={{ marginTop: 8 }}
                        container
                        spacing={2}
                        justify="center"
                    >
                        <CustomTextField
                            label="Nome do perfil"
                            name="nome"
                            value={perfil.nome}
                            error={validationErrors.nome}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            required
                            displayEmpty
                        />
                    </Grid>
                    <Grid
                        style={{ marginTop: 8 }}
                        container
                        spacing={2}
                        justify="center"
                    >
                        <CustomTextField
                            label="Descrição"
                            name="descricao"
                            value={perfil.descricao}
                            error={validationErrors.descricao}
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            displayEmpty
                        />
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button onClick={handleCloseCadastroPerfil} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={isEdit ? editPerfil : createPerfil}
                        color="primary"
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="xs"
                open={openGerenciarPermissoes}
                onClose={handleCloseGerenciarPermissoes}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    Gerenciar permissões - {perfilSelecionado.nome}
                </DialogTitle>

                <DialogContent>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <CheckboxTree
                        className={classes.teste}
                        nodes={nodes}
                        checked={checked}
                        expanded={expanded}
                        onCheck={onCheck}
                        onExpand={onExpand}
                        showExpandAll
                        icons={icons}
                    />
                </DialogContent>
                <DialogActions style={{ marginTop: 16 }}>
                    <Button
                        onClick={handleCloseGerenciarPermissoes}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleInsertPermissoesPorGrupoId}
                        color="primary"
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenExcluirPerfil}
                onClose={handleCloseExcluirPerfil}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Exclusão de Perfil
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O perfil será excluído, deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExcluirPerfil} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleExcluirPerfil} color="primary">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PerfisList;
