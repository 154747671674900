import React from 'react';
import Button from '../../../../../../components/aluno/Button';
import {
    LabelChamado,
    TicketContainer,
    TicketContent,
    TicketHeader,
    TicketTextContainer,
} from './style';
import { API_HOST } from '../../../../../../config_servers';

import {
    DefaultSmileyFace,
} from '../../../../../../components/aluno/Menu/style';
import {
    IconComment,
    SmileyFace,
} from '../../../../../../components/aluno/Icons/Icons';

const Ticket = ({
    id,
    status,
    date,
    descricao,
    handleFecharChamado,
    historico,
    usuario
}) => {
    return (
        <TicketContainer>
            <TicketHeader status={status}>
                <LabelChamado>
                    #{id} Chamado {status == '0' ? 'Aberto'  : "Fechado"}
                </LabelChamado>
                {date}
            </TicketHeader>
            <TicketContent>
                <TicketTextContainer>
                    <LabelChamado>Você:</LabelChamado>
                    {descricao}
                </TicketTextContainer>
            </TicketContent>
            {historico?.map((histori) =>(
                <>
                {console.log('mensagem', histori)}
                <TicketContent>
                    {histori.contato_resposta != usuario ? 
                        null
                        :
                        <TicketTextContainer>
                            <LabelChamado>Você:</LabelChamado>
                                {histori.mensagem}

                        </TicketTextContainer>
                    }
                    {histori.contato_resposta != usuario ?  
                    <TicketTextContainer>
                        <LabelChamado>
                             {!histori.arquivo ||
                                    histori.arquivo == 'undefined' ? (
                                        <DefaultSmileyFace>
                                            <SmileyFace />
                                        </DefaultSmileyFace>
                                    ) : (
                                        <img src={`${API_HOST}/assets/conteudos/${histori.arquivo}`} alt={histori.contato_resposta == usuario ?  histori.nome : ""} />
                                    )}
                            {histori.contato_resposta == usuario ?  "" : histori.nome }
                        </LabelChamado>
                            {histori.mensagem}
                    </TicketTextContainer>
                        : ""}
                </TicketContent>
                </>
            ))}
            {status == '0' && <Button onClick={handleFecharChamado}>Fechar chamado</Button>}
        </TicketContainer>
    );
};

export default Ticket;
