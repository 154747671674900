/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import FileSaver from 'file-saver';

import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    FormControlLabel,
    Select,
    Grid,
    Link,
    ListItemSecondaryAction,
    IconButton,
    Input,
    Chip,
    TextField,
    List,
    ListItem,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    MenuItem,
    Tooltip,
    Menu,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { API_HOST } from '../../../config_servers';
import { formatBytes } from '../../../helpers';

import { getUsuarioId } from '../../../services/auth';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        span: {
            marginLeft: '-16px',
        },
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    paper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        maxHeight: 450,
    },
    'rct-title': {
        marginLeft: -16,
    },
    teste: {
        transition: '0.2s',
    },
    dropZone: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        width: '55%',
        transition: 'border .24s ease-in-out',
    },
    uploadActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    media: {
        height: 140,
    },
    autocomplete: {
        backgroundColor: 'white',
        // height: '45.625px'
    },
}));

const Importador = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const acesso = hasAccess('Configuracoes', 'Perfis');

    const [filters, setFilters] = useState({
        nome: '',
        email: '',
        perfil_id: '',
    });
    const [errosDeValidacoes, setErrosDeValidacoes] = useState({});
    const [perfil, setPerfil] = useState({});
    const [loading, setLoading] = useState(true);
    const [idSelected, setIdSelected] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [files, setFiles] = useState([]);
    const [usuarioId] = useState(getUsuarioId());
    const [tipoTemplate, setTipoTemplate] = useState('');
    const [validacaoOk, setValidacaoOk] = useState(false);
    const [rows, setRows] = useState([]);
    const open = Boolean(anchorEl);

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        getFilesFromEvent: (event) => myCustomFileGetter(event),
    });

    async function myCustomFileGetter(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, 'tipo_documento_id', {
                value: '',
                writable: true,
            });

            _files.push(file);
        }

        return _files;
    }

    useEffect(() => {
        setFiles([...files, ...acceptedFiles]);
    }, [acceptedFiles]);

    useEffect(() => {
        getErrosDeValidacao(filters, currentPage, perPage);
    }, [currentPage, perPage]);

    const removeFile = (file) => () => {
        const newFiles = [...files];
        const id = newFiles.indexOf(file);
        newFiles.splice(id, 1);
        setFiles(newFiles);
    };

    const downloadDocumento = (id) => {
        api.get(`/documentos/${id}`).then((response) => {
            const documento = response.data;
            axios({
                url: `${API_HOST}/assets/uploads/${documento.arquivo}`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documento.nome_arquivo);
                document.body.appendChild(link);
                link.click();
            });
        });
    };

    const getErrosDeValidacao = async () => {
        try {
            setLoading(true);
            const response = await api.get('/importador/erros', {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setErrosDeValidacoes(response.data.data);
            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getErrosDeValidacao(filters);
    };

    const openMenuActions = (event, perfil) => {
        setAnchorEl(event.currentTarget);
        // setPerfilSelecionado(perfil);
        setIdSelected(perfil.perfil_id);
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const handleValidacao = async () => {
        try {
            setLoading(true);

            const data = new FormData();
            data.append('planilha', files[0]);
            data.append('usuario_id', usuarioId);

            console.log(
                '🚀 ~ file: index.js ~ line 290 ~ handleValidacao ~ files[0]',
                files[0]
            );
            const response = await api.post('/importador/validar', data);

            if (response.data.validacaoOk) {
                setValidacaoOk(true);
                setRows(response.data.rows);
                enqueueSnackbar(
                    'Validação bem-sucedida, botão de importação disponível.',
                    {
                        variant: 'success',
                    }
                );
            } else {
                setFiles([]);
            }

            await getErrosDeValidacao();
        } catch (error) {
            setLoading(false);
            setFiles([]);
            await getErrosDeValidacao();
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleImportacao = async () => {
        try {
            setLoading(true);

            await api.post('/importador/importar', {
                rows,
                usuarioId,
                nome_arquivo: files[0].name,
            });

            enqueueSnackbar('Dados importador com sucesso!', {
                variant: 'success',
            });

            setFiles([]);

            setValidacaoOk(false);
            await getErrosDeValidacao();
        } catch (error) {
            setValidacaoOk(false);

            setLoading(false);
            setFiles([]);
            await getErrosDeValidacao();
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    // eslint-disable-next-line camelcase
    const downloadArquivoDeErro = async (validacao_importador_id) => {
        try {
            const response = await api.post(
                'importador/downloadArquivoDeErro',
                { validacao_importador_id },
                // { veiculo_id: veiculo.veiculo_id },
                { responseType: 'blob' }
            );

            FileSaver.saveAs(response.data, `erros_de_validacao.txt`);
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 387 ~ downloadArquivoDeErro ~ error',
                error
            );
        }
    };

    const downloadTemplatePadrao = async () => {
        try {
            const response = await api.post(
                '/importador/templatePadraoUsuarios',
                { tipoTemplate },
                { responseType: 'arraybuffer' }
            );
            const blob = new Blob([response.data], {
                type:
                    'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
            });
            FileSaver.saveAs(blob, `template_usuarios.xlsx`);
        } catch (error) {
            console.log('error download template', error);
        }
    };
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Importador
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Importador
                    </Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={2}>
                            <Paper className={classes.autocomplete}>
                                <Select
                                    placeholder="Selecione o template"
                                    className={classes.input}
                                    onChange={(e) =>
                                        setTipoTemplate(e.target.value)
                                    }
                                    value={tipoTemplate}
                                    disableUnderline
                                    displayEmpty
                                    fullWidth
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <span style={{ color: '#a4a9ab' }}>
                                            Templates
                                        </span>
                                    </MenuItem>
                                    <MenuItem value="1">Usuários</MenuItem>
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid container xs={1} alignItems="center">
                            <Button
                                onClick={downloadTemplatePadrao}
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                BAIXAR
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <div>
                <Grid container spacing={2}>
                    <Grid
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            alignItems: 'center',
                            marginTop: 30,
                        }}
                        item
                        xs={12}
                    >
                        {files.length < 1 ? (
                            <div
                                {...getRootProps({
                                    className: classes.dropZone,
                                })}
                            >
                                <input {...getInputProps()} />
                                <Typography
                                    variant="subtitle1"
                                    component="p"
                                    gutterBottom={false}
                                >
                                    Clique aqui para anexar o arquivo a ser
                                    validado.
                                </Typography>
                            </div>
                        ) : (
                            <List className={classes.dropZone} dense>
                                {files.map((file, i) => (
                                    <>
                                        <ListItem
                                            style={{ marginRight: 24 }}
                                            key={file.name}
                                            divider={i + 1 !== files.length}
                                        >
                                            <ListItemText
                                                primary={file.name}
                                                secondary={formatBytes(
                                                    file.size
                                                )}
                                            />

                                            <ListItemSecondaryAction>
                                                <Tooltip
                                                    title="Remover arquivo"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={removeFile(
                                                            file
                                                        )}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </>
                                ))}
                            </List>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginLeft: 16,
                            }}
                        >
                            {validacaoOk ? (
                                <Button
                                    style={{
                                        color: 'green',
                                        borderColor: 'green',
                                    }}
                                    variant="outlined"
                                    onClick={handleImportacao}
                                >
                                    Importar
                                </Button>
                            ) : (
                                <Button
                                    // style={{ color: 'green', borderColor: 'green' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleValidacao}
                                >
                                    Validar
                                </Button>
                            )}

                            {/* <Button
                                style={{ marginTop: 8 }}
                                variant="outlined"
                                color="primary"
                            >
                                Limpar
                            </Button> */}
                        </div>
                    </Grid>

                    {/* {associado.documentos.map((documento) => (
                        <>
                            {!documento.remover && (
                                <Grid item xs={12} sm={4}>
                                    <Card>
                                        <CardMedia
                                            className={classes.media}
                                            image={`${API_HOST}/assets/uploads/${documento.arquivo}`}
                                            title=""
                                        />
                                        <CardContent>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant="h5"
                                                        component="h5"
                                                        gutterBottom={false}
                                                    >
                                                        {documento.nome_arquivo}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle2"
                                                        component="h6"
                                                        gutterBottom={false}
                                                    >
                                                        {formatBytes(
                                                            documento.tamanho
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} align="right">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(e) =>
                                                            handleClick(
                                                                e,
                                                                documento.documento_id
                                                            )
                                                        }
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        component="h6"
                                                        gutterBottom={false}
                                                    >
                                                        {
                                                            documento.tipo_documento
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <Divider />
                                        <CardActions
                                            style={{ justifyContent: 'center' }}
                                        >
                                            <Button
                                                color="default"
                                                startIcon={<GetAppIcon />}
                                                onClick={() =>
                                                    downloadDocumento(
                                                        documento.documento_id
                                                    )
                                                }
                                            >
                                                Download
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                        </>
                    ))} */}
                </Grid>

                {/* <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={redirectToDocumento}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <VisibilityIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Visualizar" />
                    </MenuItem>
                    <Divider />

                    <MenuItem onClick={openFormDialog}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Renomear" />
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={removeDocumento}>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Remover" />
                    </MenuItem>
                </Menu> */}
            </div>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={3}
                                                        align="center"
                                                    >
                                                        Histórico de importações
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        Nome do arquivo
                                                    </TableCell>
                                                    <TableCell>
                                                        Data do envio
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Histórico
                                                    </TableCell>
                                                    <TableCell>
                                                        Baixar
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {errosDeValidacoes.length ===
                                                        0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {errosDeValidacoes.map(
                                                                    (item) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                item.validacao_importador_id
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    item.nome_arquivo_importado
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    moment(
                                                                                        item.created_at
                                                                                    )
                                                                                        .subtract(
                                                                                            3,
                                                                                            'hours'
                                                                                        )
                                                                                        .format(
                                                                                            'DD/MM/YYYY HH:mm:ss'
                                                                                        )
                                                                                    // .remove(
                                                                                    //     3,
                                                                                    //     'hours'
                                                                                    // )
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {item.sucesso !==
                                                                                1 ? (
                                                                                    <>
                                                                                        <Chip
                                                                                            variant="outlined"
                                                                                            style={{
                                                                                                borderColor:
                                                                                                    '#FD7B72',
                                                                                            }}
                                                                                            label={
                                                                                                item.historico
                                                                                            }
                                                                                            icon={
                                                                                                <CloseIcon
                                                                                                    style={{
                                                                                                        color:
                                                                                                            '#FD7B72',
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    <Chip
                                                                                        variant="outlined"
                                                                                        style={{
                                                                                            borderColor:
                                                                                                '#4BCA81',
                                                                                        }}
                                                                                        label={
                                                                                            item.historico
                                                                                        }
                                                                                        icon={
                                                                                            <CheckIcon
                                                                                                style={{
                                                                                                    color:
                                                                                                        '#4BCA81',
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </TableCell>

                                                                            <TableCell>
                                                                                {item.sucesso !==
                                                                                    1 && (
                                                                                    <Tooltip
                                                                                        title="Baixar arquivo de erros"
                                                                                        placement="top"
                                                                                    >
                                                                                        <IconButton
                                                                                            edge="end"
                                                                                            aria-label="delete"
                                                                                            onClick={() =>
                                                                                                downloadArquivoDeErro(
                                                                                                    item.validacao_importador_id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <GetAppIcon color="secondary" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default Importador;
