import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { Link, useHistory } from 'react-router-dom';
import TitlePage from '../../../components/aluno/TitlePage';
import ModuloCard from './components/ModuloCard';
import {
    CardsContainer,
    MaskedBG,
    MinhaTrilhaContainer,
    TitleCardsContainer,
} from './style';
import cardImage from '../../../assets/img/cardImage.jpg';
import bgMinhasTrilhas from '../../../assets/img/minhatrilhabg.jpg';
import { API_HOST } from '../../../config_servers';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { LoopCircleLoading  } from 'react-loadingg';

const MinhaTrilhas = () => {
    const history = useHistory()
    const [trilhas, setTrilhas] = useState([]);
    const usuario_id = localStorage.getItem('@treinadelta/usuario_id');
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    useEffect(() => {
        verificaPrimeiroAcessoAluno()
        getTrilhasEModulos();
    }, []);

    const verificaPrimeiroAcessoAluno = async (props) => {
        try {
            const response = await api.get(
                `/usuarios/verificaPrimeiroAcesso/${usuario_id}`
            );
            const { isPrimeiroAcesso } = response.data
            if(location){
                
            } else if (isPrimeiroAcesso) {
                console.log("entrei")

                history.push('/mudar_senha')
                console.log('primeiro acesso')
            } else {
                console.log('não é')
            }
            // setLoading(false);
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 28 ~ getTrilhasEModulos ~ error',
                error
            );
        }
    };

    const getTrilhasEModulos = async () => {
        try {
            const response = await api.get(
                `/trilhas/getModulosTrilhasPorAluno/${usuario_id}`
            );
            console.log(response.data);
            setTrilhas(response.data);
            setLoading(false);
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 28 ~ getTrilhasEModulos ~ error',
                error
            );
        }
    };

    var toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10);
        var hours = Math.floor(sec_num / 3600) + 'h';
        var minutes = (Math.floor(sec_num / 60) % 60) + 'm';

        return [hours, minutes]
            .map((v) => (v < 10 ? '0' + v : v))
            .filter((v, i) => v !== '00' || i > 0)
            .join('');
    };

    const verificador = (dados) => {
        const results = dados.split('h');
        if (results[0] === '0') {
            return results[1];
        } else {
            return results[0] + 'h' + results[1];
        }
    };

    return (
        <>
       
            <MaskedBG image={bgMinhasTrilhas} />
            <MinhaTrilhaContainer>
            {loading? 
                <LoopCircleLoading />
                :  
                <>
                {trilhas.map((trilha) => (
                    <>
                        <TitleCardsContainer>
                            <TitlePage
                                title={trilha.nome_trilha}
                                tagTitle={trilha.nome_categoria}
                                modulos={trilha.qtd_modulos}
                            >
                                {trilha.descricao_trilha}
                            </TitlePage>
                            <CardsContainer>
                                {trilha.modulos.map((modulo) => (
                                    <>
                                        <Link
                                            to={`/trilhas/${trilha.trilha_id}/modulos/${modulo.modulo_id}/`}
                                        >
                                            <ModuloCard
                                                title={modulo.nome_modulo}
                                                reviewed={
                                                    modulo.assistido === 1
                                                }
                                                tag={
                                                    modulo.assistido === 1
                                                        ? 'assistido'
                                                        : modulo.iniciado === 1
                                                        ? 'assistindo'
                                                        : 'assistir'
                                                }
                                                hours={modulo.duracao_video_segundos ?
                                                    toHHMMSS(
                                                        modulo.duracao_video_segundos
                                                    ) === '0h0m'
                                                        ? '----'
                                                        : verificador(
                                                              toHHMMSS(
                                                                  modulo.duracao_video_segundos
                                                              )
                                                          )
                                                          : '0h'
                                                }
                                                image={`${API_HOST}/assets/conteudos/${modulo.imagem}`}
                                            />
                                        </Link>
                                    </>
                                ))}
                            </CardsContainer>
                        </TitleCardsContainer>
                    </>
                    ))}
                </>
                }  
                {/* <TitleCardsContainer>
                    <TitlePage
                        title="Escola UX & Design"
                        breadcrumbs
                        videos="6"
                        anexos="4"
                        comentarios="2"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Metus lectus lorem semper est. Sociis fermentum
                        ultricies sed porttitor. Suscipit sed amet consequat id
                        convallis. Ac enim adipiscing sapien, quis potenti.{' '}
                    </TitlePage>
                    <CardsContainer>
                        {Array(6).fill(
                            <ModuloCard
                                title="Design visual de um site mobile"
                                reviewed
                                tag="Avaliado"
                                hours="10"
                                image={cardImage}
                            />
                        )}
                    </CardsContainer>
                </TitleCardsContainer>
                <TitleCardsContainer>
                    <TitlePage
                        title="Escola UX & Design"
                        breadcrumbs
                        questoes="12"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Metus lectus lorem semper est. Sociis fermentum
                        ultricies sed porttitor. Suscipit sed amet consequat id
                        convallis. Ac enim adipiscing sapien, quis potenti.{' '}
                    </TitlePage>
                    <CardsContainer>
                        {Array(6).fill(
                            <ModuloCard
                                title="Design visual de um site mobile"
                                reviewed={false}
                                tag="Assistindo"
                                hours="10"
                                image={cardImage}
                            />
                        )}
                    </CardsContainer>
                </TitleCardsContainer> */}
            </MinhaTrilhaContainer>
             
        </>
    );
};

export default MinhaTrilhas;
