import styled from 'styled-components';
import { colors } from '../../../../../assets/theme/colors';
import { LinkMenu } from '../../style';

export const HamburguerContainer = styled.div`
    height: 52px;
    width: 52px;
    padding: 14px 11px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    margin-top: -11px;
    cursor: pointer;
    transition: all 0.3s ease, top 0.6s, transform 0s;
    position: relative;
    z-index: 12;
    top: 0;
    ${({ xAnimation }) =>
        xAnimation
            ? `
    right: 4px;
    top: 0px;`
            : ''}
    &:hover div {
        opacity: ${({ menuOpen }) => (menuOpen ? '1' : '0.8')};
    }
    & div {
        opacity: 0.3;
    }
    @media (max-width: 840px) {
        display: flex;
    }
    &:hover {
        opacity: ${({ menuOpen }) => (menuOpen ? '1' : '0.8')};
    }
    @keyframes pop {
        50% {
            transform: scale(1.3);
        }
    }
    @keyframes moveX {
        0% {
            top: 0;
            right: 30px;
        }
        100% {
            right: 14px;
            top: -14px;
        }
    }
`;

export const HamburguerLine = styled.div`
    height: 3px;
    width: 100%;
    background-color: #ffffff;
    transition: all 0.3s ease;
    &:nth-child(3) {
        width: 60%;
        align-self: flex-end;
    }
    ${({ xAnimation }) =>
        xAnimation
            ? `
    &:nth-child(1) {
    transform: rotate(45deg) translate(8px, 8px);
    background: ${colors.primary};
    opacity: 1;
  }
  &:nth-child(2) {
    transform: rotate(-45deg) translate(8px, 8px);
    transform: translateX(50px);
    background: ${colors.primary};
    opacity: 0 !important;
    transition: background 0.4s, opacity 0.4s, transform 0.5s;
  }
  &:nth-child(3) {
    transform: rotate(-45deg) translate(7px,-7px);
    background: ${colors.primary};
    opacity: 1;
    width: 100%;
  }`
            : ''}
`;

export const SideMenu = styled.div`
    width: 100%;
    max-width: 362px;
    height: 100vh;
    display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')};
    background: #202029;
    flex-direction: column;
    z-index: 11;
    position: absolute;
    right: 0;
    top: -42px;
    transition: all 0.3s ease;
    transform: translateX(0%);
    animation-name: appear;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    overflow-y: overlay;
    padding-bottom: 30px;

    @keyframes appear {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
    @keyframes disappear {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

export const SideUserContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 50px 50px;
    & img {
        height: 81px;
        width: 81px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.15);
        margin-bottom: 12px;
    }
`;

export const SideMenuBlocker = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    background: #000000;
    opacity: 0.6;
    left: 0;
    top: 0;
    display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')};
    z-index: 10;
    opacity: 0;
    animation-name: appearBlocker;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

    @keyframes appearBlocker {
        100% {
            opacity: 0.6;
        }
    }
    @keyframes disappearBlocker {
        0% {
            opacity: 0.6;
        }
        100% {
            opacity: 0;
        }
    }
`;

export const SideLinkMenu = styled(LinkMenu)`
    display: block;
    font-size: 1.8rem;
    padding: 20px 43px;
    transition: padding 0.3s ease;
    position: relative;
    &.selected {
        opacity: 1;
        color: #ffffff99;
        &::before {
            content: '';
            width: 7px;
            height: 70%;
            top: 13%;
            left: 0;
            background: ${colors.primary};
            position: absolute;
        }
    }
    &:hover {
        padding: 20px 55px;
    }
`;

export const TitleMenuList = styled.p`
    opacity: 0.2;
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 10px;
    text-transform: uppercase;
    padding: 0 43px;
`;

export const SectionMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;
