/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-fallthrough */
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import {
    Breadcrumbs,
    CircularProgress,
    Card,
    Grid,
    Button,
    Link,
    Typography,
    CardContent,
    Stepper,
    Step,
    StepLabel,
} from '@material-ui/core';
import * as Yup from 'yup';

import getValidationErors from '../../../utils/geValidationErrors';
import ParceiroForm1 from './components/ParceiroForm1';
import ParceiroForm2 from './components/ParceiroForm2';
import { hasAccess } from '../../../services/auth';
import api  from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '13px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

function getSteps() {
    return ['Dados gerais', 'Endereço'];
}

const ParceirosForm = () => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const history = useHistory();

    const [parceiro, setParceiro] = useState({qtd_colaboradores: 0});
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const formRef = useRef(null);
    const steps = getSteps();
    const [validationErrors, setValidationErrors] = useState({});

    const [edit] = useState(false);

    useEffect(() => {
    }, []);

    const handleBackToPage = () => {
        history.goBack();
    };

    const handleNext = async () => {
        setValidationErrors({});
        try {
            if (activeStep === 0) {
                
                const schema = Yup.object().shape({
                    nome: Yup.string().required('Nome obrigatório'),
                    email_login: Yup.string().required('E-mail para login obrigatório'),
                    email_contato: Yup.string().required('E-mail para contato obrigatório'),
                    telefone: Yup.string().required('Telefone obrigatório'),
                    cnpj: Yup.string()
                        .min(14, 'Insira um cnpj válido!')
                        .required('Cnpj obrigatório'),
                });
                await schema.validate(parceiro, {
                    abortEarly: false,
                });
            }

            if (activeStep === 1) {
                const schema = Yup.object().shape({
                    cep: Yup.string().required('CEP obrigatório'),
                    endereco: Yup.string().required('Endereço obrigatório'),
                    numero_endereco: Yup.string().required('Número obrigatório'),
                    bairro: Yup.string().required('Bairro obrigatório'),
                    cidade: Yup.string().required('Cidade obrigatório'),
                    estado: Yup.string().required('Estado obrigatório'),
                });

                await schema.validate(parceiro, {
                    abortEarly: false,
                });

                handleFinish();

                return;
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setParceiro({ ...parceiro, [name]: value });
    };

    const handleFinish = async () => {
        try {
            await api.post('/parceiros', parceiro);
            enqueueSnackbar('Parceiro cadastrado com sucesso!', {
                variant: 'success',
            });

            history.push('/admin/parceiros');
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
            setLoading(false);
        }
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <ParceiroForm1
                        formRef={formRef}
                        handleBackToPage={handleBackToPage}
                        parceiro={parceiro}
                        setParceiro={setParceiro}
                        handleInputChange={handleInputChange}
                        validationErrors={validationErrors}
                        edit={edit}
                    />
                );
            
            case 1:
                return (
                    <ParceiroForm2
                        formRef={formRef}
                        handleBackToPage={handleBackToPage}
                        parceiro={parceiro}
                        setParceiro={setParceiro}
                        handleInputChange={handleInputChange}
                        validationErrors={validationErrors}
                        edit={edit}
                    />
                );

            default:
                return (
                    <ParceiroForm1
                        formRef={formRef}
                        parceiro={parceiro}
                        setParceiro={setParceiro}
                        handleBackToPage={handleBackToPage}
                        validationErrors={validationErrors}
                        handleInputChange={handleInputChange}
                        edit={edit}
                    />
                );
        }
    }

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '80vh' }}
            >
                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/parceiros"
                >
                    Parceiros
                </Link>
                <Typography className={classes.breadCrumb}>
                    Novo Parceiro
                </Typography>
            </Breadcrumbs>
            <Typography style={{ marginTop: 16 }} variant="h3" component="h1">
                Novo Parceiro
            </Typography>

            <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className={classes.content}>
                <Card>
                    <CardContent>
                        <div>
                            {activeStep === steps.length ? (
                                <div className={classes.alignRight}>
                                    <Typography>
                                        Todas as etapas foram concluídas!
                                    </Typography>
                                </div>
                            ) : (
                                <div>
                                    <Typography>
                                        {getStepContent(activeStep)}
                                    </Typography>
                                    <div className={classes.alignRight}>
                                        {activeStep === 0 ? (
                                            <Button
                                                color="default"
                                                onClick={handleBackToPage}
                                            >
                                                Cancelar
                                            </Button>
                                        ) : (
                                            <Button onClick={handleBack}>
                                                Voltar
                                            </Button>
                                        )}
                                        {activeStep === steps.length - 1 ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                Finalizar
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                Prosseguir
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default ParceirosForm;
