import React, { useEffect, useState } from 'react';
import {
    AreaDoAlunoPagesContainer,
    SubtitleAreaDoAluno,
    TitleAreaDoAluno,
    TitleSubAreaDoAluno,
} from '../style';
import {
    InputLabel,
    InputsWrapperMeusDados,
    MeusDadosContainer,
} from './style';
import moment from "moment";

import Button from '../../../../components/aluno/Button';
import TitlePage from '../../../../components/aluno/TitlePage';
// import TextArea from "../../../../components/aluno/Inputs/TextArea";
import api from '../../../../services/api';
import { useSnackbar } from 'notistack';
import TextArea from '../../../../components/aluno/Inputs/TextArea';
import Ticket from './components/Ticket';
import { LoopCircleLoading  } from 'react-loadingg';

const Contato = () => {
    const [credentials, setCredentials] = useState({
        nome: '',
        email: '',
        assunto: '',
    });
    const { enqueueSnackbar } = useSnackbar();
    const [chamados, setChamados] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleFecharChamado = async(e) => {
        try {
            e.preventDefault();
            const data = {
                usuario_id: `${localStorage.getItem(
                    '@treinadelta/usuario_id'
                )}`,
            }
            await api.post('/contatos/fechar-chamado/', data)
            await getChamados()
            enqueueSnackbar(<h4>Chamado fechado com sucesso!</h4>, {
                variant: 'success',
            });
        } catch (error) {
            console.log(error.message)
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
        }
    }

    const getChamados = async()=>{
        try {
            const response = await api.get(`/contatos/chamados/${localStorage.getItem('@treinadelta/usuario_id')}`)
            console.log("🚀 ~ file: index.js ~ line 59 ~ getChamados ~ response", response)
            setChamados(response.data)
            setLoading(false)

        } catch (error) {
            console.log("🚀 ~ file: index.js ~ line 80 ~ getChamados ~ error", error)

        }
       
    }

    useEffect(() => {
        getUsuario();
        getChamados()
    }, []);
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    const getUsuario = async () => {
        try {
            const response = await api.get(
                `/usuarios/${localStorage.getItem('@treinadelta/usuario_id')}`
            );
            setCredentials({
                ...credentials,
                nome: response.data.nome,
                email: response.data.email,
                usuario_id: response.data.usuario_id
            });
            // setUsuario(response.data);
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 30 ~ getUsuario ~ error',
                error
            );
        }
    };

    const handleEditUsuario = async (e) => {
        e.preventDefault();
        if(credentials.assunto.length === 0){
            enqueueSnackbar(<h4>{"Por favor insira o conteudo da mensagem."}</h4>, {
                variant: 'error',
            });
        }
        try {
            console.log(credentials);
            setLoading(true)
            const data = {
                assunto: credentials.assunto,
                usuario_id: `${localStorage.getItem(
                    '@treinadelta/usuario_id'
                )}`,
            };

            
            await api.post('/contatos', data);

            enqueueSnackbar(<h4>Usuário editado com sucesso!</h4>, {
                variant: 'success',
            });

            setCredentials({ ...credentials, assunto: '' });
            await delay(100)
            getChamados()
            setLoading(false)
            
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
            console.log(
                '🚀 ~ file: index.js ~ line 30 ~ getUsuario ~ error',
                error
            );
        }
    };

    return (
        <AreaDoAlunoPagesContainer>
            <TitleSubAreaDoAluno>
                <TitlePage
                    breadcrumbs={{
                        link1: `/minhastrilhas`,
                        nome1: 'Home',
                        label: 'Contato',
                    }}
                />
                <TitleAreaDoAluno>Contato</TitleAreaDoAluno>
                <SubtitleAreaDoAluno>
                    Use esta área para contatar os administradores e sanar suas
                    dúvidas.
                </SubtitleAreaDoAluno>
            </TitleSubAreaDoAluno>
            <MeusDadosContainer action="#">
                <InputsWrapperMeusDados>
                    <InputLabel htmlFor="mensagem">Mensagem</InputLabel>
                    <TextArea
                        value={credentials.assunto}
                        id="mensagem"
                        contato
                        onChangeText={(e) =>
                            setCredentials({
                                ...credentials,
                                assunto: e.target.value,
                            })
                        }
                        placeholder="Escreva sua mensagem aqui"
                        secondary
                    />
                </InputsWrapperMeusDados>
                <Button onClick={(e) => handleEditUsuario(e)} big>
                    Enviar Mensagem
                </Button>
                
                {chamados.length == 0 ? "" :
                    <TitleAreaDoAluno  AreaDoAluno chamado>Chamados</TitleAreaDoAluno>
                }
                {loading ? <LoopCircleLoading /> :
                    chamados.map((chamado, index) => (
                        <Ticket
                            handleFecharChamado={handleFecharChamado}
                            key={chamado.contato_id}
                            usuario={credentials.usuario_id}
                            id={chamado.contato_id}
                            historico={chamado.historico}
                            status={chamado.aberto}
                            date={moment(chamado.created_at).format("D MMM, YYYY")}
                            resposta={chamado.ticket_answer}
                            nomeDoUsuario={chamado.nome}
                            foto={chamado.arquivo}
                            descricao={chamado.assunto}
                        />  
                ))}
            </MeusDadosContainer>
        </AreaDoAlunoPagesContainer>
    );
};

export default Contato;
