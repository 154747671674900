import styled from 'styled-components';

export const CardAvaliacaoContainer = styled.div`
    padding: 18px 33px 16px 18px;
    border-radius: 10px;
    background: #413e5380;
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 950px) {
        flex-direction: column;
        padding: 20px;
    }
`;

export const CardAvaliacaoModulo = styled.div`
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.81) 100%
        ),
        url(${({ image }) => image && image});
    border-radius: 5px;
    filter: drop-shadow(3px 4px 6px rgba(0, 0, 0, 0.2));
    height: 181px;
    width: 137px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px 15px;
    @media (max-width: 950px) {
        height: 300px;
        width: 227px;
    }
`;

export const CardAvaliacaoTitle = styled.p`
    font-size: 1.4rem;
    color: #fff;
    font-weight: 500;
`;

export const HoursContainerCardAvaliacao = styled.div`
    display: flex;
    align-items: center;
    opacity: 0.6;
    color: white;
    display: flex;
    align-items: center;
    gap: 3px;
    justify-content: flex-end;
    & span {
        font-size: 1rem;
    }
    & svg {
        height: 12px;
        width: 12px;
    }
    & svg path {
        fill: #fff;
    }
`;

export const CardAvaliacaoModuloInfo = styled.div`
    display: flex;
    flex-direction: column;
    //gap: 5px;
    flex: 1;
    @media (max-width: 840px) {
        width: 100%;
    }
`;

export const TitleAndTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 500px) {
        flex-direction: column;
        margin-bottom: 10px;
        align-items: flex-start;
    }
`;

export const CardAvaliacaoInfoTitle = styled.p`
    font-weight: 500;
    color: #fff;
    font-size: 1.6rem;
    margin-bottom: 5px;
`;

export const InfosWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 15px;
    margin: ${({ cardAvaliacao }) => (cardAvaliacao ? '15px 0' : '')}; ;
`;
