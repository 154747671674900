import React, { useState } from 'react';
import { IconStarRating } from '../../../../../Icons/Icons';
import { StarRatingContainer, StarRatingLabel } from './style';

const StarRating = ({ rating, setRating }) => {
    const [hover, setHover] = useState(null);

    return (
        <StarRatingContainer>
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;

                return (
                    <StarRatingLabel
                        ratingValue={ratingValue}
                        rating={rating}
                        hover={hover}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(null)}
                    >
                        <input
                            type="radio"
                            name="rating"
                            value={ratingValue}
                            onClick={() => setRating(ratingValue)}
                        />
                        <IconStarRating />
                    </StarRatingLabel>
                );
            })}
        </StarRatingContainer>
    );
};

export default StarRating;
