/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import formatBytes from '../../../../helpers/formatBytes';

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {
    Grid,
    FormControlLabel,
    Tooltip,
    Radio,
    RadioGroup,
    Select,
    TextField,
    IconButton,
    Checkbox,
    FormGroup,
    Divider,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Box,
    CardMedia,
    Card,
    CardContent,
    Button,
    CardActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CustomTextField from '../../../../components/admin/CustomTextField';
import IOSSwitch from '../../../../components/admin/IOSwitch';
import { useDropzone } from 'react-dropzone';
import { PrimaryChip } from '../../../../helpers/helper';
import { API_HOST } from '../../../../config_servers';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const QuestaoCaixaSelecao = (props) => {
    const {
        questao,
        index,
        handleChangeQuestao,
        handleAddResposta,
        handleRemoveQuestao,
        handleRemoveResposta,
        classes,
        handleChangeRespostas,
        setFiles,
        files,
        indexSelected,
        setIndexSelected,
    } = props;
    const [fileSelected, setFileSelected] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        getFilesFromEvent: (event) => myCustomFileGetter(event),
        accept: 'image/jpeg, image/png'
    });

    const openMenu = (event, file, index) => {
        setAnchorEl(event.currentTarget);
        setFileSelected(file);
        setIndexSelected(index);
    };

    useEffect(() => {
        setFiles([...files, ...acceptedFiles]);
    }, [acceptedFiles]);

    async function myCustomFileGetter(event) {
        const _files = [];
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, 'posicao', {
                value: Number(files.length) + i + 1,
                writable: true,
            });

            Object.defineProperty(file, 'index', {
                value: indexSelected,
                writable: true,
            });

            
            _files.push(file);
        }

        return _files;
    }

    const removeFile = (file, i) => () => {
        const newFiles = [...files];
        const id = newFiles.indexOf(file);
        newFiles.splice(id, 1);
        setFiles([...newFiles]);

        console.log('files', files);
    };
    const handleDescerPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index < files.length - 1) {
            const arrayNextIndex = newFiles[index + 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao + 1;
            newFiles[index + 1].posicao = newFiles[index + 1].posicao - 1;

            newFiles[index] = arrayNextIndex;
            newFiles[index + 1] = file;
        }

        setFiles([...newFiles]);
    };

    const handleSubirPosicaoDoDocumento = (file, index) => {
        const newFiles = [...files];

        if (index > 0) {
            const arrayPrevIndex = newFiles[index - 1];

            // eslint-disable-next-line operator-assignment
            newFiles[index].posicao = newFiles[index].posicao - 1;
            newFiles[index - 1].posicao = newFiles[index - 1].posicao + 1;

            newFiles[index] = arrayPrevIndex;
            newFiles[index - 1] = file;
        }

        setFiles([...newFiles]);
    };

    return (
        <>
            <Grid className={classes.root} container>
                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item container>
                        <>
                            <Grid
                                item
                                justify="center"
                                alignItems="center"
                                xs={7}
                            >
                                <CustomTextField
                                    style={{ marginTop: 17 }}
                                    label={`Questão ${index + 1}`}
                                    name="titulo"
                                    margin="normal"
                                    value={questao.titulo}
                                    multiline
                                    rowsMax={15}
                                    onChange={(e) =>
                                        handleChangeQuestao(index, e)
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item xs={3}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel htmlFor="age-native-simple">
                                            Selecione o formato da questão
                                        </InputLabel>
                                        <Select
                                            margin="normal"
                                            value={questao.tipo_questao}
                                            onChange={(e) =>
                                                handleChangeQuestao(index, e)
                                            }
                                            inputProps={{
                                                name: 'tipo_questao',
                                                id: 'age-native-simple',
                                            }}
                                        >
                                            <MenuItem value="multipla_escolha">
                                                <RadioButtonCheckedIcon
                                                    fontSize="small"
                                                    style={{
                                                        color: 'gray',
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        marginLeft: 6,
                                                    }}
                                                >
                                                    Múltipla escolha
                                                </span>
                                            </MenuItem>
                                            <MenuItem value="caixa_selecao">
                                                <CheckBoxIcon
                                                    fontSize="small"
                                                    style={{
                                                        color: 'gray',
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        marginLeft: 6,
                                                    }}
                                                >
                                                    Caixa de seleção
                                                </span>
                                            </MenuItem>
                                            <MenuItem value="dissertativo">
                                                <CreateIcon
                                                    fontSize="small"
                                                    style={{
                                                        color: 'gray',
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        marginLeft: 6,
                                                    }}
                                                >
                                                    Dissertativo
                                                </span>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Tooltip title="Remover questão" placement="top">
                                <IconButton
                                    style={{
                                        marginBottom: -15,
                                        marginLeft: 4,
                                    }}
                                    aria-label="delete"
                                    onClick={() => handleRemoveQuestao(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <FormGroup style={{ marginLeft: 16 }}>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={!!questao.obrigatoria}
                                            value={questao.obrigatoria}
                                            onChange={(e) =>
                                                handleChangeQuestao(index, e)
                                            }
                                            name="obrigatoria"
                                        />
                                    }
                                    label="Obrigatória"
                                    labelPlacement="top"
                                />
                            </FormGroup>
                        </>
                    </Grid>
                </Grid>

                <Grid
                    style={{ marginTop: 16, width: '100%' }}
                    item
                    container
                    xs={8}
                    direction="row"
                    spacing={2}
                    justify="flex-start"
                    alignItems="center"
                >
                    <RadioGroup
                        aria-label="gender"
                        name="multipla_escolha_correta"
                        style={{ width: '100%' }}
                        row
                        value={questao.multipla_escolha_correta}
                        onChange={(e) => handleChangeQuestao(index, e)}
                    >
                        {questao.respostas?.map((resposta, indexResposta) => (
                            <>
                                {!resposta.remover &&
                                    (questao.tipo_questao === 'caixa_selecao' ||
                                        questao.tipo_questao ===
                                            'multipla_escolha') && (
                                        <>
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                justify="flex-start"
                                                alignItems="flex-start"
                                                xs={6}
                                            >
                                                {questao.tipo_questao ===
                                                    'caixa_selecao' && (
                                                    <Checkbox
                                                        style={{
                                                            marginLeft: -6,
                                                        }}
                                                        name="checkbox_resposta_correta"
                                                        onChange={(e) =>
                                                            handleChangeRespostas(
                                                                e,
                                                                index,
                                                                indexResposta
                                                            )
                                                        }
                                                        checked={
                                                            resposta.checkbox_resposta_correta
                                                        }
                                                        color="primary"
                                                    />
                                                )}

                                                {questao.tipo_questao ===
                                                    'multipla_escolha' && (
                                                    <FormControlLabel
                                                        value={indexResposta}
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                style={{
                                                                    marginLeft: 7,
                                                                }}
                                                            />
                                                        }
                                                        checked={
                                                            String(
                                                                questao.multipla_escolha_correta
                                                            ) ===
                                                            String(
                                                                indexResposta
                                                            )
                                                        }
                                                    />
                                                )}

                                                <TextField
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.inputRoot,
                                                        },
                                                    }}
                                                    style={{
                                                        marginLeft: 33,
                                                        marginTop: -35,
                                                    }}
                                                    name="texto"
                                                    value={resposta.texto}
                                                    multiline
                                                    rowsMax={15}
                                                    onChange={(e) =>
                                                        handleChangeRespostas(
                                                            e,
                                                            index,
                                                            indexResposta
                                                        )
                                                    }
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid>
                                                <Tooltip
                                                    title="Remover resposta"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        style={{
                                                            marginLeft: 24,
                                                            marginTop: 4,
                                                        }}
                                                        aria-label="delete"
                                                        onClick={() =>
                                                            handleRemoveResposta(
                                                                index,
                                                                indexResposta
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </>
                                    )}

                                {!resposta.remover &&
                                    questao.tipo_questao === 'dissertativo' && (
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            justify="flex-start"
                                            alignItems="flex-start"
                                            xs={8}
                                        >
                                            <CustomTextField
                                                style={{
                                                    marginTop: -8,
                                                    marginLeft: 6,
                                                    marginBottom: 32,
                                                }}
                                                label="Resposta"
                                                name="texto"
                                                margin="normal"
                                                value={resposta.texto}
                                                multiline
                                                rowsMax={15}
                                                onChange={(e) =>
                                                    handleChangeRespostas(
                                                        e,
                                                        index,
                                                        indexResposta
                                                    )
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                            </>
                        ))}

                        {(questao.tipo_questao === 'caixa_selecao' ||
                            questao.tipo_questao === 'multipla_escolha') && (
                            <Grid container>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontSize: 15,
                                        marginLeft: 8,
                                        marginTop: -4,
                                        marginBottom: -130,
                                    }}
                                    component="p"
                                >
                                    Adicionar resposta
                                    <IconButton
                                        aria-label="add"
                                        onClick={() => handleAddResposta(index)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Typography>
                            </Grid>
                        )}
                    </RadioGroup>
                       
                    
                </Grid>
                
            </Grid>
            <Grid container >
                        <Grid item xs={12}>
                            <div
                                style={{ marginTop: 60 }}
                                {...getRootProps({
                                    className: classes.dropZone,
                                })}
                            >
                                <input {...getInputProps()} />
                                <Typography
                                    variant="subtitle1"
                                    component="p"
                                    gutterBottom={false}
                                >
                                    Arraste ou clique para adicionar imagens
                                    da questão {index + 1}
                                </Typography>
                            </div>

                            <Box
                                style={{ marginTop: 32, marginLeft: 45 }}
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid container spacing={3}
                                    justifyContent="center"
                                    alignItems="center"
                                >

                                    {files.map((file, i) => (
                                        <>
                                            {file.index === index && (
                                                <Grid item sm={4} xs={12}>
                                                    <Box
                                                        border={1}
                                                        borderColor="#DCDCDC"
                                                    >
                                                        <Card
                                                            classes={{
                                                                root: classes.card,
                                                            }}
                                                        >
                                                            <CardMedia
                                                                className={
                                                                    classes.media
                                                                }
                                                                image={file
                                                                    .conteudo_id
                                                                    ? `${API_HOST}/assets/conteudos/${file.arquivo}`
                                                                    : URL.createObjectURL(
                                                                        file
                                                                    )
                                                                }
                                                                title=""
                                                            />

                                                            <CardContent>
                                                                {/* <img
                                                                    alt='imagem da questao'
                                                                    style={{
                                                                        maxHeight: '100%',
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    src={
                                                                        file
                                                                            .conteudo_id
                                                                            ? `${API_HOST}/assets/conteudos/${file.arquivo}`
                                                                            : URL.createObjectURL(
                                                                                file
                                                                            )
                                                                    }
                                                                /> */}
                                                                <Grid container>
                                                                    <Grid item xs={6}>
                                                                        <Typography
                                                                            variant="h5"
                                                                            component="h5"
                                                                            gutterBottom={
                                                                                false
                                                                            }
                                                                        >
                                                                            {file.name}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            component="h6"
                                                                            gutterBottom={
                                                                                false
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        align="right"
                                                                    >
                                                                        <Tooltip
                                                                            title="Mudar posição"
                                                                            placement="top"
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleSubirPosicaoDoDocumento(
                                                                                        file,
                                                                                        i
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ChevronLeftIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title="Mudar posição"
                                                                            placement="top"
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleDescerPosicaoDoDocumento(
                                                                                        file,
                                                                                        i
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ChevronRightIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title="Remover conteúdo"
                                                                            placement="top"
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                edge="end"
                                                                                aria-label="delete"
                                                                                onClick={removeFile(
                                                                                    file,
                                                                                    i
                                                                                )}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            component="h6"
                                                                            gutterBottom={
                                                                                false
                                                                            }
                                                                        >
                                                                            {formatBytes(
                                                                                file.size
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                            <Divider
                                                                classes={{
                                                                    root: classes.divider,
                                                                }}
                                                            />
                                                        </Card>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </>
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
            <Grid container item xs={12}>
               
            </Grid>
        </>
    );
};

export default QuestaoCaixaSelecao;
