import styled from 'styled-components';

export const GridInfoModalInicioAvaliacao = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ iniciarTentativa }) => (iniciarTentativa ? "space-between" : "center")};;
    flex-wrap: wrap;
    gap: 40px;
    /* margin: 30px 0; */
    padding: ${({ iniciarTentativa }) => (iniciarTentativa ? "0" : "0 30px")};;
    @media (max-width: 400px) {
        margin: 0;
    }
`;
