import React from 'react';
import {
    IconJpg,
    IconPdf,
    IconPng,
    IconPpt,
} from '../../../../../components/aluno/Icons/Icons';
import { API_HOST } from '../../../../../config_servers';
import FileSaver from 'file-saver';
import api from '../../../../../services/api';
import {
    AttachmentDescription,
    AttachmentsWrapper,
    BGAttachment,
    IconAttachment,
    TitleAttachment,
    TitleSubtitle,
} from './style';

const Attachments = ({ title, description, type, nome_arquivo, conteudo }) => {
    const renderIcon = () => {
        if (type === 'image/jpeg') {
            return <IconJpg />;
        }
        if (type === 'application/vnd.ms-powerpoint') {
            return <IconPpt />;
        }
        if (type === 'application/pdf') {
            return <IconPdf />;
        }
        return <IconPng />;
    };
    const handleDownloadArquivo = async (name) => {
        FileSaver.saveAs(`${API_HOST}/assets/conteudos/${nome_arquivo}`, name);
        await api.post(`conteudos/atualiza/download/${conteudo.conteudo_id}`, {modulo_id: conteudo.modulo_id});
    };

    return (
        <AttachmentsWrapper onClick={() => handleDownloadArquivo(title)}>
            <BGAttachment />
            <IconAttachment>{renderIcon()}</IconAttachment>
            <TitleSubtitle>
                <TitleAttachment>{title}</TitleAttachment>
                <AttachmentDescription>{description}</AttachmentDescription>
            </TitleSubtitle>
        </AttachmentsWrapper>
    );
};

export default Attachments;
