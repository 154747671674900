import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';

export const ContainerTitlePage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-self: flex-start;
    margin-top: 30px;
    ${({ questoes }) => (questoes > 0 ? 'margin-bottom: 50px' : '')};
`;

export const TagTitle = styled.div`
    color: #333247;
    background: #ffffff;
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 1.4rem;
    width: fit-content;
    opacity: 0.3;
`;

export const Title = styled.h1`
    font-size: 6.5rem;
    color: white;
    font-size: 7.2rem;
    background: -webkit-linear-gradient(-0deg, #8fd2ec 1.01%, #ffffff 68.6%);
    -webkit-background-clip: text !important;
    background-clip: unset;
    -webkit-text-fill-color: transparent;
    line-height: 1.25;
    margin-left: -4px;
    max-width: 510px;
    overflow-wrap: ${({ Avaliacao }) => (Avaliacao ? 'anywhere' : '')};
    @media (max-width: 400px) {
        font-size: 5.5rem;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: flex-start;
    & svg path {
        fill: ${colors.primary};
    }
    & span {
        display: flex;
        gap: 5px;
        font-size: 1.6rem;
        font-weight: 200;
        color: #fff;
        white-space: nowrap;
        line-height: 1;
        margin-top: 3px;
    }
`;

export const InfoSubContainer = styled.div`
    display: flex;
    gap: 40px;
    @media (max-width: 850px) {
        flex-direction: column;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.6rem;
    font-weight: 200;
    color: #fff;
`;

export const ModuloInfo = styled.div`
    display: flex;
    gap: 25px;
`;
