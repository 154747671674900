module.exports = {
    STATUS_COMENTARIO_NAO_PUBLICADO: 0,
    STATUS_COMENTARIO_APROVADO: 1,
    STATUS_COMENTARIO_REPROVADO: 2,

    STATUS_AVALIACAO_NAO_FINALIZADA: 0,
    STATUS_AVALIACAO_FINALIZADA: 1,
    STATUS_AVALIACAO_PENDENTE_CORRECAO: 2,

    STATUS_QUESTAO_DISSERTATIVA_APROVADA: 1,
    STATUS_QUESTAO_DISSERTATIVA_ERRADA: 2,

    PERFIL_ADMIN: 1,
    PERFIL_RH: 2,
    PERFIL_QUALIDADE: 3,
    PERFIL_PRODUTOR: 4,
    PERFIL_ALUNO: 5,
    PERFIL_DIRETORIA: 6,
};
