import styled from 'styled-components';

export const StarRatingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const StarRatingLabel = styled.label`
    cursor: pointer;
    & input {
        display: none;
    }
    & svg path {
        transition: all 0.3s ease;
        fill: ${({ ratingValue, rating, hover }) =>
            ratingValue <= (hover || rating) ? '#FFCF02' : '#33324726'};
    }
`;
