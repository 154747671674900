import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import {
    AdditionalInfo,
    Info,
    InfoLabel,
    InfoText,
} from '../../../Trilha/components/InfoTrilha/style';
import { InfoAvaliacaoContainer } from './style';
import Button from '../../../../../components/aluno/Button';
import Modal from '../../../../../components/aluno/Modal';
import ModalAvaliacao from './components/ModalAvaliacao';
import api from '../../../../../services/api';
import ReactStopwatch from 'react-stopwatch';
import ModalEntregarAvaliacao from '../../../Trilha/components/confirmarAvaliacaoModal'
function InfoAvaliacao(props) {
    const {
        questoesRespondidas,
        qtdQuestoes,
        numeroTentativa,
        dataInicio,
        duracao,
        questoes,
        avaliacao,
        identificadorAvaliacao,
        horas,
        setHoras,
        minutos,
        setMinutos,
        segundos,
        setSegundos,
    } = props;

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const info = useRef();
    const [openModalEntregarAvaliacao, setOpenModalEntregarAvaliacao] =
        useState(false);

    const toggleOpenModalEntregarAvaliacao = () => {
        if (openModalEntregarAvaliacao === false) {
            document.body.style.overflowY = 'hidden';
            setOpenModalEntregarAvaliacao(true);
        } else {
            document.body.style.overflowY = 'overlay';
            setOpenModalEntregarAvaliacao(false);
        }
    };

    const handleModalEntregarAvaliacao = (value) => {
        setOpenModalEntregarAvaliacao(value);
    };

    const [openModal, setOpenModal] = useState(false);
    const toggleOpen = () => {
        if (openModal === false) {
            document.body.style.overflowY = 'hidden';
            setOpenModal(true);
        } else {
            document.body.style.overflowY = 'overlay';
            setOpenModal(false);
        }
    };

    const handleModal = (value) => {
        setOpenModal(value);
    };
    const handleScroll = () => {
        const position = window.scrollY;
        if (info.current) {
            if (
                window.innerHeight + position >=
                document.body.clientHeight - 150
            ) {
                window.innerHeight >= 768 ?
                info.current.style.transform = `translateY(${
                    position - 150
                }px)`
                : info.current.style.transform = `translateY(${
                    position - 400
                }px)`
            } else if (position >= 100) {
                info.current.style.transform = `translateY(${
                    position - 100
                }px)`;
            } else if (position <= 200) {
                info.current.style.transform = `translateY(0px)`;
            }
        }
    };

    const Stopwatch = () => (
        <ReactStopwatch
            seconds={segundos}
            minutes={minutos}
            hours={horas}
            limit={`${avaliacao.duracao}:00`}
            onChange={({ hours, minutes, seconds }) => {
                setHoras(hours);
                setMinutos(minutes);
                setSegundos(seconds);
            }}
            onCallback={() => {
                handleEnviarAvaliacao();
            }}
            render={({ formatted, hours, minutes, seconds }) => {
                return <h4>{formatted}</h4>;
            }}
        />
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleEnviarAvaliacao = async () => {
        try {
            const segundosNasHoras = horas * 60 * 60;
            const segundosNosMinutos = minutos * 60;
            await api.post('/avaliacoes/enviarAvaliacao', {
                questoes,
                avaliacao_id: avaliacao.avaliacao_id,
                usuario_id: localStorage.getItem('@treinadelta/usuario_id'),
                identificador_avaliacao: identificadorAvaliacao,
                duracao_segudos:
                    segundosNasHoras + segundosNosMinutos + segundos,
            });
            enqueueSnackbar(<h4>Avaliação enviada com sucesso!</h4>, {
                variant: 'success',
            });
            history.push('/areadoaluno/minhasavaliacoes');
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
        }
    };
    return (
        <>
            <InfoAvaliacaoContainer ref={info}>
                <Info>
                    <InfoLabel>Data da Avaliação</InfoLabel>
                    <InfoText>
                        {moment(dataInicio)
                            .subtract('3', 'hours')
                            .format('LLL')}
                    </InfoText>
                </Info>
                <Info>
                    <InfoLabel>Tentativa</InfoLabel>
                    <InfoText>{numeroTentativa}ª</InfoText>
                </Info>
                <Info>
                    <InfoLabel>Tempo de Resposta</InfoLabel>
                    <InfoText>
                        <Stopwatch />
                    </InfoText>
                </Info>
                <Info>
                    <InfoLabel>Falta Responder para a entrega</InfoLabel>
                    <InfoText>
                        {qtdQuestoes - questoesRespondidas < 0
                            ? 0
                            : qtdQuestoes - questoesRespondidas}
                        /{qtdQuestoes}
                    </InfoText>
                </Info>
                <Info>
                    <InfoLabel>Tempo total</InfoLabel>
                    <InfoText>{`${avaliacao.duracao}:00`}</InfoText>
                </Info>
                <Button onClick={toggleOpenModalEntregarAvaliacao}>
                    Entregar Avaliação
                </Button>
                {/* <AdditionalInfo>
                    Lorem ipsum dolor sit amet, consectetur adipi scing elit.
                    Sed amet condimentum nunc sit ipsum etiam phasellus.
                </AdditionalInfo> */}
            </InfoAvaliacaoContainer>
            <Modal openModal={openModal} handleModal={handleModal}>
                <ModalAvaliacao
                    handleModal={handleModal}
                    openModal={openModal}
                />
            </Modal>

            <Modal
                openModal={openModalEntregarAvaliacao}
                handleModal={handleModalEntregarAvaliacao}
            >
                <ModalEntregarAvaliacao
                    avaliacao={avaliacao}
                    tempoDuracao={`${horas < 10 ? '0'+horas : horas}:${minutos < 10 ? '0'+minutos : minutos}:${segundos < 10 ? '0'+ segundos : segundos}`}
                    handleModal={handleModalEntregarAvaliacao}
                    openModal={openModalEntregarAvaliacao}
                    qtdQuestoes={qtdQuestoes}
                    questoesRespondidas={questoesRespondidas}
                    handleEnviarAvaliacao={handleEnviarAvaliacao}
                />
            </Modal>
        </>
    );
}

export default InfoAvaliacao;
