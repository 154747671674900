import React from 'react';
import { Chip } from '@material-ui/core';
import api from '../services/api';

export const getMoneyFieldsUnmask = (fields) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in fields) {
        if (fields[prop] && fields[prop] !== '') {
            // eslint-disable-next-line no-param-reassign
            fields[prop] = fields[prop]
                .toString()
                .replace(/\./g, '')
                .replace(',', '.');
        }
    }
    return fields;
};

export const getMoneyFieldUnmask = (number) => {
    return number.toString().replace(/\./g, '').replace(',', '.');
};

export const PrimaryChip = ({ texto, ...rest }) => {
    return (
        <Chip
            color="primary"
            style={{
                fontWeight: '500',
            }}
            size="small"
            label={texto}
            {...rest}
        />
    );
};

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const ErrorChip = ({ texto, ...rest }) => {
    return (
        <Chip
            style={{
                fontWeight: '500',
                color: 'white',
                backgroundColor: '#FD7B72',
            }}
            size="small"
            label={texto}
            {...rest}
        />
    );
};

export const SuccessChip = ({ texto, ...rest }) => {
    return (
        <Chip
            style={{
                fontWeight: '500',
                color: 'white',
                backgroundColor: '#4BCA81',
            }}
            size="small"
            label={texto}
            {...rest}
        />
    );
};

export const getPerfilById = (id) => {
    try {
        const response = api.get(`/perfis/usuario/${id}`);
        return response;
    } catch (error) {
        if (error.response) {
            return 'Erro';
        }
    }
}