/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/styles';
import RoutesAdmin from './routes/RoutesAdmin';
import RoutesAluno from './routes/RoutesAluno';
import api from './services/api';
import theme from './themeMaterialUi';
import { setAuthSession, removeAuthSession, getToken } from './services/auth';
import GlobalStyle from './assets/theme/globalStyle';
import { SnackbarProvider } from 'notistack';
import moment from 'moment-timezone';
import { useUsuarioContext } from './contexts/UserContext';
import constants from './helpers/constants';

moment.tz.setDefault('America/Sao_Paulo');

export default function App() {
    const [authLoading, setAuthLoading] = useState(true);
    const { setImagemUsuario, insertLogUserFimTempoLogado } = useUsuarioContext();

    useEffect(() => {
        const token = getToken();

        if (!token) {
            return;
        }

        api.get('/verificar_token')
            .then((response) => {
                if (window.location.pathname.includes('/admin')) {
                    if (response.data.perfil_id === constants.PERFIL_ALUNO) {
                        insertLogUserFimTempoLogado().then(function() {
                            removeAuthSession();
                            setAuthLoading(false);
                        })
                        return;
                    } 
                } 
                setAuthSession(
                    response.data.token,
                    response.data.usuario_id,
                    response.data.acessos,
                    response.data.arquivo
                );
                setImagemUsuario(response.data.arquivo);
                setAuthLoading(false);
            })
            .catch(() => {
                removeAuthSession();
                setAuthLoading(false);
            });
    }, []);

    if (authLoading && getToken()) {
        <Backdrop
            open
            style={{
                backgroundColor: '#FFFFFF',
                color: 'primary',
            }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    return (
        <>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {!window.location.pathname.includes('/admin') ? (
                    <RoutesAluno />
                ) : (
                    <ThemeProvider theme={theme}>
                        <RoutesAdmin />;
                    </ThemeProvider>
                )}
            </SnackbarProvider>
        </>
    );
}
