import React, { useState, useEffect } from 'react';
import CardVideo from '../../Trilha/components/CardVideo';
import {
    AreaDoAlunoPagesContainer,
    SubtitleAreaDoAluno,
    TitleAreaDoAluno,
    TitleSubAreaDoAluno,
    VideosDateContainer,
    VideosWrapper,
} from '../style';
import { API_HOST } from '../../../../config_servers';
import moment from 'moment';
import VideoPlayer from '../../../../components/aluno/VideoPlayer';
import TitlePage from '../../../../components/aluno/TitlePage';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../../services/api';
import { LoopCircleLoading  } from 'react-loadingg';
import NenhumDado from '../../../../components/aluno/NenhumDado';


function MeuHistorico() {
    const history = useHistory()
    const location = useLocation()
    const [videos, setVideos] = useState([]);
    const [videoSelecionado, setVideoSelecionado] = useState({});
    const [arrayReprodutionList, setArrayReprodutionList] = useState([]);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getVideosAssistidosPorAluno();
    }, []);

    const getVideosAssistidosPorAluno = async () => {
        try {
            const response = await api.get(
                `/modulos/videosAssistidosPorAluno/${localStorage.getItem(
                    '@treinadelta/usuario_id'
                )}`
            );

            setVideos(response.data);
            setLoading(false)
        } catch (error) {
            console.log(
                '🚀 ~ file: index.js ~ line 73 ~ getVideosAssistidosPorAluno ~ error',
                error
            );
        }
    };

    return (
        <>
            <AreaDoAlunoPagesContainer>
                {loading ? <LoopCircleLoading /> :
                    <>
                        <TitleSubAreaDoAluno>
                            <TitlePage
                                breadcrumbs={{
                                    link1: `/minhastrilhas`,
                                    nome1: 'Home',
                                    label: 'Meu Histórico',
                                }}
                            />
                            <TitleAreaDoAluno>Meu Histórico</TitleAreaDoAluno>
                            <SubtitleAreaDoAluno>
                                Acompanhe seu histórico de vídeos assistidos.
                            </SubtitleAreaDoAluno>
                        </TitleSubAreaDoAluno>
                        <VideosDateContainer>
                            {/* <VideosDate>22 de Abril de 2021</VideosDate> */}
                            <VideosWrapper> 
                            {
                              videos && videos.length > 0 ?  videos.map((item, index) => {
                                    return (
                                        
                                        <CardVideo
                                            key={item.conteudo_id}
                                            breadcrumbs={{
                                                link1: `/minhastrilhas`,
                                                nome1: `Minhas trilhas`,
                                                link2: `/minhastrilhas`,
                                                nome2: item.modulo.nome_trilha,
                                                link3: `/trilhas/${item.trilha_id}/modulos/${item.modulo_id}`,
                                                nome3: item.modulo.nome,
                                                // label: item.nome_arquivo,
                                            }}
                                            index={index + 1}
                                            videoUrl={`${API_HOST}/assets/conteudos/${item.arquivo}`}
                                            title={item.nome_arquivo}
                                            duration={
                                                item.duracao_video_segundos > 3600
                                                    ? moment
                                                        .utc(
                                                            item.duracao_video_segundos *
                                                                1000
                                                        )
                                                        .format('HH:mm:ss')
                                                    : moment
                                                        .utc(
                                                            item.duracao_video_segundos *
                                                                1000
                                                        )
                                                        .format('mm:ss')
                                            }
                                            description={item.descricao}
                                            progress={item.porcentagem_conclusao * 100}
                                            areaDoAluno
                                            setIsVideoOpen={setIsVideoOpen}
                                            video={item}
                                            videoSelecionado={videoSelecionado}
                                            setVideoSelecionado={setVideoSelecionado}
                                            videos={videos}
                                            arrayReprodutionList={arrayReprodutionList}
                                            setArrayReprodutionList={
                                                setArrayReprodutionList
                                            }
                                        />
                                    );
                                })
                                : 
                                <>
                                    <NenhumDado text={`Você ainda não possui nenhum histórico de vídeo assistido.`} />
                                </>
                            }
                                </VideosWrapper>
                        </VideosDateContainer>
                    </>
                }
            </AreaDoAlunoPagesContainer>

-            {isVideoOpen && (
                <VideoPlayer
                    backButton={() => {
                        setIsVideoOpen(false);
                        getVideosAssistidosPorAluno();
                        history.push(`/areadoaluno/meuhistorico`)
                    }}
                    modulo={videoSelecionado.modulo}
                    videoSelecionado={videoSelecionado}
                    setVideoSelecionado={setVideoSelecionado}
                    videos={videoSelecionado.videos}
                    arrayReprodutionList={arrayReprodutionList}
                    setArrayReprodutionList={setArrayReprodutionList}
                    breadcrumbs={{
                        link1: `/minhastrilhas`,
                        nome1: `Minhas trilhas`,
                        link2: `/trilhas/${videoSelecionado?.trilha_id}/modulos/${videoSelecionado?.modulo_id}`,
                        nome2: videoSelecionado?.modulo?.nome,
                        label: videoSelecionado?.nome_arquivo,
                    }}
                    isMeuHistorico={true}
                />
            )}
        </>
    );
}

export default MeuHistorico;
