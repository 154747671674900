/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Box,
    Grid,
    FormControlLabel,
    FormGroup,
    Button,
    CardContent,
    Card,
    TextField,
    MenuItem,
    Breadcrumbs,
    Link,
    Typography,
} from '@material-ui/core';
import JoditEditor from "jodit-react";

import { useSnackbar } from 'notistack';
import { useHistory, Link as RouterLink, useParams } from 'react-router-dom';
import moment from 'moment';
import CustomTextField from '../../../components/admin/CustomTextField';
import CustomKeyboardDatePicker from '../../../components/admin/CustomKeyboardDatePicker';
import IOSSwitch from '../../../components/admin/IOSwitch';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '13px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

const AlertaEdit = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles();
    const { id } = useParams();

    const [alert, setAlert] = useState({});
    const [validationErrors] = useState({});
    const [grupos, setGrupos ]=useState([])
    const [corpo, setCorpo] = useState('')
    const [categorias, setCategorias] = useState([]);
    const [config] = useState({
        height: '300px',
        placeholder: 'Digite sua descrição aqui.',
        uploader: {
            url:"https://xdsoft.net/jodit/connector/index.php",
          },
          filebrowser: {
            ajax: {
              url: "https://xdsoft.net/jodit/connector/index.php",
            },
            uploader: {
               url:"https://xdsoft.net/jodit/connector/index.php",
            },
          },
          enableDragAndDropFileToEditor: true,
          uploader: {
            insertImageAsBase64URI: true,

          },
      })

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAlert({ ...alert, [name]: value });
    };

    const handleGetAlert = async () => {
        const response = await api.get(`/alertas/${id}`);
        setAlert(response.data);
        setCorpo(response.data.conteudo)
    };

    const getCategorias = async () => {
        try {
            const response = await api.get('/categorias');
            setCategorias(response.data);

            console.log(
                '🚀 ~ file: GrupoList.js ~ line 40 ~ getCategorias ~ response.data.data',
                response.data
            );
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao buscar categoria', {
                    variant: 'error',
                });
            }
        }
    };

    const getGrupos = async () => {
        try {
            const response = await api.get('/grupos')
            setGrupos(response.data)
        } catch (error) {
            enqueueSnackbar('Falha ao buscar grupos', {
                variant: 'error',
            });
        }
    }

    useEffect(() => {
        getCategorias();
        getGrupos()
        handleGetAlert();
    }, []);

    const handleCreateAlerta = async () => {
        try {
            await api.put(`/alertas/${id}`, {...alert, conteudo: corpo});
            enqueueSnackbar('Alerta editado com sucesso!', {
                variant: 'success',
            });
            history.push('/admin/alertas');
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao editar alerta', {
                    variant: 'error',
                });
            }
        }
    };

    const handlePageAnteriro = async () => {
        history.goBack();
    };

    return (
        <div className={classes.content}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/alertas"
                >
                    alertas
                </Link>
                <Typography className={classes.breadCrumb}>
                    Editar alerta
                </Typography>
            </Breadcrumbs>
            <Typography style={{ padding: 16 }} variant="h3" component="h1">
                Editar alerta
            </Typography>
            <CardContent>
                <Card>
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={alert.ativo}
                                            value={alert.ativo}
                                            onChange={(event) =>
                                                setAlert({
                                                    ...alert,
                                                    ativo: event.target.checked,
                                                })
                                            }
                                            name="status"
                                        />
                                    }
                                    label="Alerta ativo"
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Grid>

                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Nome"
                                    name="nome"
                                    margin="normal"
                                    value={alert.nome}
                                    error={validationErrors.nome}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={5}>
                                <CustomTextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Descrição"
                                    name="conteudo"
                                    margin="normal"
                                    multiline="true"
                                    value={alert.conteudo}
                                    error={validationErrors.Alert}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid> */}

                            <Grid item xs={12} md={5}>
                                <CustomKeyboardDatePicker
                                    label="Data de início"
                                    value={
                                        alert.data_inicio
                                            ? alert.data_inicio
                                            : null
                                    }
                                    onChange={(val) =>
                                        setAlert({
                                            ...alert,
                                            data_inicio: val
                                                ? moment(val).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : null,
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <CustomKeyboardDatePicker
                                    label="Data de final"
                                    value={
                                        alert.data_final
                                            ? alert.data_final
                                            : null
                                    }
                                    onChange={(val) =>
                                        setAlert({
                                            ...alert,
                                            data_final: val
                                                ? moment(val).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : null,
                                        })
                                    }
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={5}>
                                <CustomTextField
                                    InputLabelProps={{ shrink: true }}
                                    id="standard-number"
                                    label="Quantidade de disparos"
                                    type="number"
                                    name="qtd_disparos"
                                    margin="normal"
                                    value={alert.qtd_disparos}
                                    error={validationErrors.Alert}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    InputLabelProps={{ shrink: true }}
                                    id="standard-number"
                                    label="Intervalo"
                                    name="intervalo"
                                    type="number"
                                    margin="normal"
                                    value={alert.intervalo}
                                    error={validationErrors.Alert}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    id="time"
                                    name="hora_envio"
                                    value={alert.hora_envio}
                                    label="Hora de início"
                                    style={{ width: '90%', marginTop: 16 }}
                                    type="time"
                                    onChange={handleInputChange}
                                    defaultValue={alert.hora_envio}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Grid> */}

                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    select
                                    label="Categoria"
                                    name="categoria_id"
                                    value={Number(alert.categoria_id)}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    fullWidth
                                >
                                    {categorias.map((categoria) => (
                                        <MenuItem
                                            value={categoria.categoria_id}
                                        >
                                            {categoria.nome}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        select
                                        label="Grupos"
                                        name="grupo_id"
                                        value={alert.grupo_id}
                                        error={validationErrors.perfil}
                                        onChange={handleInputChange}
                                        margin="normal"
                                        fullWidth
                                    >
                                        {grupos.map((grupo) => (
                                            <MenuItem
                                                value={grupo.grupo_id}
                                            >
                                                {grupo.nome}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>
                        </Grid>
                        <Grid style={{marginTop: "4%"}}>
                            <Typography>Descrição</Typography>
                            <JoditEditor
                                value={corpo}
                                placeholder="TEXD"
                                config={config}
                                tabIndex={1}
                                onChange={newContent => setCorpo(newContent)}
                            /> 
                        </Grid>
                        <Grid
                            container
                            justify="flex-end"
                            style={{ marginTop: 90 }}
                        >
                            <Button
                                color="default"
                                onClick={handlePageAnteriro}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleCreateAlerta}
                                variant="contained"
                                color="primary"
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Box>
                </Card>
            </CardContent>
        </div>
    );
};

export default AlertaEdit;
