import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { LoginContainer } from '../Login/style';
import { RedefinaContainer, RedefinaTitleContainer } from './style';
import { TitleCard } from '../MinhasTrilhas/components/ModuloCard/style';
import TextInput from '../../../components/aluno/Inputs/TextInput';
import Button from '../../../components/aluno/Button'
import api from '../../../services/api';
import { getUsuarioId } from '../../../services/auth';

const MudarSenha = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const history = useHistory()

    const handleTrocarSenha = async (event) => {
        try {
            event.preventDefault();
            if (!password || !confirmPassword) {
                enqueueSnackbar(<h4>Preencha os campos corretamente!</h4>, {
                    variant: 'error',
                });
                return;
            }

            if (password !== confirmPassword) {
                enqueueSnackbar(<h4>As senhas digitadas não coincidem!</h4>, {
                    variant: 'error',
                });
                return;
            }

            await api.post('/trocar_senha_primeiro_acesso', {
                usuario_id: getUsuarioId(),
                senha: password,
            });

            enqueueSnackbar(<h4>Senha alterada com sucesso!</h4>, { variant: 'success' });
            history.push('/');
        } catch (error) {
            enqueueSnackbar(<h4>Tente novamente!</h4>, { variant: 'error' });
        }
    };

    return (
        <LoginContainer mudarSenha>
            <RedefinaContainer>
                <RedefinaTitleContainer>
                    <div>
                        <span>Primeiro Acesso</span>
                        <TitleCard>Redefina a sua senha</TitleCard>
                    </div>
                    <form onSubmit={handleTrocarSenha}>
                        <TextInput
                            type="password"
                            label="Nova senha"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            value={password}
                            validation={password.length > 0 ? true : null}

                        />
                        <TextInput
                            type="password"
                            label="Confirme a nova senha"
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            value={confirmPassword}
                            validation={confirmPassword.length > 0 ? true : null}

                        />
                        <Button type="submit" big>SALVAR</Button>
                    </form>
                </RedefinaTitleContainer>
            </RedefinaContainer>
        </LoginContainer>
    );
};

export default MudarSenha;
