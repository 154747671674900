/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react'; //
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Collapse, Button, colors } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import DashboardIcon from '@material-ui/icons/Dashboard';

import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';

import { hasAccess } from '../../../../../../services/auth';

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main,
        },
        // filter: 'brightness(3px)',
    },
    expand: {
        width: '16px',
        height: '16px',
        marginLeft: 'auto',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

const SidebarNav = (props) => {
    const { menu, className, ...rest } = props;

    const classes = useStyles();
    const [openUsuario, setOpenUsuario] = useState(false);
    const [openConteudo, setOpenConteudo] = useState(false);
    const [openConfiguracoes, setOpenConfiguracoes] = useState(false);

    return (
        <List {...rest} className={clsx(classes.root, className)}>
            <>
                {/* {hasAccess('Dashboard', 'Dashboard') && ( */}
                <ListItem className={classes.item} disableGutters>
                    <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        exact
                        to="/admin/"
                    >
                        <div className={classes.icon}>
                            <DashboardIcon />
                        </div>
                        Dashboard
                    </Button>
                </ListItem>

                {hasAccess('Usuario') && (
                    <>
                        <ListItem className={classes.item} disableGutters>
                            <Button
                                className={classes.button}
                                onClick={() => setOpenUsuario(!openUsuario)}
                            >
                                <div className={classes.icon}>
                                    <PersonIcon />
                                </div>
                                Usuário
                                {openUsuario ? (
                                    <ExpandLess className={classes.expand} />
                                ) : (
                                    <ExpandMore className={classes.expand} />
                                )}
                            </Button>
                        </ListItem>
                        <Collapse in={openUsuario} timeout="auto" unmountOnExit>
                            <List>
                                {hasAccess('Usuario', 'Grupo de usuarios') && (
                                    <ListItem
                                        className={classes.item}
                                        disableGutters
                                    >
                                        <Button
                                            activeClassName={classes.active}
                                            className={classes.button}
                                            component={CustomRouterLink}
                                            to="/admin/grupos"
                                            style={{ paddingLeft: '40px' }}
                                        >
                                            Grupo de usuários
                                        </Button>
                                    </ListItem>
                                )}
                                {hasAccess('Usuario', 'Usuarios') && (
                                    <ListItem
                                        className={classes.item}
                                        disableGutters
                                    >
                                        <Button
                                            activeClassName={classes.active}
                                            className={classes.button}
                                            component={CustomRouterLink}
                                            to="/admin/usuarios"
                                            style={{ paddingLeft: '40px' }}
                                        >
                                            Usuário
                                        </Button>
                                    </ListItem>
                                )}
                                {/* {hasAccess('Associados', 'Propostas') && ( */}
                                {/* )} */}
                            </List>
                        </Collapse>
                    </>
                )}

                {hasAccess('Conteudo') && (
                    <>
                        <ListItem className={classes.item} disableGutters>
                            <Button
                                className={classes.button}
                                onClick={() => setOpenConteudo(!openConteudo)}
                            >
                                <div className={classes.icon}>
                                    <AssignmentIcon />
                                </div>
                                Conteúdo
                                {openConteudo ? (
                                    <ExpandLess className={classes.expand} />
                                ) : (
                                    <ExpandMore className={classes.expand} />
                                )}
                            </Button>
                        </ListItem>
                        <Collapse
                            in={openConteudo}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List>
                                {/* {hasAccess('Associados', 'Associados') && ( */}
                                <ListItem
                                    className={classes.item}
                                    disableGutters
                                >
                                    <Button
                                        activeClassName={classes.active}
                                        className={classes.button}
                                        component={CustomRouterLink}
                                        // to="/admin/trilhas"
                                        style={{ paddingLeft: '40px' }}
                                    >
                                        Trilhas
                                    </Button>
                                </ListItem>
                                {/* )} */}
                                {hasAccess('Conteudo', 'Categorias') && (
                                    <>
                                        <ListItem
                                            className={classes.item}
                                            disableGutters
                                        >
                                            <Button
                                                activeClassName={classes.active}
                                                className={classes.button}
                                                component={CustomRouterLink}
                                                to="/admin/categorias"
                                                style={{ paddingLeft: '40px' }}
                                            >
                                                Categorias
                                            </Button>
                                        </ListItem>
                                        {/* )} */}
                                        <ListItem
                                            className={classes.item}
                                            disableGutters
                                        >
                                            <Button
                                                activeClassName={classes.active}
                                                className={classes.button}
                                                component={CustomRouterLink}
                                                to="/admin/avaliacoes"
                                                style={{ paddingLeft: '40px' }}
                                            >
                                                Avaliações
                                            </Button>
                                        </ListItem>
                                        <ListItem
                                            className={classes.item}
                                            disableGutters
                                        >
                                            <Button
                                                activeClassName={classes.active}
                                                className={classes.button}
                                                component={CustomRouterLink}
                                                to="/admin/bibliotecas"
                                                style={{ paddingLeft: '40px' }}
                                            >
                                                Biblioteca
                                            </Button>
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </Collapse>
                    </>
                )}

                {hasAccess('Configuracoes') && (
                    <>
                        <ListItem className={classes.item} disableGutters>
                            <Button
                                className={classes.button}
                                onClick={() =>
                                    setOpenConfiguracoes(!openConfiguracoes)
                                }
                            >
                                <div className={classes.icon}>
                                    <SettingsIcon />
                                </div>
                                Configurações
                                {openConfiguracoes ? (
                                    <ExpandLess className={classes.expand} />
                                ) : (
                                    <ExpandMore className={classes.expand} />
                                )}
                            </Button>
                        </ListItem>
                        <Collapse
                            in={openConfiguracoes}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List>
                                {/* {hasAccess('Associados', 'Associados') && ( */}
                                <ListItem
                                    className={classes.item}
                                    disableGutters
                                >
                                    <Button
                                        activeClassName={classes.active}
                                        className={classes.button}
                                        // component={CustomRouterLink}
                                        // to="/admin/alertas"
                                        style={{ paddingLeft: '40px' }}
                                    >
                                        Alertas
                                    </Button>
                                </ListItem>

                                <ListItem
                                    className={classes.item}
                                    disableGutters
                                >
                                    <Button
                                        activeClassName={classes.active}
                                        className={classes.button}
                                        // component={CustomRouterLink}
                                        // to="/admin/sessoes"
                                        style={{ paddingLeft: '40px' }}
                                    >
                                        Sessões
                                    </Button>
                                </ListItem>
                                {/* )} */}
                                {/* {hasAccess('Associados', 'Veículos') && ( */}
                                <ListItem
                                    className={classes.item}
                                    disableGutters
                                >
                                    <Button
                                        activeClassName={classes.active}
                                        className={classes.button}
                                        // component={CustomRouterLink}
                                        // to="/admin/importador"
                                        style={{ paddingLeft: '40px' }}
                                    >
                                        Importador
                                    </Button>
                                </ListItem>
                                {hasAccess('Configuracoes', 'Perfis') && (
                                    <ListItem
                                        className={classes.item}
                                        disableGutters
                                    >
                                        <Button
                                            activeClassName={classes.active}
                                            className={classes.button}
                                            component={CustomRouterLink}
                                            to="/admin/perfis"
                                            style={{ paddingLeft: '40px' }}
                                        >
                                            Perfis
                                        </Button>
                                    </ListItem>
                                )}
                                   {hasAccess('Configuracoes', 'Contatos') && (
                                    <ListItem
                                        className={classes.item}
                                        disableGutters
                                    >
                                        <Button
                                            activeClassName={classes.active}
                                            className={classes.button}
                                            component={CustomRouterLink}
                                            to="/admin/contatos"
                                            style={{ paddingLeft: '40px' }}
                                        >
                                            Contatos
                                        </Button>
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>
                    </>
                )}
            </>
        </List>
    );
};

SidebarNav.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    pages: PropTypes.array.isRequired,
};

export default SidebarNav;
