import React from 'react'
import {NenhumDadoContainer, NenhumDadoText, NenhumDadoImage} from './style'

export default function NenhumDado({text}) {
    return (
        <NenhumDadoContainer>
            <NenhumDadoText>{text}</NenhumDadoText>
            <NenhumDadoImage />
        </NenhumDadoContainer>
    )
}
