/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Link,
    Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { setAuthSession } from '../../../services/auth';
import api from '../../../services/api';
import { cpfCnpj, phoneMask, removeNonNumericCharacters } from '../../../helpers/masks';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    grid: {
        height: '100%',
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        height: '100%',
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '92vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px',
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 400,
        marginTop: 16,
    },
    name: {
        // marginTop: '100%',
        color: theme.palette.white,
        textAlign: 'center',
    },
    bio: {
        color: theme.palette.white,
        textAlign: 'center',
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        marginTop: theme.spacing(3),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    loginButton: {
        margin: theme.spacing(2, 0),
    },
    marginBottom: {
        margin: theme.spacing(3, 0),
    },
}));

const Login = (props) => {
    const { history } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const locations = useLocation()
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
    const [codigo, setCodigo] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [associado] = useState({});
    const [loading, setLoading] = useState(false);
    // const [isAssociadoPage, setIsAssociadoPage] = useState(props.location.pathname == "/login_associado")

    const handleBack = () => {
        history.goBack();
    };
  
    const handleLogin = (event) => {
        event.preventDefault();
        if (!usuario || !password) {
            enqueueSnackbar('Preencha o usuário e senha para continuar!', {
                variant: 'error',
            });
        } else {
            setLoading(true);
            api.post('/autenticar', {
                usuario,
                senha: password,
                adminPage: true,
            })
                .then((response) => {
                    setUsuario('');
                    setPassword('');
                    setAuthSession(
                        response.data.token,
                        response.data.usuario_id,
                        response.data.acessos,
                        response.data.imagemUsuario
                    );
                    history.push('/admin/');
                })
                .catch(() => {
                    setLoading(false);
                    enqueueSnackbar('Usuário ou senha inválidos!', {
                        variant: 'error',
                    });
                });
        }
    };

    // const handleCadastroAssociado = async (event) => {
    //     try {
           
    //         await api.post('/usuarios', {
    //             usuario,
    //             nome: associado.nome,
    //             senha: password,
    //             associado_id: associado.associado_id,
    //             status: 1, // ativo
    //             perfil_id: 14, // associado
    //             quantidade_acessos: 0,
    //         });

    //         const response = await api.post('/autenticar', {
    //             usuario,
    //             senha: password,
    //         });

    //         setAuthSession(
    //             response.data.token,
    //             response.data.usuario_id,
    //             response.data.acessos,
    //             associado.associado_id
    //         );

    //         enqueueSnackbar(`Usuário cadastrado com sucesso!`, {
    //             variant: 'success',
    //         });

    //         history.push('/admin');

    //         setLoading(false);
    //     } catch (error) {
    //         history.push('/admin');
    //         setLoading(false);
    //     }
    // };

    const handleRecoverAccess =async (event) => {
        try {
            event.preventDefault();
            if (!usuario) {
                enqueueSnackbar('Preencha o seu telefone!', { variant: 'error' });
            } else {
                setLoading(true);
               await api.post('/envio-sms', { telefone: phoneMask(usuario) })
                    .then(() => {
                        setLoading(false);
                        console.log(usuario)
                        setCodigo('');
                        history.push(`/admin/verificar_sms/${removeNonNumericCharacters(usuario)}`)
                        enqueueSnackbar(
                            `Codigo de recuperação de acesso enviado para: ${usuario}!`,
                            { variant: 'success' }
                        );
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error.response) {
                            enqueueSnackbar(error.response.data.error, {
                                variant: 'error',
                            });
                        }
                    });

            }
        } catch (error) {
            enqueueSnackbar(
                `Tente novamente!`,
                { variant: 'error' }
            );
        }
       
    };

    
    const handleTrocarSenha =async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            if (!password || !confirmPassword) {
                enqueueSnackbar('Preencha os campos corretamente!', {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            if (password !== confirmPassword) {
                enqueueSnackbar('Confirmação de senha incorreta!', {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            api.post('/trocar_senha', {
                codigo: props.match.params.codigo,
                senha: password
            })
            enqueueSnackbar(
                `Sucesso senha alterada!`,
                { variant: 'success' }
            );
            history.push("/admin")
        } catch (error) {
            enqueueSnackbar(
                `Tente novamente!`,
                { variant: 'error' }
            );
        }
       
    };

    
    const verificaCodigoSms =async (event) => {
        event.preventDefault();
     try {
        if (codigo) {
         await api.post('/envio_reset_senha',{
            "telefone": phoneMask(props.match.params.telefone),
            "codigo_recuperacao": Number(codigo)
          })
          enqueueSnackbar('Codigo verificado com sucesso', {
            variant: 'success',
        });
        history.push(`/admin/resetar_senha/${codigo}`)
        } else {
            enqueueSnackbar('Preencha todos os dados corretamente!', {
                variant: 'error',
            });
        }
     } catch (error) {
        enqueueSnackbar('Codigo errado!', {
            variant: 'error',
        }); 
     }
    };


    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.quoteContainer} item lg={5}>
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>
                            <Typography
                                className={classes.quoteText}
                                variant="h1"
                            >
                                Treina Delta
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                className={classes.name}
                                variant="body1"
                            >
                                &copy; Delta Global
                            </Typography>
                            <Typography className={classes.bio} variant="body2">
                                Todos os direitos reservados.
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.content} item lg={7} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className={classes.contentBody}>
                            {props.location.pathname === '/admin/login' && (
                                <form
                                    className={classes.form}
                                    onSubmit={handleLogin}
                                >
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Entrar
                                    </Typography>

                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Usuário"
                                        onChange={(e) =>
                                            setUsuario(e.target.value)
                                        }
                                        type="text"
                                        value={usuario}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Senha"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        type="password"
                                        value={password}
                                        variant="outlined"
                                    />
                                    <Button
                                        className={classes.loginButton}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                    >
                                        {loading ? 'Aguarde' : 'Entrar'}
                                    </Button>
                                    <Typography
                                        color="textSecondary"
                                        variant="body1"
                                    >
                                        <Link
                                            component={RouterLink}
                                            to="/admin/recuperar_acesso"
                                            variant="h6"
                                        >
                                            Esqueceu sua senha?
                                        </Link>
                                    </Typography>
                                </form>
                            )}

                            {props.location.pathname ===
                                '/admin/recuperar_acesso' && (
                                <form
                                    className={classes.form}
                                    onSubmit={handleRecoverAccess}
                                >
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Esqueceu sua senha ?
                                    </Typography>
                                    <Typography variant="subtitle1">
                                    Digite seu número para receber um código de confirmação.
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Telefone"
                                        onChange={(e) =>
                                            setUsuario((e.target.value))
                                        }
                                        type="text"
                                        value={phoneMask(usuario)}
                                        variant="outlined"
                                    />
                                    <Button
                                        className={classes.loginButton}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                    >
                                        {loading ? 'Aguarde' : 'Enviar'}
                                    </Button>
                                </form>
                            )}
                             {props.location.pathname ===
                                `/admin/verificar_sms/${props.match.params.telefone}` && (
                                <form
                                    className={classes.form}
                                    onSubmit={verificaCodigoSms}
                                >
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Confirme o código que foi enviado para seu telefone.
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Codigo"
                                        onChange={(e) =>
                                            setCodigo(e.target.value)
                                        }
                                        maxlength="4"
                                        type="number"
                                        value={codigo}
                                        variant="outlined"
                                    />
                                
                                    <Button
                                        className={classes.loginButton}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                    >
                                        {loading ? 'Aguarde' : 'Alterar Senha'}
                                    </Button>
                                </form>
                            )}

                            {props.location.pathname ===
                                `/admin/trocar_senha/${props.match.params.token}` && (
                                <form
                                    className={classes.form}
                                >
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Alteração de Senha
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Nova Senha"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        type="password"
                                        value={password}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Repita a Nova Senha"
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        type="password"
                                        value={confirmPassword}
                                        variant="outlined"
                                    />
                                    <Button
                                        className={classes.loginButton}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                    >
                                        {loading ? 'Aguarde' : 'Alterar Senha'}
                                    </Button>
                                </form>
                            )}

                            {props.location.pathname ===
                                `/admin/resetar_senha/${props.match.params.codigo}` && (
                                <form
                                    className={classes.form}
                                    onSubmit={handleTrocarSenha}
                                >
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Altere sua senha abaixo.
                                    </Typography>
                                    <Typography
                                        className={
                                            (classes.title,
                                            classes.marginBottom)
                                        }
                                        variant="h4"
                                    >
                                        Altere a sua senha abaixo.
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Nova Senha"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        type="password"
                                        value={password}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        label="Repita a Nova Senha"
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        type="password"
                                        value={confirmPassword}
                                        variant="outlined"
                                    />
                                    <Button
                                        className={classes.loginButton}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                    >
                                        {loading ? 'Aguarde' : 'Confirmar'}
                                    </Button>
                                </form>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(Login);
