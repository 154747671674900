import styled from 'styled-components';

export const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const TitleCommentContainer = styled.div`
    display: flex;
    gap: 20px;
    & img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin-top: -5px;
    }
`;

export const NameDate = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    gap: 3px;
`;

export const CommentName = styled.p`
    color: #fff;
    font-size: 1.4rem;
`;

export const CommentDate = styled.p`
    color: #ffffff99;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 9px;
`;

export const RatingContainer = styled.div`
    display: flex;
    gap: 3px;
    font-size: 1.1rem;
    color: #fff;
    margin-top: -1px;
    & svg {
        height: 13px;
        width: 13px;
        margin-top: 1px;
    }
`;

export const CommentText = styled.p`
    font-size: 1.3rem;
    color: #ffffff;
    opacity: 0.6;
    font-weight: 200;
    padding-left: 60px;
`;

export const CommentDivider = styled.div`
    width: 91%;
    height: 1px;
    background: #fff;
    opacity: 0.1;
    margin-top: 5px;
    margin-right: 5px;
    align-self: flex-end;
    @media (max-width: 1200px) {
        width: 94%;
    }
`;
