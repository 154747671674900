import styled from 'styled-components'

export const CardCertificadoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
`

export const CardCertificadoDataConclusao = styled.div`
    font-size: 1.6rem;
    color: #fff;
    opacity: 0.6;
    font-weight: 200;
    padding: 0 0 0 2.3rem ;
`

export const CardCertificadoTrilha = styled.div`
    display: flex;
    align-items: center;
    padding: 26px 40px 26px 24px;
    border-radius: 10px;
    background-color: #413E5380;
    gap: 36px;

    @media (max-width: 1250px) {
        align-items: flex-start;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        padding: 26px 24px;
    }
`

export const CardCertificadoImg = styled.div`
    width: 210px;
    height: 138px;
    background-image: url(${({ defaultImg }) => defaultImg || ""});
    background-size: cover;
    background-position: center;
    @media (max-width: 700px) {
        min-width: 100%;
        height: auto;
    }
`

export const CardCertificadoTrilhaInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
    
export const CardCertificadoTitle = styled.div`
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.3rem;
    margin-bottom: 5px;
    color: #fff;
`

export const CardCertificadoDescription = styled.div`
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 1.9rem;
    margin-bottom: 13px;
    color: #fff;
`

export const CardCertificadoInfosButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1250px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
    }

`

export const CardCertificadoWrapperCol = styled.div`
    display: flex;
    gap: 35px;

    @media (max-width: 1250px) {
        width: 100%;
        justify-content: flex-start
    }
`

export const InfosCol = styled.div`
    display: flex;
    flex-direction: column;

    & span:nth-child(1) {
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: #F5F5F6;
        opacity: 0.6;
    }
    & span:nth-child(2) {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #F5F5F6;
    }
`