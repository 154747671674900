import styled from 'styled-components';

export const ButtonDropContainer = styled.div`
    height: 52px;
    width: 52px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: auto;
    & svg path {
        fill: ${({ active, search }) => (search && active ? '#ffffff90;' : '')};
    }
    ${({ notification, active }) =>
        notification
            ? `&:hover svg:first-child path{
    fill: #ffffff90;
  }
  & svg:first-child {
    fill: #ffffff40;
    transition: fill 0.15s ease;
  }
  & svg:nth-child(2) {
    position: absolute;
    transform: scale(0);
    transition: all 0.2s ease;
    ${active ? `transform: scale(1);` : ''}
  }`
            : ''}
    ${({ user }) =>
        user
            ? `& img {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    z-index: 1;
  }
  `
            : ''}
  &::after {
        content: '';
        height: 52px;
        width: 52px;
        background: #ffffff10;
        position: absolute;
        transform: scale(0);
        transition: all 0.2s ease-in-out;
        border-radius: 50%;
        ${({ active }) =>
            active ? `transform: scale(1); animation: pop 0.3s linear;` : ''}
    }
    &::before {
        opacity: 0;
        transition: all 0.1s ease;
    }

    @media (max-width: 450px) {
        position: unset;
        &::before {
            content: '';
            opacity: ${({ active }) => (active ? '1' : '0')};
            transition: ${({ active }) => (active ? 'all 0.2s ease 0.1s' : '')};
            height: 10.54px;
            width: 10.54px;
            background: linear-gradient(45deg, transparent 35%, white 50%);
            transform: rotate(-45deg);
            position: absolute;
            bottom: -20px;
            pointer-events: none;
            z-index: 1;
        }
        @media (max-width: 300px) {
            &::before {
                bottom: -12px;
            }
        }
    }

    @keyframes pop {
        50% {
            transform: scale(1.3);
        }
    }
`;

export const Drop = styled.div`
    position: absolute;
    right: 5px;
    top: 75px;
    background: white;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease 0.2s;
    width: 250px;
    ${({ active }) =>
        active
            ? `opacity: 1; pointer-events: auto; transition: opacity 0.3s ease;`
            : ''}
    &::before {
        content: '';
        height: 10.54px;
        width: 10.54px;
        background: white;
        transform: rotate(-45deg);
        position: absolute;
        top: -5px;
        right: 15px;
        pointer-events: none;
    }
    @media (max-width: 450px) {
        width: 80%;
        right: 42px;
        &::before {
            display: none;
        }
    }
    @media (max-width: 300px) {
        top: 67px;
        width: 98%;
        right: 4px;
    }
`;

// AJUSTAR HEIGHT PARA CADA LISTA
export const DropList = styled.div`
    max-height: 0px;
    overflow: hidden;
    transition: all 0.4s ease;
    ${({ active }) =>
        active
            ? `max-height: 481px; overflow: overlay; transition: all 0.6s ease`
            : ''};
    @media (max-width: 450px) {
        ${({ active }) => (active ? `max-height: 250px;` : '')};
    }
`;

export const DropItem = styled.div`
    padding: ${({ user }) => (user ? '15px 20px' : '15px 25px')};
    display: flex;
    align-items: center;
    border-bottom: 1px solid #00000010;
    font-size: 1.6rem;
    transition: background 0.3s ease;
    &:hover {
        background: #c4c4c420;
    }
    ${({ notification }) =>
        notification ? `&:first-child {padding: 25px 25px 15px}` : ''}
`;
