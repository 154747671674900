import styled from 'styled-components';

export const InfoTrilhaContainer = styled.div`
    padding: 45px 40px;
    border-radius: 10px;
    background: #0000001a;
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 400px;
    @media (max-width: 1200px) {
        min-width: unset;
        width: 100%;
    }
`;

export const GridInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 20px;
    & > div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 4;
    }
    & > div:nth-child(2) {
        grid-area: 2 / 1 / 3 / 4;
    }
    & > div:nth-child(3) {
        grid-area: 3 / 1 / 4 / 2;
    }
    & > div:nth-child(4) {
        grid-area: 3 / 2 / 4 / 3;
    }
    & > div:nth-child(5) {
        grid-area: 3 / 3 / 4 / 4;
    }
`;

export const InfoLabel = styled.p`
    font-size: 1.4rem;
    opacity: 0.6;
    color: #fff;
`;

export const InfoText = styled.div`
    color: #fff;
    font-size: 1.6rem;
`;

export const AdditionalInfo = styled.p`
    color: #fff;
    opacity: 0.6;
    font-size: 1.2rem;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    & ${InfoLabel}, ${InfoText} {
        color: ${({ modal }) => (modal ? '#333247' : '#fff')};
    }
`;
