import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';

export const TagContainer = styled.div`
    font-size: ${({ category }) => (category ? '1.4rem' : '1.2rem')};
    color: ${colors.secondary};
    background-color: ${({ category, reviewed, aprovado }) => {
        if (category) {
            return `#FFFFFF`;
        }
        if (reviewed) {
            return `${colors.primary}`;
        }
        if (aprovado === false) {
            return `${colors.quaternary}`;
        }
        return `${colors.tertiary}`;
    }};
    padding: ${({ reviewed }) =>
        reviewed ? '0.4rem 0.8rem 0.4rem 1.2rem' : '0.4rem 1.2rem'};
    border-radius: 4px;
    opacity: ${({ category }) => (category ? '0.3' : '')};
    & svg {
        margin-left: 0.7rem;
    }
    ${({ aprovado, cardAvaliacao }) =>
        aprovado === false || cardAvaliacao ? `display: flex; align-items: center;` : ''};
`;
