import React from 'react';
import { IconStarRating } from '../../../../../components/aluno/Icons/Icons';
import {
    CommentContainer,
    CommentDate,
    CommentDivider,
    CommentName,
    CommentText,
    NameDate,
    RatingContainer,
    TitleCommentContainer,
} from './style';

const Comment = ({ image, name, date, text, rating, icon }) => {
    return (
        <CommentContainer>
            <TitleCommentContainer>
                {/* <img src={image} alt="user" /> */}
                {icon}
                <NameDate>
                    <CommentName>{name}</CommentName>
                    <CommentDate>
                        <RatingContainer>
                            <IconStarRating />
                            {rating}
                        </RatingContainer>
                        {date}
                    </CommentDate>
                </NameDate>
            </TitleCommentContainer>
            <CommentText>{text}</CommentText>
            <CommentDivider />
        </CommentContainer>
    );
};

export default Comment;
