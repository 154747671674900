import React from 'react';
import { TextField } from '@material-ui/core';

export default function CustomTextField({ error, ...rest }) {
    return (
        <TextField
            helperText={error}
            error={error && true}
            FormHelperTextProps={{
                error: true,
                autoFocus: true,
                filled: true,
                margin: 'dense',
            }}
            variant="outlined"
            style={{ width: '90%' }}
            {...rest}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}
