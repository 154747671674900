import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';

export const ButtonContainer = styled.button`
    border: 0;
    outline: 0;
    font-size: ${({ big }) => (big ? '1.8rem' : '1.6rem')};
    color: ${colors.secondary};
    background-color: ${({ cancel, disabled, aprovado, certificado }) =>
        cancel || disabled
            ? '#EAEAEA'
            : aprovado || certificado
            ? colors.tertiary
            : colors.primary};
    padding: ${({ big, cardAvaliacao, certificado }) => (big ? '1.5rem 2.5rem' : cardAvaliacao ? '5px 15px' : certificado ? '1.1rem 4.5rem' : '1rem 2rem')};
    opacity: ${({ disabled }) => (disabled ? '0.1' : '')};
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
    width: fit-content;
    height: fit-content;
    &:hover {
        opacity: 0.8;
    }
`;
