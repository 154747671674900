/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { hasAccess } from 'services/auth';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import SettingsIcon from '@material-ui/icons/Settings';

import moment from 'moment';
import { hasAccess } from '../../../services/auth';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
    Breadcrumbs,
    Button,
    Box,
    Card,
    CardActions,
    Chip,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    TextField,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import { ErrorChip, PrimaryChip, SuccessChip } from '../../../helpers/helper';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    paper: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        maxHeight: 450,
    },
    autocomplete: {
        backgroundColor: 'white',
        // height: '45.625px'
    },
}));

const ComentariosList = () => {
    const acesso = hasAccess('Conteudo', 'Comentarios');
    const acessoAdmin = hasAccess('Configuracoes', 'Parceiros');

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const acesso = hasAccess('Controle de Acesso', 'Usuários');
    const [initialFilters] = useState({
        nome: '',
        modulo_id: '',
        usuario_id: '',
    });
    const [filters, setFilters] = useState(initialFilters);
    const [comentarios, setComentarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginationInfo, setPaginationInfo] = useState({ total: 0 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [idSelected, setIdSelected] = useState();
    const [usuarios, setUsuarios] = useState([]);
    const [modulos, setModulos] = useState([]);
    const [trilhas, setTrilhas] = useState([]);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [isOpenAprovarComentario, setIsOpenAprovarComentario] =
        useState(false);
    const [isOpenReprovarComentario, setIsOpenReprovarComentario] =
        useState(false);
    const open = Boolean(anchorEl);
    const [comentarioSelecionado, setComentarioSelecionado] = useState({});
    const [isOpenVisualizarComentario, setIsOpenVisualizarComentario] =
        useState(false);

    useEffect(() => {
        getComentarios(filters, currentPage, perPage);
        getUsuarios();
        getModulos();
        getTrilhas();
    }, [currentPage, perPage]);

    const openMenuActions = (event, alertas) => {
        setAnchorEl(event.currentTarget);
        setIdSelected(alertas.alerta_id);
    };

    const closeMenuActions = () => {
        setAnchorEl(null);
    };

    const getComentarios = async (
        filters = [],
        currentPage = 0,
        perPage = 10
    ) => {
        try {
            setLoading(true);
            const response = await api.get('/comentarios/filter', {
                params: {
                    ...filters,
                    currentPage: currentPage + 1,
                    perPage,
                },
            });
            setComentarios(response.data.data);

            setPaginationInfo({
                pages: response.data.pagination.lastPage,
                total: response.data.pagination.total,
            });
            setLoading(false);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleCurrentPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setCurrentPage(0);
        setPerPage(event.target.value);
    };

    const handleLimparFiltro = () => {
        setFilters(initialFilters);
        getComentarios(null, currentPage, perPage);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(0);
        getComentarios(filters);
    };

    const getUsuarios = async () => {
        try {
            const response = await api.get('/usuarios');
            const itens = response.data;
            setUsuarios(itens);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getModulos = async () => {
        try {
            const response = await api.get('/modulos');
            const itens = response.data;
            setModulos(itens);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const getTrilhas = async () => {
        try {
            const response = await api.get('/trilhas');
            const itens = response.data;
            setTrilhas(itens);
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleOpenAprovarComentario = (comentario) => {
        setIsOpenAprovarComentario(true);
        setComentarioSelecionado(comentario);
    };

    const handleCloseAprovarComentario = () => {
        setIsOpenAprovarComentario(false);
        setComentarioSelecionado({});
    };

    const handleOpenReprovarComentario = (comentario) => {
        setIsOpenReprovarComentario(true);
        setComentarioSelecionado(comentario);
    };

    const handleCloseReprovarComentario = () => {
        setIsOpenReprovarComentario(false);
        setComentarioSelecionado({});
    };

    const handleOpenVisualizarComentario = (comentario) => {
        setIsOpenVisualizarComentario(true);
        setComentarioSelecionado(comentario);
    };

    const handleCloseVisualizarComentario = () => {
        setIsOpenVisualizarComentario(false);
        setComentarioSelecionado({});
    };

    const handleAprovarComentario = async () => {
        await api.post(
            `/comentarios/aprovar/${comentarioSelecionado.comentario_id}`
        );
        await getComentarios(filters, currentPage, perPage);
        handleCloseAprovarComentario();
    };

    const handleReprovarComentario = async () => {
        await api.post(
            `/comentarios/reprovar/${comentarioSelecionado.comentario_id}`
        );
        await getComentarios(filters, currentPage, perPage);
        handleCloseReprovarComentario();
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Breadcrumbs
                        className={classes.breadCrumb}
                        aria-label="breadcrumb"
                        gutterBottom
                    >
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to="/admin/"
                        >
                            Home
                        </Link>
                        <Typography className={classes.breadCrumb}>
                            Comentários
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" component="h1">
                        Comentários
                    </Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSearch}>
                <Box mt={4}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="start"
                        alignItems="center"
                    >
                        <Grid item xs={9} sm={3}>
                            <Autocomplete
                                getOptionLabel={(usuarios) => usuarios.nome}
                                options={usuarios}
                                value={
                                    usuarios.find(
                                        (item) =>
                                            item.usuario_id ===
                                            filters.usuario_id
                                    )
                                        ? usuarios.find(
                                              (item) =>
                                                  item.usuario_id ===
                                                  filters.usuario_id
                                          )
                                        : null
                                }
                                onChange={(event, newValue) => {
                                    setFilters({
                                        ...filters,
                                        usuario_id: newValue?.usuario_id ?? '',
                                    });
                                }}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.autocomplete}
                                        label="Usuário"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9} sm={3}>
                            <Autocomplete
                                getOptionLabel={(modulo) => modulo.nome}
                                options={modulos}
                                value={
                                    modulos.find(
                                        (item) =>
                                            item.modulo_id === filters.modulo_id
                                    )
                                        ? modulos.find(
                                              (item) =>
                                                  item.modulo_id ===
                                                  filters.modulo_id
                                          )
                                        : null
                                }
                                onChange={(event, newValue) => {
                                    setFilters({
                                        ...filters,
                                        modulo_id: newValue?.modulo_id ?? '',
                                    });
                                }}
                                loading={loading}
                                renderInput={(parametros) => (
                                    <TextField
                                        {...parametros}
                                        className={classes.autocomplete}
                                        label="Módulo"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9} sm={3}>
                            <Autocomplete
                                getOptionLabel={(trilhas) => trilhas.nome}
                                options={trilhas}
                                value={
                                    trilhas.find(
                                        (item) =>
                                            item.trilha_id === filters.trilha_id
                                    )
                                        ? trilhas.find(
                                              (item) =>
                                                  item.trilha_id ===
                                                  filters.trilha_id
                                          )
                                        : null
                                }
                                onChange={(event, newValue) => {
                                    console.log(
                                        '🚀 ~ file: ComentariosList.js ~ line 242 ~ ComentariosList ~ newValue',
                                        newValue
                                    );

                                    setFilters({
                                        ...filters,
                                        trilha_id: newValue?.trilha_id ?? '',
                                    });
                                }}
                                loading={loading}
                                renderInput={(param) => (
                                    <TextField
                                        {...param}
                                        className={classes.autocomplete}
                                        label="Trilha"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={3} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                style={{ marginLeft: '2%' }}
                                onClick={handleLimparFiltro}
                                variant="outlined"
                                color="primary"
                                className={classes.autocomplete}
                                size="medium"
                                type="submit"
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{ padding: 0 }}>
                                <PerfectScrollbar>
                                    <TableContainer
                                        className={
                                            isSmallScreen && classes.container
                                        }
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Usuário
                                                    </TableCell>
                                                    <TableCell>
                                                        Comentário
                                                    </TableCell>
                                                    <TableCell>
                                                        Módulo
                                                    </TableCell>
                                                    <TableCell>
                                                        Trilha
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Pontuação
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Status
                                                    </TableCell>
                                                    { acessoAdmin.visualizar && (
                                                        <TableCell align="center">
                                                            Parceiro
                                                        </TableCell>
                                                    )}
                                                    <TableCell
                                                        align="center"
                                                        colSpan={3}
                                                    >
                                                        Ações
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            colSpan={5}
                                                        >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <>
                                                        {comentarios.length ===
                                                        0 ? (
                                                            <TableRow>
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={5}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Nenhum
                                                                        registro
                                                                        existente!
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            <>
                                                                {comentarios.map(
                                                                    (
                                                                        comentario
                                                                    ) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={
                                                                                comentario.comentario_id
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    comentario.nome_usuario
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {comentario
                                                                                    .conteudo
                                                                                    .length >
                                                                                80
                                                                                    ? `${comentario.conteudo.slice(
                                                                                          0,
                                                                                          80
                                                                                      )}...`
                                                                                    : comentario.conteudo}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {`${comentario.nome_modulo} (${comentario.modulo_id})`}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    comentario.nome_trilha
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {
                                                                                    <PrimaryChip
                                                                                        texto={
                                                                                            comentario.rating
                                                                                        }
                                                                                        size="medium"
                                                                                    />
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {comentario.status ===
                                                                                0 ? (
                                                                                    <Chip
                                                                                        size="small"
                                                                                        variant="outlined"
                                                                                        // style={{
                                                                                        //     borderColor:
                                                                                        //         "#FD7B72",
                                                                                        // }}
                                                                                        label={
                                                                                            'Não publicado'
                                                                                        }
                                                                                    />
                                                                                ) : comentario.status ===
                                                                                  1 ? (
                                                                                    <Chip
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        style={{
                                                                                            borderColor:
                                                                                                '#4BCA81',
                                                                                        }}
                                                                                        label={
                                                                                            'Aprovado'
                                                                                        }
                                                                                        icon={
                                                                                            <CheckIcon
                                                                                                style={{
                                                                                                    color: '#4BCA81',
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <Chip
                                                                                        size="small"
                                                                                        variant="outlined"
                                                                                        style={{
                                                                                            borderColor:
                                                                                                '#FD7B72',
                                                                                        }}
                                                                                        label={
                                                                                            'Reprovado'
                                                                                        }
                                                                                        icon={
                                                                                            <CloseIcon
                                                                                                style={{
                                                                                                    color: '#FD7B72',
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                            { acessoAdmin && (
                                                                                <TableCell align="center">
                                                                                {
                                                                                    comentario.nome_parceiro ? comentario.nome_parceiro : '-'
                                                                                }
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell
                                                                                style={{
                                                                                    padding: 0,
                                                                                    margin: 0,
                                                                                }}
                                                                                align="center"
                                                                            >
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        handleOpenVisualizarComentario(
                                                                                            comentario
                                                                                        )
                                                                                    }
                                                                                    variant="none"
                                                                                >
                                                                                    <PrimaryChip
                                                                                        onClick={() => {}}
                                                                                        color="gray"
                                                                                        texto="Visualizar"
                                                                                    />
                                                                                </IconButton>
                                                                            </TableCell>

                                                                            <TableCell
                                                                                style={{
                                                                                    padding: 0,
                                                                                    margin: 0,
                                                                                }}
                                                                                align="right"
                                                                            >
                                                                                {comentario.status ===
                                                                                    0 &&
                                                                                    acesso[
                                                                                        'aprovar e reprovar'
                                                                                    ] && (
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                handleOpenAprovarComentario(
                                                                                                    comentario
                                                                                                )
                                                                                            }
                                                                                            variant="none"
                                                                                        >
                                                                                            <SuccessChip
                                                                                                onClick={() => {}}
                                                                                                texto="Aprovar"
                                                                                            />
                                                                                        </IconButton>
                                                                                    )}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                style={{
                                                                                    padding: 0,
                                                                                    margin: 0,
                                                                                }}
                                                                                align="right"
                                                                            >
                                                                                {comentario.status ===
                                                                                    0 &&
                                                                                    acesso[
                                                                                        'aprovar e reprovar'
                                                                                    ] && (
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                handleOpenReprovarComentario(
                                                                                                    comentario
                                                                                                )
                                                                                            }
                                                                                            variant="none"
                                                                                        >
                                                                                            <ErrorChip
                                                                                                onClick={() => {}}
                                                                                                texto="Reprovar"
                                                                                            />
                                                                                        </IconButton>
                                                                                    )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </PerfectScrollbar>
                            </CardContent>
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                <TablePagination
                                    component="div"
                                    count={paginationInfo.total}
                                    onChangePage={handleCurrentPageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    page={currentPage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={closeMenuActions}
            >
                <MenuItem
                    component={RouterLink}
                    to={`/admin/alertas/${idSelected}`}
                >
                    <ListItemIcon style={{ minWidth: '32px' }}>
                        <EditIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Editar alerta" />
                </MenuItem>
            </Menu>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="xs"
                open={isOpenAprovarComentario}
                onClose={handleCloseAprovarComentario}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    Publicação de comentário no módulo{' '}
                    {comentarioSelecionado.nome_modulo}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O comentário será publicado. Deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseAprovarComentario}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleAprovarComentario} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="xs"
                open={isOpenReprovarComentario}
                onClose={handleCloseReprovarComentario}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    Reprovar comentário no módulo{' '}
                    {comentarioSelecionado.nome_modulo}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O comentário será reprovado. Deseja continuar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseReprovarComentario}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleReprovarComentario} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                scroll="paper"
                fullWidth="true"
                maxWidth="lg"
                open={isOpenVisualizarComentario}
                onClose={handleCloseVisualizarComentario}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle style={{ marginBottom: 8 }} id="form-dialog-title">
                    Visualizar comentário no módulo{' '}
                    {comentarioSelecionado.nome_modulo}
                </DialogTitle>
                <DialogContent>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Usuário</TableCell>
                                <TableCell>Comentário</TableCell>
                                <TableCell>Módulo</TableCell>
                                <TableCell>Trilha</TableCell>
                                <TableCell>Data da publicação</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell align="center" colSpan={5}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    <TableRow
                                        hover
                                        key={
                                            comentarioSelecionado.comentarioSelecionado_id
                                        }
                                    >
                                        <TableCell>
                                            {comentarioSelecionado.nome_usuario}
                                        </TableCell>
                                        <TableCell>
                                            {comentarioSelecionado.conteudo}
                                        </TableCell>
                                        <TableCell>
                                            {`${comentarioSelecionado.nome_modulo} (${comentarioSelecionado.modulo_id})`}
                                        </TableCell>
                                        <TableCell>
                                            {comentarioSelecionado.nome_trilha}
                                        </TableCell>
                                        <TableCell>
                                            {moment(
                                                comentarioSelecionado.data_publicacao
                                            )
                                                .subtract(3, 'hours')
                                                .format('DD/MM/YYYY HH:mm')}
                                        </TableCell>
                                        <TableCell align="center">
                                            {comentarioSelecionado.status ===
                                            0 ? (
                                                <Chip
                                                    size="small"
                                                    variant="outlined"
                                                    // style={{
                                                    //     borderColor:
                                                    //         "#FD7B72",
                                                    // }}
                                                    label={'Não publicado'}
                                                />
                                            ) : comentarioSelecionado.status ===
                                              1 ? (
                                                <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    style={{
                                                        borderColor: '#4BCA81',
                                                    }}
                                                    label={'Aprovado'}
                                                    icon={
                                                        <CheckIcon
                                                            style={{
                                                                color: '#4BCA81',
                                                            }}
                                                        />
                                                    }
                                                />
                                            ) : (
                                                <Chip
                                                    size="small"
                                                    variant="outlined"
                                                    style={{
                                                        borderColor: '#FD7B72',
                                                    }}
                                                    label={'Reprovado'}
                                                    icon={
                                                        <CloseIcon
                                                            style={{
                                                                color: '#FD7B72',
                                                            }}
                                                        />
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseVisualizarComentario}
                        color="primary"
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ComentariosList;
