import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';
import loginBG from '../../../assets/img/loginBG.jpg';

export const LoginContainer = styled.div`
    background: ${colors.primaryGradient};
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: ${({ mudarSenha }) => mudarSenha && "center"};
    justify-content: ${({ mudarSenha }) => mudarSenha && "center"};
`;

export const LeftBG = styled.div`
    background: url(${loginBG});
    background-position: top;
    background-size: cover;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 44.5% 0px;
    filter: drop-shadow(2.7rem 11.2rem 25rem rgba(0, 0, 0, 0.35));
    @media (max-width: 700px) {
        display: none;
    }
`;

export const RightContainer = styled.div`
    min-width: 530px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 7% 5rem;
    overflow: overlay;
    @media (max-width: 700px) {
        min-width: unset;
        width: 100%;
        padding: 0 10% 5rem;
    }
`;

export const LembrarSenha = styled.div`
    display: flex;
    align-items: center;
    gap: 0.7rem;
    margin: 2rem 0;
    & p {
        font-size: 1.2rem;
        opacity: 0.6;
        color: #fff;
        font-weight: 200;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    & > svg {
        width: 24.1rem;
        height: 2.3rem;
        margin: 4rem 0;
    }
    a {
        font-size: 1.2rem;
        color: ${colors.primary};
        text-decoration: none;
        margin: 2.7rem 0;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const CopyrightContainer = styled.div`
    display: flex;
    flex-direction: column;
    & svg {
        width: 13rem;
        height: 4.25rem;
        margin-bottom: 1.7rem;
    }
    span {
        color: #fcfcfc;
        opacity: 0.4;
        font-size: 1.1rem;
        font-weight: 200;
    }
`;
