import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: 'none',
    },
    logoHeight: {
        height: '36px',
    },
}));

const Topbar = (props) => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="primary"
            position="fixed"
        >
            <Toolbar>
                <RouterLink to="/admin/">
                    <img
                        className={classes.logoHeight}
                        alt="Logo"
                        src="/images/logos/logo--white.svg"
                    />
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
};

export default Topbar;
