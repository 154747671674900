/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, Link as RouterLink, useParams } from "react-router-dom";

import {
    Box,
    Grid,
    Breadcrumbs,
    CircularProgress,
    Button,
    Link,
    Tabs,
    Card,
    CardContent,
    Typography,
    Tab,
    Divider,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import api from "../../../services/api";

import AvaliacaoCadastro from "./components/AvaliacaoFormCadastro";
import AvaliacaoQuestoes from "./components/AvaliacaoQuestoes";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    alignRight: {
        display: "flex",
        justifyContent: "flex-end",
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "0.33px",
        lineHeight: "13px",
        textTransform: "uppercase",
    },
    saveButton: {
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const AvaliacaoEditar = () => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { id } = useParams();
    const [validationErrors] = useState({});
    const [trilhas, setTrilhas] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [questoes, setQuestoes] = useState([
        { tipo_questao: "multipla_escolha", respostas: [{}] },
    ]);
    const [avaliacao, setAvaliacao] = useState({ ativo: false });
    const [questoesChecked, setQuestoesChecked] = useState({});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAvaliacao({ ...avaliacao, [name]: value });
    };

    const getAvaliacao = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/avaliacoes/${id}`);

            setAvaliacao(response.data.avaliacao);
            setQuestoes(response.data.questoes);
            
            let newFiles = [];

            for (const item of response.data.questoes) {
                newFiles = [...newFiles, ...item.conteudo];
            }

            for (const [index, file] of newFiles.entries()) {
                file.posicao = index;
            }

            setFiles(newFiles);

            setLoading(false);
        } catch (error) {
            setLoading(false);

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getCategorias = async () => {
        try {
            setLoading(true);
            const response = await api.get("/trilhas");
            setTrilhas(response.data);
            console.log(
                "🚀 ~ file: GrupoList.js ~ line 40 ~ getCategorias ~ response.data.data",
                response.data
            );
            setLoading(false);
        } catch (error) {
            setLoading(false);

            if (error.response) {
                enqueueSnackbar("Falha ao buscar trilha", {
                    variant: "error",
                });
            }
        }
    };
    useEffect(() => {
        getCategorias();
        getAvaliacao();
    }, []);

    const updateAvaliacao = async () => {
        try {
            setLoading(true)
            for await (const [index, questao] of questoes.entries()) {
                if (!questao.questao_id && !questao.remover) { //done

                    const questaoFiles = files.filter(
                        (file) => file.index === index && !file.remover
                    );

                    const response = await api.post("/avaliacoes/questao/", {
                        questao,
                        avaliacao_id: avaliacao.avaliacao_id
                    });

                    const questao_id = response.data[0];

                    if (questaoFiles.length > 0) {
                        const formData = new FormData();

                        for (const file of questaoFiles) {
                            formData.append("file", file);
                        }
    
                        formData.append("questao_id", JSON.stringify(questao_id));
    
                        await api.post("/conteudos", formData);
                    }
                   
                } else if (questao.questao_id && !questao.remover) {

                    const filesExistentesQuestao = files.filter(
                        (file) => file.index === index && file.questao_id
                    );

                    questao.conteudo = filesExistentesQuestao;
                    await api.put(`/avaliacoes/questao/${questao.id}`, { questao });
                    //criar rota para update da questao com seus conteudos.

                    const novoConteudoDaQuestao = files.filter(
                        (file) => file.index === index && !file.questao_id
                    );

                    if (novoConteudoDaQuestao.length > 0) {
                        const formData = new FormData();

                        for (const file of novoConteudoDaQuestao) {
                            formData.append("file", file);
                        }

                        formData.append(
                            "questao_id",
                            JSON.stringify(questao.questao_id)
                        );

                        await api.post("/conteudos", formData);
                    }
                } else if (questao.questao_id && questao.remover) {
                    await api.delete(`/avaliacoes/questao/${questao.questao_id}`);
                }
            }

            await api.put(`/avaliacoes/${id}`, { avaliacao });
            enqueueSnackbar("Avaliação editada com sucesso!", {
                variant: "success",
            });
            history.push('/admin/avaliacoes')
            setLoading(false)

            // history.push("/admin/avaliacoes");
        } catch (error) {
            setLoading(false)
            if (error.response) {
            console.log("🚀 ~ file: AvaliacaoEditar.js ~ line 231 ~ updateAvaliacao ~ error", error)
                enqueueSnackbar("Falha ao editar avaliação", {
                    variant: "error",
                });
            }
        }
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/avaliacoes"
                >
                    Avaliações
                </Link>
                <Typography className={classes.breadCrumb}>
                    Editar avaliação
                </Typography>
            </Breadcrumbs>
            <Typography variant="h3" component="h1">
                Editar avaliação
            </Typography>

            {loading ? (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "80vh" }}
                >
                    <Grid item xs={3}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : (
                <div className={classes.column}>
                    <Tabs
                        variant={isSmallScreen && "fullWidth"}
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab value={0} label="Cadastro" />
                        <Tab value={1} label="Questões" />
                    </Tabs>

                    <TabPanel value={tabValue} index={0}>
                        <AvaliacaoCadastro
                            avaliacao={avaliacao}
                            setAvaliacao={setAvaliacao}
                            validationErrors={validationErrors}
                            handleInputChange={handleInputChange}
                            trilhas={trilhas}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <AvaliacaoQuestoes
                            setFiles={setFiles}
                            files={files}
                            questoes={questoes}
                            setQuestoes={setQuestoes}
                            questoesChecked={questoesChecked}
                            setQuestoesChecked={setQuestoesChecked}
                        />
                    </TabPanel>

                    <Divider />
                    <div className={classes.saveButton}>
                        <Button
                            onClick={handleBackToPage}
                            className={classes.backButton}
                        >
                            Cancelar
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={updateAvaliacao}
                            disabled={loading}
                        >
                            {loading ? 'Salvando' : 'Salvar'}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AvaliacaoEditar;
