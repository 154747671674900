import React, { useState } from 'react';
import { LoginContainer } from '../Login/style';
import { RedefinaContainer, RedefinaTitleContainer } from './style';
import { TitleCard } from '../MinhasTrilhas/components/ModuloCard/style';
import TextInput from '../../../components/aluno/Inputs/TextInput';
import Button from '../../../components/aluno/Button';
import {
    Link as RouterLink,
    useLocation,
    useHistory,
    useParams,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { phoneMask, removeNonNumericCharacters } from '../../../helpers/masks';
import api from '../../../services/api';

const MudarSenha = () => {
    const history = useHistory();
    const location = useLocation();
    const { phone, codigo_user } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [telefone, setTelefone] = useState('');
    const [loading, setLoading] = useState(false);
    const [codigo, setCodigo] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const handleRecoverAccess = async (event) => {
        try {
            event.preventDefault();
            if (!telefone) {
                enqueueSnackbar(<h4>Preencha o seu telefone!</h4>, {
                    variant: 'error',
                });
            } else {
                setLoading(true);
                await api
                    .post('/envio-sms', { telefone: phoneMask(telefone) })
                    .then(() => {
                        setLoading(false);
                        setCodigo('');
                        history.push(
                            `/verificar_sms/${removeNonNumericCharacters(
                                telefone
                            )}`
                        );
                        enqueueSnackbar(
                            <h4>{`Codigo de recuperação de acesso enviado para: ${telefone}!`}</h4>,
                            { variant: 'success' }
                        );
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error.response) {
                            enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                                variant: 'error',
                            });
                        }
                    });
            }
        } catch (error) {
            enqueueSnackbar(<h4>Tente novamente!</h4>, { variant: 'error' });
        }
    };

    const verificaCodigoSms = async (event) => {
        event.preventDefault();
        try {
            if (codigo) {
                await api.post('/envio_reset_senha', {
                    telefone: phoneMask(phone),
                    codigo_recuperacao: Number(codigo),
                });
                enqueueSnackbar(<h4>Codigo verificado com sucesso</h4>, {
                    variant: 'success',
                });
                history.push(`/resetar_senha/${codigo}`);
            } else {
                enqueueSnackbar(<h4>Preencha todos os dados corretamente!</h4>, {
                    variant: 'error',
                });
            }
        } catch (error) {
            enqueueSnackbar(<h4>Codigo errado</h4>, {
                variant: 'error',
            });
        }
    };

    const handleTrocarSenha = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            if (!password || !confirmPassword) {
                enqueueSnackbar(<h4>Preencha os campos corretamente!</h4>, {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            if (password !== confirmPassword) {
                enqueueSnackbar(<h4>Confirmação de senha incorreta!</h4>, {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            await api.post('/trocar_senha', {
                codigo: codigo_user,
                senha: password,
            });
            enqueueSnackbar(<h4>Senha alterada com sucesso!</h4>, { variant: 'success' });
            history.push('/');
        } catch (error) {
            enqueueSnackbar(<h4>Tente novamente!</h4>, { variant: 'error' });
        }
    };

    return (
        <LoginContainer mudarSenha>
            <RedefinaContainer>
                <RedefinaTitleContainer>
                    {location.pathname === '/esqueci_minha_senha' && (
                        <>
                            <div>
                                <TitleCard>Esqueceu sua senha?</TitleCard>
                                <span>
                                    Digite seu número para receber um código de
                                    confirmação.
                                </span>
                            </div>
                            <form onSubmit={handleRecoverAccess}>
                                <TextInput
                                    label="Telefone"
                                    onChange={(e) => {
                                        setTelefone(e.target.value);
                                    }}
                                    value={phoneMask(telefone)}
                                    validation={telefone.length > 0 ? true : null}
                                />
                                <Button type="submit" big>
                                    Enviar
                                </Button>
                            </form>
                        </>
                    )}
                    {location.pathname === `/verificar_sms/${phone}` && (
                        <>
                            <div>
                                <TitleCard>Recuperação de senha</TitleCard>
                                <span>
                                    Confirme o código que foi enviado para o seu
                                    telefone.
                                </span>
                            </div>
                            <form onSubmit={verificaCodigoSms}>
                                <TextInput
                                    label="Código"
                                    onChange={(e) => {
                                        setCodigo(e.target.value);
                                    }}
                                    value={codigo}
                                    validation={codigo.length > 0 ? true : null}
                                />
                                <Button type="submit" big>
                                    Enviar
                                </Button>
                            </form>
                        </>
                    )}
                    {location.pathname === `/resetar_senha/${codigo_user}` && (
                        <form onSubmit={handleTrocarSenha}>
                            <div>
                                <TitleCard>Altere sua senha abaixo.</TitleCard>
                            </div>
                            <TextInput
                                label="Nova Senha"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                type="password"
                                value={password}
                                validation={password.length > 0 ? true : null}

                            />

                            <TextInput
                                label="Repita a Nova Senha"
                                onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                }
                                type="password"
                                value={confirmPassword}
                                validation={confirmPassword.length > 0 ? true : null}
                            />
                            <Button type="submit" big>
                                Alterar
                            </Button>
                        </form>
                    )}
                </RedefinaTitleContainer>
            </RedefinaContainer>
        </LoginContainer>
    );
};

export default MudarSenha;
