import React from 'react';

import {  Redirect, Route, Switch, useLocation } from 'react-router-dom';

import GlobalStyle from '../assets/theme/globalStyle';
import Menu from '../components/aluno/Menu';
import PrivateRoute from '../components/aluno/PrivateRoute/PrivateRoute';
import VideoPlayer from '../components/aluno/VideoPlayer';
import Contato from '../pages/aluno/AreaDoAluno/Contato';
import MeuHistorico from '../pages/aluno/AreaDoAluno/MeuHistorico';
import MeusDados from '../pages/aluno/AreaDoAluno/MeusDados';
import MeusFavoritos from '../pages/aluno/AreaDoAluno/MeusFavoritos';
import MinhasAvaliacoes from '../pages/aluno/AreaDoAluno/MinhasAvaliacoes';
import MeusCertificados from '../pages/aluno/AreaDoAluno/MeusCertificados';
import Avaliacao from '../pages/aluno/Avaliacao';
import Certificado from '../pages/aluno/Certificado';
import Login from '../pages/aluno/Login';
import MinhaTrilhas from '../pages/aluno/MinhasTrilhas';
import MudarSenha from '../pages/aluno/MudarSenha';
import EsqueciMinhaSenha from '../pages/aluno/EsqueciMinhaSenha';
import Trilha from '../pages/aluno/Trilha';
import ScrollToTop from "./helper/ScrollToTop";

function RoutesAluno() {
    const location = useLocation();
    // console.log("🚀 ~ file: RoutesAluno.js ~ line 25 ~ RoutesAluno ~ location", location)
    return (
        <>
            <GlobalStyle />
            <ScrollToTop />
                <Switch>
                    <Redirect exact from="/" to="/minhastrilhas" />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/login/:hashUsuarioAssist" component={Login} />
                    <Route exact path="/mudar_senha" component={MudarSenha} />
                    <Route exact path="/esqueci_minha_senha" component={EsqueciMinhaSenha} />
                    <Route exact path="/verificar_sms/:phone" component={EsqueciMinhaSenha} />
                    <Route exact path="/resetar_senha/:codigo_user" component={EsqueciMinhaSenha} />
                    
                    <Route exact path="/video" component={VideoPlayer} />
                    <Route exact path="/certificado/:trilhaId" component={Certificado} />
                    <PrivateRoute
                        exact
                        path="/minhastrilhas"
                        component={MinhaTrilhas}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/trilhas/:trilhaId/modulos/:moduloId/"
                        component={Trilha}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/trilhas/:trilhaId/modulos/:moduloId/watching/:conteudoId"
                        component={Trilha}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/avaliacao/:identificadorAvaliacao"
                        component={Avaliacao}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/meusdados"
                        component={MeusDados}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/meuhistorico"
                        component={MeuHistorico}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/meuhistorico/watching/:conteudoId"
                        component={MeuHistorico}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/meusfavoritos"
                        component={MeusFavoritos}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/meusfavoritos/watching/:conteudoId"
                        component={MeusFavoritos}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/minhasavaliacoes"
                        component={MinhasAvaliacoes}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/meuscertificados"
                        component={MeusCertificados}
                        layout={Menu}
                    />
                    <PrivateRoute
                        exact
                        path="/areadoaluno/contato"
                        component={Contato}
                        layout={Menu}
                    />
                </Switch>
        </>
    );
}

export default RoutesAluno;
