import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';
import JellyCloud from "./JellyCloud.ttf"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Poppins";
    src: font-url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
  }

  @font-face {
    font-family: "JellyCloud";
    src: url(${JellyCloud});
  }

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: 'Poppins', sans-serif;
  }

  * {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00000030;
      border-radius: 20px;
    }
  }
  
  body {
      background: ${colors.primaryGradient};
      h1, h2, h3, h4 {
        letter-spacing: 0;
      }

      h1 {
          font-size: 6.5rem;
          line-height: 7.5rem;
          font-weight: 700;
      }

      h2 {
          font-size: 2.8rem;
          line-height: 3.6rem;
          font-weight: 700;
      }

      h3 {
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 300;
      }

      h4 {
        font-size: 1.3rem;
        line-height: 2.0rem;
        font-weight: 300;
      }
      overflow: overlay;
  }
`;

export default GlobalStyle;
