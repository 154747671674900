import React from 'react';
import { colors } from '../../../../assets/theme/colors';
import { IconCheck } from '../../Icons/Icons';
import { Container } from './style';

const Checkbox = ({ name, label, large, center, gap, ...rest }) => (
    <Container gap={gap} large={large} center={center}>
        <input type="checkbox" {...rest} id={name} hidden />
        <label htmlFor={name} className="checkmark">
            <div className="icon-container">
                <IconCheck fill={colors.primary} />
            </div>
            <p>{label}</p>
        </label>
    </Container>
);

export default Checkbox;
