import styled from 'styled-components';

export const BGAttachment = styled.div`
    height: 80px;
    width: 80px;
    background: #382f46;
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: -1;
    transition: all 1s ease;
`;

export const AttachmentsWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    padding: 25px 20px;
    position: relative;
    overflow: hidden;
    width: 48%;
    @media (max-width: 850px) {
        width: 100%;
        padding: 15px 20px;
    }
    &:hover ${BGAttachment} {
        transform: scale(15);
    }
`;

export const IconAttachment = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    height: 80px;
    border-radius: 7px;
    background-color: #382f46;
    & svg path {
        fill: #716a79;
    }
`;

export const TitleSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const TitleAttachment = styled.p`
    font-size: 1.4rem;
    color: #ffffff;
    font-weight: 500;
`;

export const AttachmentDescription = styled.p`
    color: #ffffff;
    opacity: 0.6;
    font-size: 1.2rem;
    font-weight: 200;
`;
