import React from 'react';
import { IconUserPlaceHolder } from '../../../../../../components/aluno/Icons/Icons';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    ButtonImageUpload,
    ImagePlaceHolder,
    ImageUploaded,
    InputImageContainer,
    LabelImageInput,
    TitleAndLabel,
    TitleImageInput,
    ButtonDelete,
} from './style';

const InputFileImage = ({ fileName, image, imageHandler, deleteImageUser }) => {
    return (
        <InputImageContainer>
            {image !== null ? (
                <ImageUploaded image={image} />
            ) : (
                <ImagePlaceHolder>
                    <IconUserPlaceHolder />
                </ImagePlaceHolder>
            )}
            <TitleAndLabel>
                <TitleImageInput>
                    {fileName !== null ? fileName : 'Alterar sua foto'}
                    {image !== null && (
                        <ButtonDelete
                            onClick={deleteImageUser}
                            //htmlFor="inputFileImage"
                        >
                            Remover foto de perfil
                        </ButtonDelete>
                    )}
                </TitleImageInput>
                <input
                    type="file"
                    name="image-upload"
                    id="inputFileImage"
                    accept="image/*"
                    onChange={imageHandler}
                />
                <ButtonImageUpload>
                    <LabelImageInput htmlFor="inputFileImage">
                        SELECIONAR ARQUIVO
                    </LabelImageInput>
                </ButtonImageUpload>
            </TitleAndLabel>
        </InputImageContainer>
    );
};

export default InputFileImage;
