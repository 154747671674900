/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-fallthrough */
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import {
    Breadcrumbs,
    Box,
    CircularProgress,
    Card,
    Grid,
    Button,
    Link,
    Typography,
    CardContent,
    Divider,
    Tabs,
    Tab,
} from '@material-ui/core';
import * as Yup from 'yup';

import getValidationErors from '../../../utils/geValidationErrors';
import ParceiroForm1 from './components/ParceiroForm1';
import ParceiroForm2 from './components/ParceiroForm2';
import api  from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '13px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const ParceirosEdit = (props) => {
    const { match } = props;

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const history = useHistory();

    const [parceiro, setParceiro] = useState({qtd_colaboradores: 0});
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const formRef = useRef(null);
    const [validationErrors, setValidationErrors] = useState({});
    const { id } = match.params;
    const [edit] = useState(id > 0);

    useEffect(() => {
        getParceiro()
    }, []);

    const handleBackToPage = () => {
        history.goBack();
    };

    const getParceiro = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/parceiros/${id}`);
            setParceiro({
                ...response.data,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    const handleStepChange = async (event, newValue) => {
        try {
            if (activeStep === 0) {
                const schema = Yup.object().shape({
                    nome: Yup.string().required('Nome obrigatório'),
                    email_login: Yup.string().required('E-mail para login obrigatório'),
                    email_contato: Yup.string().required('E-mail para contato obrigatório'),
                    telefone: Yup.string().required('Telefoneobrigatório'),
                    cnpj: Yup.string()
                        .min(14, 'Insira um cnpj válido!')
                        .required('Cnpj obrigatório'),
                });
                await schema.validate(parceiro, {
                    abortEarly: false,
                });
            }

            if (activeStep === 1) {
                const schema = Yup.object().shape({
                    cep: Yup.string().required('CEP obrigatório'),
                    endereco: Yup.string().required('Endereço obrigatório'),
                    numero_endereco: Yup.string().required('Número obrigatório'),
                    bairro: Yup.string().required('Bairro obrigatório'),
                    cidade: Yup.string().required('Cidade obrigatório'),
                    estado: Yup.string().required('Estado obrigatório'),
                });

                await schema.validate(parceiro, {
                    abortEarly: false,
                });
            }

            setActiveStep(newValue);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setParceiro({ ...parceiro, [name]: value });
    };

    const handleFinish = async () => {
        try {
            if (activeStep === 0) {
                const schema = Yup.object().shape({
                    nome: Yup.string().required('Nome obrigatório'),
                    email_login: Yup.string().required('E-mail para login obrigatório'),
                    email_contato: Yup.string().required('E-mail para contato obrigatório'),
                    telefone: Yup.string().required('Telefoneobrigatório'),
                    cnpj: Yup.string()
                        .min(14, 'Insira um cnpj válido!')
                        .required('Cnpj obrigatório'),
                });
                await schema.validate(parceiro, {
                    abortEarly: false,
                });
            }

            if (activeStep === 1) {
                const schema = Yup.object().shape({
                    cep: Yup.string().required('CEP obrigatório'),
                    endereco: Yup.string().required('Endereço obrigatório'),
                    numero_endereco: Yup.string().required('Número obrigatório'),
                    bairro: Yup.string().required('Bairro obrigatório'),
                    cidade: Yup.string().required('Cidade obrigatório'),
                    estado: Yup.string().required('Estado obrigatório'),
                });

                await schema.validate(parceiro, {
                    abortEarly: false,
                });
            }

            await api.put(`/parceiros/${id}`, parceiro);

            history.push('/admin/parceiros');

            enqueueSnackbar('Parceiro editado com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const yupValidationErrors = getValidationErors(error);
                setValidationErrors(yupValidationErrors);

                return;
            }

            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '80vh' }}
            >
                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs
                className={classes.breadCrumb}
                aria-label="breadcrumb"
                gutterBottom
            >
                <Link color="inherit" component={RouterLink} to="/admin/">
                    Home
                </Link>
                <Link
                    color="inherit"
                    component={RouterLink}
                    to="/admin/parceiros"
                >
                    Parceiros
                </Link>
                <Typography className={classes.breadCrumb}>
                    Novo Parceiro
                </Typography>
            </Breadcrumbs>
            <Typography style={{ marginTop: 16 }} variant="h3" component="h1">
                Novo Parceiro
            </Typography>

            <>
                <Tabs
                    value={activeStep}
                    onChange={handleStepChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab value={0} label="Dados gerais" />
                    <Tab value={1} label="Endereço" />
                </Tabs>
                <div className={classes.content}>
                    <Card>
                        <CardContent>
                            <TabPanel value={activeStep} index={0}>
                                <ParceiroForm1
                                    formRef={formRef}
                                    parceiro={parceiro}
                                    setParceiro={setParceiro}
                                    validationErrors={validationErrors}
                                    handleInputChange={handleInputChange}
                                    edit={edit}
                                />
                            </TabPanel>
                            <TabPanel value={activeStep} index={1}>
                                <ParceiroForm2
                                    formRef={formRef}
                                    parceiro={parceiro}
                                    setParceiro={setParceiro}
                                    validationErrors={validationErrors}
                                    handleInputChange={handleInputChange}
                                    edit={edit}
                                />
                            </TabPanel>

                            <Divider />

                            <div className={classes.alignRight}>
                                <Button
                                    color="default"
                                    onClick={handleBackToPage}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleFinish()}
                                    disabled={loading}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </>
        </div>
    );
};

export default ParceirosEdit;
