/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { IconPlay } from '../Icons/Icons';
import { Container } from './style';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ breadcrumbs, ...rest }) => {
    const { link1, nome1, link2, nome2, link3, nome3, label } = breadcrumbs;

    return (
        <Container {...rest}>
            <li>
                <Link to={link1}>{nome1}</Link>
            </li>
            {link2 && (
                <>
                    <IconPlay fill="#ffffff" />

                    <li>
                        <Link to={link2}>{nome2}</Link>
                    </li>
                </>
            )}
            {link3 && (
                <>
                    <IconPlay fill="#ffffff" />

                    <li>
                        <Link to={link3}>{nome3}</Link>
                    </li>
                </>
            )}
            {label && (
                <>
                    <IconPlay fill="#ffffff" />
                    <li>{label}</li>
                </>
            )}
        </Container>
    );
};

export default Breadcrumb;
