/* eslint-disable no-shadow */

import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
    Button,
    Card,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableContainer,
    CardContent,
    TableBody,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Grid,
    TextField,
} from '@material-ui/core';

import api from '../../../../services/api';

const GerenciarUsuariosDialog = (props) => {
    const [usuario, setUsuario] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const {
        setUsuariosFilter,
        handleUsuariosFilter,
        usuariosFilter,
        openGerenciarUsuarios,
        handleCloseGerenciarUsuarios,
        grupoSelecionado,
        qtdUsuariosSelecionados,
        setQtdUsuariosSelecionados,
        usuariosChecked,
        setUsuariosChecked,
        usuarios,
        idSelected,
        classes,
        loading,
        loading2,
        setOpenGerenciarUsuarios
    } = props;

    const getUsuario = async () => {
        const response = await api.get('/usuarios');
        const itens = response.data;
        setUsuario(itens.filter((item) => item.nome));
    };

    useEffect(() => {
        getUsuario();
    }, []);

    const handleChangeUsuariosChecked = (event) => {
        setUsuariosChecked({
            ...usuariosChecked,
            [event.target.name]: event.target.checked,
        });

        setQtdUsuariosSelecionados((prevState) =>
            event.target.checked === true ? prevState + 1 : prevState - 1
        );
    };

    const HandleCheckAllUsuarios = (event) => {
        let update = { ...usuariosChecked };
        usuarios.forEach((item) => {
            update = { ...update, [item.usuario_id]: event.target.checked };
        });

        setQtdUsuariosSelecionados(() =>
            event.target.checked === true ? usuarios.length : 0
        );
        setUsuariosChecked({ ...update });
    };

    const handleGerenciarUsuarios = async () => {
        try {
            await api.post(`/grupos/usuarios/${idSelected}`, {
                usuariosChecked,
            });
            setOpenGerenciarUsuarios(false)
            enqueueSnackbar('Usuarios associados com sucesso!', {
                variant: 'success',
            });
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <Dialog
            scroll="paper"
            fullWidth
            maxWidth="sm"
            open={openGerenciarUsuarios}
            onClose={handleCloseGerenciarUsuarios}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Gerenciar usuarios</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Selecione abaixo os usuários pertencentes ao grupo{' '}
                    <b>{grupoSelecionado.nome ?? ''}.</b>
                    <p style={{ marginTop: 8, marginBottom: '-9%' }}>
                        Usuários ativos: {qtdUsuariosSelecionados}
                    </p>
                </DialogContentText>

                <Backdrop className={classes.backdrop} open={loading2}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box mt={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={9} sm={5} alignItems="center">
                            <Autocomplete
                                getOptionLabel={(usuario) => usuario.nome}
                                options={usuario}
                                onChange={(event, newValue) => {
                                    setUsuariosFilter({
                                        ...usuariosFilter,
                                        nome: newValue?.nome ?? '',
                                    });
                                    console.log(
                                        '🚀 ~ file: GerenciarUsuariosDialog.js ~ line 144 ~ GerenciarUsuariosDialog ~ event',
                                        event
                                    );
                                    console.log(
                                        '🚀 ~ file: GerenciarUsuariosDialog.js ~ line 144 ~ GerenciarUsuariosDialog ~ newValue',
                                        newValue
                                    );
                                }}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.autocomplete}
                                        label="Nome"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container xs={1} alignItems="center">
                            <Button
                                onClick={handleUsuariosFilter}
                                variant="contained"
                                color="primary"
                                size="medium"
                            >
                                <SearchIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Card>
                    <CardContent style={{ padding: 0 }}>
                        <TableContainer
                            className={classes.container}
                            style={{ maxHeight: '9%' }}
                        >
                            <PerfectScrollbar>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '5%' }}>
                                                <Checkbox
                                                    color="primary"
                                                    onChange={
                                                        HandleCheckAllUsuarios
                                                    }
                                                    inputProps={{
                                                        'aria-label':
                                                            'secondary checkbox',
                                                    }}
                                                    checked={
                                                        qtdUsuariosSelecionados ===
                                                        usuarios.length
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>Usuários</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading ? (
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    colSpan={2}
                                                >
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <>
                                                {usuarios.map((usuario) => (
                                                    <TableRow
                                                        hover
                                                        key={usuario.usuario_id}
                                                    >
                                                        <TableCell>
                                                            <Checkbox
                                                                value={
                                                                    usuario.usuario_id
                                                                }
                                                                name={
                                                                    usuario.usuario_id
                                                                }
                                                                checked={
                                                                    !!usuariosChecked[
                                                                        usuario
                                                                            .usuario_id
                                                                    ]
                                                                }
                                                                onChange={
                                                                    handleChangeUsuariosChecked
                                                                }
                                                                color="primary"
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'secondary checkbox',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {usuario.nome}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </PerfectScrollbar>
                        </TableContainer>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions style={{ marginTop: 16 }}>
                <Button onClick={handleCloseGerenciarUsuarios} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleGerenciarUsuarios} color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GerenciarUsuariosDialog;
