/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    CopyrightContainer,
    FormContainer,
    InputsWrapper,
    LeftBG,
    LembrarSenha,
    LoginContainer,
    RightContainer,
} from './style';
import { useUsuarioContext } from '../../../contexts/UserContext';
import TextInput from '../../../components/aluno/Inputs/TextInput';
import Checkbox from '../../../components/aluno/Inputs/Checkbox';
import Button from '../../../components/aluno/Button';
import { useSnackbar } from 'notistack';
import { setAuthSession, getUsuarioId } from '../../../services/auth';
import api from '../../../services/api';
import {
    LogoTreinaDelta,
    GrupoDeltaLogo,
} from '../../../components/aluno/Icons/Icons';

import moment from 'moment-timezone'

export default function Login({ ...rest }) {
    const history = useHistory();
    const { hashUsuarioAssist } = useParams();
    const [validationUsuario, setValidationUsuario] = useState(null);
    const [validationSenha, setValidationSenha] = useState(null);
    const [credentials, setCredentials] = useState({ usuario: '', senha: '' });
    const { setImagemUsuario } = useUsuarioContext();

    const { enqueueSnackbar } = useSnackbar();

    const verificaLoginUsuarioAssist = async (emailMd5) => {
        try {   
            const response = await api.get('/autenticar_assist', {
                params: {
                    emailMd5
                }
            })

            if (response?.data?.token?.length > 0) {
                setAuthSession(
                    response.data.token,
                    response.data.usuario_id,
                    response.data.acessos,
                    response.data.arquivo
                );
                api.post('/usuarios/insertLog/', {
                    acao: 'tempo_logado',
                    usuario_id: getUsuarioId(),
                    data_inicio: moment().format('YYYY/MM/DD HH:mm:ss'),
                })
                history.push({
                    pathname: '/',
                    trocar_senha: false
                });
            } else {
                history.push('/login');
            }
        } catch(error) {
            console.log("🚀 ~ file: index.js ~ line 74 ~ verificaLoginUsuarioAssist ~ error", error)
        }
    }

    //login automático para usuários do assist com MD5 do email e tempo de expiração do hash de 1 hora.
        if (hashUsuarioAssist) {
            const emailMd5 = hashUsuarioAssist.slice(0, hashUsuarioAssist.length-20)
            
            let dataCriacaoHash = hashUsuarioAssist.slice(hashUsuarioAssist.length-20, hashUsuarioAssist.length-8)
            dataCriacaoHash = moment(dataCriacaoHash, 'YYYYMMDDHHmm').subtract(3, 'hours').format('YYYY/MM/DD HH:mm')
            
            const duration = moment.duration(moment().diff(dataCriacaoHash));
            const tempoDeExistenciaHashEmHoras = duration.asHours();
            
            if (tempoDeExistenciaHashEmHoras < 1) {
                verificaLoginUsuarioAssist(emailMd5)
            }
        }
        
    useEffect(() => {
        if (credentials.usuario !== '') {
            setValidationUsuario(true);
        } else {
            setValidationUsuario(null);
        }
    }, [credentials.usuario]);

    useEffect(() => {
        if (credentials.senha !== '') {
            setValidationSenha(true);
        } else {
            setValidationSenha(null);
        }
    }, [credentials.senha]);

    const handleLogin = (event) => {
        console.log('ghandle o');
        event.preventDefault();

        // setLoading(true);
        api.post('/autenticar', {
            usuario: credentials.usuario,
            senha: credentials.senha,
        })
            .then((response) => {
                setCredentials({ usuario: '', senha: '' });
               
                setImagemUsuario(response.data.arquivo);
                setAuthSession(
                    response.data.token,
                    response.data.usuario_id,
                    response.data.acessos,
                    response.data.arquivo
                );
                api.post('/usuarios/insertLog/', {
                    acao: 'tempo_logado',
                    usuario_id: getUsuarioId(),
                    data_inicio: moment().format('YYYY/MM/DD HH:mm:ss'),
                })
                history.push('/');
            })
            .catch(() => {
                enqueueSnackbar(<h4>Usuário ou senha inválidos</h4>, {
                    variant: 'error',
                });
                setValidationSenha(false);
                setValidationUsuario(false);
            });
    };

    return (
        <LoginContainer {...rest}>
            <LeftBG />
            <RightContainer>
                <div />
                <FormContainer>
                    <LogoTreinaDelta />
                    <form onSubmit={handleLogin}>
                        <InputsWrapper>
                            <TextInput
                                label="Usuário"
                                id="usuario"
                                validation={validationUsuario}
                                value={credentials.usuario}
                                onChange={(e) =>
                                    setCredentials({
                                        ...credentials,
                                        usuario: e.target.value,
                                    })
                                }
                            />
                            <TextInput
                                id="senha"
                                label="Senha"
                                type="password"
                                validation={validationSenha}
                                value={credentials.senha}
                                onChange={(e) =>
                                    setCredentials({
                                        ...credentials,
                                        senha: e.target.value,
                                    })
                                }
                            />
                        </InputsWrapper>
                        <LembrarSenha>
                            <Checkbox
                                name="lembrarSenha"
                                label="Lembrar senha"
                                gap="10px"
                                center
                            />
                        </LembrarSenha>

                        <Button type="submit" primary big>
                            Entrar
                        </Button>
                    </form>
                    <a href="/esqueci_minha_senha">Esqueci minha senha</a>
                </FormContainer>
                <CopyrightContainer>
                    <GrupoDeltaLogo />
                    <span>
                        © {new Date().getFullYear()} Treina Delta by Delta Global.
                        <br />
                        Todos os direitos reservados
                    </span>
                </CopyrightContainer>
            </RightContainer>
        </LoginContainer>
    );
}
