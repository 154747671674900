import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../assets/theme/colors';

export const MenuContainer = styled.div`
    position: absolute;
    top: 72px;
    z-index: 5;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 70px;
    height: 60px;
    max-width: 1440px;
    @media (max-width: 1060px) {
        top: 42px;
        padding: 0 40px;
    }
    @media (max-width: 502px) {
        top: 32px;
        padding: 0 14px;
    }
`;

export const LeftSideMenu = styled.div`
    display: flex;
    gap: 40px;
    width: 70%;
    height: fit-content;
    & > svg:first-child {
        margin-right: 25px;
    }
    & > svg:nth-child(2) {
        display: none;
    }

    @media (max-width: 1060px) {
        & > svg:first-child {
            display: none;
        }
        & > svg:nth-child(2) {
            display: block;
            margin-right: 0;
        }
    }

    @media (max-width: 840px) {
        & > svg:first-child {
            display: block;
            margin-top: 8px;
            margin-right: 0;
        }
        & > svg:nth-child(2) {
            display: none;
        }
    }

    @media (max-width: 520px) {
        & > svg:first-child {
            display: none;
        }
        & > svg:nth-child(2) {
            display: block;
            margin-right: 0;
        }
    }

    @media (max-width: 520px) {
        & > svg:nth-child(2) {
            transform: scale(0.9);
        }
    }

    @media (max-width: 300px) {
        & > svg:nth-child(2) {
            transform: scale(1);
            width: 90px;
            margin-top: 5px;
        }
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
        width: 0;
        & > svg:nth-child(2) {
            margin-left: -24px;
        }
    }
`;

export const LinkMenu = styled(NavLink)`
    color: white;
    font-size: 1.6rem;
    line-height: 1;
    align-self: flex-end;
    text-decoration: none;
    &.selected {
        opacity: 0.6;
    }
    @media (max-width: 1060px) {
        align-self: center;
    }
    @media (max-width: 840px) {
        display: none;
    }
`;

export const NotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NotificationDate = styled.p`
    color: #33324760;
    font-size: 1rem;
    font-weight: 500;
`;

export const NotificationTitle = styled.h4`
    color: #333247;
    font-size: 1.3rem;
`;

export const NotificationText = styled.p`
    font-size: 1.1rem;
    color: #858491;
`;

export const RightSideMenu = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: -11px;
    @media (max-width: 1060px) {
        margin-top: -8px;
    }
`;

export const SearchButton = styled.div`
    cursor: pointer;
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg path {
        fill: #ffffff40;
        transition: all 0.3s ease;
    }
    &:hover svg path {
        fill: #ffffff90;
    }
`;

export const UserContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 2px;
    align-self: center;
    margin-left: 30px;
    margin-top: -7px;
    cursor: pointer;
    @media (max-width: 840px) {
        display: none;
    }
`;

export const NameAndXpContainer = styled.div`
    display: flex;
    flex-direction: column;
    //align-self: flex-end;
`;

export const UserName = styled.h3`
    color: #fff;
    font-size: 1.6rem;
    text-align: right;
    white-space: nowrap;
    @media (max-width: 840px) {
        font-weight: 600;
    }
`;

export const Xp = styled.p`
    color: ${colors.primary};
    font-size: 1.2rem;
    font-weight: 600;
    text-align: right;
`;

export const MenuUserContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 13px;
    & svg {
        width: 18px;
    }
    & svg path {
        fill: #c4c4c4;
    }
`;

export const ItemUserMenu = styled.p`
    color: ${colors.secondary};
    font-size: 1.3rem;
`;

export const Footer = styled.footer`
    background: #0000001a;
    display: flex;
    flex-direction: column;
    padding: 70px 0 50px;
    gap: 60px;

    @media (max-width: 450px) {
        gap: 40px;
        padding: 70px 0px 50px;
    }
`;

export const FooterSections = styled.div`
    display: flex;
    align-items: center;
    gap: 16%;
    @media (max-width: 1000px) {
        gap: 10%;
    }
    @media (max-width: 720px) {
        gap: 20px;
    }
    @media (max-width: 650px) {
        flex-direction: column;
        gap: 30px;
    }
    @media (max-width: 450px) {
        gap: 20px;
    }
`;

export const SectionGrupoDelta = styled.div`
    display: flex;
    gap: 40px;
    align-items: center;
    & > svg {
        min-width: 173px;
        max-width: 173px;
        min-height: 57px;
        max-height: 57px;
    }
    @media (max-width: 450px) {
        flex-direction: column;
    }
`;

export const GrupoDeltaParagraph = styled.p`
    color: #fcfcfc99;
    font-size: 1.2rem;
    max-width: 305px;
    min-width: 205px;
    @media (max-width: 450px) {
        text-align: center;
    }
`;

export const SectionFooterNav = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

export const NavLinkFooter = styled(LinkMenu)`
    font-size: 1.6rem;
    align-self: flex-start;
    white-space: nowrap;
`;

export const SectionSocial = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 650px) {
        flex-direction: row;
        gap: 40px;
    }
`;

export const SocialLinks = styled.a`
    display: flex;
    text-decoration: none;
    font-size: 1.3rem;
    color: #fcfcfc;
    gap: 10px;
    align-items: center;
    & p {
        font-size: 1.3rem;
    }
    & svg path {
        fill: ${colors.primary};
    }

    @media (max-width: 450px) {
        padding: 10px;
        & p {
            display: none;
        }
    }
`;

export const CopyrightFooter = styled.p`
    font-size: 1.1rem;
    color: #fcfcfc;
    margin: 0 auto;
    opacity: 0.6;
    font-weight: 200;
    text-align: center;
`;

export const LinkMenuDrop = styled(Link)`
    color: #333247;
    text-decoration: none;
    pointer-events: auto;
`;

export const AreaDoAlunoContainer = styled.div`
    display: flex;
    gap: 75px;
    padding: 220px 75px;
    @media (max-width: 1060px) {
        padding: 220px 40px 100px;
        gap: 40px;
    }
    @media (max-width: 300px) {
        padding: 220px 14px 100px;
    }
`;

var items = [colors.primary, colors.tertiary, colors.quaternary];

export const DefaultSmileyFace = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    background-color: ${items[Math.floor(Math.random() * items.length)]};
`;

export const Row = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: ${({ footer }) => (footer ? '0 70px' : '')};
    @media (max-width: 450px) {
        padding: ${({ footer }) => (footer ? '0 20px' : '')};
        gap: ${({ footer }) => (footer ? '40px' : '')};
    }
`;

export const ImgUserContainer = styled.div`
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: url(${({ src }) => (src ? src : '')});
    background-position: center;
    background-size: cover;
`;
