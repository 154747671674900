import styled from "styled-components";

export const InputTextArea = styled.textarea`
    font-size: 1.6rem;
    border: 0;
    outline: 0;
    resize: none;
    width: 100%;
    border-bottom: ${({ secondary }) =>
        secondary ? "1px solid #ffffff19" : "1px solid #e1e1e3"};
    height: 35px;
    background: transparent;
    color: ${({ secondary }) => (secondary ? "#ffffff" : "")};
    font-weight: ${({ secondary }) => (secondary ? "200" : "")};
    &::placeholder {
        font-style: italic;
        color: ${({ secondary, contato }) => (contato ? "#ffffff33" : secondary ? "#ffffff90" : contato ? "#ffffff99" : "")};
        font-weight: ${({ secondary }) => (secondary ? "200" : "")};
    }
`;
