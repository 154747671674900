import React from "react";
import { IconTime } from "../../../../../components/aluno/Icons/Icons";
import Tag from "../../../../../components/aluno/Tag";
import {
    CardContainer,
    HoursContainer,
    ImgCard,
    InfoCardContainer,
    TitleCard,
    TitleTagWrapper,
} from "./style";
import defaultImg from "../../../../../assets/img/DefaultCard.png";

const ModuloCard = ({ image, title, reviewed, tag, hours }) => {
    return (
        <CardContainer>
            <ImgCard image={image} defaultImg={defaultImg} />
            <TitleTagWrapper>
                <TitleCard>{title}</TitleCard>
                <InfoCardContainer>
                    <Tag reviewed={reviewed}>{tag}</Tag>
                    <HoursContainer>
                        <IconTime />
                        <span>{hours}</span>
                    </HoursContainer>
                </InfoCardContainer>
            </TitleTagWrapper>
        </CardContainer>
    );
};

export default ModuloCard;
