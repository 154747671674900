import styled from "styled-components";

export const ModalBlack = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    background: #000000;
    opacity: 0.6;
    left: 0;
    top: 0;
    display: ${({ open }) => (open ? "block" : "none")};
    z-index: 10;
    opacity: 0;
    animation-name: appearBlocker;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    z-index: 5;

    @keyframes appearBlocker {
        100% {
            opacity: 0.6;
        }
    }
    @keyframes disappearBlocker {
        0% {
            opacity: 0.6;
        }
        100% {
            opacity: 0;
        }
    }
`;

export const ModalContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${({ open }) => (open ? "flex" : "none")};
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    opacity: 0;
    animation-name: appearModal;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    z-index: 9999;

    @keyframes appearModal {
        100% {
            opacity: 1;
        }
    }
    @keyframes desaappearModal {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;
