import React from 'react';

import {
    Box,
    Grid,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Card,
    CardContent,
    Checkbox,
} from '@material-ui/core';

import IOSSwitch from '../../../../components/admin/IOSwitch';
import CustomTextField from '../../../../components/admin/CustomTextField';

const avaliacaoCadastro = (props) => {
    const {
        avaliacao,
        setAvaliacao,
        validationErrors,
        handleInputChange,
        trilhas,
    } = props;

    return (
        <Box p={2}>
            <div m={1.25}>
                <Card>
                    <CardContent>
                        <Grid
                            container
                            spacing={2}
                            style={{ marginLeft: '7%', width: '60%' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={!!avaliacao.ativo}
                                            value={!!avaliacao.ativo}
                                            onChange={(event) =>
                                                setAvaliacao({
                                                    ...avaliacao,
                                                    ativo: event.target.checked,
                                                })
                                            }
                                            name="status"
                                        />
                                    }
                                    label="Avaliação ativa"
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Grid>

                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    label="Nome"
                                    name="nome"
                                    InputLabelProps={{ shrink: true }}
                                    margin="normal"
                                    value={avaliacao.nome}
                                    error={validationErrors.nome}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    label="Descrição"
                                    name="conteudo"
                                    margin="normal"
                                    multiline="true"
                                    InputLabelProps={{ shrink: true }}
                                    value={avaliacao.conteudo}
                                    error={validationErrors.Alert}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    id="standard-number"
                                    label="Número de tentativas"
                                    name="tentativas"
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    margin="normal"
                                    value={avaliacao.tentativas}
                                    error={validationErrors.Alert}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <CustomTextField
                                    id="time"
                                    value={avaliacao.duracao}
                                    name="duracao"
                                    label="Duração"
                                    style={{ width: '90%', marginTop: 16 }}
                                    type="time"
                                    onChange={handleInputChange}
                                    defaultValue="00:00"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={5}>
                                <CustomTextField
                                    select
                                    label="Trilhas"
                                    name="trilha_id"
                                    value={Number(avaliacao.trilha_id)}
                                    error={validationErrors.perfil}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    fullWidth
                                >
                                    {trilhas.map((categoria) => (
                                        <MenuItem value={categoria.trilha_id}>
                                            {categoria.nome}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid> */}
                            {/* <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ marginLeft: '7%' }}
                            >
                                <FormControlLabel
                                    value={!!avaliacao.questoes_alternativas}
                                    control={
                                        <Checkbox
                                            value={
                                                !!avaliacao.questoes_alternativas
                                            }
                                            name="questoes_alternativas"
                                            checked={
                                                !!avaliacao.questoes_alternativas
                                            }
                                            onChange={(event) => {
                                                setAvaliacao({
                                                    ...avaliacao,
                                                    questoes_alternativas:
                                                        event.target.checked,
                                                });
                                            }}
                                            color="primary"
                                            inputProps={{
                                                'aria-label':
                                                    'secondary checkbox',
                                            }}
                                        />
                                    }
                                    label="Exibe resposta certa/errada?"
                                    labelPlacement="start"
                                />
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Box>
    );
};

export default avaliacaoCadastro;
