import React from 'react'
import {
    CertificadoContainer,
    CertificadoContent,
    TextCertificadoContainer,
    CertificadoInfos,
    SignaturesContainer,
    Sign,
    CertificadoFooter,
} from './style';

import {
    CertificadoSide,
    TreinaDeltaBlue,
    DeltaGlobalColored,
} from '../../../components/aluno/Icons/Icons';
import moment from 'moment'

export default function CertificadosView({dadosCertificado, possuiCertificado}) {
    return (
        <CertificadoContainer id="capture">
            <CertificadoSide /> {/*SVG CIRCULOS ESQUERDA*/}
            <CertificadoContent>
                {' '}
                {/* CONTAINER CENTRAL */}
                <TreinaDeltaBlue />
                {possuiCertificado ?
                    <>
                        <TextCertificadoContainer>
                            <p>Certificamos que</p>
                            <span>{dadosCertificado.nome_aluno}</span>
                            <p>
                                concluiu a trilha {' '}
                                <span>{dadosCertificado.nome_trilha}</span>.
                                {/* concluiu o módulo{' '}
                                <span>{dadosCertificado.nome_modulo}</span>,
                                da trilha
                                <br /> <span>{dadosCertificado.nome_trilha}</span> */}
                                {/* do curso{' '}
                                <span>Gestão de pessoas</span> da{' '}
                                <span>Neo Tech.</span> */}
                            </p>
                        </TextCertificadoContainer>
                        <CertificadoInfos>
                            <p>
                                Carga horária: <span>{moment
                                .duration(
                                    dadosCertificado.carga_horaria,
                                    'seconds'
                                )
                                .humanize()}</span>
                            </p>
                            <p>
                                Data de inicio: <span>{dadosCertificado.data_inicio}</span>
                            </p>
                            <p>
                                Data de conclusão: <span>{dadosCertificado.data_conclusao}</span>
                            </p>
                        </CertificadoInfos>
                        <SignaturesContainer>
                            <div>
                                <Sign>Julio Santos</Sign>
                                <span>Julio Santos</span>
                                <p>Responsável do Módulo</p>
                            </div>
                            <div>
                                <Sign>Marzon Castilho</Sign>
                                <span>Marzon Castilho</span>
                                <p>Responsável Técnico do Treina Delta</p>
                            </div>
                        </SignaturesContainer>
                        <CertificadoFooter>
                            <DeltaGlobalColored />
                            Este certificado foi emitido por Treina Delta,
                            cursos de aperfeiçoamento, <br />
                            desenvolvido por Delta Global SA.
                        </CertificadoFooter>
                    </>

                    : 
                    <TextCertificadoContainer>
                            <p>Você não possui certificado para este módulo!</p>
                    </TextCertificadoContainer>
                }
            </CertificadoContent>{' '}
            {/* CONTAINER CENTRAL FIM */}
            <CertificadoSide /> {/*SVG CIRCULOS DIREITA*/}
        </CertificadoContainer>
    )
}
