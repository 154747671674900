import styled from 'styled-components';

export const MinhaTrilhaContainer = styled.div`
    padding: 200px 70px 100px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    min-height: 100vh;
    @media (max-width: 1060px) {
        padding: 200px 40px 100px;
    }
    @media (max-width: 502px) {
        padding: 200px 5% 100px;
    }
`;

export const MaskedBG = styled.div`
    height: 100%;
    background-image: url(${({ image }) => image || ''}),
        url(${({ defaultImage }) => defaultImage || ''});
    -webkit-mask-image: linear-gradient(
        to top,
        transparent 20%,
        black 178%
    ) !important;
    mask-image: unset;
    background-size: cover;
    background-repeat: no-repeat;
    //background-position-y: -159px;
    background-position-y: top;
    background-position-x: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 30px;
    & a {
        text-decoration: none;
    }
`;

export const TitleCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
`;
