import styled from 'styled-components';

export const AvaliacaoContainer = styled.div`
    padding: 200px 70px 100px;
    display: flex;
    flex-direction: column;
    //gap: 100px;
    min-height: 100vh;
    @media (max-width: 1060px) {
        padding: 200px 40px 100px;
    }
    @media (max-width: 502px) {
        padding: 200px 5% 100px;
        gap: 30px;
    }
`;

export const QuestoesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    @media (max-width: 1200px) {
        padding-right: 0;
    }
`;

export const SlidingInfo = styled.div`
    position: absolute;
    right: 35px;
    top: 150px;
    @media (max-width: 1200px) {
        position: unset;
        display: none;
    }
`;

export const MobileInfo = styled.div`
    display: none;
    @media (max-width: 1200px) {
        display: block;
    }
`;
