import styled from 'styled-components';

export const TicketContainer = styled.div`
    padding: 30px 30px;
    background: #ffffff0d;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    & button {
        margin-top: 10px;
        align-self: flex-end;
    }
`;

export const TicketHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    color: ${({ status }) => (status === 'Fechado' ? '#ffffff99' : '#fff')};
`;

export const LabelChamado = styled.div`
    font-size: 1.2rem;
    color: #ffffff99;
    display: flex;
    align-items: center;
    gap: 10px;
    & img {
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
        height: 40px;
        width: 40px;
    }
`;

export const TicketContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

export const TicketTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    color: #fff;
    gap: 3px;
`;
