import styled from "styled-components";
import { LinkMenu } from "../../style";

export const ContainerMenuArea = styled.div`
    padding: 22px 43px 42px;
    border-radius: 10px;
    background: #2e2e40;
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 382px;
    gap: 25px;
    height: fit-content;
    min-width: 257px;
    @media (max-width: 840px) {
        display: none;
    }
`;

export const AreaTitle = styled.p`
    font-size: 1.2rem;
    color: #fff;
    opacity: 0.2;
    font-weight: 600;
    align-self: flex-end;
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    & ${LinkMenu} {
        align-self: flex-start;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #32364a;
`;
