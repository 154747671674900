/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    FormControlLabel,
    FormGroup,
    Button,
    TextField,
    MenuItem,
    Breadcrumbs,
    Card,
    CardContent,
    Link,
    Typography,
} from '@material-ui/core';
import JoditEditor from "jodit-react";

import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import CustomTextField from '../../../components/admin/CustomTextField';
import CustomKeyboardDatePicker from '../../../components/admin/CustomKeyboardDatePicker';
import IOSSwitch from '../../../components/admin/IOSwitch';
import api from '../../../services/api';
import { getUsuarioId } from '../../../services/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    breadCrumb: {
        color: theme.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    alignLeft: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
}));

const AlertaCreate = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles();

    const [alert, setAlert] = useState({ 'cadastrador_id': parseInt(getUsuarioId()) });
    const [validationErrors] = useState({});
    const [categorias, setCategorias] = useState([]);
    const [grupos, setGrupos] = useState([])
    const [corpo, setCorpo] = useState('')
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAlert({ ...alert, [name]: value });
    };
    const [config] = useState({
        height: '300px',
        placeholder: 'Digite sua descrição aqui.',
        uploader: {
            url:"https://xdsoft.net/jodit/connector/index.php",
          },
          filebrowser: {
            ajax: {
              url: "https://xdsoft.net/jodit/connector/index.php",
            },
            uploader: {
               url:"https://xdsoft.net/jodit/connector/index.php",
            },
          },
          enableDragAndDropFileToEditor: true,
          uploader: {
            insertImageAsBase64URI: true,

          },
      })

    const getCategorias = async () => {
        try {
            const response = await api.get('/categorias');
            setCategorias(response.data);
            console.log(
                '🚀 ~ file: GrupoList.js ~ line 40 ~ getCategorias ~ response.data.data',
                response.data
            );
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao buscar categoria', {
                    variant: 'error',
                });
            }
        }
    };

    const getGrupos = async () => {
        try {
            const response = await api.get('/grupos')
            setGrupos(response.data)
        } catch (error) {
            enqueueSnackbar('Falha ao buscar grupos', {
                variant: 'error',
            });
        }
    }
    useEffect(() => {
        getCategorias();
        getGrupos()
    }, []);

    const handleCreateAlerta = async () => {
        try {
            await api.post('/alertas', {...alert, conteudo: corpo});
            enqueueSnackbar('Alerta criada com sucesso!', {
                variant: 'success',
            });
            history.push('/admin/alertas');
        } catch (error) {
            if (error.response) {
                enqueueSnackbar('Falha ao criar alerta', {
                    variant: 'error',
                });
            }
        }
    };

    const handleBackToPage = () => {
        history.goBack();
    };

    return (
        <div>
            <Box p={2}>
                <Breadcrumbs
                    className={classes.breadCrumb}
                    aria-label="breadcrumb"
                    gutterBottom
                >
                    <Link color="inherit" component={RouterLink} to="/admin">
                        Home
                    </Link>
                    <Link
                        color="inherit"
                        component={RouterLink}
                        to="/admin/alertas"
                    >
                        alertas
                    </Link>
                    <Typography className={classes.breadCrumb}>
                        Novo alerta
                    </Typography>
                </Breadcrumbs>
                <Typography style={{ padding: 16 }} variant="h3" component="h1">
                    Novo alerta
                </Typography>
                <div className={classes.content}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={alert.ativo}
                                                value={alert.ativo}
                                                onChange={(event) =>
                                                    setAlert({
                                                        ...alert,
                                                        ativo:
                                                            event.target
                                                                .checked,
                                                    })
                                                }
                                                name="status"
                                            />
                                        }
                                        label="Alerta ativo"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        label="Nome"
                                        name="nome"
                                        margin="normal"
                                        value={alert.nome}
                                        error={validationErrors.nome}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        label="Descrição"
                                        name="conteudo"
                                        margin="normal"
                                        multiline="true"
                                        value={alert.descricao}
                                        error={validationErrors.Alert}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid> */}

                                <Grid item xs={12} md={5}>
                                    <CustomKeyboardDatePicker
                                        label="Data de início"
                                        value={
                                            alert.data_inicio
                                                ? alert.data_inicio
                                                : null
                                        }
                                        onChange={(val) =>
                                            setAlert({
                                                ...alert,
                                                data_inicio: val
                                                    ? moment(val).format(
                                                          'YYYY-MM-DD'
                                                      )
                                                    : null,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <CustomKeyboardDatePicker
                                        label="Data de final"
                                        value={
                                            alert.data_fim
                                                ? alert.data_fim
                                                : null
                                        }
                                        onChange={(val) =>
                                            setAlert({
                                                ...alert,
                                                data_final: val
                                                    ? moment(val).format(
                                                          'YYYY-MM-DD'
                                                      )
                                                    : null,
                                            })
                                        }
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        id="standard-number"
                                        label="Quantidade de disparos"
                                        type="number"
                                        name="qtd_disparos"
                                        margin="normal"
                                        value={alert.quantidade}
                                        error={validationErrors.Alert}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        id="standard-number"
                                        label="Intervalo em horas"
                                        name="intervalo"
                                        type="number"
                                        margin="normal"
                                        value={alert.quantidade}
                                        error={validationErrors.Alert}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        id="time"
                                        name="hora_envio"
                                        label="Hora de início"
                                        style={{ width: '90%', marginTop: 16 }}
                                        type="time"
                                        onChange={handleInputChange}
                                        defaultValue="00:00"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </Grid> */}
                                <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        select
                                        label="Categoria"
                                        name="categoria_id"
                                        value={alert.categoria_id}
                                        error={validationErrors.perfil}
                                        onChange={handleInputChange}
                                        margin="normal"
                                        fullWidth
                                    >
                                        {categorias.map((categoria) => (
                                            <MenuItem
                                                value={categoria.categoria_id}
                                            >
                                                {categoria.nome}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <CustomTextField
                                        style={{maxWidth: "586px"}}
                                        select
                                        label="Grupos"
                                        name="grupo_id"
                                        value={alert.grupo_id}
                                        error={validationErrors.perfil}
                                        onChange={handleInputChange}
                                        margin="normal"
                                        fullWidth
                                        >
                                        {grupos.map((grupo) => (
                                            <MenuItem
                                            value={grupo.grupo_id}
                                            >
                                                {grupo.nome}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>
                                <Grid item xs={12} md={5} />

                            </Grid>
                            <Grid style={{marginTop: "4%"}}>
                                <Typography>Descrição</Typography>
                                <JoditEditor
                                    value={alert.conteudo}
                                    placeholder="TEXD"
                                    config={config}
                                    tabIndex={1}
                                    onChange={newContent => setCorpo(newContent)}
                                /> 
                            </Grid>
                            <div className={classes.alignRight}>
                                <Button
                                    color="default"
                                    onClick={handleBackToPage}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={handleCreateAlerta}
                                    variant="contained"
                                    color="primary"
                                >
                                    Salvar
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Box>
        </div>
    );
};

export default AlertaCreate;
