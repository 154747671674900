import styled from "styled-components";

export const MeusDadosContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const InputsWrapperMeusDados = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    & div:nth-child(1) {
        width: calc(50% - 30px);
    }
    & div:nth-child(2) {
        width: calc(50% - 30px);
    }
    & div:nth-child(3) {
        width: calc(50% - 30px);
    }
    & div:nth-child(4) {
        width: calc(50% - 30px);
    }

    @media (max-width: 650px) {
        & div:nth-child(1) {
            width: 100%;
        }
        & div:nth-child(2) {
            width: 100%;
        }
        & div:nth-child(3) {
            width: 100%;
        }
        & div:nth-child(4) {
            width: 100%;
        }
    }
`;
