import React, { useState, useEffect } from 'react';
import NenhumDado from '../../../../components/aluno/NenhumDado';
import {
    AreaDoAlunoPagesContainer,
    SubtitleAreaDoAluno,
    TitleAreaDoAluno,
    TitleSubAreaDoAluno,
    VideosDate,
    VideosDateContainer,
    VideosWrapper,
} from '../style';
import CardAvaliacao from './components/CardAvaliacao';
import imageDefault from '../../../../assets/img/cardImage.jpg';
import api from '../../../../services/api';
import { useSnackbar } from 'notistack';
import { API_HOST } from '../../../../config_servers';
import { LoopCircleLoading  } from 'react-loadingg';

function MinhasAvaliacoes() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const [dadosAvaliacoes, setDadosAvaliacoes] = useState([]);
    const [avaliacaoId, setAvaliacaoId] = useState();
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true)
    const { enqueueSnackbar } = useSnackbar();
    const [usuarioId] = useState(
        localStorage.getItem('@treinadelta/usuario_id')
    );

    useEffect(() => {
        getAvaliacoesFinalizadas(avaliacaoId, false);
    }, []);

    const getAvaliacoesFinalizadas = async () => {
        try {
            const response = await api.get(
                `/avaliacoes/getAvaliacoesFinalizadas/${usuarioId}/`
            );
            setDadosAvaliacoes(response.data);
            console.log(
                '🚀 ~ file: index.js ~ line 38 ~ getAvaliacoesFinalizadas ~ response.data',
                response.data
            );
            setLoading(false)
        } catch (error) {
            if (error.response) {
                enqueueSnackbar(<h4>{error.response.data.error}</h4>, {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <AreaDoAlunoPagesContainer>
            {loading ? <LoopCircleLoading /> :
            <>
            <TitleSubAreaDoAluno>
                <TitleAreaDoAluno>Minhas Avaliações</TitleAreaDoAluno>
                <SubtitleAreaDoAluno>
                    Nas suas Avaliações, é possível acompanhar o resultado dos
                    testes realizados.
                </SubtitleAreaDoAluno>
            </TitleSubAreaDoAluno>
            <VideosDateContainer>
                {/* <VideosDate>23 de Abril de 2021 às 14:34</VideosDate> */}
                <VideosWrapper>
                    {dadosAvaliacoes && dadosAvaliacoes.length > 0 ? dadosAvaliacoes.map((avaliacao) => (
                        <>
                            <CardAvaliacao
                                tituloModulo={avaliacao.nome_modulo}
                                image={`${API_HOST}/assets/conteudos/${avaliacao.imagemModulo}`}
                                tentativa={avaliacao.numero_tentativa}
                                qtdTentativa={avaliacao.tentativas}
                                acertos={avaliacao.qtdAcertos}
                                qtdAcertos={avaliacao.qtdQuestoes}
                                tempoDeResposta={avaliacao.tempoDeResposta}
                                hours={10}
                                isAprovado={avaliacao.isAprovado}
                                breadcrumbs={{
                                    link1: `/minhastrilhas`,
                                    nome1: avaliacao.nome_trilha,
                                    link2: `/trilhas/${avaliacao.trilha_id}/modulos/${avaliacao.modulo_id}/`,
                                    nome2: `${avaliacao.nome_modulo}`,
                                }}
                                moduloId={avaliacao.modulo_id}
                                avaliacao={avaliacao}
                            />
                        </>
                    )) : (
                        <NenhumDado text={`Você ainda não tem nenhuma avaliação`} />
                    )}

                    {/* <CardAvaliacao
                        tituloModulo="Design visual de um site mobile"
                        image={imageDefault}
                        tentativa={2}
                        qtdTentativa={3}
                        acertos={3}
                        qtdAcertos={11}
                        tempoDeResposta="0:01:24"
                        hours={9}
                        breadcrumbs={{
                            link1: `/minhastrilhas`,
                            nome1: 'Minhas Trilhas',
                            label: 'label test',
                        }}
                    /> */}
                </VideosWrapper>
            </VideosDateContainer>
            </>
        }
        </AreaDoAlunoPagesContainer>
    );
}

export default MinhasAvaliacoes;
