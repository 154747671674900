import styled from "styled-components";

export const TitleSubAreaDoAluno = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const TitleAreaDoAluno = styled.h1`
    font-size: ${({ chamado }) => chamado ? "2.6rem" : "4rem"};
    color: white;
    background: -webkit-linear-gradient(-0deg, #8fd2ec 1.01%, #ffffff 68.6%);
    -webkit-background-clip: text !important;
    background-clip: unset;
    -webkit-text-fill-color: transparent;
    line-height: 1.25;
    margin-left: -4px;
    margin-top: ${({ chamado }) => chamado && "30px"};
    @media (max-width: 400px) {
        font-size: 4rem;
    }
`;

export const SubtitleAreaDoAluno = styled.p`
    font-size: 1.6rem;
    font-weight: 200;
    color: #fff;
`;

export const AreaDoAlunoPagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 100%;
    @media (max-width: 650px) {
        gap: 40px;
    }
`;

export const VideosDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const VideosDate = styled.p`
    font-size: 1.6rem;
    color: #fff;
    opacity: 0.6;
    font-weight: 200;
`;

export const VideosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: -20px;

    @media(max-width: 768px) {
        margin-left: 0;
    }
`;
